import React from "react";
import swal from "sweetalert2";
// import {  Link } from "react-router-dom";
import * as $ from "jquery";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import {
  getUserData,
  get,
  create,
  remove,
  hasPermission,
} from "../../../utils";
import NoResults from "../../shared/no-results/no-results";
import "./leaves.scss";
import { WithTranslations } from "../../../i18n/withTranslations";

class LeavesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leaves: [],
      showSection: "leaves",
      holidays: [],

      userType: "",
      leaveType: "add",

      fromDate: "",
      toDate: "",
      leaveReason: "",
      leaveMessage: "",
      leaveID: "",

      holidayDate: "",
      holidayReason: "",
      holidayID: "",

      isMobile: false,
    };

    this.holidayValidator = new SimpleReactValidator({});
    this.leaveValidator = new SimpleReactValidator({});
  }

  onClick(type) {
    this.setState({
      showSection: type,
      leaveType: "add",
      fromDate: "",
      toDate: "",
      leaveReason: "",
      leaveMessage: "",
      leaveID: "",
    });
    if (type === "leaves") {
      this.getLeaves();
    }
    if (type === "holidays") {
      this.getHolidays();
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  saveLeave() {
    if (this.leaveValidator.allValid()) {
      let message = "";
      if (!this.state.leaveID)
        message = "Are you sure want to apply for leave?";
      else message = "Are you sure want to update?";

      swal
        .fire({
          title: "Are you sure?",
          text: message,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((response) => {
          if (response.value) {
            let url = baseUrl + "leaves/";
            if (this.state.leaveID) url += this.state.leaveID;
            else url += "add";

            create(url, this.state.leaveID ? "put" : "post", {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
              reason: this.state.leaveReason,
              message: this.state.leaveMessage,
            })
              .then((response) => {
                this.getLeaves();
                this.setState({
                  fromDate: "",
                  toDate: "",
                  leaveReason: "",
                  leaveID: "",
                  leaveMessage: "",
                  showSection: "leaves",
                  leaveType: "add",
                });
              })
              .catch((error) => {});
          }
        });
    } else {
      this.leaveValidator.showMessages();
      this.forceUpdate();
    }
  }

  approveLeave(leaveID, type, title) {
    swal
      .fire({
        title: "Are you sure?",
        text: "Are you sure want to " + title + "?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((response) => {
        if (response.value) {
          create(baseUrl + "leaves/" + leaveID, "put", {
            status: type,
          })
            .then((response) => {
              this.getLeaves();
              this.setState({
                showSection: "leaves",
                leaveType: "add",
              });
            })
            .catch((error) => {});
        }
      });
  }

  getLeaves() {
    get(baseUrl + "leaves/list").then((response) => {
      this.setState({
        leaves: response.data.data,
      });
    });
  }

  onClickLeave(leave, type) {
    this.setState({
      fromDate: new Date(leave.fromDate).toISOString().substring(0, 10),
      toDate: new Date(leave.toDate).toISOString().substring(0, 10),
      leaveReason: leave.reason,
      leaveMessage: leave.message,
      leaveID: leave._id,
      showSection: "new-leave",
      leaveType: type,
    });
  }

  removeLeave(leaveID) {
    swal
      .fire({
        title: "Are you sure?",
        text: "Want to delete the leave?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((response) => {
        if (response.value) {
          remove(baseUrl + "leaves/" + leaveID).then((response) => {
            this.getLeaves();
          });
        }
      });
  }

  saveHoliday() {
    if (this.holidayValidator.allValid()) {
      let url = baseUrl + "holidays/";
      if (this.state.holidayID) url += this.state.holidayID;
      else url += "add";

      create(url, this.state.holidayID ? "put" : "post", {
        date: this.state.holidayDate,
        reason: this.state.holidayReason,
      })
        .then((response) => {
          this.getHolidays();
          this.setState({
            holidayDate: "",
            holidayReason: "",
            holidayID: "",
          });
          $("#add-holiday").trigger("click");
        })
        .catch((error) => {});
    } else {
      this.holidayValidator.showMessages();
      this.forceUpdate();
    }
  }

  getHolidays() {
    get(baseUrl + "holidays/list").then((response) => {
      this.setState({
        holidays: response.data.data,
      });
    });
  }

  onClickHoliday(holiday) {
    this.setState({
      holidayDate: new Date(holiday.date).toISOString().substring(0, 10),
      holidayReason: holiday.reason,
      holidayID: holiday._id,
    });
  }

  removeHoliday(holidayID) {
    swal
      .fire({
        title: "Are you sure?",
        text: "Want to delete the holiday?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((response) => {
        if (response.value) {
          remove(baseUrl + "holidays/" + holidayID).then((response) => {
            this.getHolidays();
          });
        }
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    this.getLeaves();
    if (window.innerWidth < 767) {
      this.setState({
        isMobile: true,
      });
    }
  }

  render() {
    const { userType, isMobile } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          {this.state.showSection === "leaves" && (
            <div>
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className="">{tid("Leaves")}</h2>
                </div>
                <div className="app-page-header-right">
                  <button
                    className="app-add-option-button"
                    onClick={() => this.onClick("new-leave")}
                  >
                    {tid("Apply Leave")}
                  </button>
                  <button
                    className="app-add-option-button"
                    onClick={() => this.onClick("holidays")}
                  >
                    {tid("Holidays ")}List
                  </button>
                </div>
              </div>
              <div className="table-responsive leaves-list-data-main-container">
                {!isMobile && (
                  <table
                    id="table-extended-transactions"
                    className="table mb-0"
                  >
                    <thead>
                      <tr className="table-header-row">
                        <th>{tid("S.No")}</th>
                        <th>{tid("Status")}</th>
                        {this.state.userType !== "student" && (
                          <th>{tid("Name")}</th>
                        )}
                        {this.state.userType !== "student" && (
                          <th>{tid("Class Name")}</th>
                        )}
                        <th>{tid("Date")}</th>
                        <th>{tid("Reason")}</th>
                        <th>{tid("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.leaves.map((leave, index) => {
                        return (
                          <tr className="table-data-row" key={"leave-" + index}>
                            <td>{index + 1}</td>
                            <td>{leave.status}</td>
                            {this.state.userType !== "student" && (
                              <td>{leave.name}</td>
                            )}
                            {this.state.userType !== "student" && (
                              <td>{leave.className}</td>
                            )}
                            <td>{moment(leave.date).format("DD-MM-YYYY")}</td>
                            <td>{leave.reason}</td>
                            <td className="text-bold-600">
                              <button
                                className="btn btn-success btn-xs"
                                onClick={() => this.onClickLeave(leave, "view")}
                              >
                                {tid("View")}
                              </button>
                              &nbsp;
                              {leave.status === "applied" && (
                                <button
                                  className="btn btn-info btn-xs"
                                  onClick={() =>
                                    this.onClickLeave(leave, "edit")
                                  }
                                >
                                  {tid("Edit")}
                                </button>
                              )}
                              &nbsp;
                              {this.state.userType === "student" && (
                                <button
                                  className="btn btn-danger btn-xs"
                                  onClick={() => this.removeLeave(leave._id)}
                                >
                                  {tid("Remove")}
                                </button>
                              )}
                              {this.state.userType !== "student" &&
                                this.state.userType !== "parent" && (
                                  <button
                                    className="btn btn-danger btn-xs"
                                    onClick={() =>
                                      this.approveLeave(
                                        leave._id,
                                        "rejected",
                                        "reject"
                                      )
                                    }
                                  >
                                    {tid("Reject")}
                                  </button>
                                )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {isMobile && (
                  <div className="app-leaves-xs-list">
                    {this.state.leaves.map((leave, index) => {
                      return (
                        <div className="leave-row" key={"leave-" + index}>
                          <div className="status-badge">{leave.status}</div>
                          {this.state.userType !== "student" && (
                            <p>{leave.name}</p>
                          )}
                          {this.state.userType !== "student" && (
                            <p>{leave.className}</p>
                          )}
                          <div className="xs-leave-base-details">
                            <p>{leave.reason}</p>
                            <p>{moment(leave.date).format("DD-MM-YYYY")}</p>
                          </div>
                          <div className="buttons-row">
                            <button
                              className="btn btn-success btn-xs"
                              onClick={() => this.onClickLeave(leave, "view")}
                            >
                              {tid("View")}
                            </button>
                            &nbsp;
                            {leave.status === "applied" && (
                              <button
                                className="btn btn-info btn-xs"
                                onClick={() => this.onClickLeave(leave, "edit")}
                              >
                                {tid("Edit")}
                              </button>
                            )}
                            &nbsp;
                            {this.state.userType === "student" && (
                              <button
                                className="btn btn-danger btn-xs"
                                onClick={() => this.removeLeave(leave._id)}
                              >
                                {tid("Remove")}
                              </button>
                            )}
                            {this.state.userType !== "student" &&
                              this.state.userType !== "parent" && (
                                <button
                                  className="btn btn-danger btn-xs"
                                  onClick={() =>
                                    this.approveLeave(
                                      leave._id,
                                      "rejected",
                                      "reject"
                                    )
                                  }
                                >
                                  {tid("Reject")}
                                </button>
                              )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {this.state.leaves.length === 0 && (
                  <NoResults title="No Leaves Found" />
                )}
              </div>
            </div>
          )}
          {this.state.showSection === "new-leave" && (
            <div className="">
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className="">
                    {" "}
                    <span
                      className="pointer"
                      onClick={() => this.onClick("leaves")}
                    >
                      <i className="uil-arrow-left"></i>
                    </span>{" "}
                    {this.state.leaveType === "add"
                      ? tid("Apply New Leave")
                      : this.state.leaveType === "view"
                      ?tid("View Leave")
                      : tid("Edit Leave")}
                  </h2>
                </div>
              </div>
              <div className="apply-leave-form-section">
                <div className="card-content">
                  <div className="date-flex-section">
                    <div className="df-left-side">
                      <h5 className="uk-text-bold mb-2">

                        {tid("From Date")}{" "}
                      </h5>
                      <input
                        type="date"
                        className="uk-input"
                        value={this.state.fromDate}
                        name="fromDate"
                        disabled={this.state.leaveType === "view"}
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Date")}
                      />
                      {this.leaveValidator.message(
                        "From Date",
                        this.state.fromDate,
                        "required"
                      )}
                    </div>
                    <div className="df-right-side">
                      <h5 className="uk-text-bold mb-2"> {tid("To Date")} </h5>
                      <input
                        type="date"
                        className="uk-input"
                        value={this.state.toDate}
                        name="toDate"
                        disabled={this.state.leaveType === "view"}
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Date")}
                      />
                      {this.leaveValidator.message(
                        "To Date",
                        this.state.toDate,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="reason-input-section">
                    <h5 className="uk-text-bold mb-2"> {tid("Reason")} </h5>
                    <input
                      type="text"
                      className="uk-input"
                      value={this.state.leaveReason}
                      name="leaveReason"
                      disabled={this.state.leaveType === "view"}
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Reason")}
                    />
                    {this.leaveValidator.message(
                      "Reason",
                      this.state.leaveReason,
                      "required"
                    )}
                  </div>
                  <div className="message-input-section">
                    <h5 className="uk-text-bold mb-2"> {tid("Message")} </h5>
                    <textarea
                      type="text"
                      className=""
                      rows="8"
                      value={this.state.leaveMessage}
                      name="leaveMessage"
                      disabled={this.state.leaveType === "view"}
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Leave Message")}
                    ></textarea>
                    {this.leaveValidator.message(
                      "Message",
                      this.state.leaveMessage,
                      "required"
                    )}
                  </div>
                  <div className="bottom-buttons-section">
                    <button
                      className="btn btn-link mr-2"
                      onClick={() => this.onClick("leaves")}
                    >
                      {tid("Cancel")}
                    </button>
                    {this.state.leaveType !== "view" && (
                      <button
                        className="btn btn-default grey"
                        onClick={() => this.saveLeave()}
                      >
                        {tid("Confirm")} &{" "}
                        {this.state.leaveType !== "edit" ? tid("Apply") : tid("Modify")}
                      </button>
                    )}
                    {this.state.leaveType === "view" &&
                      this.state.userType !== "student" &&
                      this.state.userType !== "parent" && (
                        <button
                          className="btn btn-default grey"
                          onClick={() =>
                            this.approveLeave(
                              this.state.leaveID,
                              "approved",
                              "approve"
                            )
                          }
                        >
                          {tid("Approve")}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.showSection === "holidays" && (
            <div className="">
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className="">
                    <span
                      className="pointer"
                      onClick={() => this.onClick("leaves")}
                    >
                      <i className="uil-arrow-left" />
                    </span>{" "}
                    {tid("Holidays")}
                  </h2>
                </div>
                {hasPermission("holidays", "create") && (
                  <div className="app-page-header-right">
                    <button
                      className="app-add-option-button"
                      data-toggle="modal"
                      data-target="#add-holiday"
                    >
                      {tid("Add New")}
                    </button>
                  </div>
                )}
              </div>
              <section
                id="table-transactions"
                className="holiday-list-table-container"
              >
                <div className="card">
                  <div className="table-responsive">
                    <table
                      id="table-extended-transactions"
                      className="table mb-0"
                    >
                      <thead>
                        <tr className="table-header-row">
                          <th>{tid("S.No")}</th>
                          <th>{tid("Date")}</th>
                          <th>{tid("Details")}</th>
                          <th>{tid("Actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.holidays.map((holiday, index) => {
                          return (
                            <tr
                              className="table-data-row"
                              key={"holiday-" + index}
                            >
                              <td>{index + 1}</td>
                              <td>
                                {moment(holiday.date).format("DD-MM-YYYY")}
                              </td>
                              <td>{holiday.reason}</td>
                              <td className="text-bold-600">
                                {hasPermission("holidays", "update") && (
                                  <button
                                    className="btn btn-info btn-xs"
                                    onClick={() => this.onClickHoliday(holiday)}
                                    data-toggle="modal"
                                    data-target="#add-holiday"
                                  >
                                    {tid("Edit")}
                                  </button>
                                )}
                                &nbsp;
                                {hasPermission("holidays", "remove") && (
                                  <button
                                    className="btn btn-danger btn-xs"
                                    onClick={() =>
                                      this.removeHoliday(holiday._id)
                                    }
                                  >
                                    {tid("Remove")}
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}

                        {this.state.holidays.length === 0 && (
                          <td colSpan="4">
                            <div className="text-center">
                              {tid("No Holidays list found..")}
                            </div>
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>

              <div
                className="modal fade text-left uk-modal-full"
                id="add-holiday"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel1"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h3 className="modal-title" id="myModalLabel1">
                        {tid("Add new holiday")}
                      </h3>
                      <button
                        type="button"
                        className="close rounded-pill"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa fa-close"></i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label className="uk-text-bold mb-2"> Date </label>
                        <input
                          type="date"
                          className="uk-input"
                          value={this.state.holidayDate}
                          name="holidayDate"
                          onChange={this.setFormField.bind(this)}
                          placeholder={tid("Date")}
                        />
                        {this.holidayValidator.message(
                          "Date",
                          this.state.holidayDate,
                          "required"
                        )}
                      </div>
                      <div className="form-group">
                        <label className="uk-text-bold mb-2"> {tid("Details")} </label>
                        <textarea
                          type="text"
                          className="uk-input"
                          rows="2"
                          value={this.state.holidayReason}
                          name="holidayReason"
                          onChange={this.setFormField.bind(this)}
                          placeholder={tid("Holiday Details")}
                        ></textarea>
                        {this.holidayValidator.message(
                          "Reason",
                          this.state.holidayReason,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-link mr-2 btn-sm"
                        data-dismiss="modal"
                      >
                        {tid("Cancel")}
                      </button>
                      <button
                        className="btn btn-default grey btn-sm"
                        onClick={() => this.saveHoliday()}
                      >
                        {tid("Save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WithTranslations(LeavesComponent);
