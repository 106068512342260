import React from "react";
export default class ImageSliderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        let { media } = this.props;
        return (
            <div class=" course-grid-slider width-100 image-slider" uk-slider="finite: true">

                <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-5@m uk-grid-match uk-grid">
                    {media.map((image, index) => {
                    return (<li key={"image-"+index}>
                        <div class="course-card completed np">
                            <div class="course-card-thumbnail mh-100">
                                <img alt="File" className="max-100" src={image.fileUrl}/>
                            </div>
                        </div>
                    </li>)})}
                </ul>

                <a class="uk-position-center-left uk-position-small uk-hidden-hover slidenav-prev" uk-slider-item="previous"> </a>
                <a class="uk-position-center-right uk-position-small uk-hidden-hover slidenav-next"  uk-slider-item="next"> </a>
            </div>
            );
    }
}