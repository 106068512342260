import React from "react";
import swal from 'sweetalert2';
import { Link } from "react-router-dom";

import * as $ from "jquery";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import SimpleReactValidator from 'simple-react-validator';
import { baseUrl } from "../../../config";
// import Loader from "../../../shared/loader";
import { get, create, uploadToS3, getUserData } from "../../../utils";
import Delete from "../../common/Delete";

class ExpensesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expenses: [],
      name: "",
      description: "",
      expenseType: "",
      amount: "",
      expenseDate: "",
      file: "",
      expenseID: ""
    }
    this.uploadFile = this.uploadFile.bind(this);
    this.expenseValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.expenseValidator.allValid()) {

      let url = baseUrl + "expenses/";
      if (!this.state.expenseID)
        url += "add";
      else
        url += this.state.expenseID

      let fileName = "";
      if (this.state.file)
        fileName = "expenses/" + Date.now() + "/" + this.state.file.name;
      new Promise((resolve, reject) => {
        if (this.state.file)
          resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
        else
          resolve();
      })
        .then((response) => {
          return create(url, (this.state.expenseID) ? "put" : "post", {
            name: this.state.name,
            description: this.state.description,
            expenseType: this.state.expenseType,
            amount: this.state.amount,
            expenseDate: this.state.expenseDate,
            fileKey: fileName ? fileName : undefined,
            fileType: (this.state.file && this.state.file.type) ? this.state.file.type.split("/")[0] : undefined,
          })
        })
        .then((response) => {
          this.getExpensesList();
          $("#add-expense").trigger("click");
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning"
          });
        })
        .catch((error) => {

        });
    } else {
      this.expenseValidator.showMessages();
      this.forceUpdate();
    }
  }

  getExpensesList() {

    get(baseUrl + "expenses/list")
      .then((response) => {
        this.setState({
          expenses: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
    })
  }

  componentDidMount() {
    this.assignUserData();
    this.getExpensesList();
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] })
  }

  editExpense(expense) {
    this.setState({
      name: expense.name,
      description: expense.description,
      expenseType: expense.expenseType,
      amount: expense.amount,
      expenseDate: new Date(expense.expenseDate).toISOString().substr(0, 10),
      expenseID: expense._id
    })
  }

  render() {
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className=""><Link to={"/accounts"}><i className="uil-arrow-left"></i></Link> Expenses</h2>
            </div>
            <div className="app-page-header-right">
              {(this.state.userType !== 'expense' && this.state.userType !== 'parent') && <div className="section-header-right">
              <button className="app-add-option-button" data-toggle="modal" data-target="#add-expense"><i className="uil-plus"></i> Add Expense</button>
              </div>}
            </div>
          </div>
          <div className="expenses-list-table-container" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 100">
            <table className="table mb-0">
              <thead>
                <tr className='table-header-row'>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Expense Type</th>
                  <th>Reference</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.expenses.map((expense, index) => {
                  return (<tr key={"expense" + index} className='table-data-row'>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="ml-1"><span className="text-bold-500">{expense.name} </span></div>
                      </div>
                    </td>
                    <td>
                      {expense.description}
                    </td>
                    <td>
                      {expense.amount}
                    </td>
                    <td>
                      {expense.expenseType}
                    </td>
                    <td>
                      {expense.fileUrl && <a target="_blank" href={expense.fileUrl}>Preview</a>}
                    </td>
                    <td>
                      <div className='action-items'>
                        <button type="button" className="btn btn-success btn-sm" data-toggle="modal" data-target="#add-expense" onClick={() => this.editExpense(expense)}><i className='uil-edit' /></button>
<Delete url={`expenses/${expense._id}`} />
                        </div>
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
          <div className="modal fade text-left" id="add-expense" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">Add Expense</h3>
                  <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.name}
                      name="name"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Enter Expense Name"
                    />
                    {this.expenseValidator.message(
                      "Expense Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.description}
                      name="description"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Enter Expense Description"
                    ></textarea>
                    {this.expenseValidator.message(
                      "Expense Description",
                      this.state.description,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>Expense Amount</label>
                    <input
                      type="number"
                      className="form-control form-control-user"
                      value={this.state.amount}
                      name="amount"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Enter Expense Amount"
                    />
                    {this.expenseValidator.message(
                      "Expense Amount",
                      this.state.amount,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>Expense Date</label>
                    <input
                      type="date"
                      className="form-control form-control-user"
                      value={this.state.expenseDate}
                      name="expenseDate"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Enter Expense Date"
                    />
                    {this.expenseValidator.message(
                      "Expense Date",
                      this.state.expenseDate,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>Expense Type</label>
                    <select
                      className="form-control form-control-user"
                      value={this.state.expenseType}
                      name="expenseType"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Enter Expense Type"
                    >
                      <option value="">Expense Type</option>
                      <option value="paid">Amount Paid</option>
                      <option value="received">Amount Received</option>
                    </select>
                    {this.expenseValidator.message(
                      "Expense Expense Type",
                      this.state.expenseType,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>Attachment</label>
                    <div className="choose-file-section">
                      <label httmlFor="addExpenseFile">
                        <input type="file" id="addExpenseFile" onChange={this.uploadFile} />
                        <i className='uil-file' /> Choose File
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-light-secondary btn-xs" data-dismiss="modal">
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">Close</span>
                  </button>&nbsp;
                      <button type="button" className="btn btn-primary ml-1 btn-xs" onClick={() => this.save()}>
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpensesComponent;