import React from "react";
import moment from "moment";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
import { baseUrl } from "../../../config";
import { get, create } from "../../../utils";
import PaymentPieChart from "./payment-pie-chart";
import { WithTranslations } from "../../../i18n/withTranslations";
class StudentPaymentsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: {},

      total: 0,
      paid: 0,
      due: 0,

      dues: [],
      payments: [],
      studentID: "",

      title: "",
      description: "",
      amount: 0,
      dueDate: "",
      classID: "",

      dueID: "",

      paymentID: "",

      transactionID: "",
      comment: "",
      mode: "",
      paidDate: "",
      selectedDueID: "",
    };

    this.dueValidator = new SimpleReactValidator({});
    this.paymentValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.dueValidator.allValid()) {
      let url = baseUrl + "payments/";
      if (this.state.dueID) url += "due/" + this.state.dueID;
      else url += "add-due";

      create(url, this.state.dueID ? "put" : "post", {
        title: this.state.title,
        description: this.state.description,
        amount: this.state.amount,
        userID: this.state.studentID,
        classID: this.state.classID,
        dueDate: this.state.dueDate,
      })
        .then((response) => {
          $("#due").trigger("click");
          this.getHistory(this.state.student);
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.dueValidator.showMessages();
      this.forceUpdate();
    }
  }

  savePayment() {
    if (this.paymentValidator.allValid()) {
      let url = baseUrl + "payments/";
      if (this.state.dueID) url += "due/" + this.state.dueID;
      else url += "add";

      create(url, this.state.dueID ? "put" : "post", {
        transactionID: this.state.transactionID,
        comment: this.state.comment,
        amount: this.state.amount,
        userID: this.state.studentID,
        classID: this.state.classID,
        mode: this.state.mode,
        dueID: this.state.selectedDueID ? this.state.selectedDueID : "",
        paidDate: this.state.paidDate,
      })
        .then((response) => {
          $("#payment").trigger("click");
          this.getHistory(this.state.student);
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.paymentValidator.showMessages();
      this.forceUpdate();
    }
  }

  componentDidMount() {
    let { student, classID } = this.props;
    this.setState({
      student,
      studentID: student._id,
      classID,
      total: student.payment,
      paid: student.paid,
      due: student.due,
    });
    this.getHistory(student);
  }

  getHistory(student) {
    get(baseUrl + "payments/student/" + student._id).then((response) => {
      let { payments, dues } = response.data.data;
      let paid = this.getSum(payments);
      let due = this.getSum(dues);
      this.setState({
        payments,
        dues,
        total: paid + due,
        paid,
        due,
      });
    });
  }

  getSum(list) {
    let sum = 0;
    list.forEach((item) => {
      sum += parseInt(item.amount, 10);
    });
    return sum;
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  render() {
    let { student, tid } = this.props;
console.log(tid);
    return (
      <div class="uk-card uk-card-default row col-lg-12 np ac-payments-due-paid-info-container">
        <div
          className="modal fade text-left"
          id="due"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="myModalLabel1">
                  {tid(`Add Due`)}
                </h6>
                <button
                  type="button"
                  className="close rounded-pill"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group ">
                  <label className="col-form-label">{tid(`Due Title:`)}</label>
                  <input
                    type="text"
                    className="form-control form-control-user"
                    value={this.state.title}
                    name="title"
                    onChange={this.setFormField.bind(this)}
                    placeholder={tid("Due Title")}
                  />
                  {this.dueValidator.message(
                    "Due Title",
                    this.state.title,
                    "required"
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">
                    {tid(`Due Description:`)}
                  </label>
                  <textarea
                    type="text"
                    className="form-control form-control-user"
                    value={this.state.description}
                    name="description"
                    onChange={this.setFormField.bind(this)}
                    placeholder={tid("Due Description")}
                  ></textarea>
                  {this.dueValidator.message(
                    "Due Description",
                    this.state.description,
                    "required"
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">{tid(`Due Amount:`)}</label>
                  <input
                    type="number"
                    className="form-control form-control-user"
                    value={this.state.amount}
                    name="amount"
                    onChange={this.setFormField.bind(this)}
                    placeholder={tid("Due Amount")}
                  />
                  {this.dueValidator.message(
                    "Due Amount",
                    this.state.amount,
                    "required"
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">{tid(`Due Date:`)}</label>
                  <input
                    type="date"
                    className="form-control form-control-user"
                    value={this.state.dueDate}
                    name="dueDate"
                    onChange={this.setFormField.bind(this)}
                    placeholder={tid("Due Date")}
                  />
                  {this.dueValidator.message(
                    "Due Date",
                    this.state.dueDate,
                    "required"
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-secondary btn-xs"
                  data-dismiss="modal"
                >
                  <i className="bx bx-x d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid(`Close`)}</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-1 btn-xs"
                  onClick={() => this.save()}
                >
                  <i className="bx bx-check d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid(`Save`)}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade text-left"
          id="payment"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="myModalLabel1">
                  {tid(`Add Payment`)}{" "}
                </h6>
                <button
                  type="button"
                  className="close rounded-pill"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group ">
                  <label className="col-form-label">
                    {tid(`Transaction/Invoice No:`)}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-user"
                    value={this.state.transactionID}
                    name="transactionID"
                    onChange={this.setFormField.bind(this)}
                    placeholder={tid("Transaction No")}
                  />
                  {this.paymentValidator.message(
                    "Transaction No",
                    this.state.transactionID,
                    "required"
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">
                    {tid(`Due Comments:`)}
                  </label>
                  <textarea
                    type="text"
                    className="form-control form-control-user"
                    value={this.state.comment}
                    name="comment"
                    onChange={this.setFormField.bind(this)}
                    placeholder={tid("Payment Comments")}
                  ></textarea>
                  {this.paymentValidator.message(
                    "Payment Comments",
                    this.state.comment,
                    "required"
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">
                    {tid(`Paid Amount`)}:
                  </label>
                  <input
                    type="number"
                    className="form-control form-control-user"
                    value={this.state.amount}
                    name="amount"
                    onChange={this.setFormField.bind(this)}
                    placeholder={tid("Paid Amount")}
                  />
                  {this.paymentValidator.message(
                    "Paid Amount",
                    this.state.amount,
                    "required"
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">{tid(`Paid Mode:`)}</label>
                  <select
                    className="form-control form-control-user"
                    value={this.state.mode}
                    name="mode"
                    onChange={this.setFormField.bind(this)}
                    placeholder={tid("Paid Mode")}
                  >
                    <option value="">{tid(`Select Paid Mode`)}</option>
                    <option value="cash">{tid(`Cash`)}</option>
                    <option value="card">{tid(`Card`)}</option>
                    <option value="neft">{tid(`NEFT`)}</option>
                    <option value="upi">{tid(`UPI`)}</option>
                    <option value="net-banking">{tid(`Net Banking`)}</option>
                  </select>
                  {this.paymentValidator.message(
                    "Paid Mode",
                    this.state.mode,
                    "required"
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">{tid(`Paid Date`)}:</label>
                  <input
                    type="date"
                    className="form-control form-control-user"
                    value={this.state.paidDate}
                    name="paidDate"
                    onChange={this.setFormField.bind(this)}
                    placeholder={tid("Paid Date")}
                  />
                  {this.paymentValidator.message(
                    "Paid Date",
                    this.state.paidDate,
                    "required"
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-secondary btn-xs"
                  data-dismiss="modal"
                >
                  <i className="bx bx-x d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid(`Close`)}</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-1 btn-xs"
                  onClick={() => this.savePayment()}
                >
                  <i className="bx bx-check d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid(`Save`)}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 pt-4 ac-payments-info-left-chart-container">
          <h6>{student.name}</h6>
          <span>{student.admissionNo}</span>
          <PaymentPieChart
            data={[
              { name: "Paid", amount: this.state.paid },
              { name: "Pending", amount: this.state.due },
            ]}
          />
          <div className="summary-payment-stats-container">
            <ul className="uk-list uk-list-divider col-lg-12">
              <li>
                <b className="pull-left">{tid(`Total`)}:</b>{" "}
                <span className="pull-right"> {this.state.total || "0"}</span>
              </li>
              <li>
                <b className="pull-left">{tid(`Paid`)}:</b>
                <span className="pull-right"> {this.state.paid || "0"}</span>
              </li>
              <li>
                <b className="pull-left">{tid(`Pending`)}:</b>
                <span className="pull-right"> {this.state.due || "0"}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-9 np">
          <div class="">
            <nav class="responsive-tab style-4">
              <ul
                data-submenu-title="Elements"
                uk-switcher="connect: #elements-nav ;animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
              >
                <li className="active">
                  <a>{tid(`Dues`)}</a>
                </li>
                <li>
                  <a>{tid(`Payments`)}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="bg-white">
            <ul
              class="uk-switcher ac-student-payments-history-tabs"
              id="elements-nav"
            >
              <li className="np ac-student-payments-due-section">
                <button
                  type="button"
                  className="btn"
                  data-toggle="modal"
                  data-target="#due"
                >
                  <i className="uil-plus" />
                  {tid(`Add Due`)}{" "}
                </button>
                {this.state.dues && this.state.dues.length !== 0 && (
                  <table className="uk-table">
                    <tr className="table-header-row">
                      <th>{tid(`Amount`)} </th>
                      <th>{tid(`Title`)}</th>
                      <th>{tid(`Description`)}</th>
                      <th>{tid(`Due Date`)}</th>
                    </tr>
                    <tbody>
                      {this.state.dues.map((due, index) => {
                        return (
                          <tr
                            className="table-data-row"
                            key={`table-data-due-${index}`}
                          >
                            <td>{due.amount}</td>
                            <td>{due.title}</td>
                            <td>{due.description}</td>
                            <td>{moment(due.dueDate).format("DD-MM-YYYY")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {this.state.dues && this.state.dues.length === 0 && (
                  <div className="no-results-found-container">
                    <div className="text-center">
                      {tid(`No due payments Found`)}
                    </div>
                  </div>
                )}
              </li>
              <li className="np ac-student-payments-due-section">
                <button
                  type="button"
                  className="btn"
                  onClick={() => this.setState({ selectedDueID: "" })}
                  data-toggle="modal"
                  data-target="#payment"
                >
                  <i className="uil-plus" /> {tid(`Add Payment`)}{" "}
                </button>
                {this.state.payments && this.state.payments.length !== 0 && (
                  <table className="uk-table">
                    <tr className="table-header-row">
                      <th>{tid(`Amount`)}</th>
                      <th>{tid(`Status`)}</th>
                      <th>{tid(`Comment`)}</th>
                      <th>{tid(`Paid Date`)}</th>
                    </tr>
                    <tbody>
                      {this.state.payments.map((due, index) => {
                        return (
                          <tr
                            className="table-data-row"
                            key={`table-data-due-${index}`}
                          >
                            <td>{due.amount}</td>
                            <td>{due.status}</td>
                            <td>{due.comment}</td>
                            <td>{moment(due.paidDate).format("DD-MM-YYYY")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {this.state.payments && this.state.payments.length === 0 && (
                  <div className="no-results-found-container">
                    <div className="text-center">
                      {tid(`No transactions found`)}
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default WithTranslations(StudentPaymentsComponent);
