import React from "react";
// import swal from 'sweetalert2';
import { Link } from "react-router-dom";
// import * as $ from "jquery";
import moment from "moment";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import {
  getUserData,
  get,
  hasPermission,
  getMerchantID,
  remove,
} from "../../../utils";
import "./live-classes.scss";

import swal from "sweetalert";
import { WithTranslations } from "../../../i18n/withTranslations";

class LiveClassesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveClasses: [],
      upcomingClasses: [],
      completedClasses: [],
      view: "live",
      uploading: false,
    };
  }

  getLiveClasssList(type) {
    get(baseUrl + "live-classes/list/" + type)
      .then((response) => {
        let {
          liveClasses,
          upcomingClasses,
          completedClasses,
        } = response.data.data;
        this.setState({
          liveClasses,
          upcomingClasses,
          completedClasses,
        });
      })
      .catch((error) => {});
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    this.getLiveClasssList(this.state.view);
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  onClick(type) {
    this.setState({
      view: type,
    });
    this.getLiveClasssList(type);
  }

  onClickRemove(liveClass) {
    swal("Remove", "Are you sure want to remove", {
      buttons: {
        cancel: "No",
        defeat: "Yes",
      },
    }).then((value) => {
      switch (value) {
        case "defeat":
          remove(baseUrl + "live-classes/" + liveClass._id).then((response) => {
            this.getLiveClasssList(this.state.view);
          });
          break;
      }
    });
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{tid(`Live Classes`)}</h2>
            </div>
            <div className="app-page-header-right">
              {hasPermission("live-classes", "create") && (
                <Link to={"/add-live-class"} className="app-add-option-button">
                  {" "}
                  <i className="uil-plus"></i> {tid(`Add Live Class`)}
                </Link>
              )}
            </div>
          </div>
          <div className="live-classess-tab-container">
            <ul>
              <li
                className={this.state.view === "live" ? "uk-active" : ""}
                onClick={() => this.onClick("live")}
              >
                <a>
                  <i className="uil-video" />
                  {tid(`Live`)}
                </a>
              </li>
              <li
                className={this.state.view === "upcoming" ? "uk-active" : ""}
                onClick={() => this.onClick("upcoming")}
              >
                <a>
                  <i className="uil-calender" /> {tid(`Upcoming`)}
                </a>
              </li>
              <li
                className={this.state.view === "completed" ? "uk-active" : ""}
                onClick={() => this.onClick("completed")}
              >
                <a>
                  <i className="uil-check-circle" />
                  {tid(`Completed`)}{" "}
                </a>
              </li>
            </ul>
          </div>
          {this.state.view === "live" && (
            <div className="live-class-list-details-container">
              <div className="lcld-flex-container">
                {this.state.liveClasses.map((liveClass, index) => {
                  return (
                    <div
                      key={"live-class-" + index}
                      className="live-class-card-container"
                    >
                      <nav className="bread-crumbs-section" id="breadcrumbs">
                        {liveClass.className && (
                          <ul>
                            {liveClass.subjectName !== "" ? (
                              <li>
                                <a> {liveClass.className} </a>
                              </li>
                            ) : (
                              <li>{liveClass.className}</li>
                            )}
                            {liveClass.chapterName !== "" ? (
                              <li>
                                <a>{liveClass.subjectName} </a>
                              </li>
                            ) : (
                              <li>{liveClass.subjectName}</li>
                            )}
                            {liveClass.topicName !== "" ? (
                              <li>
                                <a>{liveClass.chapterName} </a>
                              </li>
                            ) : (
                              <li>{liveClass.chapterName}</li>
                            )}
                            {liveClass.topicName ? (
                              <li>
                                <a>{liveClass.topicName}</a>
                              </li>
                            ) : null}
                          </ul>
                        )}
                      </nav>
                      <div className="lc-flex-container">
                        <div className="lc-left-side-section">
                          <img
                            alt="Course"
                            className="course-img"
                            src={
                              liveClass.imageUrl || "/assets/images/webinar.svg"
                            }
                          />
                        </div>
                        <div className="lc-right-side-section">
                          <h4>
                            {liveClass.title}{" "}
                            {liveClass.createdBy == this.state.userID && (
                              <Link to={"/edit-live-class/" + liveClass._id}>
                                <i className="fa fa-pencil"></i>
                              </Link>
                            )}{" "}
                            {liveClass.createdBy == this.state.userID && (
                              <i
                                onClick={() => this.onClickRemove(liveClass)}
                                className="fa fa-trash red-text pointer"
                              ></i>
                            )}{" "}
                          </h4>
                          <p className="live-class-time">
                            <i className="uil-calender"></i>
                            <a>
                              {moment(liveClass.date).format("DD MMM, YYYY")}{" "}
                              <i className="uil-clock" /> {liveClass.fromTime}{" "}
                              to {liveClass.toTime}
                            </a>
                          </p>
                          <div className="live-class-agenda">
                            <h6 className="">{tid(`Points to be Covered`)}:</h6>
                            <ul className="points-cover-list">
                              {liveClass.points.map((point, index) => {
                                return (
                                  <li key={"point-" + index}>
                                    <i className="uil-arrow-right" />
                                    {point}{" "}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          {liveClass.facultyName && (
                            <p className="live-class-by">
                              {" "}
                              {tid('By')} <a> {liveClass.facultyName} </a>
                            </p>
                          )}
                          <div className="join-now-button">
                            {!liveClass.locked && (
                              <Link
                                to={"/meet/" + liveClass._id + ""}
                                type="button"
                                disabled={liveClass.locked}
                                className="btn btn-primary btn-sm btn-icon-label"
                              >
                                <span className="btn-inner--text">
                                  {tid(`Join Now`)}
                                </span>
                                <span className="btn-inner--icon">
                                  <i className="uil-play"></i>
                                </span>
                              </Link>
                            )}
                            {liveClass.locked && (
                              <div>
                                <span className="btn-inner--text">
                                  {tid(`Join Now`)}
                                </span>
                                <span className="btn-inner--icon">
                                  <i className="icon-feather-lock"></i>
                                </span>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {this.state.liveClasses.length == 0 && (
                  <div> {tid(`No live classes at the moment`)}. </div>
                )}
              </div>
            </div>
          )}
          {this.state.view === "upcoming" && (
            <div className="live-class-list-details-container">
              <div className="lcld-flex-container">
                {this.state.upcomingClasses.map((liveClass, index) => {
                  return (
                    <div
                      key={"upcoming-class-" + index}
                      className="live-class-card-container"
                    >
                      <nav className="bread-crumbs-section" id="breadcrumbs">
                        {liveClass.className && (
                          <ul>
                            {liveClass.subjectName !== "" ? (
                              <li>
                                <a> {liveClass.className} </a>
                              </li>
                            ) : (
                              <li>{liveClass.className}</li>
                            )}
                            {liveClass.chapterName !== "" ? (
                              <li>
                                <a>{liveClass.subjectName} </a>
                              </li>
                            ) : (
                              <li>{liveClass.subjectName}</li>
                            )}
                            {liveClass.topicName !== "" ? (
                              <li>
                                <a>{liveClass.chapterName} </a>
                              </li>
                            ) : (
                              <li>{liveClass.chapterName}</li>
                            )}
                            {liveClass.topicName ? (
                              <li>
                                <a>{liveClass.topicName}</a>
                              </li>
                            ) : null}
                          </ul>
                        )}
                      </nav>
                      <div className="lc-flex-container">
                        <div className="lc-left-side-section">
                          <img
                            alt="Course"
                            className="course-img"
                            src={
                              liveClass.imageUrl || "/assets/images/webinar.svg"
                            }
                          />
                        </div>
                        <div className="lc-right-side-section">
                          <h4>
                            {liveClass.title}{" "}
                            {liveClass.createdBy == this.state.userID && (
                              <Link to={"/edit-live-class/" + liveClass._id}>
                                <i className="fa fa-pencil"></i>
                              </Link>
                            )}{" "}
                            {liveClass.createdBy == this.state.userID && (
                              <i
                                onClick={() => this.onClickRemove(liveClass)}
                                className="fa fa-trash red-text pointer"
                              ></i>
                            )}
                          </h4>
                          <p className="live-class-time">
                            <i className="uil-calender"></i>
                            <a>
                              {moment(liveClass.date).format("DD MMM, YYYY")}{" "}
                              <i className="uil-clock" /> {liveClass.fromTime}{" "}
                              to {liveClass.toTime}
                            </a>
                          </p>
                          <div className="live-class-agenda">
                            <h6 className="">{tid(`Points to be Covered`)}:</h6>
                            <ul className="points-cover-list">
                              {liveClass.points.map((point, index) => {
                                return (
                                  <li key={"point-" + index}>
                                    <i className="uil-arrow-right" />
                                    {point}{" "}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          {liveClass.facultyName && (
                            <p className="live-class-by">
                              {" "}
                              {tid('By')} <a> {liveClass.facultyName} </a>
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <div className="course-card course-card-list">
                    <div className="course-card-thumbnail">
                      <img alt="Course" className='course-img' src={liveClass.imageUrl || "/assets/images/course/1.png"} />
                    </div>
                    <div className="course-card-body width-100">
                      <a href="course-intro.html">
                        <h4>
                          <Link to={"/edit-live-class/" + liveClass._id}>{liveClass.title}</Link></h4>
                      </a>
                      <div className="row ml-2 small-text">
                        <h6 className="row col-lg-12 np">Points to be Covered:</h6>
                        <ul className="row col-lg-12">
                          {liveClass.points.map((point, index) => {
                            return (<li key={"point-" + index} className="col-lg-6"> {point} </li>)
                          })}
                        </ul>
                      </div>
                      <div className="course-details-info">
                        <ul>
                          {liveClass.facultyName && <li> By <a > {liveClass.facultyName} </a> </li>}
                          <li> <i className="icon-feather-calendar"></i>
                            <a>{moment(liveClass.date).format("DD-MM-YYYY")} {liveClass.fromTime}-{liveClass.toTime}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                    </div>
                  );
                })}
                {this.state.upcomingClasses.length == 0 && (
                  <div>{tid(` No upcoming classes found`)}. </div>
                )}
              </div>
            </div>
          )}
          {this.state.view === "completed" && (
            <div className="live-class-list-details-container">
              <div className="lcld-flex-container">
                {this.state.completedClasses.map((liveClass, index) => {
                  return (
                    <div
                      key={"upcoming-class-" + index}
                      className="live-class-card-container"
                    >
                      <nav className="bread-crumbs-section" id="breadcrumbs">
                        {liveClass.className && (
                          <ul>
                            {liveClass.subjectName !== "" ? (
                              <li>
                                <a> {liveClass.className} </a>
                              </li>
                            ) : (
                              <li>{liveClass.className}</li>
                            )}
                            {liveClass.chapterName !== "" ? (
                              <li>
                                <a>{liveClass.subjectName} </a>
                              </li>
                            ) : (
                              <li>{liveClass.subjectName}</li>
                            )}
                            {liveClass.topicName !== "" ? (
                              <li>
                                <a>{liveClass.chapterName} </a>
                              </li>
                            ) : (
                              <li>{liveClass.chapterName}</li>
                            )}
                            {liveClass.topicName ? (
                              <li>
                                <a>{liveClass.topicName}</a>
                              </li>
                            ) : null}
                          </ul>
                        )}
                      </nav>
                      <div className="lc-flex-container">
                        <div className="lc-left-side-section">
                          <img
                            alt="Course"
                            className="course-img"
                            src={
                              liveClass.imageUrl || "/assets/images/webinar.svg"
                            }
                          />
                        </div>
                        <div className="lc-right-side-section">
                          <h4>
                            <Link to={"/edit-live-class/" + liveClass._id}>
                              {liveClass.title}
                            </Link>
                          </h4>
                          <p className="live-class-time">
                            <i className="uil-calender"></i>
                            <a>
                              {moment(liveClass.date).format("DD MMM, YYYY")}{" "}
                              <i className="uil-clock" /> {liveClass.fromTime}{" "}
                              to {liveClass.toTime}
                            </a>
                          </p>
                          <div className="live-class-agenda">
                            <h6 className="">{tid(`Points to be Covered`)}:</h6>
                            <ul className="points-cover-list">
                              {liveClass.points.map((point, index) => {
                                return (
                                  <li key={"point-" + index}>
                                    <i className="uil-arrow-right" />
                                    {point}{" "}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          {liveClass.facultyName && (
                            <p className="live-class-by">
                              {" "}
                              {tid('By')} <a> {liveClass.facultyName} </a>
                            </p>
                          )}
                          <div className="activity-link-text">
                            {/* <button type="button" className="btn btn-default btn-xs btn-icon-label">
                            <span className="btn-inner--text">View Attendees: {liveClass.attendees.length}</span>
                          </button> */}
                            <Link to={"/activity-live-class/" + liveClass._id}>
                              {tid(`User Activity`)} <i className="uil-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    /* <div key={"upcoming-class-" + index} className="uk-width-4-4@m">
                  <nav className="np" id="breadcrumbs">
                    {liveClass.className && <ul>
                      {(liveClass.subjectName !== '') ? <li><a> {liveClass.className} </a></li> : <li>{liveClass.className}</li>}
                      {(liveClass.chapterName !== '') ? <li><a>{liveClass.subjectName} </a></li> : <li>{liveClass.subjectName}</li>}
                      {(liveClass.topicName !== '') ? <li><a>{liveClass.chapterName} </a></li> : <li>{liveClass.chapterName}</li>}
                      {liveClass.topicName ? <li>{liveClass.topicName}</li> : null}
                    </ul>}
                  </nav>
                  <div className="course-card course-card-list">
                    <div className="course-card-thumbnail">
                      <img alt="Course" className='course-img' src={liveClass.imageUrl || "/assets/images/course/1.png"} />
                    </div>
                    <div className="course-card-body width-100">
                      <a >
                        <h4><Link to={"/edit-live-class/" + liveClass._id}>{liveClass.title}</Link>

                        </h4>
                      </a>
                      <div className="row ml-2 small-text">
                        <h6 className="row col-lg-12 np">Points to be Covered:</h6>
                        <ul className="row col-lg-12">
                          {liveClass.points.map((point, index) => {
                            return (<li key={"point-" + index} className="col-lg-6"> {point} </li>)
                          })}
                        </ul>
                      </div>
                      <div className="course-details-info">
                        <ul>
                          <li>
                            <button type="button" className="pull-right btn btn-default btn-xs btn-icon-label">
                              <span className="btn-inner--text">View Attendees: {liveClass.attendees.length}</span>
                            </button>
                          </li>
                          {liveClass.facultyName && <li> By <a > {liveClass.facultyName} </a> </li>}
                          <li> <i className="icon-feather-calendar"></i>
                            <a>{moment(liveClass.date).format("DD-MM-YYYY")} <span className="black-font">{liveClass.fromTime}-{liveClass.toTime}</span></a>
                          </li>
                        </ul>
                      </div>
                      <div className="pl-1">
                        <ul className="row col-lg-12 uk-slider-items">
                          {liveClass.attendees.map((attendee, index) => {
                            return (<li className="green-text col-lg-4 np">
                              <small>{attendee.userID.firstName} {attendee.userID.lastName}</small>
                            </li>)
                          })}
                        </ul>
                        <Link to={"/activity-live-class/" + liveClass._id} >
                          <small className="btn-inner--text text-12">User Activity</small>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */
                  );
                })}
                {this.state.completedClasses.length == 0 && (
                  <div>{tid(` No completed classes found`)}. </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WithTranslations(LiveClassesComponent);
