import React from "react";

import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";
// import Loader from "../../shared/loader";
import SimpleReactValidator from 'simple-react-validator';
import { baseUrl } from "../../../config";

import { create } from "../../../utils";
import axios from "axios";
import { WithTranslations } from "../../../i18n/withTranslations";

class WebinarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      organizationName: "",
      message: ""
    };

    this.webinarValidator = new SimpleReactValidator({});
  }

  componentDidMount() {
    localStorage.removeItem("name");
  }

  submit() {
    if (this.webinarValidator.allValid()) {
      axios({
        url: baseUrl + "webinars/add",
        method: "post",
        responseType: "application/json",
        headers: {
          "Content-Type": "application/json"
        },
        data: this.state
      })
        .then((response) => {
        localStorage.setItem("name", this.state.name);
        this.setState({
          name: "",
          mobile: "",
          email: "",
          message: "",
          organizationName: ""
        })
        window.location.href = '/webinar/'+response.data.data._id+"/host";
        // window.UIkit.notification(response.data.message, {pos: 'bottom-right', status:'success'})
      })
      .catch((error) => {
        console.log(error)
      });
    } else {
      this.webinarValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value})
  }

  render() {const {translations, tid}=this.props;
    const bgStyles = {
      backgroundImage:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/contact-bg.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: 400,
    };
    return (
      <div className="homepage-main-container">
        <Navbar />
        <div className="about-us-info-container" style={bgStyles}>
          <div className="about-us-info-section">
            <h4>{tid('Your Virtual Event 1 step ahead.')}</h4>
            <h2>{tid(`Get Started for Free`)}</h2>
          </div>
        </div>
        <div className='about-us-details-container contact-us-container'>
          <div className='about-us-main-content'>
            <div className="row contact-us-form">
              <div className="col-md-6 left-side-section">
                <div className="form-container-contact">
                  <label className="uk-form-label">{tid('Name of the Event:')}</label>
                  <input
                    className="uk-input"
                    type="text"
                    placeholder={tid("Name")}
                    value={this.state.name}
                    name="name"
                    onChange={this.setFormField.bind(this)}
                  />

                  {this.webinarValidator.message(
                    "Event Name",
                    this.state.name,
                    "required"
                  )}
                </div>
                <div className="form-container-second-row">
                  <div className="form-container-contact-section">
                    <label className="uk-form-label">{tid('Email')}</label>
                    <input
                      className="uk-input"
                      type="text"
                      placeholder={tid("Email")}
                      value={this.state.email}
                      name="email"
                      onChange={this.setFormField.bind(this)}
                    />
  
                    {this.webinarValidator.message(
                      "Email",
                      this.state.email,
                      "required"
                    )}
                  </div>
                  <div className="form-container-contact-section">
                    <label className="uk-form-label">{tid('Mobile')}</label>
                    <input
                      className="uk-input"
                      type="number"
                      placeholder={tid('Mobile')}
                      value={this.state.mobile}
                      name="mobile"
                      onChange={this.setFormField.bind(this)}
                    />
  
                    {this.webinarValidator.message(
                      "Mobile",
                      this.state.mobile,
                      "required"
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1@s uk-first-column">
                  <label className="uk-form-label">{tid('Organization Name')}</label>
                    <input
                      className="uk-input"
                      type="text"
                      placeholder={tid("Organization Name")}
                      value={this.state.organizationName}
                      name="organizationName"
                      onChange={this.setFormField.bind(this)}
                    />

                    {this.webinarValidator.message(
                      "Organization Name",
                      this.state.organizationName,
                      "required"
                    )}
                </div>
                <div className="uk-width-1-1@s uk-first-column">
                  <label className="uk-form-label">{tid('Message')}</label>
                  <textarea
                    className="uk-textarea"
                    placeholder={tid("Enter your message here.")}
                    style={{ height: "100px" }}
                    value={this.state.message}
                    name="message"
                    onChange={this.setFormField.bind(this)}
                  ></textarea>
                </div>
                <div className="uk-grid-margin uk-first-column send-button-section-container">
                  <input
                    type="submit"
                    value={tid("Create")}
                    className="btn btn-default btn-xs success"
                    onClick={() => this.submit()}
                  />
                </div>
              </div>
              <div className="right-side-section webinar-right-side-section">
                <img src={'/assets/images/webinar.jpg'} alt={tid('Webinar Illustration')}/>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default WithTranslations(WebinarComponent);
