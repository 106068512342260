import React, { useContext } from "react";
import { LanguageContext } from "./LanguageProvider";

export function WithTranslations(WrappedComponent) {
  return class extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
      super(props);
    }
    componentDidMount() {
      // console.clear();
    }

    render() {
      const { content , siteLanguage} = this.context;
      const tid = (k) => {
        const id = k
          .replace(/[^a-z0-9]/gim, " ")
          .trim()
          .replace(/\s+/g, "_")
          .toUpperCase();
        const retu = content[id];

        if (!retu) {
          console.log(k.trim());
        }
        return retu || k;
      };
      return (
        <WrappedComponent translations={content} tid={tid} siteLanguage={siteLanguage} {...this.props} />
      );
    }
  };
}
