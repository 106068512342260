import React from "react";
import swal from 'sweetalert2';

// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
import SimpleReactValidator from 'simple-react-validator';

// import { Loader, IconLoader } from "../../shared/loader";
// import Navbar from "../shared/navbar/Navbar";
// import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { create } from "../../../utils";

const blankData = "_____________";

export default class QuestionComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      questionID: '',
      questionNo: "",
      questionFor: "test",//test, quiz
      title: "",
      questionType: "",
      question: "",
      questionImageKey: "",
      questionHtmlKey: "",
      options: [],
      blanks: [],
      essayAnswer: "",
      matchingA: [],
      matchingB: [],
      matchingAnswers: [],
      marks: 1,
      index: ''
    }
    this.saveQuestion = this.saveQuestion.bind(this);
    this.questionValidator = new SimpleReactValidator({});
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  addOption() {
    let options = this.state.options;
    options.push({
      value: "",
      optionImageKey: "",
      isCorrect: false
    });

    this.setState({
      options
    })
  }

  addMatchingOption(option) {
    let options = this.state['matching' + option];
    let title = "";
    if (option === 'A')
      title = options.length + 1;
    else
      title = String.fromCharCode(65 + options.length);

    options.push({
      optionTitle: title,
      question: "",
      questionImageKey: ""
    });

    this.setState({
      ['matching' + option]: options
    })

    if (option === 'A') {
      let matchingAnswers = this.state.matchingAnswers;
      matchingAnswers.push(-1)
      this.setState({
        matchingAnswers
      })
    }
  }

  removeMatchingOption(option, index) {
    let options = this.state['matching' + option];
    options.splice(index, 1);

    options.forEach((op, ind) => {
      op.optionTitle = (option === 'A') ? (ind + 1) : String.fromCharCode(65 + ind);
    })
    this.setState({
      ['matching' + option]: options
    });
    if (option === 'A') {
      let matchingAnswers = this.state.matchingAnswers;
      matchingAnswers.splice(index, 1);
      this.setState({
        matchingAnswers
      })
    }
  }

  insertBlank() {
    let question = this.state.question;
    let blanks = this.state.blanks;
    blanks.push("")
    question += blankData+" ";
    this.setState({
      question,
      blanks
    })
  }

  onSelectMatching(i, j) {
    let matchingAnswers = this.state.matchingAnswers;
    matchingAnswers[i] = j;
    this.setState({
      matchingAnswers
    })
  }

  saveQuestion() {
    let url = baseUrl + "questions/";
    if (!this.state.questionID)
      url += "add";
    else
      url += this.state.questionID
    create(url, (this.state.questionID) ? "put" : "post", this.state)
      .then((response) => {
        this.props.onAddQuestion(response.data.data);
        swal.fire({
          title: "Info",
          text: response.data.message,
          icon: "warning"
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  handleFieldKeysEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const key = splitted[1];
      const index = splitted[2];
      const value = event.target.value;
      let items = this.state[name];
      items[parseInt(index, 10)][key] = value;
      this.setState({
        [items]: items
      })
    }
  }

  handleFieldEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const index = splitted[1];
      const value = event.target.value;
      let items = this.state[name];
      items[parseInt(index, 10)] = value;
      this.setState({
        [name]: items
      })
    }
  }

  handleCheck = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    let options = this.state[name];
    if (name) {
      const key = splitted[1];
      const index = splitted[2];
      const value = !options[splitted[2]][splitted[1]];
      let items = this.state[name];
      items[parseInt(index, 10)][key] = value;
      this.setState({
        [items]: items
      })
    }
  }

  componentDidMount() {
    this.setState({
      index: this.props.index,
      questionFor: this.props.questionFor
    })

    if (this.props.question && this.props.question._id) {
      let {
        _id,
        questionNo,
        questionFor,//test, quiz
        title,
        questionType,
        question,
        questionImageKey,
        questionHtmlKey,
        options,
        blanks,
        essayAnswer,
        matchingA,
        matchingB,
        matchingAnswers,
        marks
      } = this.props.question;

      this.setState({
        _id,
        questionID: _id,
        questionNo,
        questionFor,//test, quiz
        title,
        questionType,
        question,
        questionImageKey,
        questionHtmlKey,
        options,
        blanks,
        essayAnswer,
        matchingA,
        matchingB,
        matchingAnswers,
        marks
      })
    }
  }

  removeOption(index) {
    let { options } = this.state;
    options.splice(index, 1);
    this.setState({
      options
    })
  }

  removeBlank(index) {
    let { blanks } = this.state;
    blanks.splice(index, 1);
    this.setState({
      blanks
    })

    let { question } = this.state;
    let questionData = question.split(blankData)
    let actualData = '';
    questionData.forEach((q, i) => {
      console.log(index, i)
      actualData+=q;
      if((index != i) && (i < (questionData.length - 1)))
        actualData+=blankData;
    })
    console.log(actualData)

    this.setState({
      question: actualData
    })

  }

  render() {
    let { disabled, hide, index, questionFor } = this.props;
    // this.setQuestion(question)
    return (
      <div className="collapse-header question-collapse-header">
        <div id="headingCollapse2" className="card-header" data-toggle="collapse" role="button" data-target={"#collapse" + this.state.index}
          aria-expanded="false" aria-controls={"collapse" + this.state.index}>
          <span className="collapse-title pointer">
            {this.state.questionNo && <a className="uk-accordion-title primary black-font bold"><b>{index + 1})</b> {this.state.questionNo} - <small>{this.state.questionType}</small> </a>} {!this.state.questionNo && <a className='uk-accordion-title'>Q - {index}. New Question - <small>{this.state.questionType}</small></a>} {this.state.questionNo && <div className="bg-soft-danger btn btn-xxs" uk-tooltip="Delete"><i className="uil-trash-alt" />Remove</div>}
          </span>
        </div>
        <div id={"collapse" + this.state.index} role="tabpanel" aria-labelledby="headingCollapse2" className="collapse question-collapse">
          <div className="card-content">
            <div className="card-body">
              <div className="form-group form-flex-row">
                <div className="left-side">
                  <label>Question Type:</label>
                </div>
                <div className="right-side">
                  <select className="form-control"
                    value={this.state.questionType}
                    name="questionType"
                    disabled={disabled}
                    onChange={this.setFormField.bind(this)}
                    placeholder="Enter Question Type"
                  >
                    <option value="singleAnswer">Single Answer</option>
                    <option value="multipleAnswer">Multiple Answers</option>
                    <option value="blanks">Fill In the Blanks</option>
                    <option value="matching">Match The Following</option>
                    <option value="boolean">True/False</option>
                    <option value="essay">Essay</option>
                  </select>
                  {this.questionValidator.message(
                    "Question Type",
                    this.state.questionType,
                    "required"
                  )}
                </div>
              </div>
              {questionFor === 'test' && <div className="form-group form-flex-row">
                <div className="left-side">
                  <label>Marks:</label>
                </div>
                <div className="right-side">
                  <input type="number"
                    className="form-control"
                    value={this.state.marks}
                    name="marks"
                    disabled={disabled}
                    onChange={this.setFormField.bind(this)}
                    placeholder="Enter Total Marks" />
                  {this.questionValidator.message(
                    "Marks",
                    this.state.marks,
                    "required"
                  )}
                </div>
              </div>}
              {this.state.questionType !== "matching" && <div className="form-group form-flex-row">
                <div className="left-side">
                  <label>Question: {this.state.questionType === 'blanks' && !hide && <button className="btn btn-xs btn-info" onClick={() => this.insertBlank()}>Add Blank</button>}</label>
                </div>
                <div className="right-side">
                  <textarea type="text"
                    className="form-control"
                    value={this.state.question}
                    name="question"
                    disabled={disabled}
                    onChange={this.setFormField.bind(this)}
                    placeholder="Enter Question Data"></textarea>

                  {this.questionValidator.message(
                    "Question Data",
                    this.state.question,
                    "required"
                  )}
                </div>
              </div>}

              {/* Options */}
              {this.state.questionType !== 'blanks' && this.state.questionType !== 'matching' && this.state.questionType !== 'essay' && <div className="form-flex-row align-start">
                <div className="left-side">
                  <label>Options: {!hide && <button className="btn btn-xxs btn-info" onClick={() => this.addOption()}><i className='uil-plus' /> Add</button>}</label>
                </div>
                <div className="right-side">
                  <div className='options-flex-container'>
                    {this.state.options.map((option, index) => {
                      return (<div key={"option-" + index} className="question-checkbox-options-container">
                        <div className="">
                          <input type="checkbox" name={"options#isCorrect#" + index}
                            value={option.isCorrect}
                            disabled={disabled}
                            checked={option.isCorrect}
                            onChange={this.handleCheck} id={"option-" + index} />
                        </div>
                        <label htmlFor={"option-" + index}>
                          <input type="text"
                            className="form-control np"
                            disabled={disabled}
                            name={"options#value#" + index}
                            value={option.value}
                            onChange={this.handleFieldKeysEdit}
                            placeholder="Enter Option" />
                        </label>
                        <button className="btn btn-xs btn-danger" onClick={() => this.removeOption(index)}><i className="fa fa-trash"></i></button>
                      </div>)
                    })}
                  </div>
                </div>
              </div>}

              {/* Essay Answer */}
              {this.state.questionType === "essay" && <div className="form-group form-flex-row">
                <div className="left-side">
                  <label>Answer:</label>
                </div>
                <div className="right-side">
                  <textarea type="text" className="form-control"
                    value={this.state.essayAnswer}
                    name="essayAnswer"
                    disabled={disabled}
                    onChange={this.setFormField.bind(this)}
                    rows="3"
                    placeholder="Enter Essay Answer"></textarea>
                </div>
              </div>}

              {/* Blanks */}

              {this.state.questionType === "blanks" && <div className="form-group form-flex-row">
                <div className="left-side">
                  <label>Blank Answers:</label>
                </div>
                <div className="right-side">
                  {this.state.blanks.map((answer, index) => {
                    return (<div key={"blank-ans-" + index} className="row col-lg-12 np">
                      <div className="col-lg-11 np">
                        <input  type="text" className="form-control p10"
                        value={answer}
                        disabled={disabled}
                        name={"blanks#" + index}
                        onChange={this.handleFieldEdit}
                        placeholder={"Enter Answer for the Blank " + (index + 1)}
                      />
                    </div>
                    <div className="col-lg-1 pt-3">
                      <button className="btn btn-xs btn-danger blank-btn" onClick={() => this.removeBlank(index)}><i className="fa fa-trash"></i></button>
                    </div>
                    </div>)
                  })}
                </div>
              </div>}

              {/* Match the following. */}
              {this.state.questionType === "matching" && <div className="col-md-12">
                <h6>Match the following Options:</h6>
                <div className="row">
                  <div className="col-md-6 text-center">
                    <h6 className="text-center">(A): {!hide && <button className="btn btn-xs btn-info pl-2" onClick={() => this.addMatchingOption('A')}>Add</button>}
                    </h6>
                    {this.state.matchingA.map((option, index) => {
                      return (<div key={"opa-" + index} className="form-group">
                        <div className="checkbox-shadow row col-lg-12 np">
                          <div className="col-lg-1 np pt-2">
                            <span>{option.optionTitle})</span>
                          </div>
                          <div className="col-lg-9 np" htmlFor={"option-" + index}>
                            <input type="text"
                              className="form-control"
                              disabled={disabled}
                              name={"matchingA#question#" + index}
                              value={option.question}
                              onChange={this.handleFieldKeysEdit}
                              placeholder="Enter Option"
                            />
                          </div>
                          {!hide && <div className="col-lg-2 pt-2"> <button className="btn btn-xs btn-danger" onClick={() => this.removeMatchingOption('A', index)}><i className="icon-feather-x"></i></button></div>}
                        </div>
                      </div>)
                    })}
                  </div>
                  <div className="col-md-6 text-center">
                    <h6 className="text-center">(B): {!hide && <button className="btn btn-xs btn-info pl-2" onClick={() => this.addMatchingOption('B')}>Add</button>}
                    </h6>
                    {this.state.matchingB.map((option, index) => {
                      return (<div key={"opa-" + index} className="form-group">
                        <div className="checkbox-shadow row col-lg-12 np">
                          <div className="col-lg-1 np pt-2">
                            <span>{option.optionTitle})</span>
                          </div>
                          <div className="col-lg-9 np" htmlFor={"option-" + index}>
                            <input type="text"
                              className="form-control"
                              disabled={disabled}
                              name={"matchingB#question#" + index}
                              value={option.question}
                              onChange={this.handleFieldKeysEdit}
                              placeholder="Enter Option"
                            />
                          </div>
                          {!hide && <div className="col-lg-2 pt-2">  <button className="btn btn-xs btn-danger" onClick={() => this.removeMatchingOption('B', index)}><i className="icon-feather-x"></i></button></div>}
                        </div>
                      </div>)
                    })}
                  </div>
                </div>
                {((this.state.matchingA.length > 0) || (this.state.matchingB.length > 0)) && <h6>Match the following Answers:</h6>}
                  {this.state.matchingA.map((option, i) => {
                    return (
                    <div key={"opp-" + i} className="match-the-following-options-flex">
                      <div className="left-side">
                        <span className="">{option.optionTitle})  Matches With <i className="icon-feather-arrow-right"></i></span>
                      </div>
                      <div className="right-side">
                        {this.state.matchingB.map((option, j) => {
                          return (
                            <button key={"bb-" + j} type="button" disabled={disabled} className={"btn ml-1 " + ((this.state.matchingAnswers[i] === j) ? " btn-default" : " btn-outline-default")} onClick={() => this.onSelectMatching(i, j)}>{option.optionTitle}</button>
                          )
                        })}
                      </div>
                    </div>)
                  })}
                </div>}
            </div>
          </div>

          {!hide && <div className="pt-3 save-button-container">
            <button className="btn btn-success btn-xs" onClick={this.saveQuestion.bind(this)}><i className='fa fa-save' />Save</button>
          </div>}

        </div>
      </div>
    );
  }
}