import React from "react";
import swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";
import { baseUrl } from "../../../config";
import { get, create } from "../../../utils";
import { WithTranslations } from "../../../i18n/withTranslations";

// import Loader from "../../shared/loader";

class AddStaffsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      prefix: "+91",
      gender: "",
      designation: "",
      password: "",
      userType: "faculty",
      staffID: "",
      classes: [],
      columns: [],
      permissions: [],
    };

    this.save = this.save.bind(this);
    this.facultyValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.facultyValidator.allValid()) {
      let url = baseUrl + "users/";

      if (this.state.staffID) {
        url += "update/" + this.state.staffID;
      } else {
        url += "add";
      }

      let data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        mobile: this.state.mobile,
        gender: this.state.gender,
        password: this.state.password,
        userType: this.state.userType,
        permissions: this.state.permissions,
      };

      if (!this.state.password) delete data.password;

      create(url, this.state.staffID ? "put" : "post", data)
        .then((response) => {
          this.props.loadStaff();
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.facultyValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({
      [event.target.name]: event.target.value,
      message: "",
      passwordMessage: "",
    });
  }

  componentDidMount() {
    let staffID = this.props.staffID;
    this.setState({
      staffID,
    });
    if (staffID) this.loadStaff(staffID);
    else this.getPermissions();
  }

  getPermissions() {
    get(baseUrl + "users/permissions-master")
      .then((response) => {
        let permissions = response.data.data;
        this.setState({ permissions });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadStaff(staffID) {
    get(baseUrl + "users/user/" + staffID)
      .then((response) => {
        let {
          firstName,
          lastName,
          email,
          mobile,
          prefix,
          gender,
          designation,
          permissions,
        } = response.data.data;
        this.setState({
          firstName,
          lastName,
          email,
          mobile,
          prefix,
          gender,
          designation,
          permissions,
        });
        if (permissions.length === 0) this.getPermissions();
        this.getAssignedClasses(staffID);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAssignedClasses(staffID) {
    get(baseUrl + "assigned-faculties/list/" + staffID)
      .then((response) => {
        this.setState({
          classes: response.data.data,
          columns: response.data.columns,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCheck = (event) => {
    const splitted = event.target.name.split("#");
    const classID = splitted[0];
    const subjectID = splitted[1];
    const staffID = this.state.staffID;
    create(baseUrl + "assigned-faculties/add", "post", {
      classID,
      subjectID,
      userID: staffID,
    })
      .then((response) => {
        this.getAssignedClasses(staffID);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePermission = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    const index = parseInt(splitted[1], 10);
    let permissions = this.state.permissions;
    permissions[index][name] = event.target.value;
    this.setState({
      permissions,
    });
  };

  getTitle(name) {
    let nameSplitted = name.split("-");
    let title = "";
    nameSplitted.forEach((key) => {
      title += " " + key.charAt(0).toUpperCase() + key.substr(1);
    });
    title = title.trim();

    return title;
  }

  render() {
    const { tid } = this.props;
    return (
      <div className="add-staff-form-page-container">
        <div className="app-page-header-section">
          <div className="app-page-header-left">
            <h2 className="">
              <span onClick={() => this.props.showList()}>
                <i className="uil-arrow-left"></i>
              </span>
              {tid("Staff Basic Details")}
            </h2>
          </div>
          <div className="app-page-header-right">
            <button
              className="btn btn-sm d-none d-sm-block btn-default"
              onClick={this.save.bind(this)}
            >
              <i className="cursor-pointer fa fa-save font-small-3 mr-50"></i>
              <span>{tid("Save Changes")}</span>
            </button>
          </div>
        </div>
        <div className="add-staff-form-section">
          <div className="first-row">
            <div className="form-group-section">
              <fieldset className="form-group">
                <label htmlFor="basicInput">{tid("First Name")}(*):</label>
                <input
                  type="text"
                  className="form-control form-control-user"
                  value={this.state.firstName}
                  name="firstName"
                  onChange={this.setFormField.bind(this)}
                  placeholder={tid("Enter First Name")}
                />
                {this.facultyValidator.message(
                  "First Name",
                  this.state.firstName,
                  "required"
                )}
              </fieldset>
            </div>
            <div className="form-group-section">
              <fieldset className="form-group">
                <label htmlFor="helperText">{tid("Last Name")}(*): </label>
                <input
                  type="text"
                  className="form-control form-control-user"
                  value={this.state.lastName}
                  name="lastName"
                  onChange={this.setFormField.bind(this)}
                  placeholder={tid("Enter Last Name")}
                />
                {this.facultyValidator.message(
                  "Last Name",
                  this.state.lastName,
                  "required"
                )}
              </fieldset>
            </div>
            <div className="form-group-section">
              <fieldset className="form-group">
                <label htmlFor="helpInputTop">
                  {tid("Email Address")}(*):{" "}
                </label>
                <input
                  type="text"
                  className="form-control form-control-user"
                  value={this.state.email}
                  name="email"
                  onChange={this.setFormField.bind(this)}
                  placeholder={tid("Enter Email")}
                />
                {this.facultyValidator.message(
                  "Email",
                  this.state.email,
                  "required|email"
                )}
              </fieldset>
            </div>
          </div>
          <div className="second-row">
            <div className="form-group-section">
              <fieldset className="form-group">
                <label htmlFor="helperText">{tid("Mobile")}:</label>
                <input
                  type="text"
                  className="form-control form-control-user"
                  value={this.state.mobile}
                  name="mobile"
                  onChange={this.setFormField.bind(this)}
                  placeholder={tid("Enter Mobile Number")}
                />
                {this.facultyValidator.message(
                  "Mobile Number",
                  this.state.mobile,
                  "required"
                )}
              </fieldset>
            </div>
            <div className="form-group-section">
              <fieldset className="form-group">
                <label htmlFor="helperText">{tid("Gender")}: </label>
                <select
                  className="form-control"
                  value={this.state.gender}
                  name="gender"
                  onChange={this.setFormField.bind(this)}
                >
                  <option value="male">{tid("Male")}</option>
                  <option value="female">{tid("Female")}</option>
                </select>
              </fieldset>
            </div>
            <div className="form-group-section">
              <fieldset className="form-group">
                <label htmlFor="helperText">{tid("User Role")}: </label>
                <select
                  className="form-control"
                  value={this.state.userType}
                  name="userType"
                  onChange={this.setFormField.bind(this)}
                >
                  <option value="">{tid("User Type Role")}</option>
                  <option value="faculty">{tid("Faculty")}</option>
                  <option value="admin">{tid("Administration")}</option>
                </select>
              </fieldset>
            </div>
          </div>
          <div className="second-row">
            <div className="form-group-section">
              <fieldset className="form-group">
                <label htmlFor="helperText">{tid("Password")}(*):</label>
                <input
                  type="password"
                  className="form-control form-control-user"
                  value={this.state.password}
                  name="password"
                  onChange={this.setFormField.bind(this)}
                  placeholder={tid("Enter Password")}
                />
              </fieldset>
            </div>
          </div>
        </div>
        {this.state.userType === "faculty" && this.state.staffID && (
          <ul
            className="course-curriculum staff-edit-accordion-container"
            uk-accordion="multiple: true"
          >
            <li className="uk-open">
              <a className="uk-accordion-title"> {tid("Permissions")} </a>
              <div className="uk-accordion-content permissions-accordion-content ml-2">
                <div classname="col-lg-12" uk-grid={"true"}>
                  <div className="col-lg-6">
                    <table className="table">
                      <thead>
                        <tr className="table-header-row">
                          <th>{tid("Name")}</th>
                          <th>{tid("Menu")}</th>
                          <th>{tid("Add")}</th>
                          <th>{tid("View")}</th>
                          <th>{tid("Update")}</th>
                          <th>{tid("Delete")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.permissions.map((permission, index) => {
                          return index % 2 === 0 ? (
                            <tr className="table-data-row">
                              <td>
                                <small>{this.getTitle(permission.name)}</small>
                              </td>

                              {[
                                "menu",
                                "create",
                                "read",
                                "update",
                                "remove",
                              ].map((option, k) => {
                                return (
                                  <td key={index + "-" + k}>
                                    <input
                                      type="checkbox"
                                      id={"checkbox-" + index}
                                      name={option + "#" + index}
                                      value={permission[option]}
                                      checked={permission[option]}
                                      onClick={this.handlePermission}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          ) : null;
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-6">
                    <table className="table">
                      <thead>
                        <tr className="table-header-row">
                          <th>{tid("Name")}</th>
                          <th>{tid("Menu")}</th>
                          <th>{tid("Add")}</th>
                          <th>{tid("View")}</th>
                          <th>{tid("Update")}</th>
                          <th>{tid("Delete")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.permissions.map((permission, index) => {
                          return index % 2 === 1 ? (
                            <tr className="table-data-row">
                              <td>
                                <small>{this.getTitle(permission.name)}</small>
                              </td>

                              {[
                                "menu",
                                "create",
                                "read",
                                "update",
                                "remove",
                              ].map((option, k) => {
                                return (
                                  <td key={index + "-" + k}>
                                    <input
                                      type="checkbox"
                                      id={"checkbox-" + index}
                                      name={option + "#" + index}
                                      value={permission[option]}
                                      checked={permission[option]}
                                      onClick={this.handlePermission}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          ) : null;
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a className="uk-accordion-title">
                {" "}
                {tid("Classes & Subjects Details he/she teaches")}:{" "}
              </a>
              <div className="uk-accordion-content permissions-accordion-content">
                <table className="table">
                  <thead>
                    <tr className="table-header-row">
                      <th>{tid("Class")}</th>
                      {this.state.columns.map((column) => {
                        return <th className="text-center">{column}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.classes.map((c, i) => {
                      return (
                        <tr className="table-data-row">
                          <td>{c.name}</td>
                          {c.subjects.map((subject, j) => {
                            return (
                              <td className="text-center">
                                {subject && subject._id && (
                                  <input
                                    type="checkbox"
                                    id={"checkbox-" + i + j}
                                    name={c._id + "#" + subject._id}
                                    value={subject.assigned}
                                    checked={subject.assigned}
                                    onChange={this.handleCheck}
                                  />
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        )}
      </div>
    );
  }
}
export default WithTranslations(AddStaffsComponent);
