import React from "react";

export default class StaffDashboardComponent extends React.Component {

  constructor(props) {
    super(props);
        this.state = {
           
        }
    }

    render() {
        return (
        <div className="row col-lg-12 card np">
            <h1>Staff Dashboard</h1>
        </div>
      );
    }
}