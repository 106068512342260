import React from "react";
import swal from 'sweetalert2';
import { Link } from "react-router-dom";

import * as $ from "jquery";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import SimpleReactValidator from 'simple-react-validator';
import { baseUrl } from "../../../config";
import { IconLoader } from "../../shared/loader";
import { getUserData, get, create, uploadToS3 } from "../../../utils";
import Delete from "../../common/Delete";

class AchievementComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      achievements: [],
      title: "",
      description: "",
      file: "",
      achievementID: "",
      savingData: false
    }
    this.uploadFile = this.uploadFile.bind(this);
    this.achievementValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.achievementValidator.allValid()) {
      this.setState({
        savingData: true
      })

      let url = baseUrl + "achievements/";
      if (!this.state.achievementID)
        url += "add";
      else
        url += this.state.achievementID

      let fileName = "";
      if (this.state.file)
        fileName = "achievements/" + Date.now() + "/" + this.state.file.title;
      new Promise((resolve, reject) => {
        if (this.state.file)
          resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
        else
          resolve();
      })
        .then((response) => {
          return create(url, (this.state.achievementID) ? "put" : "post", {
            title: this.state.title,
            description: this.state.description,
            fileKey: fileName ? fileName : undefined,
            fileType: (this.state.file && this.state.file.type) ? this.state.file.type.split("/")[0] : undefined,
          })
        })
        .then((response) => {
          this.setState({
            savingData: false
          })
          this.getAchievementsList();
          $("#add-achievement").trigger("click");
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning"
          });
        })
        .catch((error) => {

        });
    } else {
      this.achievementValidator.showMessages();
      this.forceUpdate();
    }
  }

  getAchievementsList() {
    get(baseUrl + "achievements/list")
      .then((response) => {
        this.setState({
          achievements: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
    })
  }

  componentDidMount() {
    this.assignUserData();
    this.getAchievementsList();
  }

  setFormField(event) {
    this.setState({ [event.target.title]: event.target.value, message: "" })
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] })
  }

  removeAchievementById=(id)=>()=>{
    this.setState({
      achievements:this.state.achievements.filter(k=>k._id!=id)
    })
  }

  editAchievement(achievement) {
    this.setState({
      title: achievement.title,
      description: achievement.description,
      achievementDate: new Date(achievement.achievementDate).toISOString().substr(0, 10),
      achievementID: achievement._id
    })
  }

  render() {
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className=""><Link to={"/accounts"}><i className="uil-arrow-left"></i></Link> Achievements</h2>
            </div>
            <div className="app-page-header-right">
              {(this.state.userType !== 'achievement' && this.state.userType !== 'parent') && <div className="section-header-right">
                <button className="app-add-option-button" data-toggle="modal" data-target="#add-achievement"><i className="uil-plus"></i> Add Achievement</button>
              </div>}
            </div>
          </div>
          <div className="expenses-list-table-container" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 100">
            <table className="table">
              <thead>
                <tr className='table-header-row'>
                  <th>S.No</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.achievements.map((achievement, index) => {
                  return (<tr className='table-data-row' key={"achievement" + index}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      {achievement.fileUrl && <a target="_blank" href={achievement.fileUrl}><img className="achievement-image" src={achievement.fileUrl} alt="file" /></a>}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="ml-1"><span className="text-bold-500">{achievement.title} </span></div>
                      </div>
                    </td>
                    <td>
                      {achievement.description}
                    </td>
                    <td>
                      <div className='action-items'>
                        <button type="button" className="btn btn-success btn-sm" data-toggle="modal" data-target="#add-achievement" onClick={() => this.editAchievement(achievement)}><i className='uil-edit' /></button>
                      <Delete url={`achievements/${achievement._id}`} onDeleteSuccess={this.removeAchievementById(achievement._id)}/>
                      </div>
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
          <div className="modal fade text-left" id="add-achievement" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">Add Achievement</h3>
                  <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.title}
                      title="title"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Enter Achievement Name"
                    />
                    {this.achievementValidator.message(
                      "Achievement Name",
                      this.state.title,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.description}
                      title="description"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Enter Achievement Description"
                    ></textarea>
                    {this.achievementValidator.message(
                      "Achievement Description",
                      this.state.description,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>Attachment</label>
                    <div className="choose-file-section">
                      <label httmlFor="aqqQuestionImageFile">
                        <input type="file" accept="image/*" id="aqqQuestionImageFile" onChange={this.uploadFile} />
                        <i className='uil-file' /> Choose File
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-light-secondary btn-xs" data-dismiss="modal">
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">Close</span>
                  </button>&nbsp;
                      <button type="button" className="btn btn-primary ml-1 btn-xs" onClick={() => this.save()}>
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{this.state.savingData && <IconLoader size={2} text="" color={"#fff"}></IconLoader>}&nbsp; Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AchievementComponent;