import React from "react";
import swal from "sweetalert2";
// import {  Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import {
  get,
  create,
  remove,
  uploadToS3,
  hasPermission,
  getUserData,
} from "../../../utils";
import "./gallery.scss";
import Delete from "../../common/Delete";
import { WithTranslations } from "../../../i18n/withTranslations";

class GalleryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
      title: "",
      description: "",
      galleryTitle: "",
      galleryID: "",
      mediaType: "image",
      file: "",
      uploading: false,
      userType: "",
    };
    this.uploadFile = this.uploadFile.bind(this);
    this.galleryValidator = new SimpleReactValidator({});
    this.mediaValidator = new SimpleReactValidator({});
    this.assignUserData = this.assignUserData.bind(this);
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] });
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  uploadMedia(gallery) {
    this.setState({
      galleryTitle: gallery.title,
      galleryID: gallery._id,
    });
  }

  save() {
    if (this.galleryValidator.allValid()) {
      let url = baseUrl + "gallery/";
      if (this.state.galleryID) url += this.state.galleryID;
      else url += "add";

      create(url, this.state.galleryID ? "put" : "post", {
        title: this.state.title,
        description: this.state.description,
      })
        .then((response) => {
          $("#add-gallery").trigger("click");
          this.getGallery();
          this.setState({
            title: "",
            description: "",
          });
          this.galleryValidator.purgeFields();
        })
        .catch((error) => {});
    } else {
      this.galleryValidator.showMessages();
      this.forceUpdate();
    }
  }

  saveMedia() {
    if (this.mediaValidator.allValid()) {
      this.setState({
        uploading: true,
      });

      let fileName =
        "gallery/" + this.state.galleryTitle + "/" + this.state.file.name;
      const uploadPDF = new Promise((resolve, reject) => {
        resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
      });

      const fileUploader = new Promise((resolve, reject) => {
        resolve(
          create(baseUrl + "gallery/media/" + this.state.galleryID, "post", {
            mediaType: this.state.mediaType,
            fileKey: fileName,
          })
        );
      });

      Promise.all([uploadPDF, fileUploader])
        .then((response) => {
          this.setState({
            galleryID: "",
            mediaType: "",
            file: "",
            uploading: false,
          });
          $("#file").val("");
          this.mediaValidator.purgeFields();
          $("#add-media").trigger("click");
          this.getGallery();
        })
        .catch((error) => {});
    } else {
      this.mediaValidator.showMessages();
      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.getGallery();
    this.assignUserData();
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  getGallery() {
    get(baseUrl + "gallery/list")
      .then((response) => {
        this.setState({
          gallery: response.data.data,
        });
      })
      .catch((error) => {});
  }

  onSelectMedia(index, j) {
    let gallery = this.state.gallery;
    gallery[index].displayIndex = j;
    this.setState({
      gallery,
    });
  }

  onDeleteMedia(index, j) {
    swal
      .fire({
        title: "Are you sure?",
        text: "Want to remove the media?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((response) => {
        if (response.value) {
          remove(
            baseUrl +
              "gallery/media/" +
              this.state.gallery[index]._id +
              "/" +
              this.state.gallery[index].media[j]._id
          )
            .then((response) => {
              this.getGallery();
            })
            .catch((error) => {});
        }
      });
  }

  removeGalleryItemById = (id) => () => {
    this.setState({
      gallery: this.state.gallery.filter((k) => k._id != id),
    });
  };

  onEditGallery(gallery) {
    this.setState({
      title: gallery.title,
      description: gallery.description,
      galleryID: gallery._id,
    });
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{tid("Gallery")}</h2>
            </div>
            <div className="app-page-header-right">
              {hasPermission("gallery", "create") && (
                <button
                  className="app-add-option-button"
                  data-toggle="modal"
                  data-target="#add-gallery"
                >
                  <i className="uil-plus" />
                  {tid("Add New")}
                </button>
              )}
            </div>
          </div>
          <div className="app-gallery-list-container">
            {this.state.gallery.map((gallery, index) => {
              return (
                <div className="app-gallery-details-container">
                  <div className="agd-top-info-section">
                    <h5>{gallery.title}</h5>
                    <div className="action-items">
                      {hasPermission("gallery", "create") && (
                        <button
                          className=""
                          onClick={() => this.uploadMedia(gallery)}
                          data-toggle="modal"
                          data-target="#add-media"
                        >
                          <i className="uil-plus" />
                         {tid("Add Media")}
                        </button>
                      )}
                      {hasPermission("gallery", "remove") && (
                        <Delete
                          url={`gallery/${gallery._id}`}
                          onDeleteSuccess={this.removeGalleryItemById(
                            gallery._id
                          )}
                          size="md"
                        />
                      )}
                      &nbsp;{" "}
                      {hasPermission("gallery", "edit") && (
                        <button
                          className=""
                          data-toggle="modal"
                          data-target="#add-gallery"
                          onClick={() => this.onEditGallery(gallery)}
                        >
                          <i className="uil-edit" />
                         {tid(" Edit")}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="gallery-body-container">
                    <p>{gallery.description}</p>
                    <div className="gallery-image-video-container">
                      {gallery.media.length > 0 &&
                        gallery.media[gallery.displayIndex].mediaType ===
                          "image" && (
                          <img
                            alt="Gallery"
                            src={gallery.media[gallery.displayIndex].fileUrl}
                            className="mb-1 max-150"
                          />
                        )}
                      {gallery.media.length > 0 &&
                        gallery.media[gallery.displayIndex].mediaType ===
                          "video" && (
                          <video controls autoplay>
                            <source
                              src={gallery.media[gallery.displayIndex].fileUrl}
                              type="video/mp4"
                            ></source>
                          </video>
                        )}
                    </div>
                    <div className="gallery-image-carousel">
                      <div
                        className="course-grid-slider"
                        uk-slider="finite: true"
                      >
                        <ul className="uk-slider-items uk-child-width-1-3@m uk-child-width-1-4@m uk-grid-match uk-grid media">
                          {gallery.media.map((media, j) => {
                            return (
                              <li key={"media-" + index + "-" + j}>
                                {media.mediaType === "image" && (
                                  <div className="relative">
                                    <img
                                      alt="Gallery"
                                      onClick={() =>
                                        this.onSelectMedia(index, j)
                                      }
                                      className=" mh-100"
                                      src={media.fileUrl}
                                    />
                                    {hasPermission("gallery", "remove") && (
                                      <span className="top-right-delete-icon">
                                        <i
                                          className="icon-feather-trash-2 danger"
                                          onClick={() =>
                                            this.onDeleteMedia(index, j)
                                          }
                                        ></i>
                                      </span>
                                    )}
                                  </div>
                                )}
                                {media.mediaType === "video" && (
                                  <div className="relative">
                                    <video
                                      onClick={() =>
                                        this.onSelectMedia(index, j)
                                      }
                                      className=" mh-100"
                                    >
                                      <source
                                        src={media.fileUrl}
                                        type="video/mp4"
                                      ></source>
                                    </video>
                                    {hasPermission("gallery", "remove") && (
                                      <span className="top-right-delete-icon">
                                        <i
                                          className="icon-feather-trash-2 danger"
                                          onClick={() =>
                                            this.onDeleteMedia(index, j)
                                          }
                                        ></i>
                                      </span>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                        <a
                          className="uk-position-center-left uk-position-small uk-hidden-hover slidenav-prev"
                          uk-slider-item="previous"
                        >
                          {" "}
                        </a>
                        <a
                          className="uk-position-center-right uk-position-small uk-hidden-hover slidenav-next"
                          uk-slider-item="next"
                        >
                          {" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="content-body">
            <div
              className="modal fade text-left uk-modal-full"
              id="add-media"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel1"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="myModalLabel1">
                     {tid(" New Gallery")}
                    </h3>
                    <button
                      type="button"
                      className="close rounded-pill"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fa fa-close"></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div>
                      <h5 className="uk-text-bold mb-2"> {tid("Title")}</h5>
                      <select
                        className="uk-input"
                        value={this.state.mediaType}
                        name="mediaType"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Title")}
                      >
                        <option value="">{tid("Select Media")}</option>


                        <option value="image">{tid("Image")}</option>
                        <option value="video">{tid("Video")}</option>
                      </select>
                      {this.mediaValidator.message(
                        "Media Type",
                        this.state.mediaType,
                        "required"
                      )}
                    </div>
                    <div>
                      <h5 className="uk-text-bold mb-2"> {tid("Details ")}</h5>
                      <input
                        type="file"
                        id="file"
                        accept={
                          this.state.mediaType == "image"
                            ? "image/*"
                            : "video/mp4,video/x-m4v,video/*"
                        }
                        name="myFile"
                        className="form-control"
                        onChange={this.uploadFile}
                      />
                      {this.mediaValidator.message(
                        "File",
                        this.state.file,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-link mr-2 btn-sm"
                      data-dismiss="modal"
                    >
                      {tid("Cancel")}
                    </button>
                    <button
                      className="btn btn-default grey btn-sm"
                      onClick={() => this.saveMedia()}
                    >
                      {this.state.uploading && (
                        <IconLoader
                          size={2}
                          text=""
                          color={"#fff"}
                        ></IconLoader>
                      )}
                      &nbsp;{tid("Save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade text-left uk-modal-full"
              id="add-gallery"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel1"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="myModalLabel1">
                      {tid("New Gallery")}
                    </h3>
                    <button
                      type="button"
                      className="close rounded-pill"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fa fa-close"></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label> {tid("Title ")}</label>
                      <input
                        type="text"
                        className="uk-input"
                        value={this.state.title}
                        name="title"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Title")}
                      />
                      {this.galleryValidator.message(
                        "Title",
                        this.state.title,
                        "required"
                      )}
                    </div>
                    <div className="form-group">
                      <label> {tid("Details ")}</label>
                      <textarea
                        type="text"
                        className="uk-input"
                        rows="2"
                        value={this.state.description}
                        name="description"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Description")}
                      ></textarea>
                      {this.galleryValidator.message(
                        "Description",
                        this.state.description,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-link mr-2 btn-sm"
                      data-dismiss="modal"
                    >
                      {tid("Cancel")}
                    </button>
                    <button
                      className="btn btn-default grey btn-sm"
                      onClick={() => this.save()}
                    >
                      {tid("Save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(GalleryComponent);
