import React from "react";

import moment from 'moment';
import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get, create, remove, uploadToS3, getUserData } from "../../../utils";
import './reports.scss';
import swal from 'sweetalert2';

// 'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
// 'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
// 'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
// 'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
// 'tileview', 'videobackgroundblur', 'download', 'help', 'security', 'mute-everyone'

class ReportsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: []
    }
  }

  componentDidMount() {
    this.assignUserData();
    this.getClasses();

  }

  connectToJitsi(firstName, lastName, userType, userID) {
    var domain = 'meet.tetu.co.in';

    let toolbarOptions = [
      'microphone', 'camera', 'desktop', 'fullscreen', 'hangup', 'profile', 'chat', 'recording',
      'etherpad', 'raisehand', 'tileview',
      'download'
    ]


    if (userType == 'faculty' || userType == 'principal') {
      let extraOptions = ['mute-everyone', 'recording']
      extraOptions.forEach((option) => {
        toolbarOptions.push(option)
      })
    }

    var options = {
      roomName: "TeTuVideoConference",
      width: 1000,
      height: 500,
      parentNode: document.querySelector('#meet'),
      configOverwrite: {
        enableWelcomePage: false
      },
      interfaceConfigOverwrite: {
        APP_NAME: 'TeTu Meet',
        AUDIO_LEVEL_PRIMARY_COLOR: 'rgba(255,255,255,0.4)',
        AUDIO_LEVEL_SECONDARY_COLOR: 'rgba(255,255,255,0.2)',

        AUTO_PIN_LATEST_SCREEN_SHARE: 'remote-only',
        BRAND_WATERMARK_LINK: '',

        CLOSE_PAGE_GUEST_HINT: false,
        CONNECTION_INDICATOR_AUTO_HIDE_ENABLED: true,

        CONNECTION_INDICATOR_AUTO_HIDE_TIMEOUT: 5000,

        CONNECTION_INDICATOR_DISABLED: false,

        DEFAULT_BACKGROUND: '#474747',
        DEFAULT_LOCAL_DISPLAY_NAME: 'me',
        DEFAULT_LOGO_URL: '/assets/images/logo_v3.jpg',
        DEFAULT_REMOTE_DISPLAY_NAME: 'Fellow Jitster',
        DEFAULT_WELCOME_PAGE_LOGO_URL: '/assets/images/logo_v3.jpg',

        DISABLE_DOMINANT_SPEAKER_INDICATOR: false,

        DISABLE_FOCUS_INDICATOR: false,

        DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,

        DISABLE_PRESENCE_STATUS: false,

        DISABLE_RINGING: false,

        DISABLE_TRANSCRIPTION_SUBTITLES: false,

        DISABLE_VIDEO_BACKGROUND: false,

        DISPLAY_WELCOME_FOOTER: false,
        DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
        DISPLAY_WELCOME_PAGE_CONTENT: false,
        DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,

        ENABLE_DIAL_OUT: true,

        ENABLE_FEEDBACK_ANIMATION: false,

        FILM_STRIP_MAX_HEIGHT: 120,

        GENERATE_ROOMNAMES_ON_WELCOME_PAGE: true,

        HIDE_DEEP_LINKING_LOGO: true,

        HIDE_INVITE_MORE_HEADER: true,

        INITIAL_TOOLBAR_TIMEOUT: 20000,
        JITSI_WATERMARK_LINK: 'https://tetu.co.in',

        LANG_DETECTION: true,
        LIVE_STREAMING_HELP_LINK: 'https://tetu.co.in/live',
        LOCAL_THUMBNAIL_RATIO: 16 / 9,

        MAXIMUM_ZOOMING_COEFFICIENT: 1.3,

        MOBILE_APP_PROMO: true,
        NATIVE_APP_NAME: 'TeTu Meet',
        OPTIMAL_BROWSERS: ['chrome', 'chromium', 'firefox', 'nwjs', 'electron', 'safari'],
        POLICY_LOGO: null,
        PROVIDER_NAME: 'TeTu',

        RECENT_LIST_ENABLED: true,
        REMOTE_THUMBNAIL_RATIO: 1,

        SETTINGS_SECTIONS: ['devices', 'language', 'profile', 'calendar'],
        SHOW_BRAND_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,

        SHOW_CHROME_EXTENSION_BANNER: false,

        SHOW_DEEP_LINKING_IMAGE: false,
        SHOW_JITSI_WATERMARK: true,
        SHOW_POWERED_BY: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,

        TOOLBAR_ALWAYS_VISIBLE: false,

        TOOLBAR_BUTTONS: toolbarOptions,

        TOOLBAR_TIMEOUT: 4000,

        UNSUPPORTED_BROWSERS: [],

        VERTICAL_FILMSTRIP: true,

        VIDEO_LAYOUT_FIT: 'both',
        VIDEO_QUALITY_LABEL_DISABLED: false,

        makeJsonParserHappy: 'even if last key had a trailing comma'

      },
      userInfo: {
        displayName: firstName + ' ' + lastName,
        id: userID
      }
    }
    var api = new window.JitsiMeetExternalAPI(domain, options);

    api.addEventListener('participantJoined', function (data) {
      console.log(data)
    });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });

    this.connectToJitsi(firstName, lastName, userType, userID);
  }

  getClasses() {
    get(baseUrl + "classes/list-names")
      .then((response) => {
        this.setState({
          classes: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  render() {
    const { userType } = this.state
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper" className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <button id="meet">Join</button>
        </div>
      </div>
    );
  }
}

export default ReportsComponent;