import React from 'react';

const size = ["","10px", "20px", "30px", "40px", "50px"]

export class Loader extends React.Component {
    render() {
        return (
            <div>
                <center>
                    <i className="fa fa-circle-o-notch fa-spin" style={{fontSize:size[this.props.size], color: this.props.color}}></i>
                    {this.props.text && <span><br/>{this.props.text}</span>}
                    {/* <i className="fa fa-spinner fa-spin" style={{fontSize:size[this.props.size], color: "#3e416d"}}></i> */}
                </center>
            </div>
        )
    }
}


export class IconLoader extends React.Component {
    render() {
        return (
            <i className="fa fa-circle-o-notch fa-spin" style={{fontSize:size[this.props.size], color: this.props.color}}></i>
        )
    }
}
