import React from "react";
// import swal from 'sweetalert2';

import { Link } from "react-router-dom";
// import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";

// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { getUserData, get, create } from "../../../utils";
import "./performance.scss";
import { WithTranslations } from "../../../i18n/withTranslations";

const colors = [
  "rgba(55,125,255,.2)",
  "rgba(222,68,55,.2)",
  "rgba(0,201,167,.2)",
  "rgba(255,193,7,.2)",
  "rgba(0,223,252,.2)",
];
const grades = ["A", "B", "C", "D", "E"];
class StudentPerformanceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjects: [],
      userType: "",
      userID: "",
      studentID: "",
      profile: {},
      columns: [],
      overallGrades: [],
      feedback: {},
      message: "",
    };

    this.feedbackValidator = new SimpleReactValidator({});
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    let userID = this.props.match.params.userID;
    this.setState({
      userID,
      studentID: userID,
    });

    this.loadPerformance(userID);
  }

  loadPerformance(userID) {
    let studentID = this.state.userID || userID;
    get(baseUrl + "users/performance/" + (this.state.userID || userID))
      .then((response) => {
        let { profile, data, columns, overallGrades, feedback } = response.data;

        this.setState({
          profile,
          columns,
          subjects: data,
          overallGrades,
          feedback,
          message: feedback ? feedback.feedback : "",
          studentID: studentID,
        });
      })
      .catch((error) => {});
    // get(baseUrl + "users/progress-card/" + (this.state.userID || userID))
    // .then((response) => {

    //   console.log(response.data)
    // })
    // .catch((error) => {

    // });
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleOnChange(event) {
    this.setState({ message: event.target.value });
  }

  saveFeedback() {
    create(baseUrl + "feedbacks/add", "post", {
      feedback: this.state.message,
      userID: this.state.studentID,
    })
      .then((response) => {
        let data = response.data;

        this.setState({
          feedback: data,
        });
      })
      .catch((error) => {});
  }

  render() {
    const { tid } = this.props;
    const { profile, overallGrades, userType } = this.state;
    const { classID, admissionNo, firstName, grade } = profile || {
      classID: { name: "" },
      admissionNo: "NA",
      firstName: "NA",
      grade: "NA",
    };
    const { name } = classID || { name: "NA" };
    let indexGrade = 0;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    if (grade) {
      indexGrade = grades.indexOf(grade) || 0;
    }
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">
                <Link to={"/students"}>
                  <i className="uil-arrow-left" />
                </Link>
                {tid(`Student Performance`)}
              </h2>
            </div>
            <div className="app-page-header-right">
              <button
                type="button"
                className="app-add-option-button"
                data-toggle="modal"
                data-target="#feedback"
              >
                <i className="uil-upload"></i>{tid(`Feedback`)}
              </button>
            </div>
          </div>
          <div
            className="modal fade text-left"
            id="feedback"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">
                    {tid(`Feedback`)}
                  </h3>
                  <button
                    type="button"
                    className="close rounded-pill"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="col-form-label">{tid(`Feedback`)}:</label>
                    <textarea
                      className="form-control form-control-user"
                      value={this.state.message}
                      name="message"
                      onChange={(event) => this.handleOnChange(event)}
                      placeholder="Enter Feedback"
                    ></textarea>
                    {this.feedbackValidator.message(
                      "Feedback",
                      this.state.message,
                      "required"
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-secondary btn-sm"
                    data-dismiss="modal"
                  >
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid(`Close`)}</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ml-1 btn-sm"
                    onClick={() => this.saveFeedback()}
                  >
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid(`Save`)}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="student-performance-details-container">
            <div className="top-container">
              <div className="flex-section">
                <div className="image-section">
                  <img
                    src="/assets/images/avatars/user.svg"
                    className="user-profile-image"
                    alt="user profile"
                  />
                </div>
                <div className="details-section">
                  <h5>{firstName}</h5>
                  <p>
                    <span className="heading-text">{tid(`Class`)}:</span>
                    <span>{name}</span>
                  </p>
                  <p>
                    <span className="heading-text">{tid(`ID`)}:</span>
                    <span>{admissionNo}</span>
                  </p>
                  <p>
                    <span className="heading-text">{tid(`DOB`)}:</span>
                    <span>09-10-2000</span>
                  </p>
                </div>
                <div className="overall-grade-section">
                  <h5>{tid(`Overall Grade`)}</h5>
                  <div
                    className="grade-container"
                    style={{ backgroundColor: colors[indexGrade] }}
                  >
                    <p>{grade}</p>
                  </div>
                </div>
                <div className="tests-list-section">
                  {overallGrades.map((grade, index) => {
                    return (
                      <div key={"grade-" + index} className="text-center">
                        <small className="test-name" uk-tooltip={grade.name}>
                          {grade.name.substr(0, 4)}
                        </small>
                        <br />
                        <button
                          type="button"
                          className="btn btn-icon btn-outline-primary"
                          uk-tooltip={grade.name}
                        >
                          {grade.grade}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="bottom-container">
              <div className="subject-wise-performance-container">
                <div className="header-section">
                  <h5>{tid(`Subject Wise Performance`)}</h5>
                </div>
                <div className="body-section">
                  <div className="table-responsive ">
                    <table className="table">
                      <thead>
                        <tr className="subject-wise-table-header">
                          <th className="primary">{tid(`Subject`)}</th>
                          {this.state.columns.map((column, index) => {
                            return (
                              <th key={"column-" + index} className="primary">
                                {column}
                              </th>
                            );
                          })}
                          <th className="text-center primary">{tid(`Overall`)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.subjects.map((subject, index) => {
                          return (
                            <tr
                              className="subject-wise-table-row"
                              key={"row-" + index}
                            >
                              <td className="text-bold-600">{subject.name}</td>
                              {subject.tests.map((test, j) => {
                                if (test.submitted)
                                  return (
                                    <td key={"column-" + index + "-" + j}>
                                      <span
                                        uk-tooltip={
                                          test.marksEarned + "/" + test.marks
                                        }
                                      >
                                        {test.grade}
                                      </span>
                                    </td>
                                  );
                                else
                                  return (
                                    <td key={"column-" + index + "-" + j}></td>
                                  );
                              })}
                              <td
                                className="text-bold-600 text-center"
                                uk-tooltip={
                                  subject.marksEarned + "/" + subject.marks
                                }
                              >
                                {subject.grade}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="page-user-profile"></section>
        </div>
      </div>
    );
  }
}

export default WithTranslations(StudentPerformanceComponent);
