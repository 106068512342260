import React, { PureComponent } from 'react';
import {
  Line, XAxis, YAxis, Tooltip, Legend,
  ComposedChart, Bar, PieChart, Pie, Cell
} from 'recharts';

// const pieData = [
//   { name: '8A', value: 60 },
//   { name: '9A', value: 55 },
//   { name: '7A', value: 80 },
//   { name: '6A', value: 40 },
//   { name: '8B', value: 20 },
//   { name: '9B', value: 15 },
// ];

const COLORS = ['rgba(55,125,255,.4)', 'rgba(222,68,55,.4)', 'rgba(0,201,167,.4)', 'rgba(55,125,255,.4)', 'rgba(255,193,7,.4)', 'rgba(0,223,252,.4)']
export class ClassPieChart extends PureComponent {
  
  render() {
    const pieData = this.props.classes;
    return (
      <>
        <PieChart
          width={300}
          height={180}
          margin={{
            top: 0, left: -10, right: 10, bottom: 0
          }}
        >
          <Pie
            dataKey="count"
            // isAnimationActive={false}
            data={pieData}
            cx={150}
            cy={90}
            outerRadius={70}
            fill="#8884d8"
            label
          >
            {
              pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
          <Tooltip />
        </PieChart>
        <div className='labels-list-container'>
          {pieData.map((p, index) => {
            const { name } = p;
            const color = COLORS[index];
            return (
              <div className='label-data-container'>
                <div className='color-box' style={{backgroundColor: color}} />
                <p>{name}</p>
              </div>
            )
          })}
        </div>
      </>
    );
  }
}

const data = [
  {
    name: 'Mar', '8A': 30, '7A': 90, '9A': 70,
  },
  {
    name: 'Apr', '8A': 85, '7A': 60, '9A': 65,
  },
  {
    name: 'May', '8A': 90, '7A': 78, '9A': 55,
  },
  {
    name: 'Jun', '8A': 48, '7A': 91, '9A': 80,
  },
  {
    name: 'Jul', '8A': 62, '7A': 82, '9A': 85,
  },
  {
    name: 'Aug', '8A': 78, '7A': 90, '9A': 73,
  },
  {
    name: 'Sep', '8A': 80, '7A': 74, '9A': 54,
  },
];

export class FacultyChartLine extends PureComponent {
  render() {
    let {performance, classNames} = this.props;
    return (
      <ComposedChart
        width={300}
        height={210}
        data={performance}
        margin={{
          top: 5, right: 20, left: -20, bottom: 5,
        }}
      >
        <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <YAxis tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <Tooltip />
        <Legend />
        
        {classNames.map((name, index) => {
          return <Bar key={name+"-"+index} dataKey={name} fill={COLORS[index%6]} radius={[20, 20, 0, 0]} />
        })}
        {classNames.map((name, index) => {
          return<Line type="monotone" dataKey={name} stroke={COLORS[index%6]} strokeWidth={2} />
        })}
        
      </ComposedChart>
    );
  }
}