import React, { PureComponent } from 'react';
import {
  Line, XAxis, YAxis, Tooltip, Legend,
  ComposedChart, Bar
} from 'recharts';

const data = [
  {
    name: 'Test 1', 'Maths': 30, 'Phy': 90, 'Chem': 70, 'Bio': 80,
  },
  {
    name: 'Test 2', 'Maths': 85, 'Phy': 60, 'Chem': 65, 'Bio': 95,
  },
  {
    name: 'Test 3', 'Maths': 90, 'Phy': 78, 'Chem': 55, 'Bio': 90,
  },
  {
    name: 'Test 4', 'Maths': 48, 'Phy': 91, 'Chem': 80, 'Bio': 93,
  },
  {
    name: 'Test 5', 'Maths': 85, 'Phy': 60, 'Chem': 65, 'Bio': 95,
  },
];

const attendanceData = [
  {
    name: 'Mar', 'Week1': 30, 'Week2': 90, 'Week3': 70, 'Week4': 80,
  },
  {
    name: 'Apr', 'Week1': 85, 'Week2': 60, 'Week3': 65, 'Week4': 95,
  },
  {
    name: 'May', 'Week1': 90, 'Week2': 78, 'Week3': 55, 'Week4': 90,
  },
  {
    name: 'Jun', 'Week1': 48, 'Week2': 91, 'Week3': 80, 'Week4': 93,
  },
  {
    name: 'Jul', 'Week1': 85, 'Week2': 60, 'Week3': 65, 'Week4': 95,
  },
];

export class ParentChart extends PureComponent {
  render() {
    return (
      <ComposedChart
        width={450}
        height={210}
        data={this.props.attendance}
        margin={{
          top: 5, right: 20, left: -20, bottom: 5,
        }}
      >
        <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <YAxis tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <Tooltip />
        <Legend />
        <Bar dataKey="Absent" fill="rgba(222,68,55,.4)" maxBarSize={10} radius={[20, 20, 0, 0]} />
        <Bar dataKey="Present" fill="rgba(0,201,167,.4)" maxBarSize={10} radius={[20, 20, 0, 0]} />
        {/* <Bar dataKey="Week3" fill="rgba(222,68,55,.4)" maxBarSize={10} radius={[20, 20, 0, 0]} />
        <Bar dataKey="Week4" fill="rgba(0,223,252,.4)" maxBarSize={10} radius={[20, 20, 0, 0]} /> */}
      </ComposedChart>
    );
  }
}

export class ParentLineChart extends PureComponent {
    render() {
      return (
        <ComposedChart
          width={450}
          height={210}
          data={data}
          margin={{
            top: 5, right: 20, left: -20, bottom: 5,
          }}
        >
          <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
          <YAxis tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Maths" stroke="#3e416b" strokeWidth={3} />
          <Line type="monotone" dataKey="Phy" stroke="rgba(0,201,167,.4)" strokeWidth={3} />
          <Line type="monotone" dataKey="Chem" stroke="rgba(222,68,55,.4)" strokeWidth={3} />
          <Line type="monotone" dataKey="Bio" stroke="rgba(0,223,252,.4)" strokeWidth={3} />
        </ComposedChart>
      );
    }
  }