import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, Tooltip, Legend,
  AreaChart, Area
} from 'recharts';
import { LanguageContext } from '../../../../i18n/LanguageProvider';

export class PrincipalCharts extends PureComponent {
  static contextType = LanguageContext;
  render() {
    const { content } = this.context;
console.log("--",this.props.data);
    return (
      <BarChart
        width={300}
        height={200}
        data={this.props.data}
        margin={{
          top: 5, right: 20, left: -30, bottom: 5,
        }}
      >
        <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 8, fontWeight: 600}} stroke="#ddd" />
        <YAxis tick={{ fill: '#333', fontSize: 8, fontWeight: 600 }} stroke="#ddd" />
        <Tooltip />
        <Legend />
        <Bar maxBarSize={10} dataKey={content.PRESENT||"Present"} fill='#3c9be2' radius={[50, 50, 0, 0]} />
        <Bar maxBarSize={10} dataKey={content.ABSENT||"Absent"} fill="#3e416c" radius={[50, 50, 0, 0]} />
      </BarChart>
    );
  }
}


export class RevenueReportChart extends PureComponent {
  render() {
    return (
      <AreaChart
        width={300}
        height={200}
        data={this.props.data}
        margin={{
          top: 10, right: 10, left: -20, bottom: 0,
        }}
      >
        <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 8, fontWeight: 600 }} stroke="#ddd" />
        <YAxis tick={{ fill: '#333', fontSize: 8, fontWeight: 600}} stroke="#ddd" />
        <Tooltip />
        <Area fillOpacity="1" dataKey="Amount" strokeWidth="2" stroke="#3c9be2" fill="#3e416b" dot={{ stroke: '#3c9be2', strokeWidth: 2 }} />
      </AreaChart>
    );
  }
}