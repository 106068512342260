import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { localLanguages } from "../../../../constants";
import englishJson from '../../../../i18n/en-US.json';
import teluguJson from '../../../../i18n/te.json';
import bengaliJson from '../../../../i18n/be.json';
import hindiJson from '../../../../i18n/hi.json';
import kannadaJson from '../../../../i18n/ka.json';
import malayalamJson from '../../../../i18n/ma.json';
import marathiJson from '../../../../i18n/mr.json';
import tamilJson from '../../../../i18n/ta.json';
import urduJson from '../../../../i18n/ur.json';
import oriyaJson from '../../../../i18n/or.json';
import italianJson from '../../../../i18n/it.json';
import { LanguageContext } from "../../../../i18n/LanguageProvider";

const WebsiteNavbar = ({invertLogo, homePage = false}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [width, setWidth] = useState(75);
  const lang = navigator.language;
  const [language, setLanguage] = useState(lang.startsWith("it") ? "it":'en-US');
  const languageContext = React.useContext(LanguageContext)

  useEffect(() => {
debugger;
    window.scrollTo(0, 0);
    const isMobileT = window.innerWidth <= 1024;
    setIsMobile(isMobileT)
    document.addEventListener("scroll", () => {
      const isTopTemp = window.scrollY < 40;
      setIsTop(isTopTemp);
    });
    const lang = localStorage.getItem('local-lang') || language;
    let calWidth = 75;
    if (lang === 'ma') {
      calWidth = 95
    } else if (lang === 'en-US' || lang === 'be' || lang === 'te' || lang === 'mr') {
      calWidth = 75
    } else if (lang === 'ka') {
      calWidth = 85
    } else {
      calWidth = 65
    }
    handleOnLanguageChange(lang);
    setLanguage(lang)
    setWidth(calWidth)
    // document.addEventListener("mousedown", this.handleClickOutside);
  }, [])

  // componentWillUnmount() {

  //     document.removeEventListener("mousedown", this.handleClickOutside)

  //   }

  // handleClickOutside = (e) => {
  //   console.log('e.target.classList', e.target.classList)
  //   if (!e.target.classList.contains('nav-links-section-xs') ||
  //     !e.target.classList.contains('menu-section-xs') ||
  //     !e.target.classList.contains('nav-links-section-xs-link') ||
  //     !e.target.classList.contains('uil-bars') ||
  //     !e.target.classList.contains('') ||
  //     !e.target.classList.contains('request-demo-button')) {
  //       if (this.state.showMenu) {
  //         this.setState({showMenu: false})
  //       }
  //   }
  // }

  const handleOnChange = (e) => {
    const {value} = e.target;
    let calWidth = 70
    if (value === 'ma') {
      calWidth = 95
    } else if (value === 'en-US' || value === 'be' || value === 'te' || value === 'mr') {
      calWidth = 75
    } else if (value === 'ka') {
      calWidth = 85
    } else {
      calWidth = 65
    }
    setWidth(calWidth);
    setLanguage(value)
    localStorage.setItem('local-lang', value)
    handleOnLanguageChange(value);
  }

  const handleOnLanguageChange = (value) => {
    let contentJson = englishJson;
    switch (value) {
      case 'be': contentJson = bengaliJson; break;
      case 'hi': contentJson = hindiJson; break;
      case 'ka': contentJson = kannadaJson; break;
      case 'ma': contentJson = malayalamJson; break;
      case 'mr': contentJson = marathiJson; break;
      case 'ta': contentJson = tamilJson; break;
      case 'te': contentJson = teluguJson; break;
      case 'ur': contentJson = urduJson; break;
      case 'or': contentJson = oriyaJson; break;
      case 'it': contentJson = italianJson; break;
      default: break;
    }

    languageContext.setSiteContent(contentJson);
    languageContext.setCurrentLanguage(value)
  }
  const { content:pc} = languageContext;
  return (
    <div
      className={`${
        isTop
          ? `navbar-container ${homePage ? 'theme-bg' : ''}`
          : `navbar-container navbar-container-inverted ${homePage ? 'theme-bg' : ''}`
      }`}
    >
     {language!=="it" &&  <div className='navbar-language-selection-container'>
        <div className='navbar-language-flex-container'>
          <select value={language} style={{width: width}} onChange={handleOnChange}>
            {localLanguages && localLanguages.map((l, i) => {
              return (
                <option value={l.value}>{l.name}</option>
              )
            })}
          </select>
        </div>
      </div>
          }
      <div className="navbar-flex-section">
        <div className="navbar-left-side">
          <div className="logo-section">
            <a href="/">
              <img
                className="main-logo"
                src={`${
                  invertLogo ? isTop
                    ? "/assets/images/logo.jpg"
                    : "/assets/images/logo.jpg" : "/assets/images/logo.jpg"
                }`}
                alt="TeTu"
              />
              {/* <div className="app-title">
                    <h4>TeTu</h4>
                    <span className='app-tagline'>The Next Big LMS Platform</span>
                  </div> */}
            </a>
          </div>
        </div>
        <div className="navbar-right-side">
          {isMobile && (
            <div
              className="menu-section-xs"
              onClick={() => setShowMenu(!showMenu)}
            >
              <i className={!showMenu ? "uil-bars" : "uil-times"} />
            </div>
          )}
          {!isMobile && (
            <ul className="nav-links-section">
              {/* <li>
                <a href="tel:+918341380020">
                  <i className="uil-phone" /> +91 83413 80020
                </a>
              </li> */}
              {/* <li>
                <Link to={"/about-us"}>{pc.NAV_HEADING_ABOUT_US}</Link>
              </li> */}
              <li>
                <Link to={"/pricing"}>{pc.NAV_HEADING_PRICING}</Link>
              </li>
              <li>
                <Link to={"/login"}>{pc.NAV_HEADING_LOGIN}</Link>
              </li>
              <li>
                <Link to={"/request-demo"}>
                  <button className="request-demo-button">
                    {pc.REQUEST_DEMO}
                  </button>
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
      {showMenu && (
        <ul className="nav-links-section-xs">
          {/* <li className="nav-links-section-xs-link">
            <a href="tel:+918341380020">
              <i className="uil-phone" /> +91 83413 80020
            </a>
          </li> */}
          {/* <li className="nav-links-section-xs-link">
            <Link to={"/about-us"}>{pc.NAV_HEADING_ABOUT_US}</Link>
          </li> */}
          <li className="nav-links-section-xs-link">
            <Link to={"/pricing"}>{pc.NAV_HEADING_PRICING}</Link>
          </li>
          <li className="nav-links-section-xs-link">
            <Link to={"/login"}>{pc.NAV_HEADING_LOGIN}</Link>
          </li>
          <li className="nav-links-section-xs-link">
            <Link to={"/request-demo"}>
              <button className="request-demo-button">{pc.REQUEST_DEMO}</button>
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
}


WebsiteNavbar.defaultProps = {
  invertLogo: true,
};

export default WebsiteNavbar;
