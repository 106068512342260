import React, { PureComponent } from 'react';
import {
  Tooltip, PieChart, Pie, Cell
} from 'recharts';
import { lightColors } from '../../../constants';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="#333" fontSize="10" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class PaymentPieChart extends PureComponent {
  render() {
    return (
      <>
        <PieChart width={200} height={200}>
          <Pie
            data={this.props.data}
            cx={100}
            cy={100}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="amount"
          >
            {
              this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={lightColors[index % lightColors.length]} />)
            }
          </Pie>
          <Tooltip />
        </PieChart>
        <div className='labels-list-container'>
          {this.props.data.map((p, index) => {
            const { name } = p;
            const color = lightColors[index];
            return (
              <div className='label-data-container'>
                <div className='color-box' style={{backgroundColor: color}} />
                <p>{name}</p>
              </div>
            )
          })}
        </div>
      </>
    );
  }
}