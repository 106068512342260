import React, { useState, useEffect } from "react";
import { baseUrl, videoUrl } from "../../../config";
import { get, create, getUserData } from "../../../utils";
import VideoRoomComponent from "../integrations/openvidu/components/VideoRoomComponent";
import "./live-classes.scss";

import swal from 'sweetalert';

class OV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      points: [],
      joinees: [],
      hostID: '',
      userPermission: '',
      socket: null,
      firstName: '',
      lastName: '',
      userType: '',
      fullName: '',
      mute: false,
      mutedAll: false,
      userID: '',
      liveClassID: ''
    }

    this.removeParticipant = this.removeParticipant.bind(this);
  }

  componentDidMount() {

    this.getData();

  }

  getData() {
    
    const { liveClassID, codeToken, userToken } = this.props.match.params;
    let { firstname, lastname, type, userID } = this.props.match.params;
  
    let firstName, lastName, userType;
  
    if(sessionStorage.getItem("token")) {
      let data = getUserData();
      firstName = data.firstName;
      lastName = data.lastName;
      userType = data.userType;
      if(!userID)
        userID = data.userID;
    } else {
      firstName = firstname;
      lastName = lastname;
      userType = type;
    }
    
    let fullName = `${firstName} ${lastName}`;
  
    get(baseUrl + "live-classes/load/" + liveClassID, codeToken, userToken)
    .then((response) => {
      let { title, points, joinees, createdBy, muted, locked } = response.data.data;

      this.setState({
        title,
        points,
        joinees,
        hostID: createdBy,
        userPermission: (createdBy == userID)?'accepted':'loading',
        firstName,
        lastName,
        userType,
        fullName,
        liveClassID,
        userID,
        mute: muted
      });

      if(!locked)
        this.socketConnection(userID, createdBy, liveClassID, firstName, lastName);
        
      if(userID != createdBy)
        this.setReciever(liveClassID, userID, createdBy);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  setReciever(liveClassID, userID, hostID) {

    const socket = window.io(videoUrl, { conferenceID: liveClassID, userID: userID });

    let scope = this;
    socket.on('mute'+liveClassID, function(data) {
      scope.setState({
        mute: data.muteAll
      })
    })
    
  }

  socketConnection(userID, createdBy, liveClassID, firstName, lastName) {

    const socket = window.io(videoUrl, { conferenceID: liveClassID, userID: userID });

    socket.emit("join", {
      conferenceID: liveClassID,
      userID,
      firstName,
      lastName,
      hostID: createdBy
    });

    socket.on("joining" + liveClassID+userID, function(data){
      swal('Joining Request', data.firstName+" is requesting to Join.", {
        buttons: {
          cancel: "Reject",
          defeat: "Accept",
        },
      })
      .then((value) => {

        switch (value) {
          case "defeat":
            socket.emit("accepted", {
              conferenceID: liveClassID,
              userID: data.userID
            });
            break;
          default:
            socket.emit("rejected", {
              conferenceID: liveClassID,
              userID: data.userID
            });
        }
      });
    });

    let scope = this;
    socket.on("accepted"+liveClassID+userID, function() {
      scope.setState({
        userPermission: 'accepted'
      });
    })

    socket.on("rejected"+liveClassID+userID, function() {
      window.location.href='/live-classes';
    })

    socket.on("remove"+liveClassID+userID, function() {
      window.location.href='/live-classes';
    })

  }

  muteAll(muteAll) {
    let { liveClassID, userID, hostID } = this.state;
    const socket = window.io(videoUrl, { conferenceID: liveClassID, userID: userID });
    socket.emit("mute-all", {muteAll: muteAll, liveClassID, userID, hostID});
    this.setState({
      mutedAll:muteAll
    })
    this.updateData(liveClassID, muteAll);
  }

  updateData(liveClassID, muteAll) {
    create(baseUrl + "live-classes/" + liveClassID, 'put', {muted: muteAll})
      .then((response) => {
      })
  }

  removeParticipant(e) {
    let { liveClassID, userID } = this.state;
    const socket = window.io(videoUrl, { conferenceID: liveClassID, userID: userID });
    swal("Are you sure want to remove", {
      buttons: {
        cancel: "No",
        defeat: "Yes",
      },
    })
    .then((value) => {

      switch (value) {
        case "defeat":
          socket.emit("remove", e);
          break;
      }
    });
  }

  render() {
    return (
      <div>
        {this.state.userPermission == 'accepted' && <VideoRoomComponent
          sessionName={this.state.liveClassID}
          liveClassID={this.state.liveClassID}
          user={this.state.fullName}
          meetingTitle={this.state.title}
          joinees={this.state.joinees}
          attendees={this.state.attendees}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          userType={this.state.userType}
          userID={this.state.userID}
          hostID={this.state.hostID}
          codeToken={this.state.codeToken}
          userToken={this.state.userToken}
          whiteboard={true}
          chat={true}
          screenshare={true}
          maxWindow={true}
          mute={this.state.mute}
          mutedAll={this.state.mutedAll}
          muteAll={() => this.muteAll(true)}
          unMuteAll={() => this.muteAll(false)}
          removeParticipant={this.removeParticipant}
        />}
        {this.state.userPermission == 'loading' && 
          <div className="text-center pt-5">
            <h1>Please wait...</h1>
            <i class="fa fa-spinner fa-spin fa-3x"></i>
            <h6>Your host is yet to accept your join request.</h6>
          </div>
        }
      </div>
    )
  }
}


export default OV2;
