import React from "react";
// import swal from 'sweetalert2';
// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
import { baseUrl } from "../../../config";
import { getUserData, get, create } from "../../../utils";

export default class LiveChatComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chatID: '',
      name: '',
      chatFrom: '',
      message: '',
      userID: ''
    }
  }

  assignUserData() {
    if(sessionStorage.getItem("token")) {
      let { firstName, lastName, userType, userID } = getUserData();
      this.setState({ firstName, lastName, userType, userID });
    } else {
      let { firstName, lastName, userType, userID } = this.props;
      this.setState({ firstName, lastName, userType, userID });
    }
    
  }

  componentDidMount() {
    this.assignUserData();
    let chatID = this.props.chatID;
    this.setState({
      chatID,
      name: this.props.name,
      chatFrom: this.props.chatFrom
    })
    this.addChat(chatID);
    this.loadChat(chatID);

  }

  addChat(chatID) {
    var chatConnection = new window.RTCMultiConnection();
    // this line is VERY_important
    chatConnection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';
    // if you want text chat
    chatConnection.session = {
      data: true
    };
    var scope = this;
    chatConnection.onmessage = function (event) {
      let { data } = event;
      scope.displayData(data, false)
    };
    chatConnection.openOrJoin(chatID);
    document.getElementById("send-text").onclick = function () {
      scope.saveMessage({ userID: scope.state.userID, message: document.getElementById("input-text").value })
      let data = {
        firstName: scope.state.firstName,
        lastName: scope.state.lastName,
        userID: scope.state.userID,
        message: document.getElementById("input-text").value
      }
      chatConnection.send(data);
      scope.displayData(data, true);
      document.getElementById("input-text").value = '';
    }
  }

  displayData(data, self) {
    let className = '';
    if (self)
      className = 'me';
    else
      className = '';
    var div = document.createElement('div');
    var conversationPanel = document.getElementById("chat-messages");
    div.innerHTML = `<div class="message-bubble ${className}">
        <div class="message-bubble-inner">
            `+ (this.props.hideLogo ? `` : `<div class="message-avatar"><img src="/assets/images/profile.svg" alt="" /></div>`) +
      `<div class="message-text np">
                <small class="tiny-text">${data.firstName}</small>
            <p> ${data.message}</p>
            </div>
        </div>
        <div class="clearfix"></div>
        </div>`;
    conversationPanel.appendChild(div);
  }

  saveMessage(data) {
    data.liveClassID = this.state.chatID;
    create(baseUrl + "chats/add", 'post', data, this.props.codeToken, this.props.userToken);
  }

  loadChat(chatID) {
    get(baseUrl + "chats/list/" + chatID, this.props.codeToken, this.props.userToken)
      .then((response) => {
        response.data.data.forEach((message) => {
          this.displayData(message, message.self)
        })
      })
  }

  render() {
    return (
      <div className="message-content">
        <div className="message-content-inner" id="chat-messages"></div>
        <div className={"message-reply " + (this.state.chatFrom === 'live-class' ? 'np' : '')}>
          <textarea id="input-text" cols="1" rows="1" placeholder="Your Message" data-autoresize></textarea>
          <button className="send-btn d-inline-block btn btn-default btn-xs" id="send-text">Send <i className="bx bx-paper-plane"></i></button>
        </div>
      </div>
    );
  }
}