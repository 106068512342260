import React from "react";
// import swal from 'sweetalert2';

import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';

// import { Loader, IconLoader } from "../../shared/loader";
// import Navbar from "../shared/navbar/Navbar";
// import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { get } from "../../../utils";

class QuizReviewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          quizID: "",
          title: "",
          duration: 0,
          questions: [],
          currentQuestion: {},
          currentQuestionIndex: -1,
          timeTaken: {}
        }
    }

    componentDidMount() {
      let quizID = this.props.match.params.quizID;
      this.setState({
        quizID
      })

      this.getQuestions(quizID);
    }

    getQuestions(quizID) {
      let userDetails = JSON.parse(sessionStorage.getItem("user_data"));
      this.setState({
        userType: userDetails.userData.userType
      })

      get(baseUrl + "quizs/review/"+(this.state.quizID || quizID))
        .then((response) => {
          let { title, questions, duration, timeTaken } = response.data.data;

          this.setState({
            timeTaken,
            title,
            questions,
            duration
          })

          this.startTest();
        })
        .catch((error) => {

        });
    }

    startTest() {
      this.setState({
        currentQuestion: this.state.questions[0],
        currentQuestionIndex: 0
      })
    }

    onClickActions(type) {
      let counter = 0;
      if(type === 'previous')
        counter = -1;
      else
        counter = 1;
      let currentQuestionIndex = this.state.currentQuestionIndex + counter;
      let question = this.state.questions[currentQuestionIndex];
      this.setState({
        currentQuestion: question,
        currentQuestionIndex: currentQuestionIndex
      })
    }

    render() {
        return (
          <div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 np">
                  <div className="card col-lg-12">
                    <div className="card-header pl-5">
                      
                      <h4 className="card-title"><Link to={"/dashboard"} ><i className="icon-feather-home"></i></Link> {this.state.title}</h4>
                      <span>Time taken: {this.state.timeTaken.hours<10?"0"+this.state.timeTaken.hours:"00"}:{this.state.timeTaken.minutes<10?"0"+this.state.timeTaken.minutes:"00"}:{this.state.timeTaken.seconds<10?"0"+this.state.timeTaken.seconds:"00"} </span>
                      
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 row pt-0 mt-0">
                  <div className="col-lg-9 pb-3">
                      <h6 className="pl-5">Question {this.state.currentQuestionIndex + 1} of {this.state.questions.length}:</h6>
                      {(this.state.currentQuestion && this.state.currentQuestion._id) && <div className="pl-5">
                        {(this.state.currentQuestion.questionType !== 'matching') && <div className="pb-2">
                          <pre className="question-area">{this.state.currentQuestion.question}</pre>
                        </div>}

                        {this.state.currentQuestion.questionType === 'matching' && <div>
                          <h6>Match the following:</h6>
                          <div className="row">
                            <div className="col-md-6 text-center">
                              <label className="text-center">(A):</label>
                              {this.state.currentQuestion.matchingA.map((option, index) => {
                              return(<fieldset key={"matchingA-"+index} className="form-group">
                                  <div className="checkbox-shadow">
                                    <span>{option.optionTitle})</span>&nbsp;&nbsp;
                                    <span htmlFor={"option-"+index}>
                                        {option.question}
                                    </span>
                                  </div>
                                </fieldset>)})}
                              </div>
                              <div className="col-md-6 text-center">
                                <label className="text-center">(B):</label>
                                {this.state.currentQuestion.matchingB.map((option, index) => {
                                return(<fieldset key={"matchingB-"+index} className="form-group">
                                <div className="checkbox-shadow">
                                  <span>{option.optionTitle})</span>&nbsp;&nbsp;
                                  <span htmlFor={"option-"+index}>
                                    {option.question}
                                  </span>
                                </div>
                              </fieldset>)})}
                            </div>
                          </div>
                        </div>}

                        <div className="section-header pt-1 border-0 uk-flex-middle">
                          <div className="section-header-left">
                            <h4 className="uk-heading-line text-left"><span> Your Answer </span></h4>
                          </div>
                        </div>
                        {(this.state.currentQuestion.questionType !== 'blanks') && (this.state.currentQuestion.questionType !== 'matching') && (this.state.currentQuestion.questionType !== 'essay') && <div>
                          <ul className="list-unstyled mb-0">
                           {this.state.currentQuestion.options && this.state.currentQuestion.options.map((option, index) =>{
                           return (<li className="d-inline-block col-lg-12 pb-1">
                              <fieldset>
                                <div className="checkbox checkbox-shadow row">
                                  <input type="checkbox" 
                                  disabled
                                  id={"checkboxshadow"+index} 
                                  name={ "options#selected#"+index }
                                  value={option.selected}
                                  checked={option.selected}/>&nbsp;
                                  <label htmlFor={"checkboxshadow"+index}>{option.value}</label>
                                </div>
                              </fieldset>
                           </li>)})}
                          </ul>
                        </div>}
                        {this.state.currentQuestion.questionType === 'essay' && <div>
                          <textarea className="form-control" rows="8" disabled
                          value={this.state.currentQuestion.essayAnswer}
                          name="essayAnswer"
                         ></textarea>
                        </div>}

                        {
                          this.state.currentQuestion.questionType === 'blanks' && <div className="row col-lg-12">
                            <div className="col-lg-6 np">
                              <fieldset className="form-group">
                              {this.state.currentQuestion.blanks.map((answer, index) => {
                                  return(<input key={"currentQuestion-"+index} type="text" className="form-control p10" 
                                      value={answer}
                                      disabled
                                      name={ "blanks#"+index }
                                      placeholder={"Enter Answer for Blank "+(index+1)}
                                  />)
                              })}
                              </fieldset>
                            </div>
                          </div>
                        }
                        {
                          this.state.currentQuestion.questionType === 'matching' && <div className="row col-lg-12">
                          <div className="col-lg-6 offset-lg-3 pb-2">
                            {this.state.currentQuestion.matchingA.map((option, i) => {
                              return (<div className="row pt-1">
                                  <div className="col-md-6 pt-2">
                                      <span className="pull-right float-right">{option.optionTitle}) Matches With <i className="icon-feather-arrow-right"></i> </span> 
                                  </div>
                                  <div className="col-md-6">
                                  {this.state.currentQuestion.matchingB.map((option, j) => {
                                      return (
                                      <button type="button" className={"btn ml-1 "+((this.state.currentQuestion && this.state.currentQuestion.answers && (this.state.currentQuestion.answers[i] === j))?" btn-default":" btn-outline-default")}>{option.optionTitle}</button>
                                  )})}
                                  </div>
                              </div>)})}
                            </div>
                          </div>
                        }
                        {this.state.currentQuestion.answerType !== 'skipped' && <span>You have choosen {this.state.currentQuestion.answerType === 'correct'?<b className="green-text">Correct Answer</b>:<b className="red-text">Wrong Answer</b>}</span>}
                        {this.state.currentQuestion.answerType === 'skipped' && <span>You have <b className="info-text">skipped</b> this question.</span>}
                        
                        <div className="pt-5">
                          {(this.state.currentQuestionIndex > 0) && <button type="button" className="btn btn-outline-danger" onClick={() => this.onClickActions('previous')}><i className="fa fa-angle-left"></i> <span className="align-middle ml-25">Previous</span></button>}
                          {(this.state.currentQuestionIndex < (this.state.questions.length - 1)) && <button type="button" className="btn btn-outline-primary float-right pull-right" onClick={() => this.onClickActions('next')}><span className="align-middle ml-25">Next </span><i className="fa fa-angle-right"></i></button>}
                        </div>
                      </div>}

                    </div>
                    <div className="col-lg-3">
                      <div className="pl-1 mr-1">
                        <h4 className="row">Questions:</h4>
                        <div className="row ">
                          {this.state.questions.map((question, index) => {
                            if(question.answerType !== 'skipped') {
                              return (<button key={"qstn-"+index} type="button" className={"btn btn-icon mr-1 mt-1 "+((question.answerType === 'correct')?"btn-success":"btn-danger")}>{question.index}</button>)
                            } else {
                              return (<button key={"qstn-"+index} type="button" className={"btn btn-icon mr-1 mt-1 btn-outline-info"}>{question.index}</button>);
                            }
                          })}
                        </div>
                      </div>
                      
                    </div>
                    
                  </div>
              </div>
          </div>
        );
    }
}

export default QuizReviewComponent;