import React from "react";
// import swal from 'sweetalert2';
// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
// import Navbar from "../shared/navbar/Navbar";
// import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get } from "../../../utils";
import QuestionComponent from "../questions/question";
import './quiz.scss'

export default class SubmissionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quizID: '',
      submissions: [],
      showIndex: -1
    }
  }

  componentDidMount() {
    this.setState({
      quizID: this.props.quizID
    })

    get(baseUrl + "quiz-submissions/list/" + this.props.quizID)
      .then((response) => {
        this.setState({
          submissions: response.data.data
        })
      })
  }

  showToggle(index) {
    let submissions = this.state.submissions;
    submissions[index].show = !submissions[index].show;
    this.setState({
      submissions
    })
  }

  render() {
    return (
      <div className="quiz-submission-details-container">
        {this.state.submissions.map((submission, index) => {
          return (<div key={"test-submission-" + index} className='student-quiz-data-m--container'>
            <div className="student-details-flex-container">
              <div className="left-side">
                <h4 className="np">{submission.userID.firstName} {submission.userID.lastName}</h4>
                <p className="np">{submission.userID.admissionNo}</p>
                <p className='show-more-link' onClick={() => this.showToggle(index)}>Show more details</p>
              </div>
              <div class="right-side">
                <div className='flex-container'>
                  <div class="quiz-stat-section bg-success">
                    <h6>{submission.correct}</h6>
                    <p>Correct</p>
                  </div>
                  <div class="quiz-stat-section bg-danger">
                    <h6>{submission.incorrect}</h6>
                    <p>Wrong</p>
                  </div>
                  <div class="quiz-stat-section bg-default">
                    <h6>{submission.skipped}</h6>
                    <p>Skipped</p>
                  </div>
                </div>
              </div>
            </div>
            {submission.show && <div className="submission-deatils-accordion-container">
              {submission.answers.map((answer, index) => {
                return (
                  <div key={"question-" + index} className="sdac-row">
                    <div className='aq-q-container-collapsible-container'>
                      <QuestionComponent className="np" key={"question-" + index} question={answer.questionID} index={index + 1} classID='' subjectID='' chapterID='' disabled={true} hide={true} />
                      <div className="extra-details--row">
                        <div className="answer-heading">
                          {answer.answerType === 'incorrect' ? <span className='red-text'><i className='uil-times-circle' /></span> : <span className='text-success'><i className='uil-check-circle' /></span>}
                        </div>
                        <div className="student-answer-section">
                          Answer Given: {answer.questionID.questionType === 'matching' ? answer.answers.map((ans, j) => {
                          return <span>
                            <span>{answer.questionID.matchingA[j].question.toString()} => {answer.questionID.matchingB[ans].question.toString()}</span>
                          </span>
                        }) : answer.answers.map((ans, j) => {
                          return <span className="" key={"aswers-" + index + "-" + j} > {ans} </span>
                        })}
                        </div>
                        <div className="time-taken-section">
                          <i className='uil-clock' />Time Taken: {answer.timeTaken.hours < 10 ? "0" + answer.timeTaken.hours : answer.timeTaken.hours}:{answer.timeTaken.minutes < 10 ? "0" + answer.timeTaken.minutes : answer.timeTaken.minutes}:{answer.timeTaken.seconds < 10 ? "0" + answer.timeTaken.seconds : answer.timeTaken.seconds}</div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>}
          </div>)
        })}
      </div>
    );
  }
}