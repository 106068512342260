import jwt from 'jsonwebtoken';
import AWS from 'aws-sdk';
// import * as fs from 'fs';
import axios from "axios";

import { secretCode } from "./config";

import { ignoreMenuOptions } from './constants';
var request = require('request').defaults({ encoding: null });

const s3 = new AWS.S3({
    accessKeyId: "AKIARGMA3GGT2E3ROO52",
    secretAccessKey: "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",
    region: "ap-south-1",
    params: { Bucket: "tetu" }
});

const generateJwtToken = (data) => {

    return jwt.sign({ data }, secretCode, { expiresIn: '365d' });

};

const decodeJwtToken = (jwtToken) => {

    return jwt.verify(jwtToken, secretCode, (error, decodedData) => {
        if (!error) {
            return decodedData.data;
        } else {
            return {};
        }
    });
};

const getUserData = () => {
    let token = sessionStorage.getItem("token");
    if (token) {
        return decodeJwtToken(token);
    }
}

const hasBranchPermission = () => {
    let token = sessionStorage.getItem("token");
    if (token) {
        let { branchPermission } = decodeJwtToken(token);
        return branchPermission;
    } else {
        return null;
    }
}

const getFormattedMenu = (menuList, permissions) => {
    let menuItems = [];
    if (!permissions)
        permissions = [];

    menuList.forEach((menu) => {
        let index = permissions.findIndex(e => e.name + "" === "" + menu.value);
        if (index >= 0 && permissions[index].menu)
            menuItems.push(menu);
    })

    return menuItems;
}

const getMenuOptions = (menuList) => {
    let menuItems = [];
    let token = sessionStorage.getItem("permissionsToken");
    if (token) {
        let { permissions } = decodeJwtToken(token);
        menuList.forEach((menu) => {
            let ignoreIndex = ignoreMenuOptions.indexOf(menu.value);
            if (ignoreIndex < 0) {
                let index = permissions && permissions.findIndex(e => e + "" === "" + menu.value);
                if (index >= 0)
                    menuItems.push(menu);
            } else {
                menuItems.push(menu)
            }
        })

    } else {
        return null;
    }

    return menuItems;
}

const featureAccess = (feature) => {
    let token = sessionStorage.getItem("permissionsToken");
    let { permissions } = decodeJwtToken(token);
    let permission = false;

    permissions.forEach((data) => {
        if ((data == feature))
            permission = true;
    })

    return permission;
}

const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
}

const removeScript = (scriptToremove) => {
    let allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--) {
        if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1) {
            allsuspects[i].parentNode.removeChild(allsuspects[i])
        }
    }
}

const uploadToS3 = (fileName, file, type) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        // const scope = this;
        reader.onload = function () {
            const fileContent = reader.result;

            let data = new Buffer(fileContent.replace("data:" + type + ";base64,", ""), "base64")
            var uploadabledata = {
                ACL: 'public-read',
                Key: fileName,
                Body: data,
                ContentType: type
            };

            s3.upload(uploadabledata, function (err, response) {
                console.log("Uploaded: ", fileName)
                if (err) {
                    console.log(err)
                    reject();
                } else {
                    resolve(fileName);
                }
            });
        }

        reader.readAsDataURL(file);
    });
}

const uploadFile = (fileName, file, type) => {
    return new Promise((resolve, reject) => {

        let data = new Buffer(file.replace("data:" + type + ";base64,", ""), "base64")
        var uploadabledata = {
            ACL: 'public-read',
            Key: fileName,
            Body: data,
            ContentType: type
        };

        s3.upload(uploadabledata, function (err, response) {
            console.log("Uploaded: ", fileName)
            if (err) {
                reject();
            } else {
                resolve(fileName);
            }
        });
    });
}

const getKeys = (codetoken) => {
    codetoken = codetoken ? codetoken : localStorage.getItem("codetoken");
    if (codetoken) {
        let { accessKey, licenseKey } = decodeJwtToken(codetoken);
        return { accessKey, licenseKey };
    } else {
        window.location.href = '/';
    }
}

const getMerchantID = () => {
    let codetoken = localStorage.getItem("codetoken");
    if (codetoken) {
        let { _id } = decodeJwtToken(codetoken);
        return _id;
    } else {
        return '';
    }
}

const get = (url, codetoken, token) => {
    token = token ? token : sessionStorage.getItem("token");
    let { accessKey, licenseKey } = getKeys(codetoken);
    return axios({
        url: url,
        method: "get",
        responseType: "application/json",
        headers: {
            "x-mcode-token": codetoken ? codetoken : localStorage.getItem("codetoken"),
            "x-token-code": token,
            "x-license-key": licenseKey,
            "x-access-key": accessKey,
            "Content-Type": "application/json"
        }
    })
}

const getExcel = (url, codetoken, token) => {
    token = token ? token : sessionStorage.getItem("token");
    let { accessKey, licenseKey } = getKeys(codetoken);
    return axios({
        url: url,
        method: "get",
        responseType: "application/json",
        headers: {
            "x-mcode-token": codetoken ? codetoken : localStorage.getItem("codetoken"),
            "x-token-code": token,
            "x-license-key": licenseKey,
            "x-access-key": accessKey,
            "Content-Type": "application/json",
            'Content-Disposition': "attachment; filename=template.xlsx",
            //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    });
}

const create = (url, method, data, codetoken, token) => {
    token = token ? token : sessionStorage.getItem("token");
    let { accessKey, licenseKey } = getKeys(codetoken);
    return axios({
        url: url,
        method: method,
        responseType: "application/json",
        headers: {
            "x-mcode-token": codetoken ? codetoken : localStorage.getItem("codetoken"),
            "x-token-code": token,
            "x-license-key": licenseKey,
            "x-access-key": accessKey,
            "Content-Type": "application/json"
        },
        data
    })
}

const remove = (url) => {
    let token = sessionStorage.getItem("token");
    let { accessKey, licenseKey } = getKeys();
    return axios({
        url: url,
        method: 'delete',
        responseType: "application/json",
        headers: {
            "x-mcode-token": localStorage.getItem("codetoken"),
            "x-token-code": token,
            "x-license-key": licenseKey,
            "x-access-key": accessKey,
            "Content-Type": "application/json"
        }
    })
}

export const getFile = (url, fileName) => {
    let token = sessionStorage.getItem("token");
    let { accessKey, licenseKey } = getKeys();
    return axios({

        url,
        method: 'GET',
        headers: {
            "x-token-code": token,
            "x-license-key": licenseKey,
            "x-access-key": accessKey,
            "Content-Type": "application/json"
        },
        responseType: 'blob', // important
    }).then((response) => {
        console.log("rep", response.headers);
        const xUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = xUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    });

}

const getOptionsData = (data) => {
    let options = [];

    data.forEach((option) => {
        options.push({
            name: option.name || (option.firstName + " " + option.lastName),
            id: option._id
        })
    })

    return options;
}

const getSelectedUsersData = (data) => {
    let options = [];
    data.forEach((option) => {
        options.push({
            name: option.userID.firstName + " " + option.userID.lastName,
            id: option.userID._id
        })
    })
    return options;
}

const hasPermission = (feature, option) => {
    let token = sessionStorage.getItem("token");
    let data = decodeJwtToken(token);
    let permission = false;

    if (data.userType == 'principal' || data.userType == 'chairman') {
        permission = true;
    } else {
        data.permissions.forEach((data) => {
            if ((data.name == feature) && (data[option]))
                permission = true;
        })
    }

    return permission;
}

const getBase64Data = (url) => {
    let data;
    return request.get(url, function (error, response, body) {
        if (!error && response.statusCode == 200) {
            data = "data:" + response.headers["content-type"] + ";base64," + Buffer.from(body).toString('base64');
            return data;
        }
    });

}

const getDefaultURL = () => {
    return 'https://tetu.co.in/assets/images/logo_v3.jpg';
}

const getMeetingOptions = (email, firstName, lastName, userType, userID, profilePicUrl, liveClassID, title, createdBy, invitees, isHost) => {

    let toolbarOptions = [
        'microphone', 'camera', 'closedcaptions', 'fullscreen', 'hangup', 'profile', 'chat',
        'raisehand', 'tileview', 'download'
    ]

    if (isHost) {

        let extraOptions = ['mute-everyone', 'security', 'desktop', 'localrecording']
        extraOptions.forEach((option) => {
            toolbarOptions.push(option)
        })

    } else {
        invitees = [];
    }

    return {
        roomName: liveClassID,
        width: '100%',
        height: '100%',
        parentNode: undefined,
        configOverwrite: {
            enableWelcomePage: false,
            startWithAudioMuted: true,
            startWithVideoMuted: true,
            enableClosePage: false,
            localRecording: {
                enabled: true,
                format: 'ogg'
            }
        },
        invitees: invitees,
        interfaceConfigOverwrite: {
            APP_NAME: 'TeTu Meet',
            AUDIO_LEVEL_PRIMARY_COLOR: 'rgba(255,255,255,0.4)',
            AUDIO_LEVEL_SECONDARY_COLOR: 'rgba(255,255,255,0.2)',
            AUTO_PIN_LATEST_SCREEN_SHARE: 'remote-only',
            BRAND_WATERMARK_LINK: '',
            CLOSE_PAGE_GUEST_HINT: false,
            CONNECTION_INDICATOR_AUTO_HIDE_ENABLED: true,
            CONNECTION_INDICATOR_AUTO_HIDE_TIMEOUT: 5000,
            CONNECTION_INDICATOR_DISABLED: false,
            DEFAULT_BACKGROUND: '#474747',
            DEFAULT_LOCAL_DISPLAY_NAME: 'me',
            DEFAULT_LOGO_URL: '/assets/images/logo_v3.jpg',
            DEFAULT_REMOTE_DISPLAY_NAME: 'TeTu User',
            DEFAULT_WELCOME_PAGE_LOGO_URL: '/assets/images/logo_v3.jpg',
            DISABLE_DOMINANT_SPEAKER_INDICATOR: false,
            DISABLE_FOCUS_INDICATOR: false,
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
            DISABLE_PRESENCE_STATUS: false,
            DISABLE_RINGING: false,
            DISABLE_TRANSCRIPTION_SUBTITLES: false,
            DISABLE_VIDEO_BACKGROUND: false,
            DISPLAY_WELCOME_FOOTER: false,
            DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
            DISPLAY_WELCOME_PAGE_CONTENT: false,
            DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
            ENABLE_DIAL_OUT: true,
            ENABLE_FEEDBACK_ANIMATION: false,
            FILM_STRIP_MAX_HEIGHT: 120,
            GENERATE_ROOMNAMES_ON_WELCOME_PAGE: true,
            HIDE_DEEP_LINKING_LOGO: true,
            HIDE_INVITE_MORE_HEADER: true,
            INITIAL_TOOLBAR_TIMEOUT: 20000,
            JITSI_WATERMARK_LINK: 'https://tetu.co.in',
            LANG_DETECTION: true,
            LIVE_STREAMING_HELP_LINK: 'https://tetu.co.in/live',
            LOCAL_THUMBNAIL_RATIO: 16 / 9,
            MAXIMUM_ZOOMING_COEFFICIENT: 1.3,
            MOBILE_APP_PROMO: true,
            NATIVE_APP_NAME: 'TeTu Meet',
            OPTIMAL_BROWSERS: ['chrome', 'chromium', 'firefox', 'nwjs', 'electron', 'safari'],
            POLICY_LOGO: null,
            PROVIDER_NAME: 'TeTu',
            RECENT_LIST_ENABLED: true,
            REMOTE_THUMBNAIL_RATIO: 1,
            SETTINGS_SECTIONS: ['devices', 'language', 'profile', 'calendar'],
            SHOW_BRAND_WATERMARK: false,
            SHOW_WATERMARK_FOR_GUESTS: false,
            SHOW_CHROME_EXTENSION_BANNER: false,
            SHOW_DEEP_LINKING_IMAGE: false,
            SHOW_JITSI_WATERMARK: true,
            SHOW_POWERED_BY: false,
            SHOW_PROMOTIONAL_CLOSE_PAGE: false,
            TOOLBAR_ALWAYS_VISIBLE: false,
            TOOLBAR_BUTTONS: toolbarOptions,
            TOOLBAR_TIMEOUT: 4000,
            UNSUPPORTED_BROWSERS: [],
            VERTICAL_FILMSTRIP: true,
            VIDEO_LAYOUT_FIT: 'both',
            VIDEO_QUALITY_LABEL_DISABLED: false,
            makeJsonParserHappy: 'even if last key had a trailing comma'
        },
        userInfo: {
            email,
            displayName: firstName + ' ' + lastName,
            id: userID
        }
    }
}

export {
    getUserData,
    decodeJwtToken,
    generateJwtToken,
    appendScript,
    removeScript,
    uploadToS3,
    uploadFile,
    get,
    create,
    remove,
    getOptionsData,
    getSelectedUsersData,
    getFormattedMenu,
    hasBranchPermission,
    hasPermission,
    getMenuOptions,
    featureAccess,
    getKeys,
    getMerchantID,
    getBase64Data,
    getDefaultURL,
    getMeetingOptions,
    getExcel
};