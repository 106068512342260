import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../config";
import { get, getUserData } from "../../../utils";
import VideoRoomSimpleComponent from "../../dashboard/integrations/openvidu/components/VideoRoomSimpleComponent";
import "../../dashboard/live-classes/live-classes.scss";
import swal from 'sweetalert';
import axios from "axios";

class WebinarConference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      message: "",
      organizationName: "",
      type: 'loading',
      webinarID: "",
      username: "",
      showButton: false
    };

    // this.webinarValidator = new SimpleReactValidator({});
  }

  componentDidMount() {
    
    this.updateName();
    let { webinarID } = this.props.match.params;

    axios({
      url: baseUrl + "webinars/"+webinarID,
      method: "get",
      responseType: "application/json",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        let type = 'invalid';
        let { name, mobile, email, message, organizationName } = response.data.data;

        this.setState({
          webinarID, name, mobile, email, message, organizationName
        })
      })
      .catch((error) => {
        console.log(error)
      });
  }

  updateName() {
    
    let name = localStorage.getItem("name");
    this.setState({
      showButton: false
    })
    if(!name) {
      swal({
        text: 'Enter Your Name',
        placeholder: "Enter Your Name",
        content: "input",
        button: {
          text: "Confirm!",
          closeModal: true,
        },
      })
      .then(name => {
        if(name) {
          this.setState({
            type: 'valid',
            username: name
          })
        } else {
          this.setState({
            showButton: true
          })
        }
      })
      .catch(err => {
      });
    } else {
      this.setState({
        type: 'valid',
        username: name
      })
    }
  }

  render() {
    return (
      <div>
        {this.state.type == 'valid' && 
        <VideoRoomSimpleComponent
          user={this.state.username}
          firstName={this.state.username}
        ></VideoRoomSimpleComponent>}
        {this.state.type == 'loading' && <h4><center>Loading..!! Please wait..<br/>
        {this.state.showButton && <button className="btn btn-sm btn-info" onClick={() => this.updateName()}>Verify</button>}
        </center></h4>}
        {this.state.type == 'invalid' && <h4><center>Invalid Url.</center></h4>}
      </div>
    );
  }
}

export default WebinarConference;