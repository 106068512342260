import React from "react";
import swal from 'sweetalert2';
import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { getUserData, get, create } from "../../../utils";
import { days } from "../../../constants";
import NoResults from "../../shared/no-results/no-results";
import { WithTranslations } from "../../../i18n/withTranslations";

class TimetableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjects: [],
      faculties: [],
      classID: '',
      className: '',
      timetableID: '',
      periods: [],
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear()
    }
    this.save = this.save.bind(this);
  }

  save() {
    let url = baseUrl + "timetable/";
    if (this.state.timetableID)
      url += this.state.timetableID;
    else
      url += 'add';

    create(url, this.state.timetableID ? "put" : "post", {
      periods: this.state.periods,
      classID: this.state.classID
    })
      .then((response) => {
        swal.fire({
          title: "Info",
          text: response.data.message,
          icon: "warning"
        });
      })
      .catch((error) => {

      });
  }

  getTimetable(classID) {
    get(baseUrl + "timetable/list/" + classID)
      .then((response) => {
        this.setState({
          periods: response.data.data,
          className: response.data.className,
          timetableID: response.data.timetableID
        })
      })
      .catch((error) => {

      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID })
  }

  componentDidMount() {
    this.assignUserData();
    let { classID } = this.props.match.params;
    this.setState({
      classID
    })
    this.getTimetable(classID);
    this.subjectsList(classID);
    this.getFacultiesList();
  }

  subjectsList(classID) {

    get(baseUrl + "subjects/list/" + classID)
      .then((response) => {
        this.setState({
          subjects: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  getFacultiesList() {
    get(baseUrl + "users/list/faculty")
      .then((response) => {
        this.setState({
          faculties: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  handleCheck = (event) => {
    const splitted = event.target.name.split("#");
    const studentIndex = splitted[0];
    const dayIndex = splitted[1];
    create(baseUrl + "attendance/add", "post", {
      studentID: this.state.students[studentIndex]._id,
      day: this.state.students[studentIndex].days[dayIndex],
      classID: this.state.classID
    })
      .then((response) => {
        this.getTimetable(this.state.classID);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  checkAttended(student, date) {
    let attended = false;
    this.state.attendance.forEach((attendance) => {
      if ((attendance.attendees.indexOf(student._id + "") >= 0) && (date.day === attendance.day) && (date.month === attendance.month) && (date.year === attendance.year))
        attended = true;
    })
    return attended;
  }


  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.getTimetable(this.state.classID);
  }

  addPeriod() {
    let periods = this.state.periods;

    let daysData = [];
    days.forEach((day) => {
      daysData.push({ day: day })
    })
    periods.push({
      startTime: "",
      endTime: "",
      days: daysData
    })
    this.setState({ periods })
  }

  handleListItem = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const index = splitted[1];
      const j = splitted[2];
      const value = event.target.value;
      let periods = this.state.periods;

      periods[parseInt(index, 10)].days[parseInt(j, 10)][name] = value;
      this.setState({
        periods
      })
    }
  }

  handleFieldEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const index = splitted[1];
      const value = event.target.value;
      let periods = this.state.periods;
      periods[parseInt(index, 10)][name] = value;
      this.setState({
        periods
      })
    }
  }

  render() {
    const {tid}=this.props;
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className=""><Link to={"/classes"}><i className='uil-arrow-left' /></Link>{tid('Timetable for')}{this.state.className} </h2>
            </div>
            <div className="app-page-header-right">
              <button type="button" className="app-add-option-button" onClick={this.save.bind(this)}>
                <i className='uil-file-alt' /> {tid('Save')}
              </button>
            </div>
          </div>
          <div className="scrollable add-period-for-class-container">
            <table className="table add-period-for-classs-table">
              <thead>
                <tr className='table-header-row'>
                  <th>
                    <button type="button" className="btn btn-xs" onClick={() => this.addPeriod()}>
                      <i className='uil-plus' />{tid('Add Period')}
                    </button>
                  </th>
                  <th>{tid('Monday')}</th>
                  <th>{tid('Tuesday')}</th>
                  <th>{tid('Wednesday')}</th>
                  <th>{tid('Thursday')}</th>
                  <th>{tid('Friday')}</th>
                  <th>{tid('Saturday')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.periods.map((period, index) => {
                    return (<tr className='table-data-row' key={"period" + index}>
                      <td>
                        <input type="time" className="form-control" placeholder="Enter Time" value={period.startTime} name={"startTime#" + index} onChange={this.handleFieldEdit} />
                        <input type="time" className="form-control" placeholder="Enter Time" value={period.endTime} name={"endTime#" + index} onChange={this.handleFieldEdit} />
                      </td>
                      {
                        period.days.map((day, j) => {
                          return (<td key={"day-" + index + "-" + j}>
                            <select
                              type="text"
                              className="form-control form-control-user"
                              name={"subjectID#" + index + "#" + j}
                              placeholder="Enter Key"
                              value={day.subjectID}
                              onChange={this.handleListItem}
                            >
                              <option value="">Subject</option>
                              {this.state.subjects.map((subject, k) => {
                                return (<option key={"subject-" + index + "-" + j + "-" + k} value={subject._id}>{subject.name}</option>)
                              })}
                            </select>

                            <select
                              type="text"
                              className="form-control form-control-user"
                              name={"facultyID#" + index + "#" + j}
                              placeholder="Enter Key"
                              value={day.facultyID}
                              onChange={this.handleListItem}
                            >
                              <option value="">Faculty</option>
                              {this.state.faculties.map((faculty, k) => {
                                return (<option key={"faculty-" + index + "-" + j + "-" + k} value={faculty._id}>{faculty.firstName}</option>)
                              })}
                            </select>

                          </td>)
                        })
                      }
                    </tr>)
                  })
                }
              </tbody>
            </table>
            {!this.state.periods.length && <NoResults title={tid("No Periods Added")} /> }
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(TimetableComponent);