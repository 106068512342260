import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, Tooltip, Legend,
  AreaChart, Area
} from 'recharts';

const gradeColors = ["#3c9be2","rgba(55,125,255,.4)","rgba(0,201,167,.8)","rgba(0,201,167,.4)","#3e416c",
"rgba(222,68,55,.2)","rgba(222,68,55,.65)"]

export class GradeWiseChart extends PureComponent {
  render() {
    return (
      <BarChart
        width={550}
        height={250}
        data={this.props.data}
        margin={{
          top: 5, right: 20, left: -30, bottom: 5,
        }}
      >
        <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <YAxis tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <Tooltip />
        <Legend />
        {this.props.grades.map((g, index) => {
          return <Bar key={"bar-"+index} maxBarSize={8} dataKey={g.name} fill={gradeColors[index%(gradeColors.length)]} radius={[50, 50, 0, 0]} />
        })}
      </BarChart>
    );
  }
}


export class TestPerformanceReport extends PureComponent {
  render() {
    return (
      <AreaChart
        width={550}
        height={200}
        data={this.props.data}
        margin={{
          top: 10, right: 10, left: -20, bottom: 0,
        }}
      >
        <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 8, fontWeight: 600 }} stroke="#ddd" />
        <YAxis tick={{ fill: '#333', fontSize: 8, fontWeight: 600 }} stroke="#ddd" />
        <Tooltip />
        <Area fillOpacity="1" dataKey="percentage" strokeWidth="2" stroke="#3c9be2" fill="#3e416b" dot={{ stroke: '#3c9be2', strokeWidth: 2 }} />
      </AreaChart>
    );
  }
}