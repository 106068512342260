import React from 'react'
import SVG from 'react-inlinesvg'
import './noresults.scss';

const NoResults = ({title}) => {
  return (
    <div className='no-results-container'>
      <SVG title={title} src="/assets/images/seo.svg" />
      <p>{title || "No Results Found"}</p>
    </div>
  )
}

export default NoResults;
