import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert2';
import * as $ from "jquery";

import { baseUrl } from "../../../config";
import { get, create } from "../../../utils";

export default class InventoryTrackComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inventory: {},
      tracking: [],
      employees: [],
      status: '',
      actionDate: '',
      actionType: '',
      takenBy: '',
      comments: '',
      trackingID: ''
    }
    this.hotoValidator = new SimpleReactValidator({});
  }

  componentDidMount() {
    this.setState({
      inventory: this.props.inventory
    })
    this.getList()
    this.getFaculty();
  }

  getList() {
    get(baseUrl + "inventories/track/" + this.props.inventory._id)
      .then((response) => {
        this.setState({
          tracking: response.data.data,
          status: (!response.data.data.length) ? 'handovered' : response.data.data[0].actionType
        })
      });
  }

  getFaculty() {
    get(baseUrl + "users/list/staff")
      .then((response) => {
        this.setState({
          employees: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  showList() {
    this.props.showList()
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  save() {
    if (this.hotoValidator.allValid()) {

      let url = baseUrl + "inventories/track/";
      if (!this.state.trackingID)
        url += "add";
      else
        url += this.state.trackingID

      create(url, (this.state.trackingID) ? "put" : "post", {
        actionDate: this.state.actionDate,
        actionType: this.state.actionType,
        takenBy: this.state.takenBy,
        comments: this.state.comments,
        inventoryID: this.props.inventory._id
      })
        .then((response) => {
          this.getList();
          $("#add-hoto").trigger("click");
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning"
          });
        })
        .catch((error) => {

        });
    } else {
      this.hotoValidator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div>
        <div className="inventory-track-main-container" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 100">
          <div className="inventory-track-top-container">
            <div className="go-back-container">
              <span onClick={() => this.showList()}><i className="uil-arrow-left"></i>Back to List</span>
            </div>
            <div className="inventory-details-flex-container">
              <div className="inventory-details-left-side">
                <img src={this.state.inventory.fileUrl || "/assets/images/course/1.png"} alt="Inventory" />
              </div>
              <div className="inventory-details-right-side">
                <h4>{this.state.inventory.name} </h4>
                <p>{this.state.inventory.description}</p>
                <p><i className="icon-feather-calendar"></i> {moment(this.state.inventory.createdAt).format("DD-MM-YYYY")}</p>
                <button className="btn btn-info" data-toggle="modal" data-target="#add-hoto">{this.state.status === 'handovered' ? 'Take Overed' : 'Hand Overed'}</button>
              </div>
            </div>
            <div className="inventory-track-list-container">
              <ul className="list-item">
                {this.state.tracking.map((track, index) => {
                  return (
                    <li className="row col-lg-12" key={"track-" + index}>
                      <h6>{track.actionType}({moment(track.actionDate).format('DD-MM-YYYY')})</h6>
                      <p><span>{track.comments}</span></p>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="modal fade text-left" id="add-hoto" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="myModalLabel1">Add Action</h3>
                <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <select
                    className="form-control form-control-user"
                    value={this.state.takenBy}
                    name="takenBy"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Select Employee"
                  >
                    <option value="">Select Employee</option>
                    {this.state.employees.map((faculty, index) => {
                      return (<option key={"index-" + index} value={faculty._id}>{faculty.firstName} {faculty.lastName}</option>)
                    })}
                  </select>
                  {this.hotoValidator.message(
                    "Employee",
                    this.state.takenBy,
                    "required"
                  )}
                </div>
                <div className="form-group">
                  <select
                    className="form-control form-control-user"
                    value={this.state.actionType}
                    name="actionType"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Select Action"
                  >
                    <option value="">Select Action</option>
                    <option value="handovered">Handovered</option>
                    <option value="takeovered">Takeovered</option>

                  </select>
                  {this.hotoValidator.message(
                    "Action Type",
                    this.state.actionType,
                    "required"
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control form-control-user"
                    value={this.state.actionDate}
                    name="actionDate"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Enter Action Date"
                  />
                  {this.hotoValidator.message(
                    "Action Date",
                    this.state.actionDate,
                    "required"
                  )}
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control form-control-user"
                    value={this.state.comments}
                    name="comments"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Comments"
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-light-secondary btn-xs" data-dismiss="modal">
                  <i className="bx bx-x d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">Close</span>
                </button>&nbsp;
                        <button type="button" className="btn btn-primary ml-1 btn-xs" onClick={() => this.save()}>
                  <i className="bx bx-check d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}