import React from "react";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../config";
import moment from "moment";
// import { Loader, IconLoader } from "../../shared/loader";
import { getUserData, get, create, getFile } from "../../../utils";
import { months, years } from "../../../constants";
import { WithTranslations } from "../../../i18n/withTranslations";

class AttendanceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      attendance: [],
      classID: '',
      days: [],
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear()
    }
  }

  getAttendance(classID, month, year) {
    get(baseUrl + "attendance/list/" + classID + '/' + month + "/" + year)
      .then((response) => {

        this.setState({
          students: response.data.data,
          days: response.data.days,
          attendance: response.data.attendance
        })
      })
      .catch((error) => {
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
    })
  }

  componentDidMount() {
    this.assignUserData();
    let { classID } = this.props.match.params;
    this.setState({
      classID
    })
    this.getAttendance(classID, this.state.selectedMonth, this.state.selectedYear);
  }

  handleCheck = (event) => {
    const splitted = event.target.name.split("#");
    const studentIndex = splitted[0];
    const dayIndex = splitted[1];
    create(baseUrl + "attendance/add", "post", {
      studentID: this.state.students[studentIndex]._id,
      day: this.state.students[studentIndex].days[dayIndex],
      classID: this.state.classID
    }
    )
      .then((response) => {
        this.getAttendance(this.state.classID, this.state.selectedMonth, this.state.selectedYear);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  checkAttended(student, date) {
    let attended = false;
    this.state.attendance.forEach((attendance) => {
      if ((attendance.attendees.indexOf(student._id + "") >= 0) && (date.day === attendance.day) && (date.month === attendance.month) && (date.year === attendance.year))
        attended = true;
    })
    return attended;
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value });
    let month = this.state.selectedMonth;
    let year = this.state.selectedYear;
    if (event.target.name === 'selectedMonth')
      month = event.target.value;
    else
      year = event.target.value;
    this.getAttendance(this.state.classID, month, year);
  }

  downloadHandler = () => {
    const { classID } = this.props.match.params;
    const { selectedMonth, selectedYear } = this.state;

    getFile(baseUrl + "attendance/download/" + classID + '/' + selectedMonth + "/" + selectedYear, "Attendance_"+moment([selectedYear, selectedMonth-1]).format("MMMM, YYYY")+".xlsx").then(console.log).catch(console.warn);
  }

  render() {
    const {tid}=this.props;
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className=""><Link to={"/classes"}><i className='uil-arrow-left' /></Link> {tid('Attendace')}</h2>
            </div>
            <div className="app-page-header-right">
              <select value={this.state.selectedMonth} name="selectedMonth" onChange={this.setFormField.bind(this)}>
                {months.map((month, index) => {
                  return (<option key={"month-" + index} value={month.value}>{tid(month.name)}</option>)
                })}
              </select>
              <select value={this.state.selectedYear} name="selectedYear" onChange={this.setFormField.bind(this)}>
                {years.map((year, index) => {
                  return (<option key={"year-" + index} value={year.value}>{year.name}</option>)
                })}
              </select>
              <button className="app-add-option-button" style={{backgroundColor: '#3e416c', borderColor: '#3e416c'}} onClick={this.downloadHandler}><i className='uil-download-alt' /> {tid('Download')}</button>
            </div>
          </div>
          <div className="card scrollable attendace-details-container">
            <table className="table ">
              <thead>
                <tr className='table-header-row'>
                  <th className="text-center border-right" rowSpan="2">{tid('Name')}</th>
                  <th className="text-center border-right" colSpan="7">{tid('Week')} 1</th>
                  <th className="text-center border-right" colSpan="7">{tid('Week')} 2</th>
                  <th className="text-center border-right" colSpan="7">{tid('Week')} 3</th>
                  <th className="text-center border-right" colSpan="7">{tid('Week')} 4</th>
                  <th className="text-center border-right" colSpan="7">{tid('Week')} 5</th>
                </tr>
                <tr className='table-header-second-row'>
                  {this.state.days.map((date, index) => {
                    return (<th key={"column-" + index} className="text-center border-right">{date.day}<br />{date.name}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.students.map((student, i) => {
                  return (
                    <tr key={"student-" + i} className='table-data-row'>
                      <td className="min-100">{student.firstName}</td>
                      {student.days.map((day, j) => {
                        return (
                          <td key={"day-" + j} className="text-center">
                            {(day) &&
                              <input
                                type="checkbox"
                                id={"checkbox-" + i + j}
                                value={this.checkAttended(student, day)}
                                checked={this.checkAttended(student, day)}
                                name={i + "#" + j}
                                onChange={this.handleCheck} />}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(AttendanceComponent)