import React from "react";
import jwt from 'jsonwebtoken';

import moment from 'moment';
import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get, create, remove, uploadToS3, getUserData, getDefaultURL, getMeetingOptions } from "../../../utils";
import swal from 'sweetalert2';

// 'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
// 'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
// 'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
// 'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
// 'tileview', 'videobackgroundblur', 'download', 'help', 'security', 'mute-everyone'

var api;

class MeetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      points: [],
      joinees: [],
      hostID: [],
      email: '',
      firstName: '',
      lastName: '',
      userType: '',
      liveClassID: '',
      userID: '',
      mute: '',
      host: false,
      started: false,
      joined: false
    }
  }

  componentDidMount() {
    this.assignUserData();
    this.getClasses();

    if(localStorage.getItem('jitsiLocalStorage'))
      localStorage.removeItem('jitsiLocalStorage');

  }

  connectToJitsi(domain, email, firstName, lastName, userType, userID, profilePicUrl, liveClassID, title, createdBy, invitees, isHost) {

    var options = getMeetingOptions(email, firstName, lastName, userType, userID, profilePicUrl, liveClassID, title, createdBy, invitees, isHost);
 
    new Promise((resolve, reject) => {
      resolve(new window.JitsiMeetExternalAPI(domain, options));
    })
    .then((response) => {
      api = response;
      this.setState({
        joined: true
      })
      api.executeCommand('avatarUrl', profilePicUrl);

      api.addEventListener('videoConferenceJoined', function(data) {
        get( baseUrl + "live-classes/user-actions/" + liveClassID + "/" + "joined");
      });

      api.addEventListener('videoConferenceLeft', function() {
        get( baseUrl + "live-classes/user-actions/" + liveClassID + "/" + "left");
        // window.location.href = '/live-classes';
      })

      api.addEventListener('participantKickedOut', function(data) {
        if(data.kicked.local) {
          window.location.href = '/live-classes';
        }
      })
    })
    
  }

  generateJwtToken = (data) => {
    return jwt.sign({ data }, '', { expiresIn: '1d' });
  }

  assignUserData() {
    const { liveClassID, codeToken, userToken } = this.props.match.params;
    let { email, firstname, lastname, type, userID } = this.props.match.params;
  
    let firstName, lastName, userType, profilePicUrl;
  
    if(sessionStorage.getItem("token")) {
      let data = getUserData();
      email = data.email;
      firstName = data.firstName;
      lastName = data.lastName;
      userType = data.userType;
      profilePicUrl = data.profilePicUrl;
      if(!userID)
        userID = data.userID;
    } else {
      firstName = firstname;
      lastName = lastname;
      userType = type;
    }
    
    this.setState({ email, firstName, lastName, userType, userID });

    get(baseUrl + "live-classes/load/" + liveClassID, codeToken, userToken)
    .then((response) => {
      if(response.data.data) {
        console.log(response.data.data)
        let { title, points, joinees, createdBy, muted, status, isHost, domain } = response.data.data;

        this.setState({
          title: title,
          points,
          joinees,
          hostID: createdBy,
          firstName,
          lastName,
          userType,
          liveClassID,
          userID,
          mute: muted,
          host: isHost,
          status
        }, () => {
          if(status == 'started' || isHost)
            this.connectToJitsi(domain, email, firstName, lastName, userType, userID, profilePicUrl || getDefaultURL(), liveClassID, title, createdBy, joinees, isHost);
        });
      } else {

      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  getClasses() {
    get(baseUrl + "classes/list-names")
    .then((response) => {
      this.setState({
        classes: response.data.data
      })
    })
    .catch((error) => {
    });
  }

  componentWillUnmount() {
    api.dispose();
  }

  render() {
    const { userType, joined } = this.state
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div>{joined?'':
      <div className="text-center pt-5">
        <h6>Please wait...</h6>
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      </div>}</div>
    );
  }
}

export default MeetComponent;