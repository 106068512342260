import React from "react";
import swal from 'sweetalert2';
import { Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { getUserData, get, create } from "../../../utils";
import './chapters.scss';
import NoResults from "../../shared/no-results/no-results";
import { WithTranslations } from "../../../i18n/withTranslations";

const colors = [
  'bg-soft-primary', 'bg-soft-danger', 'bg-soft-success', 'bg-soft-warning', 'bg-soft-info', 'bg-soft-dark'
]

class ChaptersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chapters: [],
      name: "",
      message: "",
      classID: "",
      subjectID: "",
      userType: "",
      chapterID: ""
    }
    this.save = this.save.bind(this);
    this.chapterValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.chapterValidator.allValid()) {
      let url = baseUrl + "chapters/";
      if (this.state.chapterID)
        url += this.state.chapterID;
      else
        url += "add";
      create(url, this.state.chapterID ? "put" : "post", {
        name: this.state.name,
        classID: this.state.classID,
        subjectID: this.state.subjectID
      })
        .then((response) => {
          $('#add-chapter').trigger('click');
          this.setState({
            name: "",
            message: "",
            chapterID: ""
          })
          this.getChaptersList();
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning"
          });
        })
        .catch((error) => {

        });
    } else {
      this.chapterValidator.showMessages();
      this.forceUpdate();
    }
  }

  getChaptersList(classID, subjectID) {
    get(baseUrl + "chapters/list/" + (this.state.classID || classID) + "/" + (this.state.subjectID || subjectID))
      .then((response) => {
        this.setState({
          chapters: response.data.data
        })
      })
      .catch((error) => {
      });
  }


  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    let classID = this.props.match.params.classID;
    let subjectID = this.props.match.params.subjectID;
    this.setState({
      classID,
      subjectID
    })
    this.getChaptersList(classID, subjectID);
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  onClickChapter(chapter) {
    let { _id, name } = chapter;
    this.setState({
      chapterID: _id,
      name
    })
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper" className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className=""><Link to={"/subjects/" + this.state.classID}><i className="uil-arrow-left" /></Link> {tid('Chapters')}</h2>
            </div>
            <div className="app-page-header-right">
              {this.state.userType !== 'student' && <div className="section-header-right">
                <button type="button" className="app-add-option-button" data-toggle="modal" data-target="#add-chapter">
                  <i className="uil-plus"></i> {tid('Add Chapter')}
                </button>
              </div>}
            </div>
          </div>
          <div className='chapters-list-main-container'>
            {this.state.chapters.map((chapter, index) => {
              return (
                <div key={"chaper-" + index} className={`chapter-detail-card ${colors[index % colors.length]}`}>
                  <div className='chapter-details-flex-container'>
                    <div className="chapter-flex-list-left-side">
                      <h5 className="mb-2">
                        <span className="font-black">{index + 1}. {chapter.name} </span>
                      </h5>
                      <p className="chapter-counts-list">
                        <span>{tid('Topics')}: <span>{chapter.topicsCount || '0'}</span></span>
                        <span>{tid('Tests')}: <span>{chapter.testsCount || '0'}</span></span>
                        <span>{tid('Videos Uploaded')}: <span>{chapter.videosCount || '0'}</span></span>
                        <span>{tid('Practice Papers')}: <span>{chapter.practicePapersCount || '0'}</span></span>
                        <span>{tid('Previous Papers')}: <span>{chapter.previousPapersCount || '0'}</span></span>
                      </p>
                      <div className='edit-button-container'>
                        <button className="btn btn-xxs btn-default" onClick={() => this.onClickChapter(chapter)} data-toggle="modal" data-target="#add-chapter">{tid('Edit')}</button>
                      </div>
                    </div>
                    <div className='go-to-button-section'>
                      <Link to={(this.state.userType === 'student') ? "/chapter-details/" + this.state.classID + "/" + this.state.subjectID + "/" + chapter._id : "/chapter-details/" + this.state.classID + "/" + this.state.subjectID + "/" + chapter._id}>
                        <button className={`${colors[index % colors.length]}`}><i className='uil-arrow-right' /></button>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
            {
              !this.state.chapters.length && <NoResults title={tid("No Chapters Found")} />
            }
          </div>
          <div className="modal fade text-left" id="add-chapter" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">{tid('Add Chapter')}</h3>
                  <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">{tid('Chapter Name')}:</label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.name}
                        name="name"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Enter Chapter Name")}
                      />
                      {this.chapterValidator.message(
                        tid("Chapter Name"),
                        this.state.name,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-light-secondary btn-sm" data-dismiss="modal">
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid('Close')}</span>
                  </button>
                  <button type="button" className="btn btn-primary ml-1 btn-sm" onClick={this.save.bind(this)}>
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid('Save')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(ChaptersComponent);