import * as $ from "jquery";
import moment from "moment";
import React from "react";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert2";
// import { Loader, IconLoader } from "../../shared/loader";
import { baseUrl } from "../../../config";
import { WithTranslations } from "../../../i18n/withTranslations";
import { create, get, hasPermission } from "../../../utils";
import Delete from "../../common/Delete";
import "./academic-years.scss";

const colors = [
  "bg-soft-primary",
  "bg-soft-danger",
  "bg-soft-success",
  "bg-soft-warning",
  "bg-soft-info",
  "bg-soft-dark",
];

class AcademicYearsListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classID: "",
      academicYears: [],
      name: "",
      message: "",
      userType: "",
      startDate: "",
      endDate: "",
      workingDays: "",
      isCurrent: "",
      academicYearID: "",
    };

    this.save = this.save.bind(this);
    this.academicYearValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.academicYearValidator.allValid()) {
      let url = baseUrl + "academic-years/";
      if (this.state.academicYearID) url += this.state.academicYearID;
      else url += "add";

      create(url, this.state.academicYearID ? "put" : "post", {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        workingDays: this.state.workingDays,
        isCurrent: this.state.isCurrent,
      })
        .then((response) => {
          $("#add-academicYear").trigger("click");
          this.setState({
            startDate: "",
            endDate: "",
            isCurrent: "",
            academicYearID: "",
            workingDays: "",
            message: "",
          });
          this.getAcademicYearsList();
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.academicYearValidator.showMessages();
      this.forceUpdate();
    }
  }

  getAcademicYearsList() {
    get(baseUrl + "academic-years/list")
      .then((response) => {
        this.setState({
          academicYears: response.data.data,
        });
      })
      .catch((error) => {});
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  componentDidMount() {
    this.getAcademicYearsList();
    this.setState({
      userType: this.state.userType,
    });
  }

  removeAYById = (id) => () => {
    this.setState({
      academicYears: this.state.academicYears.filter((k) => k._id != id),
    });
  };

  edit(year) {
    this.setState({
      academicYearID: year._id,
      startDate: year.startDate,
      endDate: year.endDate,
      workingDays: year.workingDays,
      isCurrent: year.isCurrent,
    });
  }

  render() {
    const {tid}=this.props;
    return (
      <div>
        <div className="app-page-header-section">
          <div className="app-page-header-left">
            <h2 className="">{tid('Academic Years')}</h2>
          </div>
          <div className="app-page-header-right">
            {hasPermission("academic-years", "create") && (
              <button
                className="app-add-option-button"
                data-toggle="modal"
                data-target="#add-academicYear"
              >
                <i className="uil-plus"></i> {tid('Add Academic Year')}
              </button>
            )}
          </div>
        </div>
        <div
          className="d-app-academicYear-list-container"
          uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 100"
        >
          <table className="table">
            <thead>
              <tr className="students-list-container-header">
                <th>{tid('Academic Year')}</th>
                <th>{tid('Start Date')}</th>
                <th>{tid('End Date')}</th>
                <th>{tid('Working Days')}</th>
                <th>{tid('Is Current')}</th>
                <th>{tid('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.academicYears.map((year, index) => {
                return (
                  <tr
                    className={
                      "students-list-container-row" +
                      (year.isCurrent ? " bg-soft-info" : "")
                    }
                    key={year._id}
                  >
                    <td>
                      {moment(new Date(year.startDate)).format("YYYY")} -{" "}
                      {moment(new Date(year.endDate)).format("YYYY")}
                    </td>
                    <td>
                      {moment(new Date(year.startDate)).format("DD-MM-YYYY")}
                    </td>
                    <td>
                      {moment(new Date(year.endDate)).format("DD-MM-YYYY")}
                    </td>
                    <td>{year.workingDays}</td>
                    <td>{year.isCurrent ? "Yes" : "No"}</td>
                    <td>
                      <div className="action-items">
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          data-toggle="modal"
                          data-target="#add-academicYear"
                          onClick={() => this.edit(year)}
                        >
                          <i className="uil-edit" />
                        </button>
                        &nbsp;
                        <Delete
                          url={`academic-years/${year._id}`}
                          onDeleteSuccess={this.removeAYById(year._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div
          className="modal fade text-left"
          id="add-academicYear"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="myModalLabel1">
                  {tid('Add Academic Year')}
                </h3>
                <button
                  type="button"
                  className="close rounded-pill"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <div>
                    <label className="col-form-label">
                      {tid('Academic Start Date')}:
                    </label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.startDate}
                      name="startDate"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Start Date")}
                    />
                    {this.academicYearValidator.message(
                      "Academic Start Date",
                      this.state.startDate,
                      "required"
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    <label className="col-form-label">{tid('Academic End Date')}:</label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.endDate}
                      min={this.state.startDate}
                      name="endDate"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter End Date")}
                    />
                    {this.academicYearValidator.message(
                      "Academic End Date",
                      this.state.endDate,
                      "required"
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    <label className="col-form-label">
                      {tid('Total Working Days')}:
                    </label>
                  </div>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      value={this.state.workingDays}
                      name="workingDays"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Working Days")}
                    />
                    {this.academicYearValidator.message(
                      "Academic Working Days",
                      this.state.workingDays,
                      "required"
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    <label className="col-form-label">{tid('Is Active')}:</label>
                  </div>
                  <div>
                    <select
                      className="form-control"
                      value={this.state.isCurrent}
                      name="isCurrent"
                      onChange={this.setFormField.bind(this)}
                    >
                      <option>{tid('Is Current Academic Year')}</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                    {this.academicYearValidator.message(
                      "Is Current",
                      this.state.isCurrent,
                      "required"
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-secondary btn-sm"
                  data-dismiss="modal"
                >
                  <i className="bx bx-x d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid('Close')}</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-1 btn-sm"
                  onClick={this.save.bind(this)}
                >
                  <i className="bx bx-check d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid('Save')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(AcademicYearsListComponent);
