import React from "react";

import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";
// import Loader from "../../shared/loader";
// import SimpleReactValidator from 'simple-react-validator';
import { baseUrl } from "../../../config";
import { create } from "../../../utils";
import './demo.scss';
import { WithTranslations } from "../../../i18n/withTranslations";

class DemoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      schoolName: "",
      message: ""
    };
  }

  componentDidMount() {
    
  }

  handleSubmit = () => {
    const { name, mobile, schoolName } = this.state
    if (!name) {
      document.getElementById('demo-input-name').focus()
    } else if (mobile.length !== 10) {
      document.getElementById('demo-input-mobile').focus()
    } else if (!schoolName) {
      document.getElementById('demo-input-school-name').focus()
    } else if (name && mobile && mobile.length === 10 && schoolName) {
      create(baseUrl + "demo-request/add", "post", this.state)
      .then((response) => {
        this.setState({
          name: "",
          mobile: "",
          email: "",
          schoolName: "",
          message: ""
        })
        window.UIkit.notification(response.data.message, {pos: 'bottom-right', status:'success'})
      })
      .catch((error) => {
        console.log(error)
      });
    }
  }

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value})
  }

  render() {
    const bgStyles = {
      backgroundImage:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/contact-bg.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: 400,
    };

    const { name, mobile, email, schoolName, message } = this.state;
    const {translations, tid}=this.props;
    return (
      <div className="homepage-main-container">
        <Navbar />
        <div className="about-us-info-container" style={bgStyles}>
          <div className="about-us-info-section">
            <h4>{tid('Request a Demo')}</h4>
            <h2>{tid('Get an opportunity to connect with us')}</h2><h2>{tid('to know more awesome features.')}</h2>
          </div>
        </div>
        <div className='about-us-details-container contact-us-container'>
          <div className='about-us-main-content'>
            <div className="demo-form">           
              <div className="demo-left-side-section">
                <img src={'/assets/images/demo.jpg'} alt='Demo' />
              </div>
              <div className="demo-right-side-section">
                <div className='demo-form-container'>
                  <div className='demo-input'>
                    <input
                      type="text"
                      placeholder={tid("Your Name")}
                      value={name}
                      name="name"
                      onChange={this.handleOnChange}
                      id="demo-input-name"
                    />
                  </div>
                  <div className='demo-input'>
                    <input
                      type="number"
                      placeholder={tid("Mobile Number")}
                      value={mobile}
                      name="mobile"
                      onChange={this.handleOnChange}
                      maxLength="10"
                      id="demo-input-mobile"
                    />
                  </div>
                </div>
                <div className='demo-form-container'>
                  <div className='demo-input'>
                    <input
                      type="text"
                      placeholder={tid("Your School / College Name")}
                      value={schoolName}
                      name="schoolName"
                      id="demo-input-school-name"
                      onChange={this.handleOnChange}
                    />
                  </div>
                  <div className='demo-input'>
                    <input
                      type="email"
                      placeholder={tid("Your Email")}
                      value={email}
                      onChange={this.handleOnChange}
                      name="email"
                    />
                  </div>
                </div>
                <div className="demo-form-container">
                  <div className='demo-textarea-input'>
                    <textarea
                      placeholder={tid("Enter your message here...")}
                      value={message}
                      name="message"
                      onChange={this.handleOnChange}
                    ></textarea>
                  </div>
                </div>
                {/* <div className="form-container-second-row">
                  <div className="form-container-contact-section">
                    <label className="uk-form-label">Your Preferred Date *</label>
                    <input
                      className="uk-input"
                      type="date"
                      placeholder="Select Preferred Date"
                      value={this.state.date}
                      name="date"
                      onChange={this.setFormField.bind(this)}
                    />
                    {this.demoValidator.message(
                      "Date",
                      this.state.date,
                      "required"
                    )}
                  </div>
                  <div className="form-container-contact-section">
                    <label className="uk-form-label">Time *</label>
                    <input
                      className="uk-input"
                      type="time"
                      placeholder="Select Preferred Time"
                      value={this.state.time}
                      name="time"
                      onChange={this.setFormField.bind(this)}
                    />
                    {this.demoValidator.message(
                      "Time",
                      this.state.time,
                      "required"
                    )}
                  </div>
                </div> */}
                <div className="demo-form-container">
                  <button className="demo-submit-button" onClick={this.handleSubmit}>{tid('Submit')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default WithTranslations(DemoComponent);
