import React from "react";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
// import { Loader, IconLoader } from "../../shared/loader";
import { baseUrl } from "../../../config";
import {
  get,
  create,
  hasPermission,
  featureAccess,
  getUserData,
} from "../../../utils";
import "./subjects.scss";
import NoResults from "../../shared/no-results/no-results";
import { WithTranslations } from "../../../i18n/withTranslations";

const colors = [
  "bg-soft-primary",
  "bg-soft-danger",
  "bg-soft-success",
  "bg-soft-warning",
  "bg-soft-info",
  "bg-soft-dark",
];

class SubjectsListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classID: "",
      subjects: [],
      name: "",
      message: "",
      userType: "",
    };

    this.save = this.save.bind(this);
    this.subjectValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.subjectValidator.allValid()) {
      let url = baseUrl + "subjects/";
      if (this.state.subjectID) url += this.state.subjectID;
      else url += "add";

      create(url, this.state.subjectID ? "put" : "post", {
        name: this.state.name,
        classID: this.state.classID,
      })
        .then((response) => {
          $("#add-subject").trigger("click");
          this.setState({
            name: "",
            subjectID: "",
            message: "",
          });
          this.getSubjectsList();
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.subjectValidator.showMessages();
      this.forceUpdate();
    }
  }

  getSubjectsList(classID) {
    get(baseUrl + "subjects/list/" + (this.state.classID || classID))
      .then((response) => {
        this.setState({
          subjects: response.data.data,
        });
      })
      .catch((error) => {});
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  onClickSubject(subject) {
    this.setState({
      name: subject.name,
      subjectID: subject._id,
    });
  }

  componentDidMount() {
    if (this.props.classID) {
      this.getSubjectsList(this.props.classID);
      let { userType } = getUserData();
      this.setState({
        classID: this.props.classID,
        userType: userType,
      });
    }
  }

  render() {
    const { tid } = this.props;
    return (
      <div>
        {(this.props.showTitle || this.props.create) && (
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">
                {this.state.userType !== "student" && (
                  <Link to={"/classes"}>
                    <i className="uil-arrow-left" />
                  </Link>
                )}{" "}
                {tid("Subjects")}
              </h2>
            </div>
            <div className="app-page-header-right">
              {hasPermission("subjects", "create") && (
                <button
                  className="app-add-option-button"
                  data-toggle="modal"
                  data-target="#add-subject"
                >
                  <i className="uil-plus"></i> {tid("Add Subject")}
                </button>
              )}
            </div>
          </div>
        )}
        <div
          className="d-app-subject-list-container"
          uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 100"
        >
          {this.state.subjects.map((subject, index) => {
            return (
              <div className={`subject-details-flex-card ${colors[index % 6]}`}>
                <div className={"subjct-card"}>
                  <p className="uk-h4">{subject.name}</p>
                  <p>
                    <Link
                      to={
                        "/online-tests/" +
                        this.state.classID +
                        "/" +
                        subject._id
                      }
                    >
                      {tid(`Online Tests`)}
                    </Link>
                  </p>
                  {featureAccess("assignments") && (
                    <p>
                      <Link
                        to={
                          "/assignments/" +
                          this.state.classID +
                          "/" +
                          subject._id
                        }
                      >
                        {tid(`Assignments`)}
                      </Link>
                    </p>
                  )}
                  <p>
                    <Link
                      to={
                        "/online-tests/" +
                        this.state.classID +
                        "/" +
                        subject._id
                      }
                    >
                      {tid(`Study Material`)}
                    </Link>
                  </p>
                  {this.props.edit && hasPermission("subjects", "create") && (
                    <button
                      className="edit-button btn btn-info btn-xxs"
                      onClick={() => this.onClickSubject(subject)}
                      data-toggle="modal"
                      data-target="#add-subject"
                    >
                      {tid(`Edit`)}
                    </button>
                  )}
                </div>
                <div className="go-to-button-section">
                  <Link
                    to={"/chapters/" + this.state.classID + "/" + subject._id}
                  >
                    <button className={`${colors[index % colors.length]}`}>
                      <i className="uil-arrow-right" />
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
          {!this.state.subjects.length && (
            <NoResults title="No Subjects Found" />
          )}
        </div>
        <div
          className="modal fade text-left"
          id="add-subject"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="myModalLabel1">
                  {tid(`Add Subject`)}
                </h3>
                <button
                  type="button"
                  className="close rounded-pill"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <div>
                    <label className="col-form-label">
                      {tid(`Subject Name:`)}
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.name}
                      name="name"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Subject Name")}
                    />
                    {this.subjectValidator.message(
                      "Subject Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-secondary btn-sm"
                  data-dismiss="modal"
                >
                  <i className="bx bx-x d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid(`Close`)}</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-1 btn-sm"
                  onClick={this.save.bind(this)}
                >
                  <i className="bx bx-check d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid(`Save`)}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(SubjectsListComponent);
