import React from "react";
import { Link } from "react-router-dom";
import SVG from 'react-inlinesvg';
import { LanguageContext } from "../../../../i18n/LanguageProvider";

export default class SingleLineMenuComponent extends React.Component {
  static contextType = LanguageContext;

    constructor(props) {
        super(props);
    }

    render() {
      const { content } = this.context;
        const { menuItems, pathname } = this.props;
        return (
            <ul className="dashboard-side-menu-links-list-container">
            <li className={`dashboard-side-menu-links-list-item ${pathname === '/dashboard' ? 'active-link' : ''}`}>
              <Link to={"/dashboard"}>
                <SVG
                  src="/assets/images/dashboard/dashboard.svg"
                  title={content.DASHBOARD||"Dashboard"}
                  preProcessor={code => code.replace(/fill=".*?"/g, `fill=${pathname === '/dashboard' ? "#3e416d" : "#747b90"}`)}
                />
                <h4 className="sidebar-link-text" data-i18n="i18n">
                  {content.DASHBOARD||`Dashboard`}
                </h4>
              </Link>
            </li>
            {menuItems.map((menu, index) => {
              const { name,id, icon, link} = menu;
              return (
                <li key={"sidemenu-" + index} className={`dashboard-side-menu-links-list-item ${pathname === link ? 'active-link' : ''}`}>
                  <Link to={link}>
                    <SVG
                      src={icon}
                      title={name}
                      preProcessor={code => code.replace(/fill=".*?"/g, `fill=${pathname === link ? "#3e416d" : "#747b90"}`)}
                    />
                    <h4 className="sidebar-link-text" data-i18n="i18n">
                      {content[id]||name}
                    </h4>
                  </Link>
                </li>
              );
            })}
          </ul>
        )
    }
}