import React from "react";
import swal from 'sweetalert2';
// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { getUserData, get, create } from "../../../utils";
import QuestionComponent from "../questions/question";
import PreviousQuestion from "../questions/previous-question";
import QuizSubmissionsComponent from "./submissions";
import './quiz.scss'

class AddQuizComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      questionsCount: '',
      isActive: '',
      quizID: '',
      quizFor: '',
      referenceID: '',
      video: {},
      questionsMaster: [],
      questions: [],
      questionAdded: false,

      showSection: "add-quiz"
    };
    this.quizValidator = new SimpleReactValidator({});
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    let { quizFor, referenceID } = this.props.match.params;
    this.setState({
      quizFor
    })
    this.getVideoDetails(quizFor, referenceID)
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  getVideoDetails(quizFor, referenceID) {
    get(baseUrl + quizFor + "s/" + referenceID)
      .then((response) => {
        if (response.data.data && response.data.data.quizID) {
          let { _id, title, questionsCount, isActive } = response.data.data.quizID;
          this.setState({
            quizID: _id, title, questionsCount, isActive
          })
          this.loadQuizQuestions(_id);
        }
        this.setState({
          video: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  loadQuizQuestions(quizID) {
    get(baseUrl + "quizs/questions/" + quizID)
      .then((response) => {
        let { questions, questionsMaster } = response.data.data;
        this.setState({
          questions,
          questionsMaster
        })
      })
      .catch((error) => {
      });
  }

  onClickAddQuestion() {
    if (!this.state.questionAdded) {
      let questionsMaster = this.state.questionsMaster;
      questionsMaster.push({});
      this.setState({
        questionsMaster,
        questionAdded: true
      })
    }
  }

  saveQuiz() {
    if (this.quizValidator.allValid()) {

      let url = baseUrl + "quizs/";
      if (!this.state.quizID)
        url += "add";
      else
        url += this.state.quizID
      create(url, (this.state.quizID) ? "put" : "post", {
        title: this.state.title,
        questionsCount: this.state.questionsCount,
        isActive: this.state.isActive,
        classID: this.state.video.classID._id,
        subjectID: this.state.video.subjectID._id,
        chapterID: this.state.video.chapterID._id,
        topicID: this.state.video.topicID._id,
        videoID: this.state.video._id,
        quizFor: this.state.quizFor
      })
        .then((response) => {
          this.setState({
            quizID: response.data.data._id
          })
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning"
          });
        })
        .catch((error) => {

        });

    } else {
      this.quizValidator.showMessages();
      this.forceUpdate();
    }
  }

  onAddQuestion(question) {
    let questionsMaster = this.state.questionsMaster;
    let questions = this.state.questions;

    let index = questions.map(function (q) { return "" + q.questionID; }).indexOf("" + question._id);
    if (index < 0) {
      questionsMaster.push(question);
      questions.push({
        questionID: question._id
      });

      this.setState({
        questionsMaster,
        questions
      });

      create(baseUrl + "quizs/" + this.state.quizID, (this.state.quizID) ? "put" : "post", { questions })
        .then((response) => {
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning"
          });
        })
        .catch((error) => {

        });
    } else {
      questionsMaster[index] = question;
      this.setState({
        questionsMaster
      })
    }
  }

  render() {
    const { userType } = this.state
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper"  className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">Quiz</h2>
            </div>
          </div>
          <div className='add-quiz-form-container'>
            <div className='aq-quiz-top-container'>
              <div className='aq-left-side'>
                <h6 className="aq-q-meta-info">Class: <span className='colored-text'>{this.state.video.classID && this.state.video.classID.name}</span></h6>
                <h6 className="aq-q-meta-info">Subject: <span className='colored-text'>{this.state.video.subjectID && this.state.video.subjectID.name}</span></h6>
                <h6 className="aq-q-meta-info">Chapter: <span className='colored-text'>{this.state.video.chapterID && this.state.video.chapterID.name}</span></h6>
                <h6 className="aq-q-meta-info">Topic: <span className='colored-text'>{this.state.video.topicID && this.state.video.topicID.name}</span></h6>
                <h6 className="aq-q-meta-info">Video: <span className='colored-text'>{this.state.video.name}</span></h6>
              </div>
              <div className='aq-right-side'>
                <div className="glyph fs1 pointer" onClick={() => this.setState({ showSection: 'submissions' })}>
                  <div className="clearfix bshadow0 pbs">
                    <span >{this.state.video.submissionsCount}</span>
                    <span className="mls"> Quiz Submissions</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='aq-quiz-bottom-container'>
              {this.state.showSection === 'add-quiz' && <div className='add-quiz-form-flex-container'>
                <div className="add-quiz-form-flex">
                  <div className="">
                    <label>Quiz Title:</label>
                    <input type="text"
                      className="form-control"
                      value={this.state.title}
                      name="title"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Quiz Title"
                    />
                    {this.quizValidator.message(
                      "Quiz Title",
                      this.state.title,
                      "required"
                    )}
                  </div>
                  <div className="">
                    <label>Quiz Questions Count:</label>
                    <input type="number"
                      className="form-control"
                      value={this.state.questionsCount}
                      name="questionsCount"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Quiz Questions Count"
                    />
                    {this.quizValidator.message(
                      "Quiz Questions Count",
                      this.state.questionsCount,
                      "required"
                    )}
                  </div>
                  <div className="">
                    <label>Active:</label>
                    <select className="form-control"
                      value={this.state.isActive}
                      name="isActive"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Active"
                    >
                      {this.quizValidator.message(
                        "Active",
                        this.state.isActive,
                        "required"
                      )}
                      <option value="">Select Active</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="">
                    <button className="btn btn-default btn-sm" onClick={() => this.saveQuiz()}>Save Quiz</button>
                  </div>
                </div>
                {this.state.quizID && (
                  <div className="app-page-header-section">
                    <div className="app-page-header-left">
                      <h2 className="">Add Questions</h2>
                    </div>
                    <div className='app-page-header-right'>
                      <button className="btn btn-xs btn-info pull-right" onClick={() => this.onClickAddQuestion()}><i className='uil-plus' />Add New </button>
                    </div>
                  </div>)}
                  <PreviousQuestion onAddQuestion={this.onAddQuestion.bind(this)}></PreviousQuestion>
                  {this.state.quizID && <div className="add-quiz-questions-container">
                  <section id="collapsible" className='aq-q-container-collapsible-container'>
                    {this.state.questionsMaster.map((question, index) => {
                      return (<QuestionComponent questionFor='quiz' className="col-lg-12" key={"question-" + index} question={question} index={index} onAddQuestion={this.onAddQuestion.bind(this)} />)
                    })}
                  </section>
                </div>}
              </div>}
            </div>
            {this.state.showSection === 'submissions' &&
              <>
                <div className="app-page-header-section">
                  <div className="app-page-header-left">
                    <h2 className="">
                      <span onClick={() => this.setState({ showSection: 'add-quiz' })}><i className="uil-arrow-left" /></span> Quiz Submissions
                    </h2>
                  </div>
                </div>
                <div className="">
                  <QuizSubmissionsComponent quizID={this.state.quizID}></QuizSubmissionsComponent>
                </div>
              </>
            }
          </div>
        </div>
        {/* {this.state.showSection === 'add-quiz' && <div className="content-body">
          <section id="basic-input">
            <div className="row col-lg-12">
              <div className="col-lg-12 card">
                <div className="row col-lg-12 pt-2">
                  <div className="col-lg-5">
                    <label>Quiz Title:</label>
                    <input type="text"
                      className="form-control"
                      value={this.state.title}
                      name="title"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Quiz Title"
                    />
                    {this.quizValidator.message(
                      "Quiz Title",
                      this.state.title,
                      "required"
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label>Quiz Questions Count:</label>
                    <input type="number"
                      className="form-control"
                      value={this.state.questionsCount}
                      name="questionsCount"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Quiz Questions Count"
                    />
                    {this.quizValidator.message(
                      "Quiz Questions Count",
                      this.state.questionsCount,
                      "required"
                    )}
                  </div>
                  <div className="col-lg-2">
                    <label>Active:</label>
                    <select className="form-control"
                      value={this.state.isActive}
                      name="isActive"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Active"
                    >
                      {this.quizValidator.message(
                        "Active",
                        this.state.isActive,
                        "required"
                      )}
                      <option value="">Select Active</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="col-lg-2 pt-5">
                    <button className="btn btn-default btn-sm" onClick={() => this.saveQuiz()}>Save Quiz</button>
                  </div>
                  {this.state.quizID && <h6>Add Random Questions <button className="btn btn-xs btn-info pull-right" onClick={() => this.onClickAddQuestion()}>Add New Question</button></h6>}

                  <PreviousQuestion onAddQuestion={this.onAddQuestion.bind(this)}></PreviousQuestion>

                  {this.state.quizID && <div className="row col-lg-12 width-100">
                    <section id="collapsible" className="row col-lg-12">
                      {this.state.questionsMaster.map((question, index) => {
                        return (<QuestionComponent questionFor='quiz' className="col-lg-12" key={"question-" + index} question={question} index={index} onAddQuestion={this.onAddQuestion.bind(this)}></QuestionComponent>
                        )
                      }
                      )}
                    </section>
                  </div>}

                </div>
              </div>
            </div>
          </section>
        </div>} */}
      </div>
    );
  }
}

export default AddQuizComponent;