import React from "react";

import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";
// import Loader from "../../shared/loader";
import SimpleReactValidator from 'simple-react-validator';
import { baseUrl } from "../../../config";

import { create } from "../../../utils";
import { WithTranslations } from "../../../i18n/withTranslations";

class ContactUsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      schoolName: "",
      message: ""
    };

    this.contactValidator = new SimpleReactValidator({});
  }

  componentDidMount() {

  }

  submit() {
    if (this.contactValidator.allValid()) {
      create(baseUrl + "contact-us/add", "post", this.state)
        .then((response) => {
          this.setState({
            name: "",
            mobile: "",
            email: "",
            message: ""
          })
          window.UIkit.notification(response.data.message, { pos: 'bottom-right', status: 'success' })
        })
        .catch((error) => {
          console.log(error)
        });
    } else {
      this.contactValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { tid } = this.props
    const bgStyles = {
      backgroundImage:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/contact-bg.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: 400,
    };
    return (
      <div className="homepage-main-container">
        <Navbar />
        <div className="about-us-info-container" style={bgStyles}>
          <div className="about-us-info-section">
            <h4>{tid(`Wanted to have conversation with us?`)}</h4>
            <h2>{tid(`Let's Talk`)}</h2>
          </div>
        </div>
        <div className='about-us-details-container contact-us-container'>
          <div className='about-us-main-content'>
            <div className="row contact-us-form">
              <div className="col-md-6 left-side-section">
                <div className="form-container-contact">
                  <label className="uk-form-label">{tid(`Name`)}</label>
                  <input
                    className="uk-input"
                    type="text"
                    placeholder={tid("Name")}
                    value={this.state.name}
                    name="name"
                    onChange={this.setFormField.bind(this)}
                  />

                  {this.contactValidator.message(
                    "Name",
                    this.state.name,
                    "required"
                  )}
                </div>
                <div className="form-container-second-row">
                  <div className="form-container-contact-section">
                    <label className="uk-form-label">{tid('Email')}</label>
                    <input
                      className="uk-input"
                      type="text"
                      placeholder={tid('Email')}
                      value={this.state.email}
                      name="email"
                      onChange={this.setFormField.bind(this)}
                    />

                    {this.contactValidator.message(
                      "Email",
                      this.state.email,
                      "required"
                    )}
                  </div>
                  <div className="form-container-contact-section">
                    <label className="uk-form-label">{tid('Mobile')}</label>
                    <input
                      className="uk-input"
                      type="number"
                      placeholder={tid('Mobile')}
                      value={this.state.mobile}
                      name="mobile"
                      onChange={this.setFormField.bind(this)}
                    />

                    {this.contactValidator.message(
                      "Mobile",
                      this.state.mobile,
                      "required"
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1@s uk-first-column">
                  <label className="uk-form-label">{tid(`Message`)}</label>
                  <textarea
                    className="uk-textarea"
                    placeholder={tid("Enter your message here.")}
                    style={{ height: "160px" }}
                    value={this.state.message}
                    name="message"
                    onChange={this.setFormField.bind(this)}
                  ></textarea>
                </div>
                <div className="uk-grid-margin uk-first-column send-button-section-container">
                  <input
                    type="submit"
                    value={tid("Send")}
                    className="btn btn-default btn-xs success"
                    onClick={() => this.submit()}
                  />
                </div>
              </div>
              {/* <div className="right-side-section">
                <ul class="uk-list text-center contact-us-links-section" ><li><a> +91 83413 80020 </a></li><li><a> contact@tetu.co.in </a></li></ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default WithTranslations(ContactUsComponent);
