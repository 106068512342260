import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../../config";
import {
  getUserData,
  get,
  decodeJwtToken,
  generateJwtToken,
} from "../../../../utils";
import { LanguageContext } from "../../../../i18n/LanguageProvider";
import { WithTranslations } from "../../../../i18n/withTranslations";

class Navbar extends React.Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      userType: "",
      profilePicUrl: "",
      branches: [],
      branchName: "",
      branchID: "",
      branchPermission: "",
      academicYears: [],
      activeFromDate: "",
      activeToDate: "",
      academicYearID: "",
    };
  }

  assignUserData() {
    let {
      firstName,
      branchPermission,
      lastName,
      userType,
      userID,
      profilePicUrl,
    } = getUserData();

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
      profilePicUrl,
      branchPermission,
    });
  }

  componentDidMount() {
    this.assignUserData();
    this.setState({
      branchName: sessionStorage.getItem("branch_name"),
      branchID: sessionStorage.getItem("branch_id"),
    });
    this.assignAcademicYear();
  }

  assignAcademicYear() {
    let academicYearToken = sessionStorage.getItem("academicYearToken");
    let academicYear = decodeJwtToken(academicYearToken);
    let { _id, startDate, endDate } = academicYear;
    this.setState({
      activeFromDate: startDate,
      activeToDate: endDate,
      academicYearID: _id,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.assignUserData();
  }

  openBranch() {
    get(baseUrl + "branches/list").then((response) => {
      this.setState({
        branches: response.data.data,
      });
    });
  }

  getAcademicYears() {
    get(baseUrl + "academic-years/list/dates").then((response) => {
      let activeFromDate, activeToDate;
      response.data.data.forEach((year) => {
        if (year.isCurrent) {
          activeFromDate = year.startDate;
          activeToDate = year.endDate;
        }
      });
      this.setState({
        activeFromDate,
        activeToDate,
        academicYears: response.data.data,
      });
    });
  }

  onClickBranch(branch) {
    this.setState({
      branchName: branch.name,
      branchID: branch._id,
    });

    sessionStorage.setItem("branch_name", branch.name);
    sessionStorage.setItem("branch_id", branch._id);
  }

  onClickAcademicYear(academicYear) {
    let { _id, startDate, endDate } = academicYear;
    let token = generateJwtToken({ _id, startDate, endDate });
    sessionStorage.setItem("academicYearToken", token);
    window.location.reload();
  }

  render() {
    const { content } = this.context;
    const { tid } = this.props;
    return (
      <header className="header uk-light dashboard-shared-top-navbar">
        <div className="dashboard-shared-top-navbar-container">
          <nav uk-navbar={"true"} className="row">
            <div className="uk-navbar-left">
              <span
                className="menu-trigger"
                uk-toggle="target: #wrapper ; cls: mobile-active"
              >
                <button className="hamburger hamburger--collapse" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
              </span>

              <a className="logo">
                <img src="/assets/images/logo-dark.svg" alt="" />
                <span> TeTu Online</span>
              </a>

              {this.state.branchPermission && (
                <div className=" uk-visible@s">
                  <button
                    className="btn btn-outline-default btn-xs"
                    onClick={() => this.openBranch()}
                  >
                    {tid(`Branches`)}
                  </button>
                  &nbsp;&nbsp;
                  {this.state.branchName}
                  {/* <input className="uk-search-input" type="search" placeholder="Search..."/> */}
                  <div
                    uk-dropdown="pos: top;mode:click;animation: uk-animation-slide-bottom-small"
                    className="dropdown-search"
                  >
                    <ul className="dropdown-search-list">
                      <li className="list-title">
                        {tid(`Branches List`)}{" "}
                        <Link className="pull-right" to={"/branches"}>
                          {tid(`Show`)}
                        </Link>
                      </li>
                      {this.state.branches.map((branch, index) => {
                        return (
                          <li
                            className="notifications-not-read"
                            key={"branch-" + index}
                          >
                            <a
                              className="row col-lg-12"
                              onClick={() => this.onClickBranch(branch)}
                            >
                              <span className="notification-avatar">
                                <img
                                  className="branch-logo float-left"
                                  src={
                                    branch.fileUrl ||
                                    "/assets/images/profile.svg"
                                  }
                                  alt=""
                                />
                              </span>
                              <div className="notification-text notification-msg-text">
                                <strong>{branch.name}</strong>
                                <br />
                                <small>
                                  {branch.address1}, {branch.city},{" "}
                                  {branch.state}, {branch.country},{" "}
                                  {branch.pincode}
                                </small>
                              </div>
                            </a>
                          </li>
                        );
                      })}
                      {!this.state.branches.length && (
                        <li className="text-center">
                          {tid(`No Branches found`)}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {/* <div className=" uk-visible@s">
                    <button className="btn btn-outline-default btn-xs" onClick={() => this.getAcademicYears()}>{(this.state.activeFromDate)?(moment(this.state.activeFromDate).format("YYYY") +"-"+ moment(this.state.activeToDate).format("YYYY")):"Academic Years"}</button>&nbsp;&nbsp;
                      <div uk-dropdown="pos: top;mode:click;animation: uk-animation-slide-bottom-small" className="dropdown-search">
                        <ul className="dropdown-search-list">
                          <li className="list-title">
                            Academic Years <Link className="pull-right" to={"/branches"}>Show</Link>
                          </li>
                          {this.state.academicYears.map((academicYear, index) => {
                          return (<li className="notifications-not-read academic-year" key={"branch-"+index} >
                            <a className={"col-lg-12"+((academicYear._id == this.state.academicYearID)?' active':'')} onClick={() => this.onClickAcademicYear(academicYear)}>
                              <div className="notification-text notification-msg-text">
                                <strong>{moment(academicYear.startDate).format("DD/MM/YYYY")} - {moment(academicYear.endDate).format("DD/MM/YYYY")}</strong><br/>
                              </div>
                            </a>
                          </li>)})}
                          {!this.state.academicYears.length && <li className="text-center">No Academic Years found</li>}
                        </ul>
                      </div>
                    </div> */}
            </div>

            <div className="uk-navbar-right app-db-top-right-navbar">
              <div className="header-widget">
                <div className="header-widget-icon" uk-tooltip={tid("Calendar")}>
                  <Link to={"/calendar"}>
                    <i className="icon-feather-calendar" data-icon="globe"></i>
                  </Link>
                </div>
                <div className="header-widget-icon" uk-tooltip={tid("Live Classes")}>
                  <Link to={"/live-classes"}>
                    <img
                      src="/assets/images/dashboard/live-classes.png"
                      width="35"
                      height="25"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="header-widget-icon" uk-tooltip={tid("Notice Board")}>
                  <Link to={"/notice-board"}>
                    <i className="uil-bell" data-icon="globe"></i>
                  </Link>
                </div>

                <div className="dropdown-user-details">
                  <div className="dropdown-user-avatar">
                    <img
                      src={
                        this.state.profilePicUrl || "/assets/images/profile.svg"
                      }
                      alt=""
                    />
                  </div>
                  <div className="dropdown-user-name">
                    {this.state.firstName} <span>{this.state.userType}</span>
                  </div>
                </div>

                <div
                  uk-dropdown="pos:top-right; mode:click"
                  className="dropdown-notifications small"
                >
                  <ul className="dropdown-user-menu">
                    <li>
                      <Link to={"/profile"} className="dropdown-item">
                        <i className="icon-feather-user"></i> {tid(`Profile`)}
                      </Link>
                    </li>
                    {this.state.userType == "principal" && (
                      <li>
                        <Link to={"/academic-years"} className="dropdown-item">
                          <i className="icon-feather-layers"></i>{" "}
                          {tid(`Academic Years`)}{" "}
                        </Link>
                      </li>
                    )}
                    {this.state.userType == "student" && (
                      <li>
                        <Link to={"/performance"} className="dropdown-item">
                          <i className="icon-feather-award"></i>{" "}
                          {tid(`My Performance`)}
                        </Link>
                      </li>
                    )}
                    {this.state.userType == "student" && (
                      <li>
                        <Link to={"/fee"} className="dropdown-item">
                          <i className="icon-feather-credit-card"></i>{" "}
                          {tid(`Fee
                          Payments`)}
                        </Link>
                      </li>
                    )}
                    {this.state.userType == "student" && (
                      <li>
                        <Link to={"/parents"} className="dropdown-item">
                          <i className="icon-feather-users"></i>{" "}
                          {tid(`My Parents`)}
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to={
                          this.state.userType !== "parent" &&
                          this.state.userType !== "student"
                            ? "/management-login"
                            : "/"
                        }
                        className="dropdown-item"
                      >
                        <i className="icon-feather-log-out"></i>{" "}
                        {tid(`Sign Out`)}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <span
                className="uil-user icon-small uk-hidden@s"
                uk-toggle="target: .header-widget ; cls: is-active"
              ></span>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
export default WithTranslations(Navbar);
