import React from "react";
import swal from "sweetalert2";

import { Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";

// import { Loader, IconLoader } from "../../shared/loader";

import { baseUrl } from "../../../config";
import { get, create } from "../../../utils";
import * as XLSX from "xlsx";
import NoResults from "../../shared/no-results/no-results";
import Delete from "../../common/Delete";
import { WithTranslations } from "../../../i18n/withTranslations";

class StudentsListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      classes: [],
      classID: "",
      firstName: "",
      lastName: "",
      mobile: "",
      prefix: "+91",
      gender: "",
      password: "",
      admissionNo: "",
      userType: "student",
      selectedStudentID: "",
      parentFirstName: "",
      parentLastName: "",
      parentRelation: "",
      parentMobile: "",
      parentPassword: "",
      parents: [],
      addParent: false,
      searchMobile: "",
      showSection: "students",
      parentsList: [],
      dataParse: [],
      negatives: 0,
      studentID: "",
    };

    this.save = this.save.bind(this);
    this.studentValidator = new SimpleReactValidator({});
    this.saveParent = this.saveParent.bind(this);
    this.parentValidator = new SimpleReactValidator({});

    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(e) {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    let scope = this;
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      scope.setState({
        dataParse,
        negatives: 0,
      });
    };
    reader.readAsBinaryString(f);
  }

  save() {
    if (this.studentValidator.allValid()) {
      let url = baseUrl + "users/";
      if (this.state.studentID) url += "update/" + this.state.studentID;
      else url += "add";

      create(url, this.state.studentID ? "put" : "post", {
        classID: this.state.classID,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        mobile: this.state.mobile,
        admissionNo: this.state.admissionNo,
        prefix: this.state.prefix,
        gender: this.state.gender,
        password: this.state.password ? this.state.password : undefined,
        userType: "student",
      })
        .then((response) => {
          this.setState({
            firstName: "",
            lastName: "",
            mobile: "",
            prefix: "+91",
            admissionNo: "",
            gender: "",
            password: "",
            userType: "student",
            studentID: "",
          });
          this.getStudentsList();
          $("#student").trigger("click");
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.studentValidator.showMessages();
      this.forceUpdate();
    }
  }

  saveParent() {
    if (this.parentValidator.allValid()) {
      create(baseUrl + "users/add", "post", {
        userType: "parent",
        firstName: this.state.parentFirstName,
        lastName: this.state.parentLastName,
        relation: this.state.parentRelation,
        mobile: this.state.parentMobile,
        password: this.state.parentPassword,
      })
        .then((response) => {
          $("#parents").trigger("click");
          this.addParent({
            studentID: this.state.selectedStudentID,
            parentID: response.data.data._id,
          });
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.parentValidator.showMessages();
      this.forceUpdate();
    }
  }

  addParent(data) {
    create(baseUrl + "assigned-parents/add", "post", data)
      .then((response) => {})
      .catch((error) => {});
  }

  componentDidMount() {
    this.getClassNames();
    this.getStudentsList();
  }

  getClassNames() {
    get(baseUrl + "classes/list-names")
      .then((response) => {
        this.setState({
          classes: response.data.data,
        });
      })
      .catch((error) => {});
  }

  getStudentsList() {
    get(
      baseUrl +
        "users/list/student" +
        (this.props.classID ? "/" + this.props.classID : "")
    )
      .then((response) => {
        this.setState({
          students: response.data.data,
          studentsMaster: response.data.data,
        });
      })
      .catch((error) => {});
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  getParents(studentID) {
    this.setState({
      selectedStudentID: studentID,
      parentFirstName: "",
      parentLastName: "",
      parentRelation: "",
      parentMobile: "",
      parentPassword: "",
      addParent: false,
      showSection: "parents",
    });

    get(baseUrl + "users/parents/" + studentID)
      .then((response) => {
        this.setState({
          parents: response.data.data,
        });
      })
      .catch((error) => {});
  }

  addNewParent() {
    this.setState({
      addParent: true,
    });
  }

  searchParent() {
    create(baseUrl + "users/search", "post", {
      mobile: this.state.searchMobile,
      userType: "parent",
    })
      .then((response) => {
        this.setState({
          parentsList: response.data.data,
        });
      })
      .catch((error) => {});
  }

  edit(student) {
    this.setState({
      classID: student.classID._id,
      firstName: student.firstName,
      lastName: student.lastName,
      mobile: student.mobile,
      gender: student.gender,
      admissionNo: student.admissionNo,
      studentID: student._id,
    });
  }

  previewData() {
    $("#bulk-upload").trigger("click");

    let mobiles = [];
    this.state.dataParse.forEach((data, index) => {
      if (index > 0) mobiles.push(data[3]);
    });
    create(baseUrl + "users/check-mobiles", "post", { mobiles })
      .then((response) => {
        let negatives = 0;
        this.state.dataParse.forEach((data, index) => {
          if (response.data.data.indexOf(data[3] + "") >= 0) {
            data.push("Already Exists");
            negatives += 1;
          } else data.push("Accepted");
        });
        this.setState({
          negatives: 0,
          showSection: "data-preview",
        });
      })
      .catch((error) => {});
  }

  uploadData() {
    let body = [];
    this.state.dataParse.forEach((data, index) => {
      if (index > 0 && data[7] === "Accepted")
        body.push(this.getFormatted(data));
    });

    create(baseUrl + "users/bulk-upload", "post", { data: body })
      .then((response) => {
        this.getStudentsList();
        this.getClassNames();
        this.setState({
          dataParse: [],
          negatives: 0,
          showSection: "students",
        });
      })
      .catch((error) => {});
  }

  getFormatted(data) {
    return {
      firstName: data[0],
      lastName: data[1],
      admissionNo: data[2],
      mobile: data[3],
      gender: data[4],
      standard: data[5],
      password: data[6],
    };
  }

  onSelectClass(event) {
    let selectedStudents = [];
    if (event.target.value) {
      this.state.studentsMaster.forEach((student) => {
        if (student.classID && student.classID._id == event.target.value)
          selectedStudents.push(student);
      });
    } else {
      selectedStudents = this.state.studentsMaster;
    }
    this.setState({
      [event.target.name]: event.target.value,
      students: selectedStudents,
    });
  }

  removeSkillById = (id) => () => {
    this.setState({
      students: this.state.students.filter((k) => k._id != id),
    });
  };

  render() {
    let { classID, translations, tid } = this.props;

    return (
      <div>
        <div className="app-page-header-section">
          <div className="app-page-header-left">
            <h2 className="">{translations.STUDENTS || `Students`}</h2>
          </div>
          <div className="app-page-header-right">
            {!classID && (
              <div className="section-header-right">
                <button
                  type="button"
                  className="app-add-option-button"
                  data-toggle="modal"
                  data-target="#bulk-upload"
                >
                  <i className="uil-upload"></i>
                  {translations.BULK_IMPORT || `Bulk Import`}
                </button>
                <button
                  type="button"
                  className="app-add-option-button"
                  data-toggle="modal"
                  data-target="#student"
                >
                  <i className="uil-plus"></i>{" "}
                  {translations.ADD_NEW_STUDENT || `Add New Student`}
                </button>
              </div>
            )}
          </div>
        </div>
        {!this.props.hideStudents && (
          <div className="studets-filters-container">
            <select
              className="col-lg-4"
              value={this.state.selectedClassID}
              name="selectedClassID"
              onChange={this.onSelectClass.bind(this)}
            >
              <option value="">
                {translations.ALL_STUDENTS || `All Students`}
              </option>
              {this.state.classes.map((c, index) => {
                return (
                  <option key={"class-" + index} value={c._id}>
                    {c.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div>
          {!classID && (
            <div
              className="modal fade text-left"
              id="student"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel1"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-scrollable"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="myModalLabel1">
                      {translations.ADD_STUDENT || `Add Student`}
                    </h3>
                    <button
                      type="button"
                      className="close rounded-pill"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fa fa-close"></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {translations.FIRST_NAME || `First Name`}:
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          value={this.state.firstName}
                          name="firstName"
                          onChange={this.setFormField.bind(this)}
                          placeholder={
                            translations.ENTER_FIRST_NAME || `Enter First Name`
                          }
                        />
                        {this.studentValidator.message(
                          "First Name",
                          this.state.firstName,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          L{translations.LAST_NAME || `Last Name:`}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          value={this.state.lastName}
                          name="lastName"
                          onChange={this.setFormField.bind(this)}
                          placeholder={
                            translations.ENTER_LAST_NAME || "Enter Last Name"
                          }
                        />
                        {this.studentValidator.message(
                          "Last Name",
                          this.state.lastName,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {translations.ADMISSION_NO || `Admission No:`}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          value={this.state.admissionNo}
                          name="admissionNo"
                          onChange={this.setFormField.bind(this)}
                          placeholder={
                            translations.ENTER_ADMISSION_NO ||
                            "Enter Admission No#"
                          }
                        />
                        {this.studentValidator.message(
                          "Admission No",
                          this.state.admissionNo,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {translations.CLASS || `Class`}:
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <select
                          className="form-control"
                          value={this.state.classID}
                          name="classID"
                          onChange={this.setFormField.bind(this)}
                        >
                          <option value="">
                            {translations.SELECT_CLASS || `Select Class`}
                          </option>
                          {this.state.classes.map((c, index) => {
                            return (
                              <option key={"class-" + index} value={c._id}>
                                {c.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {translations.MOBILE || `Mobile`}:
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          value={this.state.mobile}
                          name="mobile"
                          onChange={this.setFormField.bind(this)}
                          placeholder={
                            translations.ENTER_MOBILE_NUMBER ||
                            `Enter Mobile Number`
                          }
                        />
                        {this.studentValidator.message(
                          "Mobile Number",
                          this.state.mobile,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {translations.GENDER || `Gender`}:
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <select
                          className="form-control"
                          value={this.state.gender}
                          name="gender"
                          onChange={this.setFormField.bind(this)}
                        >
                          <option value="male">
                            {translations.MALE || `Male`}
                          </option>
                          <option value="female">
                            {translations.FEMALE || `Female`}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {translations.PASSWORD || `Password`}:
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          value={this.state.password}
                          name="password"
                          onChange={this.setFormField.bind(this)}
                          placeholder={
                            translations.ENTER_PASSWORD || `Enter Password`
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light-secondary btn-sm"
                      data-dismiss="modal"
                    >
                      <i className="bx bx-x d-block d-sm-none"></i>
                      <span className="d-none d-sm-block">
                        {translations.CLOSE || `Close`}
                      </span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary ml-1 btn-sm"
                      onClick={this.save.bind(this)}
                    >
                      <i className="bx bx-check d-block d-sm-none"></i>
                      <span className="d-none d-sm-block">
                        {translations.SAVE || `Save`}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="modal fade text-left"
            id="search-parent"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">
                    {translations.PARENT_SEARCH || `Parent Search`}
                  </h3>
                  <button
                    type="button"
                    className="close rounded-pill"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {translations.SEARCH || `Search`}:
                      </label>
                    </div>
                    <div className="col-lg-8 position-relative">
                      <input
                        type="number"
                        className="form-control form-control-user"
                        value={this.state.searchMobile}
                        name="searchMobile"
                        onChange={this.setFormField.bind(this)}
                        placeholder={
                          translations.ENTER_MOBILE || `Enter Mobile`
                        }
                      />
                    </div>
                  </div>
                  <div className="row col-lg-12">
                    {this.state.parentsList.map((parent, index) => {
                      return (
                        <div className="col-lg-12">
                          <span>
                            {parent.firstName} {parent.lastName}
                          </span>
                          <button
                            onClick={() =>
                              this.addParent({
                                studentID: this.state.selectedStudentID,
                                parentID: parent._id,
                              })
                            }
                          >
                            {translations.ASSIGN || `Assign`}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-xs btn-success"
                    onClick={() => this.searchParent()}
                  >
                    {translations.SEARCH || `Search`}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade text-left"
            id="bulk-upload"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">
                    {translations.BULK_UPLOAD || `Bulk Upload`}
                  </h3>
                  <button
                    type="button"
                    className="close rounded-pill"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-3">
                      <label className="col-form-label">
                        {translations.FILE || `File`}:
                      </label>
                    </div>
                    <div className="col-lg-9 position-relative">
                      <div className="choose-file-section">
                        <label htmlFor="aqqQuestionImageFile">
                          <input
                            type="file"
                            id="aqqQuestionImageFile"
                            onChange={this.uploadFile}
                          />
                          <i className="uil-file" />{" "}
                          {translations.CHOOSE_FILE || `Choose File`}
                        </label>
                      </div>
                      {/* <input
                        type="file"
                        className="form-control form-control-user"
                        onChange={this.uploadFile}
                        placeholder="Enter Mobile"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-xs btn-success"
                    onClick={() => this.previewData()}
                  >
                    {translations.PREVIEW || `Preview`}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade text-left"
            id="parents"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">
                    {translations.PARENTS_DETAILS || `Parents Details`}
                  </h3>
                  <button
                    type="button"
                    className="close rounded-pill"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {translations.MOBILE || `Mobile`}:
                      </label>
                    </div>
                    <div className="col-lg-8 position-relative">
                      <input
                        type="number"
                        className="form-control form-control-user"
                        value={this.state.parentMobile}
                        name="parentMobile"
                        onChange={this.setFormField.bind(this)}
                        placeholder={
                          translations.ENTER_MOBILE || "Enter Mobile"
                        }
                      />
                      {this.parentValidator.message(
                        "Mobile",
                        this.state.parentMobile,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {translations.FIRST_NAME || `First Name`}:
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.parentFirstName}
                        name="parentFirstName"
                        onChange={this.setFormField.bind(this)}
                        placeholder={
                          translations.ENTER_FIRST_NAME || "Enter First Name"
                        }
                      />
                      {this.parentValidator.message(
                        "First Name",
                        this.state.parentFirstName,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {translations.LAST_NAME || `Last Name`}:
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.parentLastName}
                        name="parentLastName"
                        onChange={this.setFormField.bind(this)}
                        placeholder={
                          translations.ENTER_LAST_NAME || "Enter Last Name"
                        }
                      />
                      {this.parentValidator.message(
                        "Last Name",
                        this.state.parentLastName,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {translations.RELATION || `Relation`}:
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <select
                        className="form-control"
                        value={this.state.parentRelation}
                        name="parentRelation"
                        onChange={this.setFormField.bind(this)}
                      >
                        <option value="mother">
                          {translations.MOTHER || `Mother`}
                        </option>
                        <option value="father">
                          {translations.FATHER || `Father`}
                        </option>
                        <option value="guardian">
                          {translations.GUARDIAN || `Guardian`}
                        </option>
                      </select>
                      {this.parentValidator.message(
                        "Relation",
                        this.state.parentRelation,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {translations.PASSWORD || `Password`}:
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="password"
                        className="form-control form-control-user"
                        value={this.state.parentPassword}
                        name="parentPassword"
                        onChange={this.setFormField.bind(this)}
                        placeholder={
                          translations.ENTER_PASSWORD || "Enter Password"
                        }
                      />
                      {this.parentValidator.message(
                        "Password",
                        this.state.parentPassword,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div></div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={this.saveParent.bind(this)}
                      >
                        <i className="bx bx-check d-block d-sm-none"></i>
                        <span className="d-none d-sm-block">
                          {translations.SAVE_PARENT_DETAIL ||
                            `Save Parent Detail`}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-secondary btn-sm"
                    onClick={() => this.addNewParent()}
                  >
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">
                      {translations.ADD || `Add`}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {this.state.showSection === "students" && (
            <div className="table-responsive students-data-list-table-container">
              <table className="table">
                <thead>
                  <tr className="students-list-container-header">
                    <th>{translations.PHOTO || `Photo`}</th>
                    <th>{translations.NAME || `Name`}</th>
                    <th>{translations.MOBILE || `Mobile Number`}</th>
                    <th>{translations.CLASS || `Class`}</th>
                    <th>{translations.PERFORMANCE || `Performance`}</th>
                    <th>{translations.PARENTS || `Parents`}</th>
                    <th>{translations.ACTIONS || `Actions`}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.students.map((student, index) => {
                    return (
                      <tr
                        className="students-list-container-row"
                        key={"student" + index}
                      >
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              className="rounded-circle"
                              src={
                                student.profilePicUrl ||
                                "/assets/images/avatars/user.svg"
                              }
                              alt="avatar"
                              height="32"
                              width="32"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="ml-1">
                              <span className="text-bold-500">
                                {student.firstName}{" "}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{student.mobile}</td>
                        <td>{student.classID ? student.classID.name : ""}</td>
                        <td className="performance-td">
                          <Link
                            to={"/student-performance/" + student._id}
                            className="primary"
                          >
                            <button className="btn btn-outline-default block btn-sm">
                              {tid("Report")} <i className="uil-file" />
                            </button>
                          </Link>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-outline-default block btn-sm"
                            onClick={() => this.getParents(student._id)}
                          >
                            {tid('Parents')}
                          </button>
                        </td>
                        <td>
                          <div className="action-items">
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              data-toggle="modal"
                              data-target="#student"
                              onClick={() => this.edit(student)}
                            >
                              <i className="uil-edit" />
                            </button>
                            &nbsp;
                            {/* <button type="button" className="btn btn-danger btn-sm"><i className='uil-trash' /></button> */}
                            <Delete
                              url={`users/${student._id}`}
                              onDeleteSuccess={this.removeSkillById(
                                student._id
                              )}
                              size="md"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {!this.state.students.length && (
                <NoResults title={tid("No Students found")} />
              )}
            </div>
          )}

          {this.state.showSection === "data-preview" && (
            <div className="table-responsive">
              <h6 className="p-2">
                Loading {this.state.dataParse.length - 1 - this.state.negatives}{" "}
                users out of {this.state.dataParse.length - 1}.
              </h6>
              <button
                className="btn btn-info pull-right"
                onClick={() => {
                  this.uploadData();
                }}
              >
                Confirm & Upload
              </button>
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th>{translations.FIRST_NAME || `First Name`}</th>
                    <th>{translations.LAST_NAME || `Last Name`}</th>
                    <th>{translations.ADMISSION_NO || `Admission No`}</th>
                    <th>{translations.MOBILE || `Mobile`}</th>
                    <th>{translations.GENDER || `Gender`}</th>
                    <th>{translations.STANDARD || `Standard`}</th>
                    <th>{translations.PASSWORD || `Password`}</th>
                    <th>{translations.STATUS || `Status`}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.dataParse.map((data, index) => {
                    return index > 0 ? (
                      <tr key={"data" + index}>
                        <td>{data[0]}</td>
                        <td>{data[1]}</td>
                        <td>{data[2]}</td>
                        <td>{data[3]}</td>
                        <td>{data[4]}</td>
                        <td>{data[5]}</td>
                        <td>{data[6]}</td>
                        <td>{data[7]}</td>
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </table>
            </div>
          )}

          {this.state.showSection === "parents" && (
            <div className="parents-info-container">
              <div className="parent-info-header">
                <div
                  className="pih-left-side"
                  onClick={() => this.setState({ showSection: "students" })}
                >
                  <i className="uil-arrow-left" /> {translations.BACK || `Back`}
                </div>
                <div className="pih-right-side">
                  <button data-toggle="modal" data-target="#parents">
                    <i className="uil-plus" />
                    {translations.ADD_PARENT || `Add Parent`}
                  </button>
                </div>
              </div>
              {this.state.parents.map((parent, index) => {
                return (
                  <div
                    key={"parent-" + index}
                    className="parent-info-card-container"
                  >
                    <div className="pih-left-side">
                      <img
                        src={
                          parent.profilePicUrl || "/assets/images/profile.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="pih-right-side">
                      <h3>
                        {parent.firstName} {parent.lastName}
                      </h3>
                      <p>{parent.relation}</p>
                      <h6>
                        <i className="uil-phone" />
                        {parent.mobile}
                      </h6>
                    </div>
                  </div>
                );
              })}
              {!this.state.parents.length && (
                <NoResults
                  title={translations.NO_PARENTS_FOUND || "No Parents found.."}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WithTranslations(StudentsListComponent);
