import React, { Component } from "react";
import SVG from 'react-inlinesvg';
import "./ToolbarComponent.scss";

import { baseUrl } from "../../../../../../config";
import { create } from "../../../../../../utils";

// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import Mic from "@material-ui/icons/Mic";
// import MicOff from "@material-ui/icons/MicOff";
// import Videocam from "@material-ui/icons/Videocam";
// import VideocamOff from "@material-ui/icons/VideocamOff";
// import Fullscreen from "@material-ui/icons/Fullscreen";
// import FullscreenExit from "@material-ui/icons/FullscreenExit";
// import PictureInPicture from "@material-ui/icons/PictureInPicture";
// import ScreenShare from "@material-ui/icons/ScreenShare";
// import StopScreenShare from "@material-ui/icons/StopScreenShare";
// import Tooltip from "@material-ui/core/Tooltip";
// import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
// import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
// import IconButton from "@material-ui/core/IconButton";

// const logo = require("/assets/images/logo_v3.jpg");

export default class ToolbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      fullscreen: false, 
      isMobile: false,
      muteAll: false,
      lockMeeting: false,
      showExtraOptions: false,
    };
  }

  micStatusChanged = () => {
    this.props.micStatusChanged();
  }

  camStatusChanged = () => {
    this.props.camStatusChanged();
  }

  screenShare = () => {
    this.props.screenShare();
  }

  stopScreenShare = () => {
    this.props.stopScreenShare();
  }

  toggleFullscreen = () => {
    this.setState({ fullscreen: !this.state.fullscreen });
    this.props.toggleFullscreen();
  }

  leaveSession = () => {
    this.props.leaveSession();
  }

  toggleChat = () => {
    console.log('showParticipants called')
    this.props.toggleChat();
  }

  componentDidMount () {
    if (window.innerWidth < 767) {
      this.setState({
        isMobile: true
      })
    }
  }

  updateLock(locked) {
    create(baseUrl + "live-classes/" + this.props.liveClassID, 'put', {locked})
      .then((response) => {
        this.setState({
          lockMeeting: locked
        })
      })
  }

  muteAll(muteAll) {
    if(muteAll)
      this.props.muteAll();
    else
      this.props.unMuteAll();
  }

  toggleExtraOptions = () => {
    const {showExtraOptions} = this.state
    this.setState({
      showExtraOptions: !showExtraOptions
    })
  }

  render() {
    const { fullscreen, isMobile, showExtraOptions } = this.state;
    const { toggleWhiteBoard, mySessionId, localUser, toggleParticipants } = this.props;
    const micActiveFlag = localUser !== undefined && localUser.isAudioActive()
    const videoActiveFlag = localUser !== undefined && localUser.isVideoActive()
    const screenShareFlag = localUser !== undefined && localUser.isScreenShareActive()
    return (
      <>
        <div className='top-toggle-full-screen-section'>
          {this.props.whiteboard && <div
            onClick={() => toggleParticipants()}       
            className='chat-control-section'>
            <SVG
              src={"/assets/images/student-white.svg"}
              title={"Participants"}
              preProcessor={code => code.replace(/fill=".*?"/g, `fill=${"#fff"}`)}
            />
          </div>}
          {this.props.whiteboard && <div
            onClick={() => toggleWhiteBoard()}       
            className='chat-control-section'>
            <SVG
              src={"/assets/images/whiteboard.svg"}
              title={"White Board"}
              preProcessor={code => code.replace(/fill=".*?"/g, `fill=${"#fff"}`)}
            />
          </div>}
          {this.props.chat && <div
            onClick={this.toggleChat}          
            className='chat-control-section'>
            <SVG
              src={"/assets/images/comment.svg"}
              title={"Chat"}
              preProcessor={code => code.replace(/fill=".*?"/g, `fill=${"#fff"}`)}
            />
          </div>}
          <div onClick={this.toggleFullscreen} className='full-screen-control-section'>
            <SVG
              src={fullscreen ? "/assets/images/exit-fullscreen.svg" : "/assets/images/fullscreen.svg"}
              title={fullscreen ? "Exit Full Screen" : "Full Screen"}
              preProcessor={code => code.replace(/fill=".*?"/g, `fill=${"#fff"}`)}
            />
          </div>
        </div>
        <div className='video-page-top-nav-container'>
          <div className='video-session-info-container'>
            <div className='app-logo-section'>
              {isMobile && (
                <span onClick={() => this.toggleExtraOptions()}>
                  <i className={!showExtraOptions ? 'uil-bars' : 'uil-times'} />
                </span>
              )}
              <img src="/assets/images/logo.jpg" alt="TeTu" />
            </div>
            {this.props.sessionId && (
              <p>{mySessionId}</p>
            )}
          </div>
          {showExtraOptions && (
            <div className="extra-options-section-xs">
              {(this.props.hostID == this.props.userID) && <div className='mute-section'>
                  {this.props.mutedAll ? (
                    <div className='mute-now-section' onClick={() => this.muteAll(false)}>
                      <i className="icon-feather-mic-off action-icon" />
                      <p>Unmute All</p>
                    </div>) : (
                    <div className='mute-now-section' onClick={() => this.muteAll(true)}>
                      <i className="icon-feather-mic action-icon" />
                      <p>Mute All</p>
                    </div>
                  )}
              </div>}
              {(this.props.hostID == this.props.userID) && <div className='lock-section'>
                  {this.state.lockMeeting ? (
                    <div className='lock-now-section' onClick={() => this.updateLock(false)}>
                      <i className="uil-lock-open-alt action-icon" />
                      <p>Unlock</p>
                    </div>) : (
                    <div className='lock-now-section' onClick={() => this.updateLock(true)}>
                      <i className="uil-lock action-icon" />
                      <p>Lock</p>
                    </div>
                  )}
              </div>}
              {this.props.screenshare && (
                <div className='screen-share-section'>
                  <div
                    onClick={!screenShareFlag ? this.screenShare : this.stopScreenShare}>
                    {!screenShareFlag ? (
                      <div className='share-now-section'>
                        <SVG
                          src={"/assets/images/screen.svg"}
                          title={"Present Screen"}
                          preProcessor={code => code.replace(/fill=".*?"/g, `fill=${"#50b37e"}`)}
                        />
                        <p>Present Screen</p>
                      </div>) : (
                      <div className='stop-now-section'>
                        <i className='uil-stop-circle' />
                        <p>Stop Sharing</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className='video-tools-container'>
            <button
              type="button"
              id="navMicButton"
              onClick={this.micStatusChanged}
              className={`mic-button btn btn-animated btn-animated-y ${micActiveFlag ? "btn-default" : "btn-outline-default"}`}
            >
              <span className={
                  (micActiveFlag && !this.props.mute)
                    ? "btn-inner--visible"
                    : "btn-inner--hidden"
                }
              >
                <i className="icon-feather-mic action-icon" />
              </span>
              <span className={
                  (!micActiveFlag || this.props.mute)
                    ? "btn-inner--visible"
                    : "btn-inner--hidden"
                }
              >
                <i className="icon-feather-mic-off action-icon" />
              </span>
            </button>
            <button
              id="navCamButton"
              onClick={this.camStatusChanged}
              className={`btn btn-animated btn-animated-y ${videoActiveFlag ? "btn-success" : "btn-outline-success"}`}
            >
              <span className={
                  videoActiveFlag
                    ? "btn-inner--visible"
                    : "btn-inner--hidden"
                }
              >
                <i className="icon-feather-video action-icon" />
              </span>
              <span className={
                  !videoActiveFlag
                    ? "btn-inner--visible"
                    : "btn-inner--hidden"
                }
              >
                <i className="icon-feather-video-off action-icon" />
              </span>
            </button>
            <button
              onClick={this.leaveSession}
              id="disconnect"
              type="button"
              className="btn btn-animated btn-danger btn-animated-y"
            >
              <span className="btn-inner--visible">
                <i className="icon-feather-phone action-icon"></i>
              </span>
              <span className="btn-inner--hidden">
                <i className="icon-feather-phone-off action-icon"></i>
              </span>
            </button>
          </div>
          {(this.props.hostID == this.props.userID) && <div className='mute-section'>
            <div>
              {this.props.mutedAll ? (
                <div className='mute-now-section' onClick={() => this.muteAll(false)}>
                  <i className="icon-feather-mic-off action-icon" />
                  <p>Unmute All</p>
                </div>) : (
                <div className='mute-now-section' onClick={() => this.muteAll(true)}>
                  <i className="icon-feather-mic action-icon" />
                  <p>Mute All</p>
                </div>
              )}
            </div>
          </div>}
          {(this.props.hostID == this.props.userID) && <div className='lock-section'>
            <div>
              {this.state.lockMeeting ? (
                <div className='lock-now-section' onClick={() => this.updateLock(false)}>
                  <i className="uil-lock-open-alt action-icon" />
                  <p>Unlock</p>
                </div>) : (
                <div className='lock-now-section' onClick={() => this.updateLock(true)}>
                  <i className="uil-lock action-icon" />
                  <p>Lock</p>
                </div>
              )}
            </div>
          </div>}
          {!isMobile && this.props.screenshare && (
            <div className='screen-share-section'>
              <div
                onClick={!screenShareFlag ? this.screenShare : this.stopScreenShare}>
                {!screenShareFlag ? (
                  <div className='share-now-section'>
                    <SVG
                      src={"/assets/images/screen.svg"}
                      title={"Present Screen"}
                      preProcessor={code => code.replace(/fill=".*?"/g, `fill=${"#50b37e"}`)}
                    />
                    <p>Present Screen</p>
                  </div>) : (
                  <div className='stop-now-section'>
                    <i className='uil-stop-circle' />
                    <p>Stop Sharing</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </>
      // <AppBar className="toolbar" id="header">
      //   <Toolbar className="toolbar">
      //     {/* <div id="navSessionInfo">
      //       {this.props.sessionId && (
      //         <div id="titleContent">
      //           <span id="session-title">{mySessionId}</span>
      //         </div>
      //       )}
      //     </div> */}

      //     <div className="buttonsContent">
      //       <IconButton
      //         color="inherit"
      //         className="navButton"
      //         id="navMicButton"
      //         onClick={this.micStatusChanged}
      //       >
      //         {localUser !== undefined && localUser.isAudioActive() ? (
      //           <Mic />
      //         ) : (
      //           <MicOff color="secondary" />
      //         )}
      //       </IconButton>

      //       <IconButton
      //         color="inherit"
      //         className="navButton"
      //         id="navCamButton"
      //         onClick={this.camStatusChanged}
      //       >
      //         {localUser !== undefined && localUser.isVideoActive() ? (
      //           <Videocam />
      //         ) : (
      //           <VideocamOff color="secondary" />
      //         )}
      //       </IconButton>

      //       <IconButton
      //         color="inherit"
      //         className="navButton"
      //         onClick={this.screenShare}
      //       >
      //         {localUser !== undefined && localUser.isScreenShareActive() ? (
      //           <PictureInPicture />
      //         ) : (
      //           <ScreenShare />
      //         )}
      //       </IconButton>

      //       {localUser !== undefined && localUser.isScreenShareActive() && (
      //         <IconButton onClick={this.stopScreenShare} id="navScreenButton">
      //           <StopScreenShare color="secondary" />
      //         </IconButton>
      //       )}

      //       <IconButton
      //         color="inherit"
      //         className="navButton"
      //         onClick={this.toggleFullscreen}
      //       >
      //         {localUser !== undefined && this.state.fullscreen ? (
      //           <FullscreenExit />
      //         ) : (
      //           <Fullscreen />
      //         )}
      //       </IconButton>
      //       <IconButton
      //         color="secondary"
      //         className="navButton"
      //         onClick={this.leaveSession}
      //         id="navLeaveButton"
      //       >
      //         <PowerSettingsNew />
      //       </IconButton>
      //       <IconButton
      //         color="inherit"
      //         onClick={this.toggleChat}
      //         id="navChatButton"
      //       >
      //         {this.props.showNotification && <div id="point" className="" />}
      //         <Tooltip title="Chat">
      //           <QuestionAnswer />
      //         </Tooltip>
      //       </IconButton>
      //     </div>
      //   </Toolbar>
      // </AppBar>
    );
  }
}
