import React from 'react';
import { Link } from "react-router-dom";

export const StatCardNew = ({ title, url, image, desc, transparent, className = "col-lg-3" }) => (
<Link to={"/live-class-feature"} className={className}>
    <div className={transparent?"":"uk-card-default uk-card-hover uk-card-body rounded-lg animate-this"}>
    {image && (
        <img src={image} className="scale-up-medium mb-3" width="75" alt="" />
    )}
    <p className="uk-text-bold">{title}</p>
    <p className="uk-text">{desc}</p>
    </div>
</Link>
);

export const Header = ({ children }) => (
    <h3 className="uk-heading-line uk-text-center uk-padding-small">
        <span>{children}</span>
    </h3>
);

export const StatCard = ({ title, image, description, className = "col-lg-3" }) => (
<div className={className}>
    <div className="uk-card-default uk-card-hover uk-card-body rounded-lg animate-this">
    {image && (
        <img src={image} className="scale-up-medium mb-3" width="100" alt="" />
    )}
    {description && <h5>{description}</h5>}
    <p className="uk-text-bold"> {title} </p>
    </div>
</div>
);