import React from "react";
// import _ from 'lodash';

import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { get } from "../../../utils";
// import { Loader } from "../../shared/loader";

import AddBranchComponent from "./add-branch";

var branches = ["bg-success", "bg-warning", "bg-info", "bg-grey"];

class BranchesComponent extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        branches : [],
        showSection: "list",
        branchID: "",

        loadingBranches: false
      }
    }

    getBranches() {
      this.setState({
        showSection: 'list',
        loadingBranches: true
      })

      get(baseUrl + "branches/list")
        .then((response) => {
          this.setState({
            loadingBranches: false
          })
          this.setState({
            branches: response.data.data
          })
        })
        .catch((error) => {
          
        });
    }

    componentDidMount() {
      this.getBranches();
    }

    render() {
        return (
          <div id="wrapper" className="admin-panel">
          <Sidebar></Sidebar>
          <Navbar></Navbar>
            <div className="page-content">
              <div className="page-content-inner">
                <div className="section-header pt-1 mb-lg-2 border-0 uk-flex-middle">
                    <div className="section-header-left">
                        <h2 className="uk-heading-line text-left"><span> Branches </span></h2>
                    </div>
                    <div className="section-header-right">
                      <button className="btn btn-xs btn-default uk-visible@s" onClick={() => this.setState({showSection: 'add-branch'})}> <i className="uil-plus"></i> Add Branch</button>
                    </div>
                </div>
                
                {/* {this.state.loadingBranches && <div className="text-center">
                  <Loader size={3} text="Loading Branches..." color={"#3e416d"}></Loader>
                </div>} */}

                {this.state.showSection === 'list' && <div className="uk-child-width-2-4@m uk-child-width-1-2@s" uk-grid={"true"} uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200">
                  {this.state.branches.map((branch, index) => {
                  return(<div key={"branch-"+index}>
                    <div className={"uk-card uk-card-body p-15 border-15 "+branches[index%6]}>
                      <div className="row col-lg-12 np">
                        <div className="col-lg-2">
                          <img className="branch-logo float-left" src={branch.fileUrl || "/assets/images/profile.svg"} alt=""/>
                        </div>
                        <div className="col-lg-10">
                          <h6 className="uk-card-title np pointer float-left white-font" onClick={() => this.setState({branchID: branch._id, showSection: 'add-branch'})} >{branch.name}</h6>
                          <div className="row col-lg-12"><i className="icon-feather-phone-call"></i><small className="pl-1">{branch.phone}</small></div>
                          <p>{branch.address1}, {branch.city}, {branch.state}, {branch.country}, {branch.pincode}</p>
                        </div>
                      </div>
                    </div>
                </div>)})}
              </div>}
                {this.state.showSection === 'add-branch' && 
                <div className="uk-child-width-1-2@m uk-grid-small uk-grid-match pb-3" uk-grid={"true"}>
                  <AddBranchComponent branchID={this.state.branchID} showList={() => this.setState({showSection: 'list'})} getBranches={() => this.getBranches()}></AddBranchComponent>
                </div>}
              </div>
            </div>

          </div>
        );
    }
}

export default BranchesComponent;