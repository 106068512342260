import React from "react";
import swal from "sweetalert2";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";

import * as $ from "jquery";
import moment from "moment";

import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import SimpleReactValidator from "simple-react-validator";

import { baseUrl } from "../../../config";
// import Loader from "../../../../shared/loader";

import {
  getUserData,
  get,
  create,
  getSelectedUsersData,
  getOptionsData,
} from "../../../utils";
import "./meetings.scss";
import Delete from "../../common/Delete";
import { WithTranslations } from "../../../i18n/withTranslations";

class MeetingsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetings: [],
      employees: [],
      title: "",
      startTime: "",
      endTime: "",
      agenda: "",
      meetingDate: "",
      meetingID: "",
      selectedFaculties: [],
    };
    this.meetingValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.meetingValidator.allValid()) {
      let url = baseUrl + "meetings/";
      if (!this.state.meetingID) url += "add";
      else url += this.state.meetingID;

      create(url, this.state.meetingID ? "put" : "post", {
        title: this.state.title,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        agenda: this.state.agenda,
        meetingDate: this.state.meetingDate,
        attendees: this.state.selectedFaculties,
      })
        .then((response) => {
          this.getMeetingsList();
          $("#add-meeting").trigger("click");
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.meetingValidator.showMessages();
      this.forceUpdate();
    }
  }

  onChangeFaculties(selectedList, selectedItem) {
    this.setState({ selectedFaculties: selectedList });
  }

  getMeetingsList() {
    get(baseUrl + "meetings/list")
      .then((response) => {
        this.setState({
          meetings: response.data.data,
        });
      })
      .catch((error) => {});
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
    });
  }

  componentDidMount() {
    this.assignUserData();
    this.getMeetingsList();
    this.getFaculty();
  }

  getFaculty() {
    get(baseUrl + "users/list/staff")
      .then((response) => {
        this.setState({
          employees: getOptionsData(response.data.data),
        });
      })
      .catch((error) => {});
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  removeMeetingById = (id) => () => {
    this.setState({
      meetings: this.state.meetings.filter((k) => k._id != id),
    });
  };

  editMeeting(meeting) {
    let selectedFaculties = getSelectedUsersData(meeting.attendees);

    this.setState({
      title: meeting.title,
      startTime: meeting.startTime,
      endTime: meeting.endTime,
      agenda: meeting.agenda,
      meetingDate: new Date(meeting.meetingDate).toISOString().substr(0, 10),
      meetingID: meeting._id,
      selectedFaculties,
    });
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">
                <Link to={"/accounts"}>
                  <i className="uil-arrow-left"></i>
                </Link>
                {tid("Meetings")}
              </h2>
            </div>
            <div className="app-page-header-right">
              {this.state.userType !== "meeting" &&
                this.state.userType !== "parent" && (
                  <div className="section-header-right">
                    <button
                      className="app-add-option-button"
                      data-toggle="modal"
                      data-target="#add-meeting"
                    >
                      <i className="uil-plus"></i> {tid("Add Meeting")}
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="meetings-list-container">
            <table className="table mb-0">
              <thead>
                <tr className="meetings-list-container-header">
                  <th>{tid("S.No")}</th>
                  <th>{tid("Title")}</th>
                  <th>{tid("Start Time")}</th>
                  <th>{tid("End Time")}</th>
                  <th>{tid("Date")}</th>
                  <th>{tid("Attendees")}</th>
                  <th>{tid("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.meetings.map((meeting, index) => {
                  return (
                    <tr
                      className="meetings-list-container-row"
                      key={"meeting" + index}
                    >
                      <td>{index + 1}</td>
                      <td>
                        <div className="">
                          <div className="ml-1">
                            <span
                              className="text-bold-500"
                              uk-tooltip={meeting.agenda}
                            >
                              {meeting.title}{" "}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>{meeting.startTime}</td>
                      <td>{meeting.endTime}</td>
                      <td>
                        <span>
                          {moment(meeting.meetingDate).format("DD-MM-YYYY")}
                        </span>
                      </td>
                      <td>
                        {meeting.attendees.map((attendee, index) => {
                          return (
                            attendee.userID.firstName +
                            " " +
                            attendee.userID.lastName +
                            ", "
                          );
                        })}
                      </td>
                      <td>
                        <Link
                          to={"/meeting/" + meeting._id}
                          type="button"
                          className="float-left pr-1 btn btn-primary btn-xs"
                        >
                          <span className="btn-inner--text">{tid("Join")}</span>
                        </Link>
                        <div className="action-items">
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                            data-toggle="modal"
                            data-target="#add-meeting"
                            onClick={() => this.editMeeting(meeting)}
                          >
                            <i className="uil-edit" />
                          </button>
                          <Delete
                            url={`meetings/${meeting._id}`}
                            onDeleteSuccess={this.removeMeetingById(
                              meeting._id
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            className="modal fade text-left"
            id="add-meeting"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">
                   {tid("Add Meeting")}
                  </h3>
                  <button
                    type="button"
                    className="close rounded-pill"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label>{tid("Title")}</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.title}
                      name="title"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Meeting Title")}
                    />
                    {this.meetingValidator.message(
                      "Title",
                      this.state.title,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>{tid("Agenda")}</label>
                    <textarea
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.agenda}
                      name="agenda"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Agenda")}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>{tid("Meeting Date")}</label>
                    <input
                      type="date"
                      className="form-control form-control-user"
                      value={this.state.meetingDate}
                      name="meetingDate"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Meeting Date")}
                    />
                    {this.meetingValidator.message(
                      "Meeting Date",
                      this.state.meetingDate,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>{tid("Start Time")}</label>
                    <input
                      type="time"
                      className="form-control form-control-user"
                      value={this.state.startTime}
                      name="startTime"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Start Time")}
                    />
                    {this.meetingValidator.message(
                      "Start Time",
                      this.state.startTime,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>{tid("End Time")}</label>
                    <input
                      type="time"
                      className="form-control form-control-user"
                      value={this.state.endTime}
                      name="endTime"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("End Time")}
                    />
                    {this.meetingValidator.message(
                      "End Time",
                      this.state.endTime,
                      "required"
                    )}
                  </div>
                  <div className="form-group col-lg-12 np">
                    <label>{tid("Attendees")}</label>
                    <Multiselect
                      className="form-control"
                      options={this.state.employees} // Options to display in the dropdown
                      value={this.state.selectedFaculties} // Preselected value to persist in dropdown
                      selectedValues={this.state.selectedFaculties}
                      onSelect={this.onChangeFaculties.bind(this)} // Function will trigger on select event
                      onRemove={this.onChangeFaculties.bind(this)} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-secondary btn-xs"
                    data-dismiss="modal"
                  >
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid("Close")}</span>
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-primary ml-1 btn-xs"
                    onClick={() => this.save()}
                  >
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid("Save")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(MeetingsComponent);
