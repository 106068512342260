import React from "react";
import swal from "sweetalert2";

import { Link } from "react-router-dom";
// import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";

import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { getUserData, get, create } from "../../../utils";
import { WithTranslations } from "../../../i18n/withTranslations";

class AddLiveClassComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      subjects: [],
      chapters: [],
      topics: [],
      faculties: [],
      facultyType: "faculty",
      guestName: "",
      classID: "",
      subjectID: "",
      chapterID: "",
      topicID: "",
      facultyID: "",
      date: "",
      fromTime: "",
      toTime: "",
      title: "",
      points: [],
      imageData: "",
      liveClassID: "",
      imageUrl: "",
      uploading: false,
    };

    this.save = this.save.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.facultyValidator = new SimpleReactValidator({});
  }

  save() {
    this.setState({
      uploading: true,
    });
    create(
      baseUrl +
        "live-classes/" +
        (this.state.liveClassID ? this.state.liveClassID : "add"),
      this.state.liveClassID ? "put" : "post",
      {
        facultyType: this.state.facultyType,
        guestName: this.state.guestName,
        classID: this.state.classID,
        subjectID: this.state.subjectID,
        chapterID: this.state.chapterID,
        topicID: this.state.topicID,
        facultyID: this.state.facultyID,
        date: this.state.date,
        fromTime: this.state.fromTime,
        toTime: this.state.toTime,
        title: this.state.title,
        points: this.state.points,
        imageData: this.state.imageData,
      }
    )
      .then((response) => {
        this.setState({
          uploading: false,
        });
        swal.fire({
          title: "Info",
          text: response.data.message,
          icon: "warning",
        });
        this.props.history.push("/live-classes");
      })
      .catch((error) => {});
  }

  setFormField(event) {
    this.setState({
      [event.target.name]: event.target.value,
      message: "",
      passwordMessage: "",
    });
    if (event.target.name === "classID")
      this.getSubjectsList(event.target.value);
    if (event.target.name === "subjectID")
      this.getChaptersList(this.state.classID, event.target.value);
    if (event.target.name === "chapterID")
      this.getTopicsList(
        this.state.classID,
        this.state.subjectID,
        event.target.value
      );
  }

  getTopicsList(classID, subjectID, chapterID) {
    get(
      baseUrl +
        "topics/list/" +
        (this.state.classID || classID) +
        "/" +
        (this.state.subjectID || subjectID) +
        "/" +
        (this.state.chapterID || chapterID)
    )
      .then((response) => {
        this.setState({
          topics: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  uploadFile(event) {
    const file = event.target.files[0];
    let reader = new FileReader();
    const scope = this;
    reader.onload = function () {
      scope.setState({
        imageData: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  getChaptersList(classID, subjectID) {
    get(baseUrl + "chapters/list/" + classID + "/" + subjectID)
      .then((response) => {
        this.setState({
          chapters: response.data.data,
        });
      })
      .catch((error) => {});
  }

  getClasses() {
    get(baseUrl + "classes/list")
      .then((response) => {
        this.setState({
          classes: response.data.data,
        });
      })
      .catch((error) => {});
  }

  getSubjectsList(classID) {
    get(baseUrl + "subjects/list/" + (this.state.classID || classID))
      .then((response) => {
        this.setState({
          subjects: response.data.data,
        });
      })
      .catch((error) => {});
  }

  getFacultiesList() {
    get(baseUrl + "users/list/faculty")
      .then((response) => {
        this.setState({
          faculties: response.data.data,
        });
      })
      .catch((error) => {});
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();

    let liveClassID = this.props.match.params.liveClassID;
    this.setState({
      liveClassID,
    });
    if (liveClassID) this.loadLiveClass(liveClassID);

    this.getClasses();
    this.getFacultiesList();
  }

  loadLiveClass(liveClassID) {
    get(baseUrl + "live-classes/get/" + liveClassID)
      .then((response) => {
        let {
          facultyType,
          guestName,
          classID,
          subjectID,
          chapterID,
          topicID,
          facultyID,
          date,
          fromTime,
          toTime,
          title,
          points,
          imageUrl,
        } = response.data.data;
        // date = moment(new Date(date)).format('DD/MM/YYYY');

        this.setState({
          facultyType,
          guestName,
          classID,
          subjectID,
          chapterID,
          topicID,
          facultyID,
          date,
          fromTime,
          toTime,
          title,
          points,
          imageUrl,
        });
        if (classID) {
          this.getSubjectsList(classID);
        }
        if (classID && subjectID) {
          this.getChaptersList(classID, subjectID);
        }
        if (classID && subjectID && chapterID) {
          this.getTopicsList(classID, subjectID, chapterID);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addPoint() {
    let points = this.state.points;
    points.push("");
    this.setState({
      points,
    });
  }

  handleFieldEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const index = splitted[1];
      const value = event.target.value;
      let items = this.state[name];
      items[parseInt(index, 10)] = value;
      this.setState({
        [name]: items,
      });
    }
  };

  remove(index) {
    let { points } = this.state;
    points.splice(index, 1);
    this.setState({
      points,
    });
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">
                <Link to={"/live-classes"}>
                  <i className="uil-arrow-left" />
                </Link>{" "}
                {tid(`Add Live Class`)}
              </h2>
            </div>
          </div>
          <div className="add-live-class-form-container" uk-grid={"true"}>
            <div className="uk-width-2-5@m">
              <div className="uk-card-default rounded text-center p-1">
                <div className="uk-position-relative">
                  <h6>{tid(`Cover Image`)}</h6>
                  {(this.state.imageData || this.state.imageUrl) && (
                    <div className="m-auto">
                      <img
                        src={this.state.imageData || this.state.imageUrl}
                        alt=""
                      />
                    </div>
                  )}
                  {!this.state.imageData && !this.state.imageUrl && (
                    <div className="choose-file-section">
                      <label httmlFor="aqqQuestionImageFile">
                        <input
                          type="file"
                          id="aqqQuestionImageFile"
                          onChange={this.uploadFile}
                        />
                        <i className="icon-feather-camera" />
                      </label>
                    </div>
                  )}
                  {/* <div className="banner-image-upload-section">
                    <div uk-form-custom={"true"}>
                      <input type="file" onChange={this.uploadFile} />
                      <i className='icon-feather-camera' />
                      <span className="uk-link icon-feather-camera icon-medium" />
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="uk-card-default rounded mt-2">
                <hr className="m-0" />
                <div className="p-3">
                  <div
                    className="uk-child-width-1-2@s uk-grid-small"
                    uk-grid={"true"}
                  >
                    <div>
                      <h6 className="uk-text-bold ">
                        {" "}
                        {tid(`Live Class Date`)}:{" "}
                      </h6>
                    </div>
                    <div>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={tid("Enter Date")}
                        value={
                          this.state.date
                            ? new Date(this.state.date)
                                .toISOString()
                                .substr(0, 10)
                            : ""
                        }
                        name="date"
                        onChange={this.setFormField.bind(this)}
                      />
                    </div>
                    <div>
                      <h6 className="uk-text-bold "> {tid(`From Time`)}: </h6>
                    </div>
                    <div>
                      <input
                        type="time"
                        className="form-control"
                        placeholder={tid("Enter Time")}
                        value={this.state.fromTime}
                        name="fromTime"
                        onChange={this.setFormField.bind(this)}
                      />
                    </div>

                    <div>
                      <h6 className="uk-text-bold ">{tid(` To Time`)}: </h6>
                    </div>
                    <div>
                      <input
                        type="time"
                        className="form-control"
                        placeholder={tid("Enter Time")}
                        value={this.state.toTime}
                        name="toTime"
                        onChange={this.setFormField.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-expand@m add-live-class-form-container-basic-details">
              <div className="uk-card-default rounded">
                <div className="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
                  <h5 className="mb-0"> {tid(`Basic details`)} </h5>
                </div>
                <hr className="m-0" />
                <div
                  className="uk-child-width-2-2@s uk-grid-small pl-4 pt-3 pr-4 py-1"
                  uk-grid={"true"}
                >
                  <div>
                    <h6 className="uk-text-bold"> {tid(`Title`)} </h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={tid("Enter Title")}
                      value={this.state.title}
                      name="title"
                      onChange={this.setFormField.bind(this)}
                    />
                  </div>
                </div>
                <div
                  className="uk-child-width-1-2@s uk-grid-small  pl-4 pr-4 py-1"
                  uk-grid={"true"}
                >
                  <div>
                    <h6 className="uk-text-bold"> {tid(`Class`)} </h6>
                    <select
                      className="form-control"
                      value={this.state.classID}
                      name="classID"
                      onChange={this.setFormField.bind(this)}
                    >
                      <option value="">Select Class</option>
                      {this.state.classes.map((c, index) => {
                        return (
                          <option key={"class-" + index} value={c._id}>
                            {c.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> {tid(`Subject`)} </h6>
                    <select
                      className="form-control"
                      value={this.state.subjectID}
                      name="subjectID"
                      onChange={this.setFormField.bind(this)}
                    >
                      <option value="">{tid(`Select Subject`)}</option>
                      {this.state.subjects.map((subject, index) => {
                        return (
                          <option key={"subject-" + index} value={subject._id}>
                            {subject.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> {tid(`Chapter`)} </h6>
                    <select
                      className="form-control"
                      value={this.state.chapterID}
                      name="chapterID"
                      onChange={this.setFormField.bind(this)}
                    >
                      <option value="">Select Chapter</option>
                      {this.state.chapters.map((chapter, index) => {
                        return (
                          <option key={"chapter-" + index} value={chapter._id}>
                            {chapter.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> {tid(`Topic`)} </h6>
                    <select
                      className="form-control"
                      value={this.state.topicID}
                      name="topicID"
                      onChange={this.setFormField.bind(this)}
                    >
                      <option value="">{tid(`Select Topic`)}</option>
                      {this.state.topics.map((topic, index) => {
                        return (
                          <option key={"topic-" + index} value={topic._id}>
                            {topic.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div>
                    <h6 className="uk-text-bold"> {tid(`Faculty Type`)} </h6>
                    <select
                      className="form-control"
                      value={this.state.facultyType}
                      name="facultyType"
                      onChange={this.setFormField.bind(this)}
                    >
                      <option value="">{tid(`Select Faculty Type`)}</option>
                      <option value="guest">{tid(`Guest Faculty`)}</option>
                      <option value="faculty">{tid(`Own Faculty`)}</option>
                    </select>
                  </div>

                  {this.state.facultyType === "guest" && (
                    <div>
                      <h6 className="uk-text-bold"> {tid(`Guest Name`)} </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={tid("Enter Guest name")}
                        value={this.state.guestName}
                        name="guestName"
                        onChange={this.setFormField.bind(this)}
                      />
                    </div>
                  )}

                  {this.state.facultyType === "faculty" && (
                    <div className=" mb-4">
                      <h6 className="uk-text-bold"> {tid(`Faculty`)} </h6>
                      <select
                        className="form-control"
                        value={this.state.facultyID}
                        name="facultyID"
                        onChange={this.setFormField.bind(this)}
                      >
                        <option value="">{tid(`Select Faculty`)}</option>
                        {this.state.faculties.map((faculty, index) => {
                          return (
                            <option
                              key={"faculty-" + index}
                              value={faculty._id}
                            >
                              {faculty.firstName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="uk-card-default rounded mt-1 points-to-cover-accordion">
            <div className="uk-width-expand@m">
              <div
                className="uk-child-width-1-2@s uk-grid-small p-4"
                uk-grid={"true"}
              >
                <div>
                  <h6 className="uk-text-bold">
                    {" "}
                    {tid(`Points to be Covered`)}:{" "}
                    <button
                      className="btn btn-outline-info btn-sm"
                      onClick={() => this.addPoint()}
                    >
                      {" "}
                      <i className="uil-plus" />
                      {tid(`Add`)}
                    </button>{" "}
                  </h6>
                </div>
                <div></div>
                {this.state.points.map((point, index) => {
                  return (
                    <div>
                      <div className="row col-lg-12">
                        <div className="col-lg-10">
                          <input
                            key={"ans-" + index}
                            type="text"
                            className="form-control p10"
                            value={point}
                            name={"points#" + index}
                            onChange={this.handleFieldEdit}
                            placeholder={tid("Enter Point ") + (index + 1)}
                          />
                        </div>
                        <div className="col-lg-2 pt-2">
                          <button
                            className="btn btn-xs btn-danger"
                            onClick={() => this.remove(index)}
                          >
                            {tid(`Remove`)}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div></div>
              </div>
            </div>
          </div>
          <div className="row col-lg-12 text-center save-live-class-button-section">
            <div className="col-lg-12 p-2">
              <button
                type="button"
                className="btn btn-default btn-sm"
                onClick={this.save.bind(this)}
              >
                <i className="bx bx-check d-block d-sm-none"></i>
                <span className="d-none d-sm-block">
                  {this.state.uploading && (
                    <IconLoader size={2} text="" color={"#fff"}></IconLoader>
                  )}
                  &nbsp;{tid(`Save Live Class`)}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(AddLiveClassComponent);
