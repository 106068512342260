import React from "react";
import { Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
// import Navbar from "../shared/navbar/Navbar";
// import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { WithTranslations } from "../../../i18n/withTranslations";
import { getUserData, get } from "../../../utils";

class TestDataComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classID: "",
      subjectID: "",
      chapterID: "",
      years: [],
      yearIndex: 0,
      userType: "",
      fromSubjects: false,
    };
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    let classID = this.props.classID;
    let subjectID = this.props.subjectID;
    let chapterID = this.props.chapterID;
    let fromSubjects = false;
    if (window.location.href.indexOf("online-tests") >= 0) fromSubjects = true;
    this.setState({
      classID,
      subjectID,
      chapterID,
      fromSubjects,
    });
    this.getTestsList(classID, subjectID, chapterID);
  }

  getTestsList(classID, subjectID, chapterID) {
    get(
      baseUrl +
        "tests/list/" +
        classID +
        "/" +
        subjectID +
        "/" +
        (chapterID ? chapterID : "")
    )
      .then((response) => {
        this.setState({
          years: response.data.data,
          className: response.data.class.name,
          subjectName: response.data.subject.name,
          chapterName: response.data.chapter.name,
        });
      })
      .catch((error) => {});
  }

  render() {
    const {tid}=this.props;
    return (
      <div className="card">
        <div className="app-page-header-section">
          <div className="app-page-header-left">
            <h2 className="">
              {" "}
              {this.state.fromSubjects && (
                <Link
                  className="app-add-option-button"
                  to={"/subjects/" + this.state.classID}
                >
                  <i className="uil-arrow-left"></i>
                </Link>
              )}{" "}
              {tid('Online Tests')}
            </h2>
          </div>
          <div className="app-page-header-right">
            {this.state.userType !== "student" &&
              this.state.userType !== "parent" && (
                <div className="section-header-right">
                  <Link
                    className="app-add-option-button"
                    to={
                      "/add-test/" +
                      this.state.classID +
                      "/" +
                      this.state.subjectID +
                      (this.state.chapterID ? "/" + this.state.chapterID : "")
                    }
                  >
                    <i className="uil-plus"></i> {tid('Add Test')}
                  </Link>
                </div>
              )}
          </div>
        </div>
        {this.props.display && (
          <div className="ot-class-info-container">
            <h6 className="">{tid('Class')}: {this.state.className}</h6>
            <h6 className="">{tid('Subject')}: {this.state.subjectName}</h6>
            {this.state.chapterName && (
              <h6 className="">{tid('Chapter')}: {this.state.chapterName}</h6>
            )}
          </div>
        )}
        <ul
          className="uk-tab ot-years-list-container"
          uk-switcher="animation: uk-animation-fade"
        >
          {this.state.years.map((year, index) => {
            return (
              <li key={"year-" + index} className="ot-year-name">
                <a>{year.year}</a>
              </li>
            );
          })}
        </ul>
        <ul className="card-body uk-switcher np pb-3 pt-3">
          <li className="ot-list-details-container">
            <ul className="uk-list uk-list-divider">
              {this.state.years.length > 0 &&
                this.state.years[this.state.yearIndex].tests.map(
                  (test, index) => {
                    return (
                      <li key={"test-" + index} className="np active">
                        <div className="ot-list-detail-flex">
                          <div className="ot-list-left-side">
                            <div className="box cal-box text-center">
                              <div className="uk-countdown-number uk-countdown-days cal-month">
                                {test.month}
                              </div>
                              <div className="countdown-text cal-date">
                                <p>{test.date}</p>
                              </div>
                              {test.grade && (
                                <div className="text-center width-75">
                                  <small className="bold">
                                    {tid('Grade')}: {test.grade}
                                  </small>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="ot-list-right-side">
                            <div className="test-details pl-3">
                              <div className="mt-1 np test-details-header">
                                <h5>
                                  {test.name}
                                  <span className="test-type-badge">
                                    {test.testType}
                                  </span>
                                </h5>
                                {this.state.userType !== "student" &&
                                  this.state.userType !== "parent" && (
                                    <div className="pull-right float-right">
                                      {test.submittedCount > 0 && (
                                        <Link
                                          className="btn btn-xs btn-info"
                                          to={"/test-submissions/" + test._id}
                                          uk-tooltip={tid("View Submissions")}
                                        >
                                          {tid('View Submissions')}
                                        </Link>
                                      )}{" "}
                                      <Link
                                        className="btn btn-xxs btn-default"
                                        to={"/load-questions/" + test._id}
                                      >
                                        <i className="uil-edit-alt"></i> {tid('Edit')} &
                                        {tid('Load Questions')}
                                      </Link>
                                    </div>
                                  )}
                                {this.state.userType === "student" && (
                                  <Link
                                    className="btn btn-xxs btn-info"
                                    to={
                                      (test.submitted
                                        ? "/review/"
                                        : "/online-test/") + test._id
                                    }
                                  >
                                    {" "}
                                    {test.submitted ? (
                                      <i className="uil-file-alt"></i>
                                    ) : (
                                      <i className="uil-edit-alt"></i>
                                    )}{" "}
                                    {test.submitted ? tid("Review") : tid("Attempt Test")}{" "}
                                  </Link>
                                )}
                              </div>
                              <p>{test.mcqs} {tid('Questions')}</p>
                              <div className="ot-extra-details-info">
                                <div className="uk-text-info">
                                  <span>
                                    <i className="uil-clock" />
                                    {test.duration}{tid('Min')}
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    <i className="uil-check-square" />
                                    {test.evaluation} {tid('Evaluation')}
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    <i className="uil-user" />
                                    {tid('No.of students attempted')}:{" "}
                                    {test.submittedCount} / {test.totalUsers}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  }
                )}
            </ul>
          </li>
        </ul>
        {this.state.years.length === 0 && (
          <span className="text-center pb-5">{tid('No tests are added')}..</span>
        )}
      </div>
    );
  }
}

export default WithTranslations(TestDataComponent);
