import React from "react";
import { OBJModel } from "react-3d-viewer";

import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";
import { LanguageContext } from "../../../i18n/LanguageProvider";
import { WithTranslations } from "../../../i18n/withTranslations";



const texts = {
  en: [
    "3D View in Education and bring all the benefits",
    "3D Rendering Benefits 3D animation provides better understanding and clarity of softer designs, such as interior or exterior designs, which increases the design prospects of the model and allows your ideas to flourish.",
    `3D Rendering in its simplest explanation is that it is the creation of images using computer software. 3D rendering is visual creative work, but unlike its relations photography and cinematography, everything in the image is imaginary and must be created. It involves the adapting of the likeness of an object into an image. The C3EL design engineers working on 3D images must input data on the objects in a scene. These data sets include details such as color, lighting, material, and texture. Then the 2D image is captured from a specific perspective and location for the final design.`,
    `The first step involves the design engineer getting a solid idea of what kind of scene is to be created for the client based on the consultation. The plans will be drawn up, sketches made, and reference images identified that will give the design team direction.`,
    `The next step is the rendering which means the computer is used to capture 2D images of the scene as it has been laid out in the sketches. This step can greatly vary depending on the details and the quality of the image required.`,
    `Because 3D rendering is a subjective process that draws on the creativity of the design engineer, there may be a need for several revisions if the client is not satisfied with the end results. Below are a few stages that our design engineers take before a final design is released to our client:`
  ],
  it: [
    "Vista 3D in Education e porta tutti i vantaggi",
    `Vantaggi del rendering 3D L'animazione 3D fornisce una migliore comprensione e chiarezza di progetti più morbidi, come i progetti di interni o esterni, il che aumenta le prospettive di progettazione del modello e consente alle tue idee di prosperare.`,
    `Il rendering 3D nella sua spiegazione più semplice è che si tratta della creazione di immagini utilizzando un software per computer. Il rendering 3D è un lavoro creativo visivo, ma a differenza delle sue relazioni fotografia e cinematografia, tutto nell'immagine è immaginario e deve essere creato. Implica l'adattamento della somiglianza di un oggetto a un'immagine. I progettisti C3EL che lavorano su immagini 3D devono inserire dati sugli oggetti in una scena. Questi set di dati includono dettagli come colore, illuminazione, materiale e consistenza. Quindi l'immagine 2D viene catturata da una prospettiva e una posizione specifiche per il progetto finale.`,
    `Il primo passo prevede che l'ingegnere progettista abbia una solida idea del tipo di scena da creare per il cliente in base alla consulenza. Verranno redatte le planimetrie, saranno realizzati schizzi e identificate immagini di riferimento che daranno indicazioni al team di progettazione.`,
    `Il passaggio successivo è il rendering, il che significa che il computer viene utilizzato per acquisire immagini 2D della scena così come è stata disposta negli schizzi. Questo passaggio può variare notevolmente a seconda dei dettagli e della qualità dell'immagine richiesta.`,
    `Poiché il rendering 3D è un processo soggettivo che si basa sulla creatività dell'ingegnere progettista, potrebbero essere necessarie diverse revisioni se il cliente non è soddisfatto dei risultati finali. Di seguito sono riportate alcune fasi che i nostri ingegneri progettisti eseguono prima che un progetto finale venga rilasciato al nostro cliente:`
  ],
};

class ThreeDViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { siteLanguage, translations } = this.props;
    const t = siteLanguage === "it" ? texts.it : texts.en;
    const bgStyles = {
      backgroundImage:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/three-d-bg.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: 550,
    };
    return (
      <div className="homepage-main-container">
        <Navbar />
        <div className="about-us-info-container" style={bgStyles}>
          <div className="about-us-info-section">
            <h4>{translations.MISSION || `We're on a mission to use`}</h4>
            <h2>{t[0]}</h2>
            <button className="btn know-more-button">
              {translations.KNOW_MORE || ` Know More`}
              <i className="uil-arrow-down" />
            </button>
          </div>
        </div>
        <div className="about-us-details-container">
          <div className="about-us-main-content">
            <h3 className="text-center">
              {translations.THREE_D_HEADING || `3D View`}
            </h3>
            <div className="row tetu-2-extra-details-container">
              <div className="col-md-6">
                <p className="tetu-2-main-desc">{t[1]}</p>
                <p className="tetu-2-main-desc">
                  {t[2]}
                </p>
              </div>
              <div className="col-md-6 three-d-view-object-modal">
                <OBJModel
                  className="pointer border-round"
                  src="/assets/obj/sample.obj"
                  texPath=""
                />
              </div>
            </div>
            <div className="benefits-details-main-section">
              <h4 className="text-center">{translations.HOW_IT_WORKS||`How it works`}</h4>
              <p>
              {t[3]} 
              </p>
              <p className="pt-3">
               {t[4]}
              </p>
              <p className="pt-3">
                {t[5]}
              </p>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default WithTranslations(ThreeDViewComponent);
