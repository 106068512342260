import React from "react";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { getUserData, get } from "../../../utils";
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay';
import './calendar.scss'
import { WithTranslations } from "../../../i18n/withTranslations";
require('react-big-calendar/lib/css/react-big-calendar.css');
const locales = {
  'en-US': require('date-fns/locale/en-US'),
}
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
  messages:{
    today:"xxx"
  }
})

class CalendarComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      events : []
    }
  }
  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID});
  }
  
  componentDidMount() {
    this.assignUserData();
      get(baseUrl + "notices/events")
      .then((response) => {

        let events = [];

        response.data.data.forEach((event) => {
          event.start = new Date(event.start)
          event.end = new Date(event.end)
          events.push(event)
        })
        this.setState({
          events: events
        })
      })
      .catch((error) => {
      });
    }

    render() {
      const { userType } = this.state
     const {tid}=this.props
      const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
      return (
        <div id="wrapper"  className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
          <Sidebar></Sidebar>
          <Navbar></Navbar>
            <div className="page-content app-dashboard-details-main-container">
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className="">{tid('Calendar')}</h2>
                </div>
              </div>
              <div className='app-calendar-main-container'>
                <Calendar
                  className=" pt-3"
                  events={this.state.events}
                  step={60}
                  style={{ height: 500 }}
                  showMultiDayTimes
                  views={['month', 'week', 'day', 'agenda']}
                  localizer={localizer}
                />
              </div>
            </div>
          </div>
        );
    }
}

export default WithTranslations(CalendarComponent);