import React, { Component } from "react";
import "./ParticipantComponent.scss";

export default class ParticipantComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attendees: [],
      showJoined: true,
      showNotJoined: true,
    };
  }

  componentDidMount() {
    console.log(this.props.joinees)
  }

  close = () => {
    this.props.close(undefined);
  }

  render() {
    const {showJoined, showNotJoined} = this.state
    return (
      <div className='participants-list-main-container'>
        <div className="participants-header-container">
          <div className="left-side">
            <h4 className="heading">
              List of Participants
            </h4>
          </div>
          <div className="right-side">
            <i className="uil-times-circle" onClick={this.close} />
          </div>
        </div>
        <div className="participants-body-section" ref={this.chatScroll}>
          <div className="collapse-section" onClick={() => this.setState({showJoined: !showJoined, showNotJoined: false})}>
            <h4 className="heading">
              Joined Participants
            </h4>
            <div className="rside-collapse">
              <i className={!showJoined ? "icon-feather-chevron-down" : "icon-feather-chevron-up"} />
            </div>
          </div>
          {showJoined && this.props.joinees.map((attendee, index) => {
            return (
              <>
                {!attendee.joined && (
                  <div className='p-body-section'>
                    <div className="lside">
                      <div className="l-circle joined-circle">
                        {attendee.firstName ? attendee.firstName.charAt(0) : 'G'}
                      </div>
                    </div>
                    <div className="rside">
                      {attendee.firstName}
                    </div>
                  </div>
                )}
              </>
            )})
          }
          <div className="collapse-section"  onClick={() => this.setState({showNotJoined: !showNotJoined, showJoined: false})}>
            <h4 className="heading">
              Participants who've not Joined
            </h4>
            <div className="rside-collapse">
              <i className={!showNotJoined ? "icon-feather-chevron-down" : "icon-feather-chevron-up"} />
            </div>
          </div>
          {showNotJoined && this.props.joinees.map((attendee, index) => {
            return (
              <>
                {!attendee.joined && (
                  <div className='p-body-section'>
                    <div className="lside">
                      <div className="l-circle not-joined-circle">
                        {attendee.firstName ? attendee.firstName.charAt(0) : 'G'}
                      </div>
                    </div>
                    <div className="rside">
                      {attendee.firstName}
                    </div>
                  </div>
                )}
              </>
            )})
          }
        </div>
      </div>
    );
  }
}
