import React from "react";
import swal from 'sweetalert2';
// import _ from 'lodash';

import * as $ from "jquery";

import SimpleReactValidator from 'simple-react-validator';

import { baseUrl } from "../../../config";
import { get, create, uploadToS3 } from "../../../utils";
import { IconLoader } from "../../shared/loader";

export default class AddBranchComponent extends React.Component {

  constructor(props) {
    super(props);
        this.state = {
            branchID: '',
            file: '',
            displayImg: '',
            name: '',
            code: '',
            phone: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            fileUrl: '',
            savingData: false,

            firstName: '',
            lastName: '',
            email: '',
            userType: 'principal',
            mobile: '',
            password: '',
            principalID: '',
            savingPrincipal: false
        }

        this.uploadFile = this.uploadFile.bind(this);
        this.branchValidator = new SimpleReactValidator({});
        this.principalValidator = new SimpleReactValidator({});
    }

    componentDidMount() {
        if(this.props.branchID) {
            get(baseUrl+"branches/get/"+this.props.branchID)
            .then((response) => {
                let { _id, name, code, phone, address1, address2, city, state, country, pincode, fileUrl } = response.data.data;
                this.setState({
                    branchID: _id, name, code, phone, address1, address2, city, state, country, pincode, fileUrl
                })
            })
        }
    }

    onClickInputFile() {
      $("#input-file").trigger('click')
    }

    uploadFile(event) {
        this.setState({file: event.target.files[0], displayImg: URL.createObjectURL(event.target.files[0])})
    }

    setFormField(event) {
        this.setState({ [event.target.name]: event.target.value, message: ""})
    }

    saveBranch() {
        if (this.branchValidator.allValid()) {

            this.setState({savingData: true})
        
            let url =  baseUrl + "branches/";
            if(!this.state.branchID)
            url+="add";
            else
            url+=this.state.branchID

            let fileName = "";
            if(this.state.file)
                fileName = "branches/"+Date.now()+"/"+this.state.file.name;
            new Promise((resolve, reject) => {
                if(this.state.file)
                    resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
                else 
                    resolve();
            })
            .then((response) => {
                return create(url, (this.state.branchID)?"put":"post", {
                    name: this.state.name,
                    code: this.state.code,
                    phone: this.state.phone,
                    address1: this.state.address1,
                    address2: this.state.address2,
                    city: this.state.city,
                    state: this.state.state,
                    country: this.state.country,
                    pincode: this.state.pincode,
                    fileKey: fileName?fileName: undefined
                })
            })
            .then((response) => {
                this.setState({
                    savingData: false,
                    branchID: response.data.data._id
                })
                swal.fire({
                    title: "Info",
                    text: response.data.message,
                    icon: "warning"
                });
            })
            .catch((error) => {

            });
        } else {
            this.branchValidator.showMessages();
            this.forceUpdate();
        }
    }

    savePrincipal() {
        if (this.principalValidator.allValid()) {

            this.setState({savingPrincipal: true})
        
            let url =  baseUrl + "users/";
            if(!this.state.principalID)
            url+="add";
            else
            url+=this.state.principalID;

            let data = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                userType: 'principal',
                mobile: this.state.mobile,
                branchID: this.state.branchID,
                password: this.state.password
            }

            if(!this.state.password)
                delete data.password;

            create(url, (this.state.principalID)?"put":"post", data)
            .then((response) => {
                $('#principal').trigger('click');
                this.setState({
                    savingPrincipal: false,
                    firstName: '',
                    lastName: '',
                    email: '',
                    userType: 'principal',
                    mobile: '',
                    password: ''
                })
                swal.fire({
                    title: "Info",
                    text: response.data.message,
                    icon: "warning"
                });
            })
            .catch((error) => {

            });
        } else {
            this.principalValidator.showMessages();
            this.forceUpdate();
        }
    }

    getPrincipal() {
        get(baseUrl+"users/get-principal/"+this.props.branchID)
            .then((response) => {
                let { firstName, lastName, email, mobile, _id } = response.data.data;
                this.setState({
                    principalID: _id, firstName, lastName, email, mobile
                })
            })
    }

    render() {
        return (
        <div className="row col-lg-12 card np">
            <div className="card-header">
                <span><small onClick={() => this.props.getBranches()}><i className="icon-feather-arrow-left"></i></small> Branches List</span> {this.state.branchID && <button className="btn btn-xs btn-info pull-right" onClick={() => this.getPrincipal()} data-toggle="modal" data-target="#principal">Principal/Manager</button>}
            </div>
            <div className="modal fade text-left" id="principal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel1">Add Princiapl</h4>
                        <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                        <i className="fa fa-close"></i>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="form-group row align-items-center t-0 b-0">
                            <div className="col-lg-4">
                            <label className="col-form-label">First Name:</label>
                            </div>
                            <div className="col-lg-8">
                                <input
                                    type="text"
                                    className="form-control form-control-user"
                                    value={this.state.firstName}
                                    name="firstName"
                                    onChange={this.setFormField.bind(this)}
                                    placeholder="Enter First Name"
                                />
                                {this.principalValidator.message(
                                    "First Name",
                                    this.state.firstName,
                                    "required"
                                )}
                            </div>
                        </div>
                        <div className="form-group row align-items-center t-0 b-0">
                            <div className="col-lg-4">
                            <label className="col-form-label">Last Name:</label>
                            </div>
                            <div className="col-lg-8">
                            <input
                                type="text"
                                className="form-control form-control-user"
                                value={this.state.lastName}
                                name="lastName"
                                onChange={this.setFormField.bind(this)}
                                placeholder="Enter Last Name"
                            />
                            {this.principalValidator.message(
                                "Last Name",
                                this.state.lastName,
                                "required"
                            )}
                            </div>
                        </div>
                        <div className="form-group row align-items-center t-0 b-0">
                            <div className="col-lg-4">
                            <label className="col-form-label">Email:</label>
                            </div>
                            <div className="col-lg-8">
                                <input
                                    type="text"
                                    className="form-control form-control-user"
                                    value={this.state.email}
                                    name="email"
                                    onChange={this.setFormField.bind(this)}
                                    placeholder="Enter Email"
                                />
                                {this.principalValidator.message(
                                    "Email",
                                    this.state.email,
                                    "required"
                                )}
                            </div>
                        </div>
                        <div className="form-group row align-items-center t-0 b-0">
                            <div className="col-lg-4">
                            <label className="col-form-label">Mobile:</label>
                            </div>
                            <div className="col-lg-8">
                            <input
                                type="number"
                                className="form-control form-control-user"
                                value={this.state.mobile}
                                name="mobile"
                                onChange={this.setFormField.bind(this)}
                                placeholder="Enter Mobile Number"
                            />
                            {this.principalValidator.message(
                                "Mobile Number",
                                this.state.mobile,
                                "required"
                            )}
                            </div>
                        </div>
                        <div className="form-group row align-items-center t-0 b-0">
                            <div className="col-lg-4">
                            <label className="col-form-label">Password:</label>
                            </div>
                            <div className="col-lg-8">
                                <input
                                    type="password"
                                    className="form-control form-control-user"
                                    value={this.state.password}
                                    name="password"
                                    onChange={this.setFormField.bind(this)}
                                    placeholder="Enter Password"
                                />
                                {this.principalValidator.message(
                                    "Password",
                                    this.state.password,
                                    "required"
                                )}
                            </div>
                        </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-light-secondary btn-xs" data-dismiss="modal">
                            <i className="bx bx-x d-block d-sm-none"></i>
                            <span className="d-none d-sm-block">Close</span>
                        </button>
                        <button type="button" className="btn btn-primary ml-1 btn-xs" onClick={this.savePrincipal.bind(this)}>{this.state.savingPrincipal && <IconLoader size={2} text="" color={"#fff"}></IconLoader> }&nbsp;Save
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row col-lg-12">
                    <div className="col-lg-8">
                        <div className="form-group">
                            <label>Branch Name</label>
                            <input className="form-control"
                                value={this.state.name}
                                name="name"
                                onChange={this.setFormField.bind(this)}
                                placeholder="Branch Name" 
                            />
                            {this.branchValidator.message(
                                "Branch Name",
                                this.state.name,
                                "required"
                            )}
                        </div>
                        <div className="form-group row col-lg-12">
                            <div className="col-lg-6 pl-0">
                                <label>Branch Code</label>
                                <input className="form-control"
                                    value={this.state.code}
                                    name="code"
                                    onChange={this.setFormField.bind(this)}
                                    placeholder="Branch Code" 
                                />
                                {this.branchValidator.message(
                                    "Branch Code",
                                    this.state.code,
                                    "required"
                                )}
                            </div>
                            <div className="col-lg-6">
                                <label>Branch Phone</label>
                                <input className="form-control"
                                    value={this.state.phone}
                                    name="phone"
                                    onChange={this.setFormField.bind(this)}
                                    placeholder="Branch Phone" 
                                />
                                {this.branchValidator.message(
                                    "Branch Phone",
                                    this.state.phone,
                                    "required"
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group text-center">
                            <label>Branch Logo</label>
                            <div className="uk-position-relative my-4">

                                <div className="user-profile-photo  m-auto">
                                    <img src={this.state.displayImg || this.state.fileUrl  || "/assets/images/profile.svg"} alt=""/>
                                </div>
        
                                <div className="uk-position-center">
                                    <div uk-form-custom={"true"}>
                                        <input type="file" id="input-file" accept="image/*" onChange={this.uploadFile} />
                                        <span className="uk-link icon-feather-camera icon-medium text-white" onClick={() => this.onClickInputFile()} > </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h6 className="col-lg-12">Address </h6>
                <div className="row col-lg-12">
                    
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>Address 1</label>
                            <textarea className="form-control"
                                value={this.state.address1}
                                name="address1"
                                onChange={this.setFormField.bind(this)}
                                placeholder="Address 1" 
                            ></textarea>
                            {this.branchValidator.message(
                                "Address 1",
                                this.state.address1,
                                "required"
                            )}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>Address 2</label>
                            <textarea className="form-control"
                                value={this.state.adddress2}
                                name="adddress2"
                                onChange={this.setFormField.bind(this)}
                                placeholder="Address 2" 
                            ></textarea>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>City</label>
                            <input className="form-control"
                                value={this.state.city}
                                name="city"
                                onChange={this.setFormField.bind(this)}
                                placeholder="City" 
                            />
                            {this.branchValidator.message(
                                "City",
                                this.state.city,
                                "required"
                            )}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>State</label>
                            <input className="form-control"
                                value={this.state.state}
                                name="state"
                                onChange={this.setFormField.bind(this)}
                                placeholder="State" 
                            />
                            {this.branchValidator.message(
                                "State",
                                this.state.state,
                                "required"
                            )}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>Country</label>
                            <input className="form-control"
                                value={this.state.country}
                                name="country"
                                onChange={this.setFormField.bind(this)}
                                placeholder="Country" 
                            />
                            {this.branchValidator.message(
                                "Country",
                                this.state.country,
                                "required"
                            )}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>Pin Code</label>
                            <input type="number" className="form-control"
                                value={this.state.pincode}
                                name="pincode"
                                onChange={this.setFormField.bind(this)}
                                placeholder="Pin Code" 
                            />
                            {this.branchValidator.message(
                                "Pin Code",
                                this.state.pincode,
                                "required"
                            )}
                        </div>
                    </div>

                </div>
            </div>

            <div className="card-footer text-center">
                <button className="btn btn-default btn-sm grey" onClick={() => this.saveBranch()}> {this.state.savingData && <IconLoader size={2} text="" color={"#fff"}></IconLoader> }&nbsp; Save Branch</button>
            </div>
        </div>
      );
    }
}