import React from "react";
// import swal from 'sweetalert2';

import { Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';

// import { Loader, IconLoader } from "../../shared/loader";
// import Navbar from "../shared/navbar/Navbar";
// import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { get, create } from "../../../utils";

class QuizComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subjects: [],
      quizID: "",
      classID: "",
      subjectID: "",
      chapterID: "",
      typeID: "",
      name: "",
      duration: 0,
      timePassed: 0,
      remainingTime: 0,
      questions: [],
      currentQuestion: {},
      hours: 0,
      minutes: 0,
      seconds: 0,
      currentQuestionDuration: 0,
      currentQuestionIndex: -1,
      answers: [],
      submitted: false
    }
  }

  componentDidMount() {
    let quizID = this.props.match.params.quizID;
    this.setState({
      quizID
    })

    this.getQuestions(quizID);
  }

  getQuestions(quizID) {
    get(baseUrl + "quizs/load-questions/" + (this.state.quizID || quizID))
      .then((response) => {
        let { name, _id,
          classID,
          subjectID,
          chapterID, typeID, duration, questions } = response.data.data;
        let answers = [];
        questions.forEach((question) => {
          answers.push({
            questionID: question._id,
            answers: [],
            answerType: question.questionType
          })
        })

        questions.forEach((question) => {
          question.duration = 0;
        })

        duration *= 60;

        this.setState({
          quizID: _id,
          classID,
          subjectID,
          chapterID,
          typeID,
          name,
          duration,
          questions,
          answers
        })
      })
      .catch((error) => {

      });
  }

  setTimer() {
    this.interval = setInterval(function () {
      let { timePassed, remainingTime, duration, currentQuestionDuration } = this.state;
      timePassed += 1;
      remainingTime = duration - timePassed;
      currentQuestionDuration += 1;

      this.setState({ timePassed, remainingTime, currentQuestionDuration })
    }.bind(this), 1000);
  }

  startTest() {
    this.setState({
      currentQuestion: this.state.questions[0],
      currentQuestionIndex: 0
    })
    this.setTimer();
  }

  onClickActions(type) {
    let counter = 0;
    if (type === 'previous')
      counter = -1;
    else
      counter = 1;

    let questions = this.state.questions;

    questions[this.state.currentQuestionIndex].duration += this.state.currentQuestionDuration;

    let currentQuestionIndex = this.state.currentQuestionIndex + counter;

    let question = this.state.questions[currentQuestionIndex];

    if (question.questionType === "blanks") {
      let count = question.question.split("_____________").length - 1;
      if (count <= 0)
        count = 1;

      question.blanks = [];

      for (var i = 0; i < count; i++) {
        question.blanks.push("");
      }
    }
    this.setState({
      currentQuestionDuration: 0,
      questions,
      currentQuestion: question,
      currentQuestionIndex: currentQuestionIndex
    })
  }

  submitTest() {

    let answersMaster = [];
    let questions = this.state.questions;
    questions[this.state.currentQuestionIndex].duration += this.state.currentQuestionDuration;

    questions.forEach((question) => {
      let answers = [];
      if ((question.questionType !== 'blanks') && (question.questionType !== 'essay') && (question.questionType !== 'matching')) {
        question.options.forEach((option) => {
          if (option.isCorrect)
            answers.push(option.value);
        })
      }
      if (question.questionType === 'blanks') {
        answers = question.blanks;
      }
      if (question.questionType === 'essay') {
        answers = [question.essayAnswer];
      }
      if (question.questionType === 'matching') {
        answers = question.matchingAnswers;
      }
      answersMaster.push({
        questionID: question._id,
        answers,
        timeTaken: {
          hours: parseInt(this.getHours(question.duration), 10),
          minutes: parseInt(this.getMinutes(question.duration), 10),
          seconds: parseInt(this.getSeconds(question.duration), 10)
        }
      })
    })

    create(baseUrl + "quiz-submissions/add", "post", {
      timeTaken: {
        hours: parseInt(this.getHours(this.state.timePassed), 10),
        minutes: parseInt(this.getMinutes(this.state.timePassed), 10),
        seconds: parseInt(this.getSeconds(this.state.timePassed), 10)
      },
      quizID: this.state.quizID,
      classID: this.state.classID,
      subjectID: this.state.subjectID,
      chapterID: this.state.chapterID,
      typeID: this.state.typeID,
      answers: answersMaster
    })
      .then((response) => {
        this.setState({
          submitted: true
        })
      })
      .catch((error) => {

      });

  }

  setFormField(event) {
    let questions = this.state.questions;
    questions[this.state.currentQuestionIndex][event.target.name] = event.target.value;
    this.setState({ questions })
  }

  onSelectMatching(i, j) {
    let questions = this.state.questions;
    let matchingAnswers = questions[this.state.currentQuestionIndex].matchingAnswers || new Array(questions[this.state.currentQuestionIndex].matchingA.length);
    matchingAnswers[i] = j;
    questions[this.state.currentQuestionIndex].matchingAnswers = matchingAnswers;
    this.setState({
      questions
    })
  }

  handleFieldKeysEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const key = splitted[1];
      const index = splitted[2];
      const value = event.target.value;
      let items = this.state.questions[this.state.currentQuestionIndex][name];
      items[parseInt(index, 10)][key] = value;
      this.setState({
        questions: items
      });
    }
  }

  handleFieldEdit = (event) => {
    const splitted = event.target.name.split("#");
    if (splitted[0]) {
      let questions = this.state.questions;
      questions[this.state.currentQuestionIndex][splitted[0]][parseInt(splitted[1], 10)] = event.target.value;
      this.setState({
        questions,
        currentQuestion: questions[this.state.currentQuestionIndex]
      });
    }
  }

  handleCheck = (event) => {
    const splitted = event.target.name.split("#");
    if (splitted[0]) {
      let questions = this.state.questions;
      questions[this.state.currentQuestionIndex][splitted[0]][splitted[2]][splitted[1]] = !questions[this.state.currentQuestionIndex][splitted[0]][splitted[2]][splitted[1]];
      this.setState({
        questions,
        currentQuestion: questions[this.state.currentQuestionIndex]
      })
    }
  }

  getHours(time) {
    let hours = parseInt(time / (60 * 60), 10)
    return hours < 10 ? "0" + hours : hours;
  }
  getMinutes(time) {
    let minutes = parseInt(time / (60), 10)
    return minutes < 10 ? "0" + minutes : minutes;
  }
  getSeconds(time) {
    let seconds = parseInt(time % 60, 10);
    return seconds < 10 ? "0" + seconds : seconds;
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 np">
            <div className="col-lg-12">
              <div className="px-5">

                <div className="uk-flex-middle" uk-grid={"true"}>

                  <div className="uk-width-expand@m">

                    <div uk-grid={"true"}>

                      <div className="uk-width-2-3@m">
                        <a className="uk-text-bold uk-h4"> Design Instruments for Communication
                                    </a>
                        <br />
                        <a href="course-dashboard.html" className="uk-text-muted">
                          <i className="icon-feather-arrow-left"></i> Back to Dashboard
                                    </a>
                      </div>



                    </div>
                  </div>
                </div>

              </div>

              <hr />

            </div>
          </div>
          <div className="col-xl-12 col-lg-12 row pt-0 mt-0">
            <div className="col-lg-9 pb-2">
              {(!this.state.submitted) && (this.state.currentQuestion && this.state.currentQuestion._id) && <div className="pl-5">
                <div className="bold red-font"><span>Question:</span><span className="green-font pull-right float-right">{this.state.currentQuestion.marks} {this.state.currentQuestion.marks === 1 ? "Mark" : "Marks"}</span></div>
                {(this.state.currentQuestion.questionType !== 'matching') && <div className="pb-2">

                  <pre>{this.state.currentQuestion.question}</pre>
                </div>}

                {this.state.currentQuestion.questionType === 'matching' && <div>
                  <h6>Match the following:</h6>
                  <div className="row">
                    <div className="col-md-6 text-center">
                      <label className="text-center">(A):</label>
                      {this.state.currentQuestion.matchingA.map((option, index) => {
                        return (<fieldset key={"matchingA-" + index} className="form-group">
                          <div className="checkbox-shadow">
                            <span>{option.optionTitle})</span>&nbsp;&nbsp;
                                    <span htmlFor={"option-" + index}>
                              {option.question}
                            </span>
                          </div>
                        </fieldset>)
                      })}
                    </div>
                    <div className="col-md-6 text-center">
                      <label className="text-center">(B):</label>
                      {this.state.currentQuestion.matchingB.map((option, index) => {
                        return (<fieldset key={"matchingB-" + index} className="form-group">
                          <div className="checkbox-shadow">
                            <span>{option.optionTitle})</span>&nbsp;&nbsp;
                                  <span htmlFor={"option-" + index}>
                              {option.question}
                            </span>
                          </div>
                        </fieldset>)
                      })}
                    </div>
                  </div>
                </div>}

                <div className="section-header pt-1 mb-lg-2 border-0 uk-flex-middle">
                  <div className="section-header-left">
                    <h4 className="uk-heading-line text-left"><span> Your Answer </span></h4>
                  </div>
                </div>
                {(this.state.currentQuestion.questionType !== 'blanks') && (this.state.currentQuestion.questionType !== 'matching') && (this.state.currentQuestion.questionType !== 'essay') && <div>
                  <ul className="list-unstyled mb-0">
                    {this.state.currentQuestion.options && this.state.currentQuestion.options.map((option, index) => {
                      return (<li className="d-inline-block col-lg-12 pb-1">
                        <fieldset>
                          <div className="checkbox checkbox-shadow row">
                            <input type="checkbox"
                              id={"checkboxshadow" + index}
                              name={"options#isCorrect#" + index}
                              value={option.isCorrect}
                              checked={option.isCorrect}
                              onChange={this.handleCheck} />
                                  &nbsp;&nbsp;<label htmlFor={"checkboxshadow" + index}>{option.value}</label>
                          </div>
                        </fieldset>
                      </li>)
                    })}
                  </ul>
                </div>}
                {this.state.currentQuestion.questionType === 'essay' && <div>
                  <textarea className="form-control" rows="8"
                    value={this.state.currentQuestion.essayAnswer}
                    name="essayAnswer"
                    onChange={this.setFormField.bind(this)}
                  ></textarea>
                </div>}
                {
                  this.state.currentQuestion.questionType === 'blanks' && <div className="row col-lg-12">
                    <div className="col-lg-6 np">
                      <fieldset className="form-group">
                        {this.state.currentQuestion.blanks.map((answer, index) => {
                          return (<input key={"currentQuestion-" + index} type="text" className="form-control p10"
                            value={answer}
                            name={"blanks#" + index}
                            onChange={this.handleFieldEdit}
                            placeholder={"Enter Answer for Blank " + (index + 1)}
                          />)
                        })}
                      </fieldset>
                    </div>
                  </div>
                }

                {
                  this.state.currentQuestion.questionType === 'matching' && <div className="row col-lg-12">
                    <div className="col-lg-6 offset-lg-3">
                      {this.state.currentQuestion.matchingA.map((option, i) => {
                        return (<div className="row pt-1">
                          <div className="col-md-6 pt-2">
                            <span className="pull-right float-right">{option.optionTitle}) Matches With <i className="icon-feather-arrow-right"></i> </span>
                          </div>
                          <div className="col-md-6">
                            {this.state.currentQuestion.matchingB.map((option, j) => {
                              return (
                                <button type="button" className={"btn ml-1 " + ((this.state.currentQuestion && this.state.currentQuestion.matchingAnswers && (this.state.currentQuestion.matchingAnswers[i] === j)) ? " btn-primary" : " btn-outline-primary")} onClick={() => this.onSelectMatching(i, j)}>{option.optionTitle}</button>
                              )
                            })}
                          </div>
                        </div>)
                      })}
                    </div>
                  </div>
                }
                <div className="pt-5">
                  {(this.state.currentQuestionIndex > 0) && <button type="button" className="btn btn-outline-danger" onClick={() => this.onClickActions('previous')}><i className="fa fa-angle-left"></i> <span className="align-middle ml-25" >Previous</span></button>}
                  {(this.state.currentQuestionIndex < (this.state.questions.length - 1)) && <button type="button" className="btn btn-outline-primary float-right pull-right" onClick={() => this.onClickActions('next')}><span className="align-middle ml-25">Next </span><i className="fa fa-angle-right"></i></button>}
                  {(this.state.currentQuestionIndex === (this.state.questions.length - 1)) && <button type="button" className="btn btn-success float-right pull-right" onClick={() => this.submitTest()}><span className="align-middle ml-25">Submit </span></button>}
                </div>
              </div>}
              {(!this.state.submitted) && (!this.state.currentQuestion || !this.state.currentQuestion._id) && <div className="row col-lg-12">
                <div className="col-lg-12 text-center pt-5">
                  <h6 className="col-lg-8 offset-lg-2 pt-5">Your quiz is almost ready. Click the Start button below to Start your Test.</h6>
                  <button type="button" className="btn btn-default" onClick={() => this.startTest()}>Start Test</button>
                </div>
              </div>}
              {(this.state.submitted) && <div className="text-center pt-5 mt-5">
                <i className="fa fa-check-circle-o text-success fa-3x"></i>
                <h4 className="col-lg-8 offset-lg-2">Your quiz has been submitted successfully.</h4>
                <Link type="button" className="btn btn-default" to={"/review-quiz/" + this.state.quizID}>Review Answers</Link>
              </div>}
            </div>
            <div className="col-lg-3">
              <div className="pl-1 mr-1">
                <h4 className="row">Questions:</h4>
                <div className="row ">
                  {this.state.questions.map((question, index) => {
                    return (<button key={"qstn-" + index} type="button" className={"btn btn-icon mr-1 mt-1 " + ((index === this.state.currentQuestionIndex) ? "btn-success" : (index < this.state.currentQuestionIndex) ? "btn-default" : "btn-outline-primary")}>{question.index}</button>)
                  })}
                  {/* <button type="button" className="btn btn-icon btn-outline-primary mr-1">02</button> */}

                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default QuizComponent;