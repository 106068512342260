import React from "react";
import swal from 'sweetalert2';
// import {  Link } from "react-router-dom";
import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { getUserData, get, create, hasPermission } from "../../../utils";
import * as XLSX from 'xlsx';
import './grading-system.scss';
import { WithTranslations } from "../../../i18n/withTranslations";

class GradingSystemComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      gradesMaster: [],
      name: '',
      userType: '',
      grades: [],
      uploading: false,
      loading: false
    };

    this.uploadFile = this.uploadFile.bind(this);
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    this.getGradingSystem();
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  getGradingSystem() {
    this.setState({
      loading: true
    })
    get(baseUrl + "grading-system/list")
      .then((response) => {
        this.setState({
          loading: false,
          gradesMaster: response.data.data
        })
      })
  }

  save() {

    this.setState({
      uploading: true
    })
    let url = baseUrl + "grading-system/";
    if (this.state.gradingSystemID)
      url += this.state.gradingSystemID;
    else
      url += 'add';

    create(url, this.state.gradingSystemID ? 'put' : 'post', {
      name: this.state.name,
      grades: this.state.grades.length ? this.state.grades : undefined
    })
      .then((response) => {
        $('#grading-system').trigger('click');
        this.getGradingSystem();
        this.setState({
          uploading: false
        })
        this.setState({
          name: '',
          grades: []
        });
        swal.fire({
          title: "Info",
          text: response.data.message,
          icon: "warning"
        });
      })
      .catch((error) => {
      });
  }

  uploadFile(e) {
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    let scope = this;
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      let jsonData = [], columnsMaster = [];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      dataParse.forEach((row, index) => {
        if (row[0] && index)
          jsonData.push(row)
        else if (!index)
          columnsMaster = row;
      });

      let grades = [];

      jsonData.forEach((cloumns) => {
        let classes = cloumns[0].split(",");
        let marks = [];
        cloumns.forEach((column, index) => {
          if (index >= 3) {
            marks.push({
              total: columnsMaster[index],
              from: column.split("-")[0],
              to: column.split("-")[1]
            })
          }
        })

        grades.push({
          classes: classes,
          name: cloumns[1],
          points: cloumns[2],
          marks: marks
        })
      })
      scope.setState({
        grades,
        columns: columnsMaster
      })
    };
    reader.readAsBinaryString(f)
  }

  onClickEdit(gradingSystem) {
    this.setState({
      name: gradingSystem.name,
      gradingSystemID: gradingSystem._id
    })
  }

  render() {
    const { userType }= this.state;
    const {tid}=this.props;
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper" className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{tid("Grading System")}</h2>
            </div>
            <div className="app-page-header-right">
              {hasPermission('grading-system', 'create') && <button type="button" className="app-add-option-button" data-toggle="modal" data-target="#grading-system">
                <i className="uil-plus"></i> {tid("Upload New")}
              </button>}
            </div>
          </div>
          {this.state.loading && <div className="text-center acg-loader-container">
            <Loader size={3} text="Loading Grading System..." color={"#3e416d"}></Loader>
          </div>}
          <div className='grading-list-details-container'>
            <div className='switcher-container'>
              <nav className="responsive-tab style-4">
                <ul data-submenu-title="Elements"
                  uk-switcher="connect: #elements-nav ;animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                  {this.state.gradesMaster.map((grade, index) => {
                    return (<li key={"grade-name-" + index} className='active'><a>{grade.name}</a></li>)
                  })}
                </ul>
              </nav>
            </div>
            <div className='switcher-details-container'>
              <ul className="uk-switcher " id="elements-nav">
                {this.state.gradesMaster.map((gradesMaster, index) => {
                  return (
                    <li className="grading-info-main-container" key={"grade-" + index}>
                      {hasPermission('grading-system', 'edit') &&<p>{tid("You can update the Grading System with edit option")}: <button type="button" className="btn btn-xs btn-info uk-visible@s" onClick={() => this.onClickEdit(gradesMaster)} data-toggle="modal" data-target="#grading-system"> {tid("Edit")} </button></p>}
                      <div className="grades-system-flex">
                        <table className="grades-table">
                          {gradesMaster.grades.map((grade, j) => {
                            return (<tr key={"grade-" + index + "-" + j}>
                              <th className="headcol classes-td">
                                {grade.classes.map((c, cc) => {
                                  return (
                                    <button className="btn btn-xxs btn-info text-center">
                                      {c}
                                    </button>)
                                  })}
                                  <p>{tid("Grade")}: <span className="red-text">{grade.name}</span> {tid("Points")}: {grade.points}</p>
                              </th>
                              {grade.marks.map((mark, k) => {
                                return (<td key={"grade-" + index + "-" + j + "-" + k} className="grade-info-td">
                                  <button type="button" className="marks-btn btn btn-secondary marks-td">
                                    <h5 className="border-bottom">{mark.total || 0}</h5>
                                    <p>{mark.from || 0} - {mark.to || 0}</p>
                                  </button>
                                </td>)
                              })}
                            </tr>)
                          })}
                        </table>
                      </div>
                    </li>)
                })}
              </ul>
            </div>
          </div>

          <div className="modal fade text-left" id="grading-system" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">{tid("Add Grading System")}</h3>
                  <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">{tid("Grading System Name")}:</label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.name}
                        name="name"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Enter Grading System Name")}
                      />
                    </div>
                  </div>

                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">{tid("File")}:</label>
                    </div>
                    <div className="choose-file-section">
                      <label httmlFor="aqqQuestionImageFile">
                        <input type="file" id="aqqQuestionImageFile" onChange={this.uploadFile} />
                        <i className='uil-file' /> {tid("Choose File")}
                      </label>
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-light-secondary btn-sm" data-dismiss="modal">
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid("Close")}</span>
                  </button>
                  <button type="button" className="btn btn-primary ml-1 btn-sm" onClick={this.save.bind(this)}>
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{this.state.uploading && <IconLoader size={2} text="" color={"#fff"}></IconLoader>}&nbsp;{tid("Save")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default  WithTranslations(GradingSystemComponent);