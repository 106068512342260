import React from 'react'
import { Link } from "react-router-dom"
import Navbar from '../shared/website-navbar/Navbar'
import Footer from '../shared/website-footer/Footer'
import {LanguageContext} from '../../../i18n/LanguageProvider'
import { services, adminFeatures, teacherFeatures, parentFeatures, studentFeatures } from "../../../features"

import './index.scss'

const dbImages = [
  {
    id:"PRINCIPAL_DASHBOARD",
    name: 'Principal Dashboard',
    image: '/assets/images/principal.svg',
    dbImage: '/assets/images/dashboard-images/Principal-Dashboard.png'
  },
  {
    id:"FACULTY_DASHBOARD",
    name: 'Faculty Dashboard',
    image: '/assets/images/faculty.svg',
    dbImage: '/assets/images/dashboard-images/Faculty-Dashboard.png'
  },
  {
    id: 'STUDENT_DASHBOARD',
    name: 'Student Dashboard',
    image: '/assets/images/student.svg',
    dbImage: '/assets/images/dashboard-images/Student-Dashboard.png'
  },
  {
    id: 'PARENTS_DASHBOARD',
    name: 'Parents Dashboard',
    image: '/assets/images/parents.svg',
    dbImage: '/assets/images/dashboard-images/Parent-Dashboard.png'
  }
]

const features = [
  {
    id: 'FOR_ADMINSTRATION',
    name: 'For Adminstration',
    list: adminFeatures,
    image: '/assets/images/principal.svg',
    headingPrefix: 'ADMIN_FEATURE_HEADING_',
    descPrefix: 'ADMIN_FEATURE_DESC_'
  },
  {
    id: 'FOR_TEACHERS',
    name: 'For Teachers',
    list: teacherFeatures,
    image: '/assets/images/faculty.svg',
    headingPrefix: 'TEACHER_FEATURE_HEADING_',
    descPrefix: 'TEACHER_FEATURE_DESC_'
  },
  {
    id: 'FOR_PARENTS',
    name: 'For Parents',
    list: parentFeatures,
    image: '/assets/images/parents.svg',
    headingPrefix: 'PARENT_FEATURE_HEADING_',
    descPrefix: 'PARENT_FEATURE_DESC_'
  },
  {
    id: 'FOR_STUDENTS',
    name: 'For Students',
    image: '/assets/images/student.svg',
    list: studentFeatures,
    headingPrefix: 'STUDENT_FEATURE_HEADING_',
    descPrefix: 'STUDENT_FEATURE_DESC_'
  }
]

const HomeVersionTwo = () => {
  const langugeContext = React.useContext(LanguageContext)
  const pc = langugeContext.content
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [activeFeatureIndex, setActiveFeatureIndex] = React.useState(0)
  const {dbImage} = dbImages[activeIndex]
  const featureList = features[activeFeatureIndex].list
  const {headingPrefix, descPrefix} = features[activeFeatureIndex]
  return (
    <div className="app-home-page--conatiner">
      <Navbar homePage />
      <div className="landing--section">
        <div className="ls-flex--section">
          <div className="ls--lside">
            <h4 className='app--ladning-heading'>
              {pc.PAGE_MAIN_HEADING}
            </h4>
            <p className='app--landing-desc'>
              {pc.PAGE_MAIN_SUBHEADING}
            </p>
            <Link to={"/request-demo"} >
              <button id="request-demo" className="request-demo-button">
                {pc.REQUEST_DEMO}
              </button>
            </Link>
          </div>
          <div className="ls--rside">
            <img loading="lazy" src="/assets/images/landing.png" alt='Principal Dashboard' />
          </div>
        </div>
      </div>
      <div className="features--section">
        <div className="fs--flex-container">
          <div className="fs--lside">
            <h4 className="fs--heading">
              {pc.OUR_FEATURES_HEADING}
            </h4>
            <p className="fs--desc">
              {pc.OUR_FEATURES_DESC}
            </p>
            <div className="fs--list-section">
              {features.map((f, i) => (
                <div
                  onClick={() => setActiveFeatureIndex(i)}
                  className={`feature--list-card ${activeFeatureIndex === i ? 'active' : ''}`}
                  key={`feature--card-${i}`}
                >
                  <img src={f.image} alt=""/>
                  <h4>{pc[f.id] || f.name}</h4>
                  {activeFeatureIndex === i && (
                    <div className='check-mark-section'>
                      <img src="/assets/images/check.svg" alt="" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="fs--rside">
            <div className="fs--list-flex">
              {featureList.map((fl, i) => {
                return (
                  <>
                    {i < 8 && (
                      <div className="feature--card--info">
                        <div className="fci--lside">
                          <img src={fl.image} alt=""/>
                        </div>
                        <div className="fci--rside">
                          <h4 className="fci--heading">
                            {pc[`${headingPrefix}${i + 1}`]}
                          </h4>
                          <p className="fci--desc">
                            {pc[`${descPrefix}${i + 1}`]}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )})}
            </div>
          </div>
        </div>
      </div>
      <div className="webinar--section">
        <div className="webinar--flex-section">
          <div className="webinar--lside">
            <h3 className='wls--heading'>{pc.CONDUCT_WEBINAR}</h3>
            <p className='wls--desc'>{pc.WEBINAR_DESCRIPTION}</p>
            <Link to={"/webinar"} className='create-webinar-button'>
              {pc.WEBINAR_HEADING}
            </Link>
          </div>
          <div className="webinar--rside">
            <img src="/assets/images/webinar-illustration.jpg" alt=""/>
          </div>
        </div>
      </div>
      <div className="new-way-of-learing--section">
        <div className="nwl--flex-container">
          <div className="nsw--lside">
            <img src="/assets/images/learning.jpg" alt=""/>
          </div>
          <div className="nsw--rside">
            <h4 className="nsw--heading">
              {pc.NEW_WAY_LEARNING_HEADING}
            </h4>
            <p className="nsw--desc">
              {pc.NEW_WAY_LEARING_DESC}
            </p>
            <div className="tech--flex-section">
              <div className="tech--card-section">
                <div className="image--section">
                  <img src="/assets/images/v2/ai.svg" alt=""/>
                </div>
                <div className="content--section">
                  <h4 className="card--heading">
                    {pc.AI_HEADING} (AI)
                  </h4>
                </div>
              </div>
              <div className="tech--card-section">
                <div className="image--section">
                  <img src="/assets/images/v2/vr.svg" alt=""/>
                </div>
                <div className="content--section">
                  <h4 className="card--heading">
                  {pc.VR_HEADING} (VR)
                  </h4>
                </div>
              </div>
              <div className="tech--card-section">
                <div className="image--section">
                  <img src="/assets/images/v2/ar.svg" alt=""/>
                </div>
                <div className="content--section">
                  <h4 className="card--heading">
                    {pc.AR_HEADING} (AR)
                  </h4>
                </div>
              </div>
              <div className="tech--card-section">
                <div className="image--section">
                  <img src="/assets/images/v2/3d.svg" alt=""/>
                </div>
                <div className="content--section">
                  <h4 className="card--heading">
                    {pc.THREE_D_HEADING}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="language--info-section">
        <div className="li--flex-container">
          <div className="li--lside">
            <h2 className="li--heading">{pc.LEARNING_HEADING}</h2>
            <p className="li--desc">{pc.LEARNING_DESC}</p>
            <div className="li--list-conatiner">
              <ul>
                <li>
                  <i className="uil-arrow-right" /> Telugu<span>(తెలుగు)</span>
                </li>
                <li>
                  <i className="uil-arrow-right" />Hindi<span>(हिन्दी)</span>
                </li>
                <li>
                  <i className="uil-arrow-right" />Malayalam<span>(മലയാലം)</span>
                </li>
                <li>
                  <i className="uil-arrow-right" />Bengali<span>(বেংগালি)</span>
                </li>
                <li>
                  <i className="uil-arrow-right" />Tamil<span>(தமிள்)</span>
                </li>
              </ul>
              <ul>
                <li>
                  <i className="uil-arrow-right" />
                  Assamese<span>(অস্সামি)</span>
                </li>
                <li>
                  <i className="uil-arrow-right" />
                  Kannada<span>(ಕನ್ನಡ)</span>
                </li>
                <li>
                  <i className="uil-arrow-right" />
                  Marathi<span>(मराठि)</span>
                </li>
                <li>
                  <i className="uil-arrow-right" />
                  Sanskrit<span>(सांस्क्रित्)</span>
                </li>
                <li>
                  <i className="uil-arrow-right" />
                  Oriya<span>(ଓରିଯା)</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="li--rside">
            <img loading="lazy" src="/assets/images/Language.png" alt=""/>
          </div>
        </div>
      </div>
      <div className="dashboard--images-container">
        <div className="di--flex-container">
          <div className="di--lside">
            <img src={dbImage} alt=""/>
          </div>
          <div className="di--rside">
            <h4 className="di--heading">
              {pc.DEMO_HEADING}
            </h4>
            <p className="di--desc">
              {pc.DEMO_DESC}
            </p>
            <div className="dic--flex-container">
              {dbImages.map((db, i) => {
                return (
                  <div className={`dif--card ${activeIndex === i ? 'active' : ''}`} onClick={() => setActiveIndex(i)}>
                    <div className="l--side">
                      <img src={db.image} alt=""/>
                    </div>
                    <div className="r--side">
                      {pc[db.id] || db.name}
                    </div>
                    {activeIndex === i && (
                      <div className='check-mark-section'>
                        <img src="/assets/images/check.svg" alt="" />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="why-us--conatiner">
        <h4 className='wu--heading'>{pc.WHY_US_HEADING}</h4>
        <p className="wu--desc">{pc.WHY_US_DESC}</p>
        <div className="why-us--cards-flex-container">
          {services.map((stat, i) => (
            <div className="wu--card" key={`wu-card-${i}`}>
              <div className="image-section">
                <img src={stat.image} alt="" />
              </div>
              <div className="content-section">
                <h4 className="wu--c-heading">
                  {pc[`WHY_US_FEATURE_HEADING_${i+1}`]}
                </h4>
                <p className="wu--c-desc">
                  {pc[`WHY_US_FEATURE_DESC_${i+1}`]}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='request-demo--container' id="reques-demo">
        <div className='container'>
          <h3 className='text-center request-demo--heading'>{pc.GET_STARTED_TODAY_HEADING}</h3>
          <p className='text-center request-demo--desc'>{pc.GET_STARTED_DESC}</p>
          <div className='buttons-section-container'>
            <Link to={"/request-demo"} className='request-demo-button'>
              {pc.REQUEST_DEMO}
            </Link>
            <Link to={"/contact-us"} className='contact-us-button'>
              {pc.CONTACT_US_HEADING}
            </Link>
          </div>
        </div>
      </div>
      <div className="footer--container">
        <Footer />
      </div>
    </div>
  )
}

export default HomeVersionTwo;
