import React from "react";
import { Link } from "react-router-dom";
import swal from 'sweetalert2';
import * as $ from "jquery";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import SimpleReactValidator from 'simple-react-validator';
import { baseUrl } from "../../../config";
// import { Loader, IconLoader } from "../../shared/loader";
import { getUserData, get, create, featureAccess, hasPermission } from "../../../utils";
import { lightColors, lightColorsOne } from '../../../constants';
import './classes.scss'
import Delete from "../../common/Delete";
import { WithTranslations } from "../../../i18n/withTranslations";

class ClassesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      standard: '',
      name: "",
      section: "",
      medium: "",
      classID: "",
      message: "",
      userType: "'"
    };
    this.save = this.save.bind(this);
    this.classValidator = new SimpleReactValidator({});
  }

  removeClassById = (id) => () => {
    this.setState({
      classes: this.state.classes.filter(k => k._id != id)
    })
  }

  save() {
    if (this.classValidator.allValid()) {
      let url = baseUrl + "classes/";
      if (this.state.classID)
        url += this.state.classID;
      else
        url += "add";

      create(url, (this.state.classID) ? "put" : "post", {
        standard: this.state.standard,
        name: this.state.name,
        section: this.state.section,
        medium: this.state.medium
      })
        .then((response) => {
          $('#default').trigger('click');
          this.setState({
            standard: "",
            name: "",
            section: "",
            medium: "",
            message: "",
            classID: ""
          })
          this.getClassesList();
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning"
          });
        })
        .catch((error) => {

        });

    } else {
      this.classValidator.showMessages();
      this.forceUpdate();
    }
  }

  getClassesList() {
    get(baseUrl + "classes/list")
      .then((response) => {
        this.setState({
          classes: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
    })
  }

  componentDidMount() {
    this.assignUserData();
    this.getClassesList();
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  onClickClass(classData) {
    this.setState({
      standard: classData.standard,
      name: classData.name,
      section: classData.section,
      medium: classData.medium,
      classID: classData._id
    })
  }

  render() {
const {translations}=this.props;
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{translations.CLASSES||`Classes`}</h2>
            </div>
            <div className="app-page-header-right">
              {hasPermission('classes', 'create') && <button type="button" className="app-add-option-button" data-toggle="modal" data-target="#default">
                <i className="uil-plus"></i> {translations.ADD_CLASS||`Add Class`}
              </button>}
            </div>
          </div>
          <div className='classes-list-flex-container'>
            {this.state.classes.map((c, index) => {
              return (
                <div className='class-details-card-container' key={c._id} style={{ backgroundColor: lightColorsOne[index % 5] }}>
                  <div className='cdc-top-container'>
                    <div className='cdc-left-side'>
                      <p className='standard-heading'>Standard</p>
                      <div className='standard-info' style={{ backgroundColor: lightColors[index % 5] }}>
                        <p>{c.standard}</p>
                      </div>
                      <div className='edit-button-container'>
                        <button className="btn btn-xxs" onClick={() => this.onClickClass(c)} data-toggle="modal" data-target="#default">{translations.EDIT||`Edit`}</button>
                       &nbsp; <Delete url={`classes/${c._id}`} size="xxs" onDeleteSuccess={this.removeClassById(c._id)} />
                      </div>
                    </div>
                    <div className='cdc-right-side'>
                      <h5><Link to={"/class-details/" + c._id}>{c.name} <i className='uil-external-link-alt' /></Link></h5>
                      <p><small>{translations.STUDENTS||`Students`}:</small> {c.studentsCount}</p>
                      <p><small>{translations.SUBJECTS||`Subjects`}:</small> {c.subjectsCount} </p>
                      <p><small>{translations.TEACHERS_HEADING||`Teachers`}:</small> {c.teachersCount} </p>
                      <p className='subject-link'><Link to={"/subjects/" + c._id}>{translations.VIEW_SUBJECTS||`View Subjects `}<i className='uil-arrow-right' /></Link></p>
                    </div>
                  </div>
                  <div className='cdc-bottom-contianer'>
                    {featureAccess('assignments') && <div><Link to={"/attendance/" + c._id}><i className='uil-eye' />{translations.ATTENDANCE||`Attendance`}</Link></div>}
                    {featureAccess('timetable') && <div><Link to={"/timetable/" + c._id}><i className='uil-calender' />{translations.TIMETABLE||`TimeTable`}</Link></div>}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="modal fade text-left" id="default" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">{translations.ADD_CLASS||`Add Class`}</h3>
                  <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">{translations.STANDARD||`Standard`}:</label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="number"
                        className="form-control form-control-user"
                        value={this.state.standard}
                        name="standard"
                        onChange={this.setFormField.bind(this)}
                        placeholder={translations.ENTER_STANDARD_NO||"Enter Standard No"}
                      />
                      {this.classValidator.message(
                        "Standard",
                        this.state.standard,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">{translations.CLASS_NAME||`Class Name`}:</label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.name}
                        name="name"
                        onChange={this.setFormField.bind(this)}
                        placeholder={translations.ENTER_CLASS_NAME||"Enter Class Name"}
                      />
                      {this.classValidator.message(
                        "Class Name",
                        this.state.name,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">{translations.SECTION_COURSE||`Section/Course`}:</label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.section}
                        name="section"
                        onChange={this.setFormField.bind(this)}
                        placeholder="Enter Section/Course"
                      />
                    </div>
                  </div>
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">{translations.MEDIUM||`Medium`}:</label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.medium}
                        name="medium"
                        onChange={this.setFormField.bind(this)}
                        placeholder={translations.ENTER_MEDIUM||"Enter Medium"}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-light-secondary btn-sm" data-dismiss="modal">
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{translations.CLOSE||`Close`}</span>
                  </button>
                  <button type="button" className="btn btn-primary ml-1 btn-sm" onClick={this.save.bind(this)}>
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{translations.SAVE||`Save`}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(ClassesComponent);