import React from "react";
import swal from 'sweetalert2';

// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';

// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { get, create, getUserData } from "../../../utils";

import QuestionComponent from "../questions/question";

class SubmissionsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submissions : [],
      test: {},
      testID: "",
      userType: "",
      showSection: "list"
    }
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  getSubmissionsList(testID) {
    this.assignUserData();
    get(baseUrl + "submissions/list/"+testID)
      .then((response) => {
        this.setState({
          submissions: response.data.data,
          test: response.data.test
        })
      })
      .catch((error) => {
      });
  }

  componentDidMount() {
    let testID = this.props.match.params.testID;
    this.setState({
      testID
    })

    this.getSubmissionsList(testID);
  }

  viewSubmission(submission) {
    this.setState({
      selectedSubmission: submission,
      showSection: 'details'
    })
  }

  onClick(type) {
    this.setState({
      showSection: type
    })
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value})
  }

  handleFieldKeysEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if(name) {
        const key = splitted[1];
        const index = splitted[2];
        const value = event.target.value;
        let selectedSubmission = this.state.selectedSubmission;
        selectedSubmission[name][parseInt(index)][key] = value;
        this.setState({
          selectedSubmission
        })
    }
  }

  saveEvalution() {

    let submission = {};
    submission = this.state.selectedSubmission;

    submission.answers.forEach((answer) => {
      answer.question = undefined;
    })

    let submissionID = submission._id;
    submission._id = undefined;

    submission.marks = 0;
    submission.answers.forEach((answer) => {
      submission.marks+=parseInt(answer.marks);
    })
    
    create(baseUrl + "submissions/"+submissionID, "put", submission)
      .then((response) => {
        swal.fire({
          title: "Info",
          text: response.data.message,
          icon: "warning"
        });
      })
      .catch((error) => {
      });

  }

  render() {
    const { userType } = this.state
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper"  className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">Test Submissions</h2>
            </div>
          </div>
          <div className="app-test-submissions-list-container">
            <div className="test-details-container">
              <div className="test-details-left">
                <h6 className="">Test : <span>{this.state.test.name}</span></h6>
                <h6 className="">Class : <span>{this.state.test && this.state.test.classID && this.state.test.classID.name}</span></h6>
                <h6 className="">Subject : <span>{this.state.test && this.state.test.subjectID && this.state.test.subjectID.name}</span></h6>
              </div>
              <div className="test-details-right">
                <h6 className="">Evaluation : <span>{this.state.test.evaluation}</span></h6>
                <h6 className="">Duration : <span>{this.state.test.duration} mins</span></h6>
                <h6 className="">Marks : <span>{this.state.test.marks}</span></h6>
              </div>
            </div>
          </div>
          <div className="app-test-submissions-list">
            {this.state.showSection == 'list' && <div className='at-sl-container'>
              {this.state.submissions.map((submission, index) => {
                return (
                  <div key={"chaper-"+index} className='at-sl-content'>
                    <h5 className="mb-2">
                      {submission.userID.firstName} {submission.userID.lastName}
                      <span className="evaluate-badge"> {submission.evaluated? "Evaluated":"Not Evaluated"}</span>
                      <small className="pointer" onClick={() => this.viewSubmission(submission)} >
                        <i className='uil-eye' /> Submission
                      </small>
                    </h5>
                    <p className="details-info">
                      <span className=""><i className="uil-check-square" /> {submission.marks || '0'} Marks</span> 
                      <span className=""><i className="uil-check-circle" />{submission.grade} Grade</span> 
                      <span className=""><i className='uil-clock' /> Time Taken: {submission.timeTaken.hours<10?"0"+submission.timeTaken.hours:submission.timeTaken.hours}:{submission.timeTaken.minutes<10?"0"+submission.timeTaken.minutes:submission.timeTaken.minutes}:{submission.timeTaken.seconds<10?"0"+submission.timeTaken.seconds:submission.timeTaken.seconds}</span> 
                    </p>
                  </div>
                )})}
              </div>
            }
            {this.state.showSection == 'details' && <div className="app-selected-submission-details">
              <div className='as-sd-top-details-container'>
                <div className="left-side">
                  <h6 className="" ><i className="uil-arrow-left" onClick={() => this.onClick('list')}></i> Submission Details</h6>
                  <h6 className="">{this.state.selectedSubmission.userID.firstName} {this.state.selectedSubmission.userID.lastName}</h6>
                  <h6 className="">Marks: {this.state.selectedSubmission.marks}</h6>
                </div>
                <div className="right-side">
                  <div className="l-side">
                    <h5>Evaluation:</h5>
                    <select className="form-control medium-height mx-150" value={this.state.selectedSubmission.evaluated}
                      name="evaluted"
                      onChange={this.setFormField.bind(this)}>
                      <option value="">Select Evaluation</option>
                      <option value="true">Evaluated</option>
                      <option value="false">Not Evaluated</option>
                    </select>
                  </div>
                  <div className="r-side">
                    <button className="btn btn-default btn-xs pull-right float-right" onClick={() => this.saveEvalution()}>Save Evaluation</button>
                  </div>
                </div>
              </div>
              <div className="app-q-list-container">
                {this.state.selectedSubmission.answers.map((answer, index) => {
                  return (<div  key={"question-"+index} className="main-container">
                    <QuestionComponent className="np" key={"question-"+index} question={answer.question} index={index + 1} classID = '' subjectID = '' chapterID = '' disabled = {true} hide={true}></QuestionComponent>
                    <div className="flex-container">
                      <div className="section-one">
                        <h5 className="">Student Answer: </h5>
                        {answer.question.questionType == 'matching'?answer.answers.map((ans, j) => {
                          return <div>
                        <span className="">{answer.question.matchingA[j].question.toString()} {"=>"} {answer.question.matchingB[ans].question.toString()}</span>
                            </div>
                        }):answer.answers.map((ans, j) => {
                          return <span className="" key={"aswers-"+index+"-"+j} > {ans} </span>
                        })}
                      </div>
                      <div className="section-two">
                        <h5>
                          Answer: {answer.answerType == 'correct' && <i className="icon-feather-check-circle green-text"></i>}
                          {answer.answerType == 'incorrect' && <i className="icon-feather-x-square red-text"></i>}
                        </h5>
                        <h5>Marks: {answer.marks}/{answer.question.marks}</h5>
                      </div>
                      {/* <div className="section-three">
                        <b>Time Taken:</b>
                        <span className="mr-3 p-1 mt-1"> {answer.timeTaken.hours<10?"0"+answer.timeTaken.hours:answer.timeTaken.hours}:{answer.timeTaken.minutes<10?"0"+answer.timeTaken.minutes:answer.timeTaken.minutes}:{answer.timeTaken.seconds<10?"0"+answer.timeTaken.seconds:answer.timeTaken.seconds}</span> 
                      </div> */}
                      <div className="section-four">
                        <h5>Faculty Evaluation: </h5>
                        <select className="form-control medium-height" name={ "answers#answerType#"+index }
                          value={answer.answerType}
                          onChange={ this.handleFieldKeysEdit} >
                          <option value="">Select Answer</option>
                          <option value="correct">Correct Answer</option>
                          <option value="incorrect">Incorrect Answer</option>
                        </select>
                      </div>
                      <div className="section-five">
                        <h5>Faculty Marks:</h5>
                        <input
                          type="number" 
                          name={ "answers#marks#"+index }
                          value={answer.marks}
                          onChange={ this.handleFieldKeysEdit}
                          className="form-control medium-height" 
                          placeholder="Enter marks" />
                      </div>
                    </div>
                  </div>)})
                }
              </div>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

export default SubmissionsComponent;