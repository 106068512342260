import React from "react";
import { Link } from "react-router-dom";
// import { withRouter } from 'react-router';
import { getUserData, getFormattedMenu, getMenuOptions } from "../../../../utils";
import { menuList } from "../../../../menu-list";
import SingleLineMenuComponent from "./single-line-menu";
import GridMenuComponent from "./grid-menu";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      menuItems: [],
      menuClass: "",
    };
  }

  assignUserData() {
    let {
      firstName,
      lastName,
      userType,
      userID,
      profilePicUrl,
      permissions,
    } = getUserData();
    let menuItems = [];
    if (userType === 'student') {
      let menuTemp = getFormattedMenu(menuList, permissions);
      let menu = [];
      menuTemp.forEach((m) => {
        if (
          m.value !== "fee" &&
          m.value !== "parents"
        )
          menu.push(m);
      });

      menuItems = menu;
    } else if (userType !== "principal" && userType !== "chairman")
      menuItems = getFormattedMenu(menuList, permissions);
    else {
      let menu = [];
      menuList.forEach((m) => {
        if (
          m.value !== "performance" &&
          m.value !== "fee" &&
          m.value !== "parents" &&
          m.value !== "subjects"
        )
          menu.push(m);
      });
      menuItems = menu;
    }

    let finalMenuOptions = getMenuOptions(menuItems);

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
      profilePicUrl,
      menuItems: finalMenuOptions,
    });
  }

  componentDidMount() {
    this.assignUserData();
    this.setState({
      menuClass: sessionStorage.getItem("class_name"),
    });
  }

  toggleSidebar() {
    const { menuClass } = this.state
    let menuClassTemp = menuClass;
    menuClassTemp = menuClassTemp ? "" : "menu-large";
    if (menuClassTemp) sessionStorage.setItem("class_name", menuClassTemp);
    else sessionStorage.removeItem("class_name");
    this.setState({ menuClass: menuClassTemp });
  }

  render() {
    const { menuItems, userType } = this.state
    const { router } = this.props
    const { route } = router ? router : {};
    const { location } = route ? route : {};
    const { pathname } = location ? location : {};

    return (
      <div className={`page-menu ${(userType === 'student' || userType === 'parent') ? 'app-dashboard-secondary-side-menu' : 'app-dashboard-side-menu'}`}>
        <div className="sidebar-app-logo-section">
          <img
            className='main-logo'
            src={"/assets/images/logo.jpg"}
            alt="TeTu"
          />{" "}
          <i className="uil-times db-menu-close-button" uk-toggle="target: #wrapper ; cls: mobile-active" />
        </div>
        <div className="page-menu-inner" data-simplebar id='appSideMenuSecondary'>
          {(userType === 'student' || userType === 'parent') ?
            <GridMenuComponent userType={userType} menuItems={menuItems} pathname={pathname} /> :
            <SingleLineMenuComponent menuItems={menuItems} pathname={pathname} userType={userType} />}
        </div>
      </div>
    );
  }
}

export default Sidebar;