import React from 'react'
import { useState } from 'react';
import englishJson from './en-US.json';
import itJson from './it.json';

export const LanguageContext = React.createContext({
  siteLanguage: 'it',
  content: itJson,
  setSiteContent: () => {},
  setCurrentLanguage: () => {},
})

export const LanguageProvider = ({ children, currentLanguage, siteContent}) => {
  const [siteLanguage, setCurrentLanguage] = useState(currentLanguage);
  const [content, setSiteContent] = useState(siteContent);
  return (
    <LanguageContext.Provider
      value={{
        siteLanguage,
        setCurrentLanguage,
        content,
        setSiteContent,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
