import React from "react";
// import swal from 'sweetalert2';
import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
import moment from "moment";
import axios from "axios";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { getUserData, get, getExcel } from "../../../utils";
import './activity.scss';

class LiveClassActivityComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      facultyType: 'faculty',
      guestName: '',
      classID: '',
      subjectID: '',
      chapterID: '',
      topicID: '',
      facultyID: '',
      date: '',
      fromTime: '',
      toTime: '',
      title: '',
      points: [],
      imageData: '',
      liveClassID: '',
      imageUrl: '',
      attendees: [],
      activityLog: [],
      logUserID: '',
      userID: '',
      createdBy: ''
    };
  }

  loadLiveClass(liveClassID) {

    get(baseUrl + "live-classes/get-attendees/" + liveClassID)
      .then((response) => {
        let {
          facultyType,
          guestName,
          classID,
          subjectID,
          chapterID,
          topicID,
          facultyID,
          date,
          fromTime,
          toTime,
          title,
          points,
          imageUrl,
          userID,
          createdBy
        } = response.data.data;

        this.setState({
          facultyType,
          guestName,
          classID,
          subjectID,
          chapterID,
          topicID,
          facultyID,
          date,
          fromTime,
          toTime,
          title,
          points,
          imageUrl,
          attendees: response.data.attendees,
          activityLog: response.data.activityLog,
          userID,
          createdBy
        })
      })
      .catch((error) => {
        console.log(error)
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    this.loadLiveClass(this.props.match.params.liveClassID)
  }

  setFormField(event) {
    console.log(event.target)
    this.setState({ [event.target.name]: event.target.value })
  }

  downloadAsExcel() {
    let { liveClassID } = this.props.match.params;

    getExcel(baseUrl + "live-classes/download/" + liveClassID).then((response) => {
      let { fileURL } = response.data.data;
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', this.state.title+'_attendees.xlsx');
        document.body.appendChild(link);
        link.click();
    })
      .catch((error) => console.log(error));
  }

  render() {
    const { userType, userID, createdBy } = this.state
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper" className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">
                <Link to={"/live-classes"}><i className='uil-arrow-left' /></Link> Live Class Activity
               
              </h2>
            </div>
            {((userID == createdBy) || (userType == 'principal')) && <div className="app-page-header-right">
              <div className="pull-right right">
                Download Avtivity: <button onClick={() => this.downloadAsExcel()} className="btn btn-xs btn-info"> <i className="fa fa-download"></i> </button>
                </div>  
            </div>}
          </div>
          <div className='live-class-activity-details-container'>
            <div className='lcdc-top-container'>
              <div className='lcdc-left-side'>
                <div className="course-thumbnail text-center">
                  <img src={this.state.imageUrl || "/assets/images/webinar.svg"} alt="" />
                </div>
              </div>
              <div className='lcdc-right-side'>
                <h2>{this.state.title}</h2>
                <div className="course-details-info">
                  <div className="lc-info">Presentor: <a>{this.state.guestName || (this.state.facultyID?this.state.facultyID.firstName:"")} </a> </div>
                  <div className="lc-info"><i className='uil-calender' /><a >{moment(this.state.date).format("DD MMM, YYYY")} </a> </div>
                  <div className="lc-info"><i className='uil-clock' /><a>{this.state.fromTime} to {this.state.toTime} </a> </div>
                </div>
              </div>
            </div>
            <div className='live-classess-tab-container'>
              <ul uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium" uk-tab={"true"}>
                <li><a > <i className="uil-user"></i> <span> Attendees </span> </a></li>
                <li><a > <i className="uil-info-circle"></i> <span> Details </span></a></li>
                <li><a > <i className="uil-blogger-alt"></i> <span> Activity Log </span></a></li>
              </ul>
            </div>
            <div className='live-class-tabs-content-main-container'>
              <ul id="course-intro-tab" className="uk-switcher">
                <li className="course-description-content">
                  <table className="uk-table">
                    <thead>
                      <tr className='table-header-row'>
                        <th>S.No</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Mobile</th>
                        <th>User Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.attendees.map((attendee, index) => {
                        return (<tr className='table-data-row' key={"attendee-" + index}>
                          <td>{index + 1}</td>
                          <td>{attendee.firstName}</td>
                          <td>{attendee.lastName}</td>
                          <td>{attendee.mobile}</td>
                          <td>{attendee.userType}</td>
                        </tr>)
                      })}
                    </tbody>
                  </table>
                </li>
                <li>
                  <div className='lc-more-details-container'>
                    <div className='left-side-container'>
                      <ul class="uk-list uk-list-divider">
                        {this.state.classID && <li><label>Class Name: <span className='colored-text'>{this.state.classID.name}</span></label></li>}
                        {this.state.subjectID && <li><label>Subject Name: <span className='colored-text'>{this.state.subjectID.name}</span></label> </li>}
                        {this.state.chapterID && <li><label>Chapter Name: <span className='colored-text'>{this.state.chapterID.name}</span></label> </li>}
                        {this.state.topicID && <li><label>Topic Name: <span className='colored-text'>{this.state.topicID.name}</span></label></li>}
                      </ul>
                    </div>
                    <div className='right-side-container'>
                      <h6>Points to be covered:</h6>
                      <ul>
                        {this.state.points.map((point, index) => {
                          return (<li key={"point-" + index}><i className='uil-arrow-right' />{point}</li>)
                        })}
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="course-description-content">
                  <div className="filter-select-section">
                    <label className='filter-label-name'>Filter By Name: </label> &nbsp;&nbsp;
                        <select className="form-control col-lg-3" name="logUserID" onChange={this.setFormField.bind(this)}>
                      <option value="">All Attendees</option>
                      {this.state.attendees.map((attendee, index) => {
                        return (<option key={"filter-attendee-" + index} value={attendee._id}>{attendee.firstName} {attendee.lastName}</option>)
                      })}
                    </select>
                  </div>
                  <table className="uk-table">
                    <thead>
                      <tr className='table-header-row'>
                        <th>S.No</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>User Type</th>
                        <th>Action</th>
                        <th>Action Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.activityLog.map((attendee, index) => {
                        if ((this.state.logUserID && (attendee.userID === this.state.logUserID)) || !this.state.logUserID) {
                          return (<tr className='table-data-row' key={"attendee-" + index}>
                            <td>{index + 1}</td>
                            <td>{attendee.firstName}</td>
                            <td>{attendee.lastName}</td>
                            <td>{attendee.userType}</td>
                            <td>{attendee.action}</td>
                            <td>{attendee.createdAt}</td>
                          </tr>)
                        } else {
                          return null;
                        }
                      })}
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="pt-2">
            <div className="container">
              <div className="container">
                <div className="uk-grid-large mt-4 mb-2" uk-grid={"true"}>
                  <div className="uk-width-3-3@m">
                    <ul id="course-intro-tab" className="uk-switcher">
                      <li className="course-description-content">
                        <table className="uk-table uk-table-divider">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Mobile</th>
                              <th>User Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.attendees.map((attendee, index) => {
                              return (<tr key={"attendee-" + index}>
                                <td>{index + 1}</td>
                                <td>{attendee.firstName}</td>
                                <td>{attendee.lastName}</td>
                                <td>{attendee.mobile}</td>
                                <td>{attendee.userType}</td>
                              </tr>)
                            })}
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <div class="uk-grid-collapse uk-child-width-expand@s" uk-grid={"true"}>
                          <div>
                            <div class="uk-background-light mr-2">
                              <div class="uk-width-2-2@m ml-2">
                                <ul class="uk-list uk-list-divider">
                                  {this.state.classID && <li><label>Class Name: {this.state.classID.name}</label> </li>}
                                  {this.state.subjectID && <li><label>Subject Name: {this.state.subjectID.name}</label> </li>}
                                  {this.state.chapterID && <li><label>Chapter Name: {this.state.chapterID.name}</label> </li>}
                                  {this.state.topicID && <li><label>Topic Name: {this.state.topicID.name}</label> </li>}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="uk-background-light ml-2">
                              <h6>Points to be covered:</h6>
                              <ul>
                                {this.state.points.map((point, index) => {
                                  return (<li key={"point-" + index}>{point}</li>)
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="course-description-content">
                        <div className="row col-lg-12">
                          <label>Filter By Name: </label> &nbsp;&nbsp;
                              <select className="form-control col-lg-3" name="logUserID" onChange={this.setFormField.bind(this)}>
                            <option value="">All Attendees</option>
                            {this.state.attendees.map((attendee, index) => {
                              return (<option key={"filter-attendee-" + index} value={attendee._id}>{attendee.firstName} {attendee.lastName}</option>)
                            })}
                          </select>
                        </div>
                        <table className="uk-table uk-table-divider">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>User Type</th>
                              <th>Action</th>
                              <th>Action Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.activityLog.map((attendee, index) => {
                              if ((this.state.logUserID && (attendee.userID === this.state.logUserID)) || !this.state.logUserID) {
                                return (<tr key={"attendee-" + index}>
                                  <td>{index + 1}</td>
                                  <td>{attendee.firstName}</td>
                                  <td>{attendee.lastName}</td>
                                  <td>{attendee.userType}</td>
                                  <td>{attendee.action}</td>
                                  <td>{attendee.createdAt}</td>
                                </tr>)
                              } else {
                                return null;
                              }
                            })}
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default LiveClassActivityComponent;