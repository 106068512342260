import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../config";
import { get, getUserData } from "../../../utils";
import VideoRoomComponent from "../integrations/openvidu/components/VideoRoomComponent";
import "./live-classes.scss";

export const Meeting = ({ match }) => {
  const { meetingID, codeToken, userToken } = match.params;
  let { firstname, lastname, type, userID } = match.params;
  const [meetingMeta, setMeetingMeta] = useState({});

  useEffect(() => {
    get(baseUrl + "meetings/" + meetingID, codeToken, userToken)
      .then((response) => {
        let { title } = response.data.data;
        setMeetingMeta({
          title: 'Parents Meeting',
          points: [],
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {};
  }, [meetingID]);

  let firstName, lastName, userType;

  if(sessionStorage.getItem("token")) {
    let data = getUserData();
    firstName = data.firstName;
    lastName = data.lastName;
    userType = data.userType;
  } else {
    firstName = firstname;
    lastName = lastname;
    userType = type;
  }
  
  let fullName = `${firstName} ${lastName}`;

  return (
    <VideoRoomComponent
      sessionName={meetingID}
      liveClassID={meetingID}
      user={fullName}
      meetingTitle={meetingMeta.title}
      firstName={firstName}
      lastName={lastName}
      userType={userType}
      userID={userID}
      codeToken={codeToken}
      userToken={userToken}
      whiteboard={false}
      chat={false}
      screenshare={false}
      maxWindow={false}
    />
  );
};
