import React from "react";
import { Link } from "react-router-dom";
import SVG from 'react-inlinesvg';
import { WithTranslations } from "../../../../i18n/withTranslations";


class GridMenuComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount() {
  //   const { pathname } = this.props
  //   const linkRef = this[`${pathname}_ref`]
  //   const pEl = document.querySelector('#appSideMenuSecondary')
  //   console.log('linkRef.current.offsetTop', linkRef.current.offsetTop, linkRef.current.offsetHeight)
  //   if (pEl) {
  //     pEl.scrollTo(0, 2 * linkRef.current.offsetTop)
  //   }
  // }

  render() {
    const { menuItems, pathname, tid } = this.props;
    return (
      <ul className="dashboard-side-menu-secondary-links-list-container">
        <li className={`dashboard-side-menu-links-list-item ${pathname === '/dashboard' ? 'active-link' : ''}`}>
          <Link to={"/dashboard"}>
            <SVG
              src="/assets/images/dashboard/dashboard.svg"
              title="Dashboard"
              preProcessor={code => code.replace(/fill=".*?"/g, `fill=${pathname === '/dashboard' ? "#3e416d" : "#747b90"}`)}
            />
            <h4 className="sidebar-link-text" data-i18n="i18n">
              {tid('Dashboard')}
            </h4>
          </Link>
        </li>
        {menuItems.map((menu, index) => {
          const { name, icon, link} = menu;
          this[`${link}_ref`] = React.createRef()
          return (
            <li key={"sidemenu-" + index} ref={this[`${link}_ref`]} className={`dashboard-side-menu-links-list-item ${pathname === link ? 'active-link' : ''}`}>
              <Link to={link}>
                <SVG
                  src={icon}
                  title={name}
                  preProcessor={code => code.replace(/fill=".*?"/g, `fill=${pathname === link ? "#3e416d" : "#747b90"}`)}
                />
                <h4 className="sidebar-link-text" data-i18n="i18n">
                  {tid(name)}
                </h4>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  }
}
export default WithTranslations(GridMenuComponent);