import React,{useState} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import englishJson from "./i18n/en-US.json";

// import * as serviceWorker from './serviceWorker';
import "./index.scss";
import "./app-modal.scss";
import App from "./App";
import { LanguageProvider } from "./i18n/LanguageProvider";

const TheRoot = () => {
  const lang = navigator.language;
  const [language, setLanguage] = useState(
    lang.startsWith("it") ? "it" : localStorage.getItem("local-lang") || "en-Us"
  );

  const json = language === "en-Us" ? englishJson : require(`./i18n/${language}.json`);
  return (
    <BrowserRouter>
      <LanguageProvider currentLanguage={language} siteContent={json}>
        <App />
      </LanguageProvider>
    </BrowserRouter>
  );
};

const clientRender = () => {
  ReactDOM.render(<TheRoot />, document.getElementById("root"));
};

const serverRender = () => {};

if (typeof window === "object") {
  clientRender();
} else {
  serverRender();
}
