export const adminFeatures = [
  {
    title: "Account Management",
    url: "/account-management-feature",
    image: "/assets/images/admin/account.png",
    desc: 'Keep track of all small details that would usually go unnoticed',
  },
  {
    title: "Staff Management",
    url: "/staff-management-feature",
    image: "/assets/images/admin/staff.png",
    desc: 'Streamline the entire staff management.',
  },
  {
    title: "Branch Management",
    url: "/branches-management-feature",
    image: "/assets/images/admin/school-manage.png",
    desc: 'Regular analytics access for each branch made easier',
  },
  {
    title: "Access Management",
    url: "/access-management-feature",
    image: "/assets/images/admin/login.png",
    desc: 'One-click insights, live alerts, and simple gradebook navigation.',
  },
  {
    title: "Grading System",
    url: "/grading-system-feature",
    image: "/assets/images/admin/exam.png",
    desc: 'The big picture visibility needed with paperless technology at your fingertips',
  },
  {
    title: "Student Management",
    url: "/students-management-feature",
    image: "/assets/images/admin/user.png",
    desc: 'One-click access for attendance and behaviour tracking',
  },
  {
    title: "Class Management",
    url: "/classes-management-feature",
    image: "/assets/images/admin/class.png",
    desc: 'Enjoy a simpler and more transparent approach to classroom management',
  },
  {
    title: "Statistics",
    url: "/statistics-feature",
    image: "/assets/images/admin/report.png",
    desc: 'Extensive reporting, tracking, and scheduling to make better decisions.',
  },
  {
    title: "Calendar",
    url: "/admin-calendar-feature",
    image: "/assets/images/admin/calendar.png",
    desc: 'Schedule and manage training sessions in an effective manner to enable a smooth transition.',
  },
  {
    title: "Staff/Parents Meetings",
    url: "/staff-meetings-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Notice Board",
    url: "/notice-board-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Events/Gallery",
    url: "/gallery-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Skill Management",
    url: "/skill-management-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Social Timeline",
    url: "/social-timeline-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  }
]

export const teacherFeatures = [
  {
    title: "Content Management",
    url: "/content-management-feature",
    image: "/assets/images/teacher/content.png",
    desc: 'Upload your class content using our unique Audio & Video recording tools',
  },
  {
    title: "Student Performance",
    url: "/student-performance-feature-for-teachers",
    image: "/assets/images/admin/report.png",
    desc: `Access to all students' assessment grades, academic progress, and demographic information`,
  },
  {
    title: "Tests/Quizzes Evaluation",
    url: "/test-evaluation-feature",
    image: "/assets/images/teacher/quiz.png",
    desc: 'Learning games or quizzes on any topic, in any language.',
  },
  {
    title: "Live Classes",
    url: "/live-classes-feature",
    image: "/assets/images/teacher/live.png",
    desc: 'Get the feel of physical classes through online interactive classes',
  },
  {
    title: "Calendar",
    url: "/faculty-calendar-feature",
    image: "/assets/images/admin/calendar.png",
    desc: 'Avoid conflicts in timings by easily creating timetable and sending calendar to students',
  },
  {
    title: "Notice Board",
    url: "/notice-board-feature-for-teachers",
    image: "/assets/images/teacher/notes.png",
    desc: 'Send individual secure messages or broadcast with the entire class',
  },
  {
    title: "Library",
    url: "/library-feature",
    image: "/assets/images/teacher/book.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Student Doubts",
    url: "/doubts-feature",
    image: "/assets/images/teacher/doubt.png",
    desc: 'Clarify doubts easily by posting it in the class group',
  },
  {
    title: "Social Timeline",
    url: "/social-timeline-feature",
    image: "/assets/images/teacher/timeline.png",
    desc: 'Schedule and manage classes in an effective manner to enable a smooth transition',
  },
  {
    title: "Leaves/Holidays",
    url: "/leaves-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Grading System",
    url: "/grading-system-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Permission Based Access",
    url: "/permission-based-access-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  }
]

export const parentFeatures = [
  {
    title: "Childrens Access",
    url: "/childrens-access-feature",
    image: "/assets/images/parent/student.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Student Attendance",
    url: "/student-attendance-feature",
    image: "/assets/images/parent/attendance.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Student Time Table",
    url: "/student-time-table-feature",
    image: "/assets/images/parent/timetable.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Student Live Classes",
    url: "/parent-live-classes-feature",
    image: "/assets/images/teacher/live.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Student Complaints",
    url: "/student-complaints-feature",
    image: "/assets/images/parent/complaint.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Student Performance",
    url: "/student-performance-feature-for-parents",
    image: "/assets/images/admin/report.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Parent Meetings",
    url: "/parent-meetings-feature",
    image: "/assets/images/parent/meeting.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Student Fees",
    url: "/student-fees-feature",
    image: "/assets/images/parent/late-fee.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Notice Board",
    url: "/notice-board-feature-for-parents",
    image: "/assets/images/teacher/notes.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Student Leaves",
    url: "/student-leaves-feature",
    image: "/assets/images/parent/timetable.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Events Calendar",
    url: "/calendar-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  }
]

export const studentFeatures = [
  {
    title: "Live Classes",
    url: "/live-classes-for-student",
    image: "/assets/images/teacher/live.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Online Tests",
    url: "/online-tests-feature",
    image: "/assets/images/student/test.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Quiz",
    url: "/quizzes-feature",
    image: "/assets/images/teacher/quiz.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Video Lessons",
    url: "/video-lessons-feature",
    image: "/assets/images/student/video.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Library",
    url: "/library-for-student",
    image: "/assets/images/teacher/book.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Previous & Practice Papers",
    url: "/previous-papers-feature",
    image: "/assets/images/student/paper.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Performance",
    url: "/student-performance-feature",
    image: "/assets/images/admin/report.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Subjects Access",
    url: "/subjects-feature",
    image: "/assets/images/student/code.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Assignments",
    url: "/assignments-feature",
    image: "/assets/images/student/homework.png",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Doubts",
    url: "/doubts-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Complaints",
    url: "/complaints-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Calendar",
    url: "/calendar-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Notice Board",
    url: "/notice-board-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Social Timeline",
    url: "/social-timeline-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Events/Gallery",
    url: "/gallery-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Leaves/Holidays",
    url: "/leaves-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  },
  {
    title: "Grading System",
    url: "/grading-system-feature",
    image: "/assets/images/webinar.svg",
    desc: 'Fully featured, High quality live classes',
  }
]


export const services =  [
  {
    title: "Easy Setup",
    image: "/assets/images/server.png",
    description: "Easy installation for any school and new feature support when asked for.",
  },
  {
    title: "Data Security",
    image: "/assets/images/data-security.png",
    description: "We follow standard protocols in securing data.",
  },
  {
    title: "Technical Support",
    image: "/assets/images/technical-support.png",
    description: "We provide technical support with existing and also new features.",
  },
  {
    title: "24x7 Support",
    image: "/assets/images/customer-support.png",
    description: "We have  24X7  customer support to help with any issues at any point of time",
  },
]

export const teamMembers = [
  {
    name: 'Laxminarayana',
    role: 'Founder & CEO',
    subrole: 'The Visionary',
    image: '/assets/images/team/laxminarayana_dasari.jpg',
    linkedin: 'https://www.linkedin.com/in/laxminarayana-dasari-2a4004119/',
  },
  {
    name: 'Santhosh Kumar',
    role: 'CTO',
    subrole: 'The Hustler',
    image: '/assets/images/team/santhosh_korimi.png',
    linkedin: 'https://www.linkedin.com/in/santhosh-korimi/',
  },
  {
    name: 'Ajay Kumar',
    role: 'COO',
    subrole: 'The Hacker',
    image: '/assets/images/team/ajay_kumar.jpg',
    linkedin: 'https://www.linkedin.com/in/theajr/',
  },
  {
    name: 'Sanjeev',
    role: 'Director, Sales',
    image: '/assets/images/team/sanjeev.jpg',
    linkedin: 'https://www.linkedin.com/in/sanjeev-j-a4a967167/',
  },
  {
    name: 'Sindhu Rathod',
    role: 'AR Expert',
    image: '/assets/images/team/sindhu.png',
    linkedin: 'https://www.linkedin.com/in/sindhu-rathlavath-142b6088/',
  },
  {
    name: 'Sasirekha',
    role: 'Full Stack Developer',
    image: '/assets/images/team/sasi.png',
    linkedin: 'https://www.linkedin.com/in/sasirekha-ch-871422119/',
  },
]

export const advisoryMembers = [
  {
    name: 'Sikandar Khan',
    role: 'CTO, RudraLabs Pvt. Ltd.',
    image: '/assets/images/team/sikandar.jpg',
    linkedin: 'https://www.linkedin.com/in/dearsikandarkhan/',
  },
  {
    name: 'Madhu Sudhana Rao',
    role: 'Founder, Madblocks Technologies',
    image: '/assets/images/team/madhu.png',
    linkedin: 'https://www.linkedin.com/in/madhupiot/',
  },
]