import React from "react";
// import swal from 'sweetalert2';

// import {  Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import {
  get,
  create,
  uploadToS3,
  hasPermission,
  getUserData,
} from "../../../utils";
import "./skill-development.scss";
import Delete from "../../common/Delete";
import { WithTranslations } from "../../../i18n/withTranslations";

class SkillDevelopmentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: [],
      showSection: "skills",
      skillID: "",
      title: "",
      instructions: [],
      file: "",
      uploading: false,
    };
    this.uploadFile = this.uploadFile.bind(this);
    this.skillValidator = new SimpleReactValidator({});
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] });
  }

  save() {
    if (this.skillValidator.allValid()) {
      this.setState({
        uploading: true,
      });
      let fileName = "";
      const uploadFile = new Promise((resolve, reject) => {
        fileName = "skills/" + this.state.title + "/" + this.state.file.name;
        if (this.state.file) {
          resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
        } else {
          resolve();
        }
      });

      const fileUploader = new Promise((resolve, reject) => {
        let url = baseUrl + "skills/";
        if (this.state.skillID) url += this.state.skillID;
        else url += "add";

        resolve(
          create(url, this.state.skillID ? "put" : "post", {
            title: this.state.title,
            fileKey: fileName ? fileName : undefined,
            fileType: this.state.file
              ? this.state.file.type.split("/")[0]
              : undefined,
            instructions: this.state.instructions,
          })
        );
      });

      Promise.all([uploadFile, fileUploader])
        .then((response) => {
          this.getSkills();
          this.setState({
            uploading: false,
            showSection: "skills",
            title: "",
            skillID: "",
            file: "",
            instructions: [],
          });
          this.skillValidator.purgeFields();
        })
        .catch((error) => {});
    } else {
      this.skillValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  onClick(type) {
    this.setState({
      showSection: type,
    });
    if (type == "add") {
      this.setState({
        skillID: "",
        title: "",
        instructions: [],
        file: "",
      });
      $("#file").val("");
    }
  }

  onClickSkill(skill) {
    this.setState({
      skillID: skill._id,
      title: skill.title,
      instructions: skill.instructions,
      showSection: "add",
    });
  }

  addInstruction() {
    let instructions = this.state.instructions;
    instructions.push("");
    this.setState({
      instructions,
    });
  }

  componentDidMount() {
    this.getSkills();
    this.assignUserData();
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  getSkills() {
    get(baseUrl + "skills/list")
      .then((response) => {
        this.setState({
          skills: response.data.data,
        });
      })
      .catch((error) => {});
  }

  removeSkillById = (id) => () => {
    this.setState({
      skills: this.state.skills.filter((k) => k._id != id),
    });
  };

  handleFieldEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const index = splitted[1];
      const value = event.target.value;
      let items = this.state[name];
      items[parseInt(index, 10)] = value;
      this.setState({
        [name]: items,
      });
    }
  };

  removeInstruction(index) {
    let { instructions } = this.state;
    instructions.splice(index, 1);
    this.setState({
      instructions,
    });
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          {this.state.showSection === "skills" && (
            <section id="table-transactions">
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className="">{tid("Skill Development")}</h2>
                </div>
                <div className="app-page-header-right">
                  {hasPermission("skills", "create") && (
                    <button
                      className="app-add-option-button"
                      onClick={() => this.onClick("add")}
                    >
                      <i className="uil-plus" />
                     {tid(" Add New")}
                    </button>
                  )}
                </div>
              </div>
              <div className="skill-development-list-container">
                {this.state.skills.map((skill, index) => {
                  return (
                    <div
                      key={"skill-" + index}
                      className="sd-details-flex-container"
                    >
                      <div className="sd-top-container">
                        <div className="sd-top-thumbnail-inner">
                          {skill.fileType === "image" && (
                            <img src={skill.fileUrl} alt="" />
                          )}
                          {skill.fileType === "video" && (
                            <video controls>
                              <source
                                src={skill.fileUrl}
                                type="video/mp4"
                              ></source>
                            </video>
                          )}
                        </div>
                      </div>
                      <div className="sd-bottom-container">
                        <h3>
                          {skill.title}
                          <div>
                            <button
                              className="edit-button"
                              onClick={() => this.onClickSkill(skill)}
                            >
                              <i className="uil-edit" />
                            </button>
                            &nbsp;
                            <Delete
                              url={`skills/${skill._id}`}
                              onDeleteSuccess={this.removeSkillById(skill._id)}
                              size="md"
                            />
                          </div>
                        </h3>
                        <ul>
                          {skill.instructions.map((instruction, j) => {
                            return (
                              <li key={"instruction-" + index + "-" + j}>
                                <i className="uil-arrow-right" />
                                {instruction}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          )}
          {this.state.showSection === "add" && (
            <div>
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className="">
                    <span onClick={() => this.onClick("skills")}>
                      <i className="uil-arrow-left" /> {tid("New Skill Details")}{" "}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="skill-development-list-container">
                <div className="card-content">
                  <div className="row col-lg-12">
                    <div className="col-lg-6">
                      <h5 className="uk-text-bold mb-2"> {tid("Title")} </h5>
                      <input
                        type="text"
                        className="uk-input"
                        value={this.state.title}
                        name="title"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Enter Title")}
                      />
                      {this.skillValidator.message(
                        "Title",
                        this.state.title,
                        "required"
                      )}
                    </div>
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6">
                      <h5 className="uk-text-bold mb-2"> File </h5>
                      <input
                        type="file"
                        className="uk-input"
                        id="file"
                        onChange={this.uploadFile}
                        accept="image/*, video/*"
                        placeholder={tid("Date")}
                      />
                    </div>
                    <div className="row col-lg-12">
                      <h5 className="col-lg-12 pt-1">
                       {tid(" Instructions")} &nbsp;{" "}
                        <button
                          className="btn btn-outline-info btn-xs"
                          onClick={() => this.addInstruction()}
                        >
                       {tid("   Add")}
                        </button>
                      </h5>

                      {this.state.instructions.map((instruction, index) => {
                        return (
                          <div className="col-lg-6 float-left">
                            <div className="row col-lg-12">
                              <div className="col-lg-10 np">
                                <input
                                  key={"instruction-" + index}
                                  type="text"
                                  className="uk-input"
                                  value={instruction}
                                  name={"instructions#" + index}
                                  onChange={this.handleFieldEdit}
                                  placeholder={tid("Instruction ") + (index + 1)}
                                />
                              </div>
                              <div className="col-lg-2 pt-2">
                                <button
                                  className="btn btn-danger btn-xs"
                                  onClick={() => this.removeInstruction(index)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="row col-lg-12">
                      <div className="uk-flex uk-flex-right p-4">
                        <button
                          className="btn btn-link mr-2 btn-xs"
                          onClick={() => this.onClick("skills")}
                        >
                          {tid("Cancel")}
                        </button>
                        <button
                          className="btn btn-default grey btn-xs"
                          onClick={() => this.save()}
                        >
                          {this.state.uploading && (
                            <IconLoader
                              size={2}
                              text=""
                              color={"#fff"}
                            ></IconLoader>
                          )}
                          &nbsp;{tid("Submit")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WithTranslations(SkillDevelopmentComponent);
