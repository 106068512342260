import React from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import HomeComponent from "./components/website/home/home";
import AboutUsComponent from "./components/website/about-us/about-us";
import ContactUsComponent from "./components/website/contact-us/contact-us";
import FAQsComponent from "./components/website/faqs/faqs";
import BlogsComponent from "./components/website/blogs/blogs";
import DemoComponent from "./components/website/demo/demo";
import WebinarComponent from "./components/website/webinar/webinar";
import WebinarConference from "./components/website/webinar/conference";
import PrivacyPolicyComponent from "./components/website/privacy-policy/privacy-policy";
import TermsAndConditionsComponent from "./components/website/terms-and-conditions/terms-and-conditions";

import LiveClassesFeatureComponent from "./components/website/features/live-classes";
import ParentsLiveClassesFeatureComponent from "./components/website/features/parent-live-classes";
import AccountsFeatureComponent from "./components/website/features/accounts";
import StaffFeatureComponent from "./components/website/features/staff";
import BranchesFeatureComponent from "./components/website/features/branches";
import AccessFeatureComponent from "./components/website/features/access";
import GradingFeatureComponent from "./components/website/features/grading";
import StudentsFeatureComponent from "./components/website/features/students";
import ClassesFeatureComponent from "./components/website/features/classes";
import StatisticsFeatureComponent from "./components/website/features/statistics";
import AdminCalendarFeatureComponent from "./components/website/features/admin-calendar";
import FacultyCalendarFeatureComponent from "./components/website/features/faculty-calendar";
import ContentFeatureComponent from "./components/website/features/content";
import PerformanceForTeachersFeatureComponent from "./components/website/features/performance-teachers";
import PerformanceForParentsFeatureComponent from "./components/website/features/performance-parents";
import EvaluationFeatureComponent from "./components/website/features/evaluation";
import NoticeBoardForTeachersFeatureComponent from "./components/website/features/notice-board-for-teachers";
import NoticeBoardForParentsFeatureComponent from "./components/website/features/notice-board-for-parents";
import LibraryFeatureComponent from "./components/website/features/library";
import StudentsLibraryFeatureComponent from "./components/website/features/student/library";
import DoubtsFeatureComponent from "./components/website/features/doubts";
import TimelineFeatureComponent from "./components/website/features/timeline";
import ChildrensFeatureComponent from "./components/website/features/childrens";
import AttendanceFeatureComponent from "./components/website/features/attendance";
import TimeTableFeatureComponent from "./components/website/features/timetable";
import ComplaintsFeatureComponent from "./components/website/features/complaints";
import ParentMeetingsFeatureComponent from "./components/website/features/parent-meetings";
import FeeFeatureComponent from "./components/website/features/fee";
import LeavesFeatureComponent from "./components/website/features/leaves";
import OnlineTestsFeatureComponent from "./components/website/features/student/tests";
import QuizzesFeatureComponent from "./components/website/features/quizzes";
import VideoLessonsFeatureComponent from "./components/website/features/videos";
import PapersFeatureComponent from "./components/website/features/papers";
import AssignmentsFeatureComponent from "./components/website/features/assignments";
import SubjectsFeatureComponent from "./components/website/features/subjects";

import StudentLiveClassesFeature from "./components/website/features/student/live-classes";
import StudentsPerformanceFeatureComponent from "./components/website/features/student/performance";

import ThreeDComponent from "./components/website/td-view/td-view";
import ArtificialIntelligenceComponent from "./components/website/artificial-intelligence/artificial-intelligence";
import AugumentedRealityComponent from "./components/website/augumented-reality/augumented-reality";
import VirtualRealityComponent from "./components/website/virtual-reality/virtual-reality";

import LoginComponent from "./components/dashboard/login/user-login";
import ManagementComponent from "./components/dashboard/login/management-login";

import DashboardComponent from "./components/dashboard/dashboard/dashboard";
import BranchesComponent from "./components/dashboard/branches/branches";
import ProfileComponent from "./components/dashboard/profile/profile";
import StaffComponent from "./components/dashboard/staff/staff";

import AccountsComponent from "./components/dashboard/accounts/accounts";
import AchievementsComponent from "./components/dashboard/accounts/achievements";
import ExpensesComponent from "./components/dashboard/accounts/expenses";
import InventoriesComponent from "./components/dashboard/accounts/inventories";
import MeetingsComponent from "./components/dashboard/accounts/meetings";
import SalariesComponent from "./components/dashboard/accounts/salaries";
import ToppersComponent from "./components/dashboard/accounts/toppers";

import StudentsComponent from "./components/dashboard/students/students";
import StudentPerformanceComponent from "./components/dashboard/students/performance";
import ClassesComponent from "./components/dashboard/classes/classes";
import ClassDetailsComponent from "./components/dashboard/classes/class-details";
import AttendanceComponent from "./components/dashboard/classes/attendance";
import TimeTableComponent from "./components/dashboard/classes/timetable";
import SubjectsComponent from "./components/dashboard/subjects/subjects";
import ChaptersComponent from "./components/dashboard/chapters/chapters";
import ChapterDetailsComponent from "./components/dashboard/chapters/chapter-details";
import AddTestComponent from "./components/dashboard/tests/add";
import GradingSystemComponent from "./components/dashboard/grading-system/grading-system";
import TestsListComponent from "./components/dashboard/tests/list";
import OnlineTestComponent from "./components/dashboard/tests/online-test";
import ReviewTestComponent from "./components/dashboard/tests/review";
import TestSubmissionsComponent from "./components/dashboard/tests/submissions";
import AssignmentsComponent from "./components/dashboard/assignments/assignments";
import FeeComponent from "./components/dashboard/fee/fee";

import CalendarComponent from "./components/dashboard/calendar/calendar";
import NoticeBoardComponent from "./components/dashboard/notice-board/notice-board";
import LiveClassesComponent from "./components/dashboard/live-classes/list";
import AddLiveClassComponent from "./components/dashboard/live-classes/add";
import ActivityLiveClassComponent from "./components/dashboard/live-classes/activity";
import LiveClassMeetComponent from "./components/dashboard/live-classes/meet";
import VideoPreviewComponent from "./components/dashboard/videos/video-preview";
import PaperPreviewComponent from "./components/dashboard/papers/paper-preview";

import LeavesComponent from "./components/dashboard/leaves/leaves";
import DoubtsComponent from "./components/dashboard/doubts/doubts";
import LibraryComponent from "./components/dashboard/library/library";
import SkillsComponent from "./components/dashboard/skill-development/skill-development";
import GalleryComponent from "./components/dashboard/gallery/gallery";
import SocialComponent from "./components/dashboard/social/social";
import ComplaintsComponent from "./components/dashboard/complaints/complaints";

import ReportsComponent from "./components/dashboard/reports/reports";

import AddQuizComponent from "./components/dashboard/quiz/add";
import ReviewQuizComponent from "./components/dashboard/quiz/review";
import QuizComponent from "./components/dashboard/quiz/quiz";
import ChatDoubtsComponent from "./components/dashboard/chats/chats";

import ParentsComponent from "./components/dashboard/students/parents";
import PricingComponent from "./components/website/pricing/pricing";
import ProgressCard from "./components/dashboard/progress-card/progress-cards";
import AcademicYearsComponent from "./components/dashboard/academic-years/academic-years";
import OV2 from "./components/dashboard/live-classes/OV2";
import { Meeting } from "./components/dashboard/live-classes/meeting";
import HomeVersionTwo from "./components/website/home-v2";

// import NotFoundComponent from "./components/shared/not-found/not-found";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem("admin_exists") ? (
        <Redirect to={{ pathname: "/dashboard" }} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class Routing extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <div className="site-wrapper">
            <Route exact strict path="/" component={HomeVersionTwo} />
            <Route exact strict path="/home-v2" component={HomeVersionTwo} />
            {/* <Route exact strict path="/about-us" component={AboutUsComponent} /> */}
            <Route exact strict path="/contact-us" component={ContactUsComponent} />
            <Route exact strict path="/faqs" component={FAQsComponent} />
            <Route exact strict path="/blogs" component={BlogsComponent} />
            <Route exact strict path="/webinar" component={WebinarComponent} />
            <Route exact strict path="/webinar/:webinarID/:type" component={WebinarConference} />
            <Route exact strict path="/pricing" component={PricingComponent} />
            <Route exact strict path="/request-demo" component={DemoComponent} />
            <Route exact strict path="/privacy-policy" component={PrivacyPolicyComponent} />
            <Route exact strict path="/terms-and-conditions" component={TermsAndConditionsComponent} />

            <Route exact strict path="/3d-view" component={ThreeDComponent} />
            <Route exact strict path="/virtual-reality" component={VirtualRealityComponent} />
            <Route exact strict path="/augumented-reality" component={AugumentedRealityComponent} />
            <Route exact strict path="/artificial-intelligence" component={ArtificialIntelligenceComponent} />

            <Route exact strict path="/live-classes-feature" component={LiveClassesFeatureComponent} />
            <Route exact strict path="/parent-live-classes-feature" component={ParentsLiveClassesFeatureComponent} />
            <Route exact strict path="/account-management-feature" component={AccountsFeatureComponent} />
            <Route exact strict path="/staff-management-feature" component={StaffFeatureComponent} />
            <Route exact strict path="/branches-management-feature" component={BranchesFeatureComponent} />
            <Route exact strict path="/access-management-feature" component={AccessFeatureComponent} />
            <Route exact strict path="/grading-system-feature" component={GradingFeatureComponent} />
            <Route exact strict path="/students-management-feature" component={StudentsFeatureComponent} />
            <Route exact strict path="/classes-management-feature" component={ClassesFeatureComponent} />
            <Route exact strict path="/statistics-feature" component={StatisticsFeatureComponent} />
            <Route exact strict path="/admin-calendar-feature" component={AdminCalendarFeatureComponent} />
            <Route exact strict path="/faculty-calendar-feature" component={FacultyCalendarFeatureComponent} />
            <Route exact strict path="/content-management-feature" component={ContentFeatureComponent} />
            <Route exact strict path="/student-performance-feature-for-teachers" component={PerformanceForTeachersFeatureComponent} />
            <Route exact strict path="/student-performance-feature-for-parents" component={PerformanceForParentsFeatureComponent} />
            <Route exact strict path="/test-evaluation-feature" component={EvaluationFeatureComponent} />
            <Route exact strict path="/notice-board-feature-for-teachers" component={NoticeBoardForTeachersFeatureComponent} />
            <Route exact strict path="/notice-board-feature-for-parents" component={NoticeBoardForParentsFeatureComponent} />
            <Route exact strict path="/library-feature" component={LibraryFeatureComponent} />
            <Route exact strict path="/doubts-feature" component={DoubtsFeatureComponent} />
            <Route exact strict path="/social-timeline-feature" component={TimelineFeatureComponent} />
            <Route exact strict path="/childrens-access-feature" component={ChildrensFeatureComponent} />
            <Route exact strict path="/student-attendance-feature" component={AttendanceFeatureComponent} />
            <Route exact strict path="/student-time-table-feature" component={TimeTableFeatureComponent} />
            <Route exact strict path="/student-complaints-feature" component={ComplaintsFeatureComponent} />
            <Route exact strict path="/parent-meetings-feature" component={ParentMeetingsFeatureComponent} />
            <Route exact strict path="/student-fees-feature" component={FeeFeatureComponent} />
            <Route exact strict path="/leaves-feature" component={LeavesFeatureComponent} />
            <Route exact strict path="/online-tests-feature" component={OnlineTestsFeatureComponent} />
            <Route exact strict path="/quizzes-feature" component={QuizzesFeatureComponent} />
            <Route exact strict path="/video-lessons-feature" component={VideoLessonsFeatureComponent} />
            <Route exact strict path="/previous-papers-feature" component={PapersFeatureComponent} />
            <Route exact strict path="/assignments-feature" component={AssignmentsFeatureComponent} />
            <Route exact strict path="/subjects-feature" component={SubjectsFeatureComponent} />
            <Route exact strict path="/progress-card" component={ProgressCard} />

            <Route exact strict path="/live-classes-for-student" component={StudentLiveClassesFeature} />
            <Route exact strict path="/library-for-student" component={StudentsLibraryFeatureComponent} />
            <Route exact strict path="/student-performance-feature" component={StudentsPerformanceFeatureComponent} />

            <Route exact strict path="/login" component={LoginComponent} />

            <Route exact strict path="/management-login" component={ManagementComponent} />

            <PrivateRoute exact strict path="/dashboard" component={DashboardComponent} />
            <PrivateRoute exact strict path="/branches" component={BranchesComponent} />
            <PrivateRoute exact strict path="/profile" component={ProfileComponent} />
            <PrivateRoute exact strict path="/staff" component={StaffComponent} />

            <PrivateRoute exact strict path="/accounts" component={AccountsComponent} />
            <PrivateRoute exact strict path="/achievements" component={AchievementsComponent} />
            <PrivateRoute exact strict path="/expenses" component={ExpensesComponent} />
            <PrivateRoute exact strict path="/inventories" component={InventoriesComponent} />
            <PrivateRoute exact strict path="/meetings" component={MeetingsComponent} />
            <PrivateRoute exact strict path="/salaries" component={SalariesComponent} />
            <PrivateRoute exact strict path="/toppers" component={ToppersComponent} />
            <PrivateRoute exact strict path="/fee" component={FeeComponent} />

            <PrivateRoute exact strict path="/calendar" component={CalendarComponent} />
            <PrivateRoute exact strict path="/notice-board" component={NoticeBoardComponent} />

            <PrivateRoute exact strict path="/students" component={StudentsComponent} />
            <PrivateRoute exact strict path="/performance" component={StudentPerformanceComponent} />
            <PrivateRoute exact strict path="/student-performance/:userID" component={StudentPerformanceComponent} />

            <PrivateRoute exact strict path="/classes" component={ClassesComponent} />
            <PrivateRoute exact strict path="/class-details/:classID" component={ClassDetailsComponent} />
            <PrivateRoute exact strict path="/attendance/:classID" component={AttendanceComponent} />
            <PrivateRoute exact strict path="/timetable/:classID" component={TimeTableComponent} />
            <PrivateRoute exact strict path="/subjects/:classID?" component={SubjectsComponent} />
            <PrivateRoute exact strict path="/chapters/:classID/:subjectID" component={ChaptersComponent} />
            <PrivateRoute exact strict path="/online-tests/:classID/:subjectID" component={TestsListComponent} />
            <PrivateRoute exact strict path="/online-test/:testID" component={OnlineTestComponent} />
            <PrivateRoute exact strict path="/review/:testID" component={ReviewTestComponent} />
            <PrivateRoute exact strict path="/test-submissions/:testID" component={TestSubmissionsComponent} />
            <PrivateRoute exact strict path="/chapter-details/:classID/:subjectID/:chapterID" component={ChapterDetailsComponent} />
            <PrivateRoute exact strict path="/add-test/:classID/:subjectID/:chapterID?" component={AddTestComponent} />
            <PrivateRoute exact strict path="/load-questions/:testID" component={AddTestComponent} />
            <PrivateRoute exact strict path="/grading-system" component={GradingSystemComponent} />
            <PrivateRoute exact strict path="/assignments/:classID/:subjectID" component={AssignmentsComponent} />

            <PrivateRoute exact strict path="/live-classes" component={LiveClassesComponent} />
            <PrivateRoute exact strict path="/add-live-class" component={AddLiveClassComponent} />
            <PrivateRoute exact strict path="/edit-live-class/:liveClassID" component={AddLiveClassComponent} />
            <PrivateRoute exact strict path="/meet/:liveClassID" component={LiveClassMeetComponent} />
            <PrivateRoute exact strict path="/activity-live-class/:liveClassID" component={ActivityLiveClassComponent} />

            {/* <PrivateRoute exact strict path="/live-classes/:liveClassID" component={VideoLiveClassComponent} /> */}

            <PrivateRoute exact strict path="/topic-videos/:topicID/:videoID" component={VideoPreviewComponent} />
            <PrivateRoute exact strict path="/topic-papers/:topicID/:paperID" component={PaperPreviewComponent} />

            <PrivateRoute exact strict path="/quiz/:quizFor/:referenceID" component={AddQuizComponent} />
            <PrivateRoute exact strict path="/quiz/:quizID" component={QuizComponent} />
            <PrivateRoute exact strict path="/review-quiz/:quizID" component={ReviewQuizComponent} />

            <PrivateRoute exact strict path="/chat/:chatID" component={ChatDoubtsComponent} />
            <PrivateRoute exact strict path="/reports" component={ReportsComponent} />

            <PrivateRoute exact strict path="/leaves" component={LeavesComponent} />
            <PrivateRoute exact strict path="/doubts" component={DoubtsComponent} />
            <PrivateRoute exact strict path="/library" component={LibraryComponent} />
            <PrivateRoute exact strict path="/gallery" component={GalleryComponent} />
            <PrivateRoute exact strict path="/skills" component={SkillsComponent} />
            <PrivateRoute exact strict path="/social" component={SocialComponent} />
            <PrivateRoute exact strict path="/complaints" component={ComplaintsComponent} />
            <PrivateRoute exact strict path="/academic-years" component={AcademicYearsComponent} />

            <PrivateRoute exact strict path="/video-conference/:liveClassID/live-class" component={OV2} />
            <PrivateRoute exact strict path="/meeting/:meetingID" component={Meeting} />

            <Route exact strict path="/mobile-video-conference/:codeToken/:userToken/:liveClassID/:type/:userType/:firstname/:lastname/:userID" component={OV2} />

            <PrivateRoute
              exact
              strict
              path="/parents"
              component={ParentsComponent}
            />
            {/* <Route path="/*" component={NotFoundComponent} /> */}
          </div>
        </Router>
      </div>
    );
  }
}

export default Routing;
