import React from "react";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get } from "../../../utils";
// import { Loader } from "../../shared/loader";
import AddStaffComponent from "./add-staff";
import ListStaffComponent from "./staff-list";
import StaffPayrollComponent from "./staff-payroll";
import './staff.scss';
import { WithTranslations } from "../../../i18n/withTranslations";

class StaffComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      staffs: [],
      showSection: "list"
    }
  }

  getClientsList() {
    get(baseUrl + "users/list/faculty")
      .then((response) => {
        this.setState({
          staffs: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  loadStaff() {
    this.setState({
      showSection: "list"
    })
    this.getClientsList();
  }

  componentDidMount() {
    this.getClientsList();
  }

  render() {
const {translations}=this.props;
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          {this.state.showSection === 'list' && <div className="ac-staff-list-main-container">
            <div className="app-page-header-section">
              <div className="app-page-header-left">
                <h2 className="">{translations.STAFF_MANAGEMENT || `Staff Management`}</h2>
              </div>
              <div className="app-page-header-right">
                <button className="app-add-option-button" onClick={() => this.setState({ showSection: 'add-staff' })}> <i className="uil-plus"></i> {translations.ADD_STAFF || `Add Staff`}</button>
              </div>
            </div>
            <div className="ac-staff-list-flex-container">
              {this.state.staffs.map((staff, index) => {
                return (
                  <ListStaffComponent staff={staff} index={index} payroll={() => this.setState({ staffID: staff._id, showSection: 'payroll' })} showAdd={() => this.setState({ staffID: staff._id, showSection: 'add-staff' })}></ListStaffComponent>
                )
              })}
            </div>
          </div>}
          {this.state.showSection === 'add-staff' && <div className="ac-add-staff-form-container">
            <AddStaffComponent staffID={this.state.staffID} loadStaff={() => this.loadStaff()} showList={() => this.setState({ showSection: 'list' })}></AddStaffComponent>
          </div>}
          {this.state.showSection === 'payroll' && <div className="ac-add-staff-form-container">
            <StaffPayrollComponent staffID={this.state.staffID} showList={() => this.setState({ showSection: 'list' })}></StaffPayrollComponent>
          </div>}
        </div>
      </div>
    );
  }
}

export default WithTranslations(StaffComponent);