import React from "react";
// import swal from 'sweetalert2';

import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';

// import { Loader, IconLoader } from "../../shared/loader";
// import Navbar from "../shared/navbar/Navbar";
// import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { get } from "../../../utils";

class PaperPreviewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          topicID: "",
          paperID: "",
          papers: [],
          previewPaper: {
            paperUrl: ""
          }
        }
    }

    componentDidMount() {
      let topicID = this.props.match.params.topicID;
      let paperID = this.props.match.params.paperID;
      this.setState({
        topicID,
        paperID
      })
      this.getTopicPapers(topicID, paperID);
    }

    getTopicPapers(topicID, paperID) {
      this.setState({
        papers: [],
        previewPaper: {
          paperUrl: ""
        }
      })

      get(baseUrl + "papers/topic-papers/"+(this.state.topicID || topicID)+"/"+(this.state.paperID || paperID))
        .then((response) => {
          this.setState({
            previewPaper: response.data.previewPaper,
            papers: response.data.data
          })
        })
        .catch((error) => {
        });
    }

    render() {

        return (
          <div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 np">
                <div className="card col-lg-12">
                  <div className="card-header">
                    <h4 className="card-title"><Link to={"/dashboard"} ><i className="icon-feather-home"></i></Link> Paper for Telugu Subject <button className="btn round-btn btn-close float-right">X</button></h4>
                  </div>
                  
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 row pt-0 mt-0">
                <div className="col-lg-9">
                  <div className="card ml-1 h350">
                    <iframe src={this.state.previewPaper.paperUrl} title="Paper Preview" frameBorder="0" height="450" width="100%"></iframe>
                  </div>
                  <div className="ml-1 pt-1 pl-1">
                    <h4>{this.state.previewPaper.name}</h4>
                  </div>
                </div>
                <div className="col-lg-3">
                  <h4>Similar Papers</h4>
                  <div class="uk-accordion-content">
                    <ul class="vidlist-3">
                    {this.state.papers.map((paper, index) => {
                    return (<li>
                        <a href={"/topic-papers/"+paper.topicID+"/"+paper._id} key={"paper-"+index}> <label> {paper.name} </label>
                        </a> 
                      </li>)})}
                    </ul>
                </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default PaperPreviewComponent;