import React from 'react';
import './pdf-reader.scss';

const PdfReader = ({pdfUrl, title, closeReader}) => {
  return (
    <div className='pdf-reader-container'>
      <div className='close-button' onClick={() => closeReader()}>
        <i className="uil-times-circle" />
      </div>
      <div className='pdf-reader-overlay'>
        <iframe src={pdfUrl} frameBorder="0" title={title} width="100%"></iframe>
      </div>
    </div>
  )
}

export default PdfReader;