import React from "react";
import { OBJModel } from "react-3d-viewer";

import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";
import { LanguageContext } from "../../../i18n/LanguageProvider";

const basic = [
`Staff Management
Student Management`,`
Class management`,`
Mobile App`,`
Student Performance`,`
Toppers List`,`
Achievements`,`
Online Tests`,`
Library Access`,`
Leaves Management`,`
Holidays List`,`
Events/Gallery`,`
Social Access`,`
Complaints`,`
Calendar`,`
Notifications`,`
Assignments`,`
Attendance`];

const standard = [
`Online Tests With Essay Questions`,`
Online Tests Manual Evaluation`,`
Video Lessons`,`
Library/Upload Books`,`
Download Report Card`,`
Grade Wise Student Performance Review`,`
Attendance Report Download`,`
Grading System`,`
Skill Development`,`
Live Classes`,`
Quizzes`,`
Doubts`,`
Custom Report Card`,`
Staff/Parents Meetings`];

const premium = [
`Online Payments`,`
Expense Management`,`
Inventory Management`,`
Staff Salaries`,`
Bulk SMS`,`
Whatsapp Integration`,`
Parents Fee Online Payments`,`
Branch Wise Schools`];

const enterprise = [
`Bus Tracking`,`
Biometric Attedance`,`
ID Cards Printing`,`
T-Shirts Printing`,`
Sub Domain`];

export class RowsRendererComponent extends React.Component{

  render(){
    let { feature, index, basic, standard, premium, enterprise} = this.props;
      return(
        <tr key={"feature-"+index}>
          <td>
            {feature}
          </td>
          <td className="text-center">
            {basic?<img className="pricing-mark-icons" src="/assets/images/checkmark.png"/>:<img className="pricing-mark-icons" src="/assets/images/cross.png"/>}
          </td>
          <td className="text-center">
            {standard?<img className="pricing-mark-icons" src="/assets/images/checkmark.png"/>:<img className="pricing-mark-icons" src="/assets/images/cross.png"/>}
          </td>
          <td className="text-center">
            {premium?<img className="pricing-mark-icons" src="/assets/images/checkmark.png"/>:<img className="pricing-mark-icons" src="/assets/images/cross.png"/>}
          </td>
          <td className="text-center">
            {enterprise?<img className="pricing-mark-icons" src="/assets/images/checkmark.png"/>:<img className="pricing-mark-icons" src="/assets/images/cross.png"/>}
          </td>
        </tr>
      )
  }
}

const PricingComponent = () => {
  const languageContext = React.useContext(LanguageContext);
  const { content: pc} = languageContext
  const bgStyles = {
    backgroundImage:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/pricing.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: 550,
  };

  return (
    <div className="homepage-main-container">
      <Navbar />
      <div className="about-us-info-container" style={bgStyles}>
        <div className="about-us-info-section">
          <h2>{pc.PRICING_PAGE_HEADING}</h2>
          <h4>{pc.PRICING_PAGE_SUB_HEADING}</h4>
          <button className="btn know-more-button">
            {pc.KNOW_MORE}
            <i className="uil-arrow-down" />
          </button>
        </div>
      </div>
      <div className='pricing-details-container'>
        <table className="table">
          <thead>
            <th>{pc.MODULE_NAME}</th>
            <th className="text-center">{pc.BASIC}</th>
            <th className="text-center">{pc.STANDARD}</th>
            <th className="text-center">{pc.PREMIUM}</th>
            <th className="text-center">{pc.ENTERPRISE}</th>
          </thead>
          <tbody>
            {basic.map((feature, index) => {
              return <RowsRendererComponent feature={pc[`PRICING_FEATURE_HEADING${index + 1}`]} index={index} basic={true} standard={true} premium={true} enterprise={true}></RowsRendererComponent>
            })
            }
            {standard.map((feature, index) => {
              return <RowsRendererComponent feature={pc[`PRICING_FEATURE_HEADING${basic.length + index + 1}`]} index={index} basic={false} standard={true} premium={true} enterprise={true}></RowsRendererComponent>
            })
            }
            {premium.map((feature, index) => {
              return <RowsRendererComponent feature={pc[`PRICING_FEATURE_HEADING${basic.length + standard.length + index + 1}`]} index={basic.length + standard.length + index} basic={false} standard={false} premium={true} enterprise={true}></RowsRendererComponent>
            })
            }
            {enterprise.map((feature, index) => {
              return <RowsRendererComponent feature={pc[`PRICING_FEATURE_HEADING${basic.length + standard.length + premium.length + index + 1}`]} index={basic.length + standard.length + premium.length + index} basic={false} standard={false} premium={false} enterprise={true}></RowsRendererComponent>
            })
            }
          </tbody>
        </table>
      </div>
      <div className="footer-container">
        <Footer></Footer>
      </div>
    </div>
  );
}

export default PricingComponent;
