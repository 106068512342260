import React from "react";

import { baseUrl } from "../../../config";
import { get } from "../../../utils";

export default class QuestionComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      previousQuestionNo: ''
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  componentDidMount() {
    this.setState({
      index: this.props.index,
      questionFor: this.props.questionFor
    })
  }

  checkExists() {
    get(baseUrl + "questions/find/" + this.state.previousQuestionNo)
      .then((response) => {
        if (response.data.data) {
          this.setState({
            previousQuestion: response.data.data,
            questionMessage: response.data.message
          })
        } else {
          this.setState({
            questionMessage: response.data.message,
            previousQuestion: {}
          })
        }
      })
      .catch((error) => {

      });
  }

  render() {

    // this.setQuestion(question)

    return (
      <div className='load-previous-questions-container'>
        <button className="btn btn-xs btn-default load-button" data-toggle="modal" data-target="#previous-question">Load previous question</button>
        <div className="modal fade text-left" id="previous-question" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1"
          aria-hidden="true">
          <div className="modal-dialog modal-sm modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel1" >Load Previous Question</h4>
                <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row align-items-center t-0 b-0">
                  <div className="col-lg-4">
                    <label className="col-form-label">Question ID:</label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.previousQuestionNo}
                      name="previousQuestionNo"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Enter Previous Question ID. Ex: Q00013"
                    />
                  </div>
                </div>
                <p className="text-center">
                  {this.state.questionMessage}
                  {this.state.previousQuestion && this.state.previousQuestion._id && <button type="button" className="btn btn-info ml-1 btn-xs" onClick={() => this.props.onAddQuestion(this.state.previousQuestion)}>
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">Assign Question</span>
                  </button>}
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-light-secondary btn-xs" data-dismiss="modal">
                  <i className="bx bx-x d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">Close</span>
                </button>
                <button type="button" className="btn btn-default ml-1 btn-xs" onClick={this.checkExists.bind(this)}>
                  <i className="bx bx-check d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">Check Exists</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}