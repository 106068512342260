import React from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { baseUrl } from "../../../config";
import { create } from "../../../utils";
import SchoolCode from "./school-code";
import "./login.scss";
import {LanguageContext} from "../../../i18n/LanguageProvider"

class LoginComponent extends React.Component {
   static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      password: "",
      message: "",
      student_mobile: "",
      student_password: "",
      student_message: "",
      activeTab: "Student",
      activePage: "SchoolCode",
      schoolCode: "",
    };

    // sessionStorage.clear();
    this.parentLogin = this.parentLogin.bind(this);
    this.studentLogin = this.studentLogin.bind(this);
    this.parentValidator = new SimpleReactValidator({});
    this.studentValidator = new SimpleReactValidator({});
  }

  componentDidMount() {
    const schoolCode = localStorage.getItem("schoolcode");
    if (schoolCode) {
      this.setState({
        activePage: "login",
        schoolCode,
      });
    }
  }

  parentLogin() {
    if (this.parentValidator.allValid()) {
      this.requestAPI({
        username: this.state.mobile,
        password: this.state.password,
        userType: "parent",
      });
    } else {
      this.parentValidator.showMessages();
      this.forceUpdate();
    }
  }

  studentLogin() {
    if (this.studentValidator.allValid()) {
      this.requestAPI({
        username: this.state.student_mobile,
        password: this.state.student_password,
        userType: "student",
      });
    } else {
      this.studentValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  requestAPI(data) {
    if(data.username && data.password) {
      create(baseUrl + "users/login", "post", data)
        .then((response) => {
          if (response.data.success) {
            sessionStorage.setItem("token", response.data.data);
            sessionStorage.setItem(
              "permissionsToken",
              response.data.permissionsToken
            );
            sessionStorage.setItem("academicYearToken", response.data.academicYearToken);
            this.props.history.push("/dashboard");
          } else {
            this.setState({
              message: response.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleOnEditSchoolCode = () => {
    this.setState({
      activePage: "SchoolCode",
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { activeTab } = this.state;
    const fn = activeTab === "Student" ? this.studentLogin : this.parentLogin;
    fn();
  };

  render() {
    const { content } = this.context;
    const { activeTab, schoolCode } = this.state;
    const bgStyles = {
      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.85), rgba(62, 65, 109, 1.0)), url(/assets/images/${activeTab}.jpg)`,
      backgroundSize: "cover",
      backgroundPosition: "center center",
    };
    return (
      <div className="login-page-main-container" >
        <div className="login-page-layout">
          <div className="login-page-flex-section">
            <div className="left-side-section" style={bgStyles}>
              <div className="app-details-section">
                <Link to={"/"}>
                  <img
                    className="main-logo"
                    src={"/assets/images/logo.jpg"}
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="extra-info-container">
                {activeTab === "Student" && (
                  <div className="student-info-container">
                    <h4>{content.WELCOMBACK_STUDENT || 'Welcome Back Student!'}</h4>
                    <p>
                     { content.WELCOMBACK_STUDENT_DESC || `Access the most advanced learning features by logging into
                      your account.`}
                    </p>
                    <ul>
                      <li>
                        <i className="uil-arrow-right" /> {content.LIVE_CLASSES || 'Live Classes'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.QUALITY_VIDEO_LECTURES || 'Quality Video lectures'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.ONLINE_ASSESSMENTS_AND_QUIZES || 'Online Assignments & Quizzes'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.SKILL_DEV_PROGRAMS ||`Skill Development
                        Programmes`}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.MANY_MORE || 
                        "Many more..."}
                      </li>
                    </ul>
                  </div>
                )}
                {activeTab === "Parents" && (
                  <div className="student-info-container">
                    <h4>{content.WELCOME_BACK_PARENT ||'Welcome Back Parent!'}</h4>
                    <p>
                      {content.WELCOME_BACK_PARENT_DESC || `Easily access your child education details by logging into
                      your account.`}
                    </p>
                    <ul>
                      <li>
                        <i className="uil-arrow-right" /> {content.CHILD_PERFORMANCE||'Child Performance'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.ATTENDANCE||'Attendance'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.ONLINE_FEE_PAYMENTS||'Online Fee Payments'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.PARENT_MEETINGS||'Parent Meetings'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.MANY_MORE||'Many more...'}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {/* <img src='/assets/images/login.png' className='login-illustration-image' alt="Login"/> */}
            </div>
            {this.state.activePage === "login" && (
              <div className="right-side-section">
                <div className='image-section-xs'>
                  <Link to={"/"}>
                    <img
                      className="main-logo"
                      src={"/assets/images/logo.jpg"}
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="login-tab-flex-section">
                  <div
                    className={`student-section ${
                      activeTab === "Student" ? "active-tab" : ""
                    }`}
                    onClick={() =>
                      this.setState({ activeTab: "Student", message: "" })
                    }
                  >
                    <img
                      src={
                        activeTab === "Student"
                          ? "/assets/images/student-white.svg"
                          : "/assets/images/student.svg"
                      }
                      alt="Student"
                      className="login-tab-image"
                    />
                    <h4 className="login-tab-heading">{content.STUDENT||'Student'}</h4>
                  </div>
                  <div
                    className={`parent-section ${
                      activeTab === "Parents" ? "active-tab" : ""
                    }`}
                    onClick={() =>
                      this.setState({ activeTab: "Parents", message: "" })
                    }
                  >
                    <img
                      src={
                        activeTab === "Parents"
                          ? "/assets/images/parents-white.svg"
                          : "/assets/images/parents.svg"
                      }
                      alt="Parent"
                      className="login-tab-image"
                    />
                    <h4 className="login-tab-heading">{content.PARENT||'Parent'}</h4>
                  </div>
                </div>
                <div className="code-data-container">
                  <div className="school-code-section">
                    <p>
                      <span>
                       {content.SCHOOL_CODE || 'Code '}
                        <i
                          className="uil-info-circle"
                          uk-tooltip={content.SCHOOL_CODE_HELP || "School / College / Institute / Tutor Code"}
                        />
                      </span>{" "}
                      {schoolCode}
                    </p>
                    <i
                      className="uil-edit"
                      onClick={this.handleOnEditSchoolCode}
                    />
                  </div>
                </div>
                {activeTab === "Student" && (
                  <div className="login-form-container-main">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> {content.MOBILE||'Mobile'}</label>
                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-phone"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="number"
                          value={this.state.student_mobile}
                          name="student_mobile"
                          onChange={this.setFormField.bind(this)}
                          placeholder={content.MOBILE_PLACEHOLDER || "Registered Mobile No"}
                        />
                      </div>
                      {this.studentValidator.message(
                        "Mobile Number",
                        this.state.student_mobile,
                        "required"
                      )}
                    </div>
                    <div className="uk-form-group">
                      <label className="uk-form-label"> {content.PASSWORD||'Password'}</label>
                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-lock"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="password"
                          name="student_password"
                          placeholder={content.PASSWORD||"Password"}
                          value={this.state.student_password}
                          onChange={this.setFormField.bind(this)}
                        />
                      </div>
                      {this.studentValidator.message(
                        "Password",
                        this.state.student_password,
                        "required"
                      )}
                    </div>
                    <div className="uk-width-auto@s">
                      <span className="red-text text-center">
                        {this.state.message}
                      </span>
                      <button
                        onClick={() => this.studentLogin()}
                        className="btn btn-default login-button"
                      >
                        {content.LOGIN||'Login'}
                      </button>
                    </div>
                  </div>
                )}
                {activeTab === "Parents" && (
                  <div className="login-form-container-main">
                    <div className="uk-form-group">
                      <label className="uk-form-label">  {content.MOBILE||'Mobile'}</label>
                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-phone"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="number"
                          value={this.state.mobile}
                          name="mobile"
                          onChange={this.setFormField.bind(this)}
                          placeholder={content.PARENT_REG_MOBILE||"Parent Registered Mobile No"}
                        />
                      </div>
                      {this.parentValidator.message(
                        "Mobile Number",
                        this.state.mobile,
                        "required"
                      )}
                    </div>
                    <div className="uk-form-group">
                      <label className="uk-form-label">  {content.PASSWORD||'Password'}</label>
                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-lock"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="password"
                          name="password"
                          placeholder={content.PASSWORD||"Password"}
                          value={this.state.password}
                          onChange={this.setFormField.bind(this)}
                        />
                      </div>
                      {this.parentValidator.message(
                        "Password",
                        this.state.password,
                        "required"
                      )}
                    </div>
                    <div className="uk-width-auto@s">
                      <span className="red-text text-center">
                        {this.state.message}
                      </span>
                      <button
                        onClick={() => this.parentLogin()}
                        className="btn btn-default login-button"
                      >
                        {content.LOGIN||'Login'}
                      </button>
                    </div>
                  </div>
                )}
                <div className="faculty-login-link-section">
                  <Link to={"/management-login"}>{content.FACULTY_LOGIN||'Faculty Login'}</Link>
                </div>
              </div>
            )}
            {this.state.activePage === "SchoolCode" && (
              <div className="right-side-section">
                <SchoolCode
                  schoolCode={schoolCode}
                  showLogin={(code) =>
                    this.setState({ activePage: "login", schoolCode: code })
                  }
                ></SchoolCode>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LoginComponent;
