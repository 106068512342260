import React from "react";
import swal from 'sweetalert2';
import { Link } from "react-router-dom";
import * as $ from "jquery";

import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import SimpleReactValidator from 'simple-react-validator';

import { baseUrl } from "../../../config";
import { years } from "../../../constants";
// import Loader from "../../../../shared/loader";

import { getUserData, get, create, uploadToS3 } from "../../../utils";

class ToppersComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toppers: [],
      employees: [],
      userID: "",
      year: "",
      comments: "",
      file: "",
      topperID: ""
    }
    this.uploadFile = this.uploadFile.bind(this);
    this.topperValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.topperValidator.allValid()) {

      let url = baseUrl + "toppers/";
      if (!this.state.topperID)
        url += "add";
      else
        url += this.state.topperID

      let fileName = "";
      if (this.state.file)
        fileName = "toppers/" + Date.now() + "/" + this.state.file.name;
      new Promise((resolve, reject) => {
        if (this.state.file)
          resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
        else
          resolve();
      })
        .then((response) => {
          return create(url, (this.state.topperID) ? "put" : "post", {
            userID: this.state.userID,
            year: this.state.year,
            comments: this.state.comments,
            fileKey: fileName ? fileName : undefined,
            fileType: (this.state.file && this.state.file.type) ? this.state.file.type.split("/")[0] : undefined,
          })
        })
        .then((response) => {
          this.getToppersList();
          $("#add-topper").trigger("click");
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning"
          });
        })
        .catch((error) => {

        });
    } else {
      this.topperValidator.showMessages();
      this.forceUpdate();
    }
  }

  getToppersList() {

    get(baseUrl + "toppers/list")
      .then((response) => {
        this.setState({
          toppers: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
    })
  }

  componentDidMount() {
    this.assignUserData();
    this.getToppersList();
    this.getFaculty();
  }

  getFaculty() {
    get(baseUrl + "users/list/student")
      .then((response) => {
        this.setState({
          employees: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] })
  }

  editTopper(topper) {
    this.setState({
      userID: topper.userID._id,
      year: topper.year,
      comments: topper.comments,
      topperID: topper._id
    })
  }

  render() {
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className=""><Link to={"/accounts"}><i className="uil-arrow-left"></i></Link> Toppers</h2>
            </div>
            <div className="app-page-header-right">
              {(this.state.userType !== 'topper' && this.state.userType !== 'parent') && <div className="section-header-right">
                <button className="app-add-option-button" data-toggle="modal" data-target="#add-topper"><i className="uil-plus"></i> Add Topper</button>
              </div>}
            </div>
          </div>
          <div className="expenses-list-table-container" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 100">
            <table className="table">
              <thead>
                <tr className='table-header-row'>
                  <th>S.No</th>
                  <th>Student Name</th>
                  <th>Year</th>
                  <th>Image</th>
                  <th>Comments</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.toppers.map((topper, index) => {
                  return (<tr className='table-data-row' key={"topper" + index}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="ml-1"><span className="text-bold-500">{topper.userID.firstName} </span></div>
                      </div>
                    </td>
                    <td>
                      {topper.year}
                    </td>
                    <td>
                      {topper.fileUrl && <a target="_blank" href={topper.fileUrl}>Preview</a>}
                    </td>
                    <td>
                      {topper.comments}
                    </td>
                    <td>
                      <div className='action-items'>
                        <button type="button" className="btn btn-success btn-sm" data-toggle="modal" data-target="#add-topper" onClick={() => this.editTopper(topper)}><i className='uil-edit' /></button>
                        <button type="button" className="btn btn-danger btn-sm"><i className='uil-trash' /></button>
                      </div>
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
          <div className="modal fade text-left" id="add-topper" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">Add Topper</h3>
                  <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label>StudentName</label>
                    <select
                      className="form-control form-control-user"
                      value={this.state.userID}
                      name="userID"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Select Student"
                    >
                      <option value="">Select Student</option>
                      {this.state.employees.map((faculty, index) => {
                        return (<option key={"index-" + index} value={faculty._id}>{faculty.firstName} {faculty.lastName}</option>)
                      })}
                    </select>
                    {this.topperValidator.message(
                      "Student",
                      this.state.userID,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>Year</label>
                    <select
                      className="form-control form-control-user"
                      value={this.state.year}
                      name="year"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Select Year"
                    >
                      <option value="">Select Year</option>
                      {years.map((year, index) => {
                        return (<option key={"year-" + index} value={year.value}>{year.name}</option>)
                      })}
                    </select>
                    {this.topperValidator.message(
                      "Year",
                      this.state.year,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label>Comments</label>
                    <textarea
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.comments}
                      name="comments"
                      onChange={this.setFormField.bind(this)}
                      placeholder="Comments"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>Attachment</label>
                    <div className="choose-file-section">
                      <label httmlFor="aqqQuestionImageFile">
                        <input type="file" id="aqqQuestionImageFile" onChange={this.uploadFile} />
                        <i className='uil-file' /> Choose File
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-light-secondary btn-xs" data-dismiss="modal">
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">Close</span>
                  </button>&nbsp;
                      <button type="button" className="btn btn-primary ml-1 btn-xs" onClick={() => this.save()}>
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ToppersComponent;