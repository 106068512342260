import React from 'react'
import './progress-card.scss'

const subjects = [
  {
    name: 'First Language',
    grade: 'A1',
    gradePoints: 9,
    gradePointsInWords: 'Nine'
  },
  {
    name: 'Subject One',
    grade: 'A1',
    gradePoints: 9,
    gradePointsInWords: 'Nine'
  },
  {
    name: 'Subject Two',
    grade: 'A1',
    gradePoints: 9,
    gradePointsInWords: 'Nine'
  },
  {
    name: 'Subject Three',
    grade: 'A1',
    gradePoints: 9,
    gradePointsInWords: 'Nine'
  },
  {
    name: 'Subject Four',
    grade: 'A1',
    gradePoints: 9,
    gradePointsInWords: 'Nine'
  }
]

const grades = [
  {
    range: '91-100',
    grade: 'A1'
  },
  {
    range: '81-90',
    grade: 'A2'
  },
  {
    range: '71-80',
    grade: 'B1'
  },
  {
    range: '61-70',
    grade: 'B2'
  },
  {
    range: '51-60',
    grade: 'C1'
  },
  {
    range: '41-50',
    grade: 'C2'
  },
  {
    range: '33-40',
    grade: 'D'
  },
  {
    range: '32 & Below',
    grade: 'E (Needs improvement)'
  }
]
const ProgressCard = () => {
  return (
    <div className='progress-card-main-container'>
      <div className='card-content'>
        <div className='pc-header'>
          <div className='pc-left-side'>
            <img className='school-image' src="/assets/images/school.png" />
          </div>
          <div className='pc-right-side'>
            <h4 className='school-name'>Brilliant Grammar High School</h4>
            <h4 className='affliation-name'>Affiliation to CBSE, Affilication No: 123112</h4>
            <p className='address'>Address: #3-4-52, Barkatpura, Kachiguda</p>
            <p className='address'>Hyderabad, Telangana - 500027</p>
            <p className='address'>Ph: 9948241288  mail: contact@bghs.com</p>
          </div>
        </div>
        <div className='pc-body'>
          <div className='pc-body-header'>
            <p>Report Card</p>
          </div>
          <div className='pc-student-details-container'>
            <div className='pcb-left-side'>
              <p>Admission No: <span className='value'>12312</span></p>              
              <p>Name: <span className='value'>Santhosh Korimi</span></p>
              <p>Father's Name: <span className='value'>Ravi Korimi</span></p>
            </div>
            <div className='pcb-right-side'>
              <p>Academic Year: <span className='value'>2019-20</span></p>              
              <p>Date of Birth: <span className='value'>14-07-1995</span></p>
              <p>Class/Section: <span className='value'>8A</span></p>
            </div>
          </div>
          <div className='pc-student-results-table'>
            <div className='table-header'>
              <div className='table-tr'>Subject</div>
              <div className='table-tr'>Grade</div>
              <div className='table-tr'>
                <div className='inner-heading'>Grade Points</div>
                <div className='inner-div'>
                  <div className='table-tr-inner'>In Figures</div>
                  <div className='table-tr-inner'>In Words</div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {subjects && subjects.map((s, index) => {
                const {name, grade, gradePoints, gradePointsInWords} = s;
                return (
                  <div key={`subject-row-${index}`} className='subject-details-row'>
                    <div className='table-tr'>{name}</div>
                    <div className='table-tr'>{grade}</div>
                    <div className='table-tr'>
                      <div className='inner-div'>
                        <div className='table-tr-inner'>{gradePoints}</div>
                        <div className='table-tr-inner'>{gradePointsInWords}</div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='pc-extra-info-container'>
            <div className='pce-left-side'>
              <div className='pce-header'>
                <p>Attendance</p>
              </div>
              <div className='pce-body'>
                <div className='pce-row'>
                  <p>Total working days:</p><p className='value'>264</p>
                </div>
                <div className='pce-row'>
                  <p>Days attended:</p><p className='value'>254</p>
                </div>
                <div className='pce-row'>
                  <p>Days absent:</p><p className='value'>10</p>
                </div>
                <div className='pce-row'>
                  <p>Days late:</p><p className='value'>4</p>
                </div>
              </div>
            </div>
            <div className='pce-right-side'>
              <div className='pce-header'>
                <p>Class Teacher's Remarks</p>
              </div>
              <div className='pce-body'>
                <p className='remarks-desc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
              </div>
            </div>
          </div>
          <div className='pc-instructions-container'>
            <h4>Instructions</h4>
            <p>Grading scale for scholastic areas: Gradings are awarded oon a 10-point grading scale as follows</p>
            <div className='pc-grading-info-table'>
              <div className='table-header'>
                <div>Marks Range</div>
                <div>Grade</div>
              </div>
              <div className='table-body'>
                {grades && grades.map((g, index) => {
                  const { range, grade } = g;
                  return (
                    <div className='table-body-tr'>
                      <div>{range}</div>
                      <div>{grade}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgressCard