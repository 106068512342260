import React from "react";
import swal from 'sweetalert2';
// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
import moment from "moment";
import SimpleReactValidator from 'simple-react-validator';
import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { getUserData, get, create, uploadToS3, getOptionsData } from "../../../utils";
import { Multiselect } from 'multiselect-react-dropdown';
import ImageSliderComponent from "./image-slider";
import NoResults from '../../shared/no-results/no-results';
import './doubts.scss'
import { WithTranslations } from "../../../i18n/withTranslations";

class DoubtsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      userType: "",
      userID: "",
      classID: "",
      profilePicUrl: "",
      subjects: [],
      chapters: [],
      selectedFaculties: [],
      selectedChapters: [],
      topics: [],
      showSection: 'list',
      faculties: [],
      title: "",
      description: "",
      media: [],
      doubts: [],
      selectedDoubt: {},
      comment: "",
      commentMedia: [],
      uploading: false
    }
    this.uploadMedia = this.uploadMedia.bind(this);
    this.commentMedia = this.commentMedia.bind(this);
    this.doubtValidator = new SimpleReactValidator({});
    this.commentValidator = new SimpleReactValidator({});
  }

  assignUserData() {
    let { firstName, lastName, userType, userID, classID } = getUserData();
    this.setState({ firstName, lastName, userType, userID, classID });
    this.getDoubts();
  }

  componentDidMount() {
    this.assignUserData();
  }

  getDoubts() {
    get(baseUrl + "doubts/list")
      .then((response) => {
        this.setState({
          doubts: response.data.data
        })
      })
  }

  uploadMedia(event) {
    let media = this.state.media;
    media.push(event.target.files[0]);
    this.setState({
      media
    })
  }

  onClickAdd() {
    this.setState({ showSection: 'new-doubt' });
    this.getSubjects();
    this.getFaculties();
  }

  getFaculties() {
    get(baseUrl + "users/list/faculty")
      .then((response) => {
        this.setState({
          faculties: getOptionsData(response.data.data)
        })
      })
      .catch((error) => {
      });
  }

  getSubjects() {
    get(baseUrl + "subjects/list/" + this.state.classID)
      .then((response) => {
        this.setState({
          subjects: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  getChaptersList(classID, subjectID) {
    get(baseUrl + "chapters/list/" + (this.state.classID || classID) + "/" + (this.state.subjectID || subjectID))
      .then((response) => {
        this.setState({
          chapters: getOptionsData(response.data.data)
        })

      })
      .catch((error) => {
        console.log(error)
      });
  }

  getTopicsList(classID, subjectID, chapters) {
    create(baseUrl + "topics/list/" + (this.state.classID || classID) + "/" + (this.state.subjectID || subjectID), 'post', { chapterIDs: chapters })
      .then((response) => {
        this.setState({
          topics: getOptionsData(response.data.data)
        })
      })
      .catch((error) => {
        console.log(error)
      });
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })

    if (event.target.name === 'subjectID') {
      this.getChaptersList(this.state.classID, event.target.value)
    }

  }

  onChangeChapters(selectedList, selectedItem) {
    this.setState({ selectedChapters: selectedList })
    let ids = [];
    selectedList.forEach((chapter) => {
      ids.push(chapter.id)
    })

    this.getTopicsList(this.state.classID, this.state.subjectID, ids)
  }

  onChangeTopics(selectedList, selectedItem) {
    this.setState({ selectedTopics: selectedList })
  }

  onChangeFaculties(selectedList, selectedItem) {
    this.setState({ selectedFaculties: selectedList })
  }

  onClickSubmit() {
    let promises = [], media = [];

    this.setState({
      uploading: true
    })
    this.state.media.forEach((file) => {
      let fileName = "doubts/" + this.state.classID + "/" + this.state.subjectID + "/" + this.state.userID + "/" + Date.now() + "/" + file.name;
      media.push({
        fileType: file.type.split("/")[0],
        fileKey: fileName,
        name: file.name
      })
      promises.push(new Promise((resolve, reject) => {
        resolve(uploadToS3(fileName, file, file.type));
      }))
    })

    Promise.all(promises)
      .then((response) => {

        let url = baseUrl + "doubts/";
        if (this.state.doubtID)
          url += this.state.doubtID;
        else
          url += 'add';

        return create(url, this.state.doubtID ? 'put' : 'post', {
          title: this.state.title,
          description: this.state.description,
          classID: this.state.classID,
          subjectID: this.state.subjectID,
          chapters: this.state.selectedChapters,
          topics: this.state.selectedTopics,
          tags: this.state.selectedFaculties,
          media
        })
      })
      .then((response) => {
        swal.fire({
          title: "Info",
          text: response.data.message,
          icon: "warning"
        });
        this.getDoubts();
        this.setState({
          uploading: false,
          showSection: 'list',
          selectedFaculties: [],
          selectedChapters: [],
          topics: [],
          faculties: [],
          title: "",
          description: "",
          media: []
        })
      })
      .catch((error) => {
      });
  }

  onSelectDoubt(doubt) {
    this.setState({
      selectedDoubt: doubt,
      showSection: 'doubt-details'
    })
  }

  removeMedia(sIndex, name) {
    let submissionMedia = this.state[name];

    submissionMedia.splice(sIndex, 1);
    this.setState({
      [name]: submissionMedia
    })
  }

  commentMedia(event) {
    let media = this.state.media;
    media.push(event.target.files[0]);
    this.setState({
      commentMedia: media
    })
  }

  onComment() {
    let promises = [], media = [];

    this.state.commentMedia.forEach((file) => {
      let fileName = "doubts/comments/" + this.state.userID + "/" + this.state.selectedDoubt._id + "/" + Date.now() + "/" + file.name;
      media.push({
        fileType: file.type.split("/")[0],
        fileKey: fileName,
        name: file.name
      })

      promises.push(new Promise((resolve, reject) => {
        resolve(uploadToS3(fileName, file, file.type));
      }))
    })

    let comment = {
      comment: this.state.comment,
      media,
      commentedBy: this.state.userID
    }

    let comments = this.state.selectedDoubt.comments;

    comments.push(comment)

    Promise.all(promises)
      .then((response) => {

        return create(baseUrl + "doubts/" + this.state.selectedDoubt._id, 'put', {
          comments
        })
      })
      .then((response) => {
        this.getSelectedDoubt(this.state.selectedDoubt._id)
      })
      .catch((error) => {
      });
  }

  getSelectedDoubt() {
    get(baseUrl + "doubts/list/" + this.state.selectedDoubt._id)
      .then((response) => {
        this.setState({
          selectedDoubt: response.data.data
        })
      })
  }

  updateHelpful(commentID) {
    create(baseUrl + "doubts/comments/" + this.state.selectedDoubt._id, 'put', {
      commentID: commentID
    })
      .then((response) => {
        this.setState({
          selectedDoubt: response.data.data
        })
      })
  }

  render() {
    const { userType } = this.state;
const {tid}=this.props;
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper"className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{tid("Questions")}</h2>
            </div>
            <div className="app-page-header-right">
              <button type="button" className="app-add-option-button" onClick={() => this.onClickAdd()}>
                <i className="uil-doubt"></i> {tid("Ask Question")}
              </button>
            </div>
          </div>
          {this.state.showSection === 'list' && <div className='ac-doubts-list-container'>
            {this.state.doubts.map((doubt, index) => {
              return (
                <div key={"doubts-" + index} className='ac-doubt-list-card'>
                  <p className="doubt-heading pointer" ><span onClick={() => this.onSelectDoubt(doubt)}> {doubt.title} </span></p>
                  <div className="row col-lg-12 extra-details-section">
                    <p className="col-lg-3 np">{tid("By")}: {doubt.createdBy.name}</p>
                    <p className="col-lg-5 np"> {(doubt.tags.length > 0) && tid("Tagged:")} {
                      doubt.tags.map((faculty, k) => {
                        return (
                          <span key={"faculty-" + index + "-" + k}>{faculty.userID.name}, </span>
                        )
                      })
                    } </p>
                    <p className="col-lg-2 np">{doubt.answered ? tid('Answered') : tid('Not Answered')}</p>
                    <p className="col-lg-2 np">{tid("Posted on")}: {moment(doubt.createdAt).format('DD MMM YYYY')}</p>
                  </div>
                  <div className="row col-lg-12">
                    <div className="col-lg-3 np">
                      <small>{doubt.media.length} {tid("Attachments")}</small>
                    </div>
                    <div className="col-lg-9 float-right pull-right np">
                      <nav className="np small-text" id="breadcrumbs">
                        <ul>
                          {doubt.classID && <li><a> {doubt.classID.name}</a></li>}
                          {doubt.subjectID && <li><a> {doubt.subjectID.name}</a></li>}
                          <li><a> {
                            doubt.chapters.map((chapter, k) => {
                              return (
                                <span key={"chapter-" + index + "-" + k}>{chapter.chapterID.name}, </span>
                              )
                            })
                          }</a>
                          </li>
                          <li><a> {
                            doubt.topics.map((topic, k) => {
                              return (
                                <span key={"topic-" + index + "-" + k}>{topic.topicID.name}, </span>
                              )
                            })
                          }</a></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              )})
            }
            {!this.state.doubts.length && <NoResults title={tid("No Doubts Found")} /> }
            </div>}
          {/* {this.state.showSection === 'list' && <div className="uk-child-width-2-2@m uk-grid-small uk-grid-match" uk-grid={"true"}>
            {this.state.doubts.map((doubt, index) => {
              return (<div key={"doubts-" + index}>
                <div className="uk-card uk-card-default uk-card-body p-2 pl-3">
                  <p className="bold pointer" ><span onClick={() => this.onSelectDoubt(doubt)}> {doubt.title} </span></p>
                  <div className="row col-lg-12">
                    <small className="col-lg-3 np">By: {doubt.createdBy.name}</small>
                    <small className="col-lg-5 np"> {(doubt.tags.length > 0) && "Tagged:"} {
                      doubt.tags.map((faculty, k) => {
                        return (
                          <span key={"faculty-" + index + "-" + k}>{faculty.userID.name}, </span>
                        )
                      })
                    } </small>
                    <small className="col-lg-2 np">{doubt.answered ? 'Answered' : 'UnAnswered'}</small>
                    <small className="col-lg-2 np">Posted on: {moment(doubt.createdAt).format('DD MMM YYYY')}</small>
                  </div>
                  <div className="row col-lg-12">
                    <div className="col-lg-3 np">
                      <small>{doubt.media.length} Images</small>
                    </div>
                    <div className="col-lg-9 float-right pull-right np">
                      <nav className="np small-text" id="breadcrumbs">
                        <ul>
                          {doubt.classID && <li><a> {doubt.classID.name}</a></li>}
                          {doubt.subjectID && <li><a> {doubt.subjectID.name}</a></li>}
                          <li><a> {
                            doubt.chapters.map((chapter, k) => {
                              return (
                                <span key={"chapter-" + index + "-" + k}>{chapter.chapterID.name}, </span>
                              )
                            })
                          }</a>
                          </li>
                          <li><a> {
                            doubt.topics.map((topic, k) => {
                              return (
                                <span key={"topic-" + index + "-" + k}>{topic.topicID.name}, </span>
                              )
                            })
                          }</a></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>)
            })}
          </div>} */}

          {this.state.showSection === 'new-doubt' && <div className="uk-child-width-2-2@m uk-grid-small uk-grid-match" uk-grid={"true"}>
            <div>
              <div className="uk-card uk-card-default uk-card-body">
                <div className='go-back-section'>
                  <span onClick={() => this.setState({ showSection: 'list' })}><i className='uil-arrow-left' /> {tid("Back")}</span>
                </div>
                <h6 className="">{tid("New Question")}</h6>
                <div className="add-new-doubt-form-container">
                  <div className="and-left-side">
                    <div className="form-group">
                      <div className="">
                        <label>{tid("Title")}:</label>
                      </div>
                      <div className="">
                        <input className="form-control"
                          placeholder={tid("Question Title")}
                          value={this.state.title}
                          name="title"
                          onChange={this.setFormField.bind(this)}
                        />
                        {this.doubtValidator.message(
                          "Title",
                          this.state.title,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="">
                        <label>{tid("Description")}:</label>
                      </div>
                      <div className="">
                        <textarea className="form-control" placeholder={tid("Question Description")} value={this.state.description}
                          name="description"
                          onChange={this.setFormField.bind(this)}></textarea>
                        {this.doubtValidator.message(
                          "Description",
                          this.state.description,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="">
                        <label>{tid("Tag Faculty:")}</label>
                      </div>
                      <div className="">
                        <Multiselect
                          className="form-control"
                          options={this.state.faculties} // Options to display in the dropdown
                          value={this.state.selectedFaculties} // Preselected value to persist in dropdown
                          selectedValues={this.state.selectedFaculties}
                          onSelect={this.onChangeFaculties.bind(this)} // Function will trigger on select event
                          onRemove={this.onChangeFaculties.bind(this)} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <h6>{tid("Attach reference images/videos:")}</h6>
                      {this.state.media.map((submission, sIndex) => {
                        return (<div className="submission-file" key={"submission-media-" + sIndex}>
                          <p>{submission.name} <span className="pointer pull-right float-right" onClick={() => this.removeMedia(sIndex, 'media')} >X</span> </p>
                        </div>)
                      })}
                      {this.state.submissionStatus !== 'verified' && <div className="choose-file-section">
                        <label httmlFor="aqqQuestionImageFile">
                          <input type="file" id="aqqQuestionImageFile" onChange={this.uploadMedia} />
                          <i className='uil-plus' /> {tid("Add Image/ Video")}
                        </label>
                      </div>}
                    </div>
                  </div>
                  <div className="and-right-side">
                    <div className="form-group">
                      <div className="">
                        <label>{tid("Subject")}:</label>
                      </div>
                      <div className="">
                        <select className="form-control" placeholder="Tag Faculty" name="subjectID" onChange={this.setFormField.bind(this)}>
                          <option value="">{tid("Select Subject")}</option>
                          {this.state.subjects.map((subject, index) => {
                            return (
                              <option key={"subject-" + index} value={subject._id}>{subject.name}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="">
                        <label>{tid("Chapters")}:</label>
                      </div>
                      <div className="">
                        <Multiselect
                          className="form-control"
                          options={this.state.chapters} // Options to display in the dropdown
                          value={this.state.selectedChapters} // Preselected value to persist in dropdown
                          selectedValues={this.state.selectedChapters}
                          onSelect={this.onChangeChapters.bind(this)} // Function will trigger on select event
                          onRemove={this.onChangeChapters.bind(this)} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="">
                        <label>{tid("Topics")}:</label>
                      </div>
                      <div className="">
                        <Multiselect
                          className="form-control"
                          options={this.state.topics} // Options to display in the dropdown
                          value={this.state.selectedTopics} // Preselected value to persist in dropdown
                          selectedValues={this.state.selectedTopics}
                          onSelect={this.onChangeTopics.bind(this)} // Function will trigger on select event
                          onRemove={this.onChangeTopics.bind(this)} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      </div>
                    </div>
                    <div className="form-group submit-button-section">
                      <button className="btn btn-sm btn-default" onClick={() => this.onClickSubmit()}>{this.state.uploading && <IconLoader size={2} text="" color={"#fff"}></IconLoader>}&nbsp; {tid("Submit")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }

          {this.state.showSection === 'doubt-details' && <div className='doubt-details-page-container'>
            <div className='go-back-section'>
              <span onClick={() => this.setState({ showSection: 'list' })}><i className='uil-arrow-left' /> {tid("Back")}</span>
            </div>
            <div className='doubt-basic-info-container'>
              <h5 className='doubt-heading'>{this.state.selectedDoubt.title}</h5>
              <p className='doubt-desc'>{this.state.selectedDoubt.description}</p>
              <div className="doubts-meadia-attachments">
                <h6>{tid("References")}:</h6>
                <ImageSliderComponent media={this.state.selectedDoubt.media}></ImageSliderComponent>
              </div>
              <div className="doubts-extra-info-section row col-lg-12">
                <p className="col-lg-3">{tid('Bys')}: {this.state.selectedDoubt.createdBy.name}</p>
                <p className="col-lg-5 np"> {(this.state.selectedDoubt.tags.length > 0) && "Tagged:"} {
                  this.state.selectedDoubt.tags.map((faculty, k) => {
                    return (
                      <span key={"selected-faculty-" + k}>{faculty.userID.name}, </span>
                    )
                  })
                } </p>
                <p className="col-lg-2 np">{this.state.selectedDoubt.answered ? 'Answered' : 'Not Answered'}</p>
                <p className="col-lg-2 np">{tid("Posted on")}: {moment(this.state.selectedDoubt.createdAt).format('DD MMM YYYY')}</p>
              </div>
            </div>
            <div className='doubt-answers-container'>
              <h5 className='answer-heading'>{tid("Answers")}</h5>
              <div className="answer-card-list-container">
                {this.state.selectedDoubt.comments.map((comment, index) => {
                  return (<div className='answer-card-flex' key={"comment-" + index}>
                    <div className="as-left-side">
                      <div><img src={comment.commentedBy.profilePicUrl || "/assets/images/avatars/user.svg"} alt="" />
                      </div>
                    </div>
                    <div className="as-right-side">
                      <div className="comment-by-info-section">
                        <h5 className='commentor-name'>
                          {comment.commentedBy.name}
                        </h5>
                        <p className='date'>{moment(comment.createdAt).format('DD MMM YYYY')}</p>
                        <p className='user-type'>{comment.commentedBy.userType}</p>
                      </div>
                      <p className='comment-desc'>{comment.comment}</p>
                      {comment.media  && comment.media.length > 0 && (
                        <div className='referamce-media-container'>
                          <h6 className="row col-lg-12 np">{tid("References")}:</h6>
                          <div className="row col-lg-12">
                            <ImageSliderComponent media={comment.media}></ImageSliderComponent>
                          </div>
                        </div>
                      )}
                      {(this.state.userID === this.state.selectedDoubt.createdBy._id) && <div className="comment-footer">
                        <span> {tid("Was this review helpful?")} </span>
                        <button className={comment.helpful ? "btn btn-default" : ""} onClick={() => this.updateHelpful(comment._id)}> {tid("Yes")} </button>
                      </div>}
                    </div>
                  </div>)
                })}
                {!this.state.selectedDoubt.comments.length && <NoResults title={tid("No Answers Found")} /> }
              </div>
            </div>
            {this.state.selectedDoubt.canAnswer && <div className='doubt-submit-answer-section'>
              <h4 className="heading">{tid("Submit Answer")}:</h4>
              <div className='subimt-answer-form'>
                <div className='sa-left-side'>
                  <div className="user-image">
                    <img src={this.state.profilePicUrl || "/assets/images/profile.svg"} alt="" />
                  </div>
                </div>
                <div className='sa-right-side'>
                  <div className="col-lg-10">
                    <textarea className="form-control" placeholder="Type your answer.." value={this.state.comment}
                      name="comment"
                      onChange={this.setFormField.bind(this)}></textarea>
                    {this.commentValidator.message(
                      "Comment",
                      this.state.comment,
                      "required"
                    )}
                    <h6 className='media-heading'>{tid("Media Attachments")}:</h6>
                    {this.state.commentMedia.map((media, sIndex) => {
                      return (<div className="media-file" key={"media-media-" + sIndex}>
                        <p>{media.name} <span className="pointer pull-right float-right" onClick={() => this.removeMedia(sIndex, 'commentMedia')} >X</span> </p>
                      </div>)
                    })}
                    <input type="file" name="file" className="form-control col-lg-6" onChange={this.commentMedia} />
                  </div>
                  <div className='submit-button'>
                    <button className="btn btn-default btn-sm" onClick={() => this.onComment()}>{tid("Submit")}</button>
                  </div>
                </div>
              </div>
            </div>}
          </div>}
          {/* {this.state.showSection === 'doubt-details' && <div className="uk-child-width-2-2@m uk-grid-small uk-grid-match mb-3" uk-grid={"true"}>
            <div>
              <div className="uk-card uk-card-default uk-card-body p-2 pl-3">
                <h5 className="bold"><small onClick={() => this.setState({ showSection: 'list' })}><i className="icon-feather-arrow-left"></i></small> {this.state.selectedDoubt.title}</h5>
                <p>{this.state.selectedDoubt.description}</p>

                <div className="row col-lg-12">
                  <h6 className="row col-lg-12 np">Reference Media Attachments:</h6>
                  <ImageSliderComponent media={this.state.selectedDoubt.media}></ImageSliderComponent>
                </div>
                <div className="row col-lg-12">
                  <small className="col-lg-3 np">By: {this.state.selectedDoubt.createdBy.name}</small>
                  <small className="col-lg-5 np"> {(this.state.selectedDoubt.tags.length > 0) && "Tagged:"} {
                    this.state.selectedDoubt.tags.map((faculty, k) => {
                      return (
                        <span key={"selected-faculty-" + k}>{faculty.userID.name}, </span>
                      )
                    })
                  } </small>
                  <small className="col-lg-2 np">{this.state.selectedDoubt.answered ? 'Answered' : 'UnAnswered'}</small>
                  <small className="col-lg-2 np">Posted on: {moment(this.state.selectedDoubt.createdAt).format('DD MMM YYYY')}</small>
                </div>

                <h4 className="pt-3">Answers:</h4>
                <ul className="no-style">
                  {this.state.selectedDoubt.comments.map((comment, index) => {
                    return (<li key={"comment-" + index}>
                      <div className="row col-lg-12">
                        <div className="col-lg-1 text-center">
                          <div className="comments-avatar"><img src={comment.commentedBy.profilePicUrl || "/assets/images/avatars/user.svg"} alt="" />
                          </div>
                        </div>
                        <div className="col-lg-11 np">
                          <div>
                            <div className="comment-by">{comment.commentedBy.name} <span>{comment.commentedBy.userType}</span></div>
                            <p>{comment.comment}</p>
                            <h6 className="row col-lg-12 np">Reference Media Attachments:</h6>
                            <div className="row col-lg-12">
                              <ImageSliderComponent media={comment.media}></ImageSliderComponent>
                            </div>
                            {(this.state.userID === this.state.selectedDoubt.createdBy._id) && <div className="comment-footer">
                              <span> Was this review helpful? </span>
                              <button className={comment.helpful ? "btn btn-default" : ""} onClick={() => this.updateHelpful(comment._id)}> Yes </button>
                            </div>}
                          </div>
                        </div>
                      </div>
                    </li>)
                  })}
                </ul>
                {this.state.selectedDoubt.canAnswer && <h4 className="pt-3">Submit Answer:</h4>}
                {this.state.selectedDoubt.canAnswer && <div className="col-lg-12">
                  <div className="row col-lg-12">
                    <div className="col-lg-1">
                      <div className="comments-avatar"><img src={this.state.profilePicUrl || "/assets/images/profile.jpg"} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-11">
                      <div className="row">
                        <div className="col-lg-10">
                          <textarea className="form-control" placeholder="Type your answer.." value={this.state.comment}
                            name="comment"
                            onChange={this.setFormField.bind(this)}></textarea>
                          {this.commentValidator.message(
                            "Comment",
                            this.state.comment,
                            "required"
                          )}
                          <h6>Media Attachments:</h6>
                          {this.state.commentMedia.map((media, sIndex) => {
                            return (<div className="media-file" key={"media-media-" + sIndex}>
                              <p>{media.name} <span className="pointer pull-right float-right" onClick={() => this.removeMedia(sIndex, 'commentMedia')} >X</span> </p>
                            </div>)
                          })}
                          <input type="file" name="file" className="form-control col-lg-6" onChange={this.commentMedia} />
                        </div>
                        <div className="col-lg-2">
                          <button className="btn btn-default btn-sm" onClick={() => this.onComment()}>Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
          } */}
        </div>
      </div>
    );
  }
}

export default WithTranslations(DoubtsComponent);