import React from "react";
import {Link} from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { baseUrl } from "../../../config";

import axios from "axios";
export default class SchoolCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: props.schoolCode || "",
      message: "",
    };

    // sessionStorage.clear();
    this.schoolLogin = this.schoolLogin.bind(this);
    this.schoolCodeValidator = new SimpleReactValidator({});
  }

  schoolLogin() {
    if (this.schoolCodeValidator.allValid()) {
      axios({
        url: baseUrl + "merchants/validate",
        method: "post",
        responseType: "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          code: this.state.code,
        },
      })
        .then((response) => {
          if (response.data.success && response.data.data) {
            localStorage.setItem("codetoken", response.data.data);
            localStorage.setItem("schoolcode", this.state.code);
            this.props.showLogin(this.state.code);
          } else {
            this.setState({
              message: response.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.schoolCodeValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  render() {
    return (
      <div className="login-form-container-main">
        <div className='image-section-xs'>
          <Link to={"/"}>
            <img
              className="main-logo"
              src={"/assets/images/logo.jpg"}
              alt="Logo"
            />
          </Link>
        </div>
        <div className="uk-form-group pt-5 mt-5">
          <label className="uk-form-label">
            {" "}
            School / College / Institute / Tutor Code:{" "}
          </label>
          <div className="uk-position-relative w-100">
            <span className="uk-form-icon">
              <i className="icon-feather-type"></i>
            </span>
            <input
              className="uk-input"
              value={this.state.code}
              name="code"
              onChange={this.setFormField.bind(this)}
              placeholder="Enter Code"
            />
          </div>
          {this.schoolCodeValidator.message(
            "Code",
            this.state.code,
            "required"
          )}
        </div>
        <div className="uk-width-auto@s">
          <span className="red-text text-center">{this.state.message}</span>
          <button
            type="submit"
            onClick={this.schoolLogin.bind(this)}
            className="btn btn-default login-button"
          >
            Validate
          </button>
        </div>
      </div>
    );
  }
}
