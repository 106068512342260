import React from "react";
import swal from 'sweetalert2';
import SimpleReactValidator from 'simple-react-validator';
import {  Link } from "react-router-dom";
// import * as $ from "jquery";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { getUserData, get, create, uploadToS3 } from "../../../utils";
import { IconLoader } from "../../shared/loader";
// import { Multiselect } from 'multiselect-react-dropdown';
import './assignments.scss';

class AssignmentsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classID: '',
      subjectID: '',
      chapterID: '',
      topicID: '',
      studentID: '',
      assignmentID: '',
      showSection: 'assignments',
      students: [],
      chapters: [],
      topics: [],
      assignments: [],
      assignment: {},
      title: '',
      description: '',
      marks: '',
      file: '',
      fromDate: '',
      toDate: '',
      userType: '',
      userID: '',

      submissionID: '',
      submissionDescription: '',
      submissionMedia: [],
      submissionStatus: '',
      files: [],
      uploading: false
    }

    this.uploadFile = this.uploadFile.bind(this);
    this.uploadMedia = this.uploadMedia.bind(this);
    this.save = this.save.bind(this);
    this.assignmentValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.assignmentValidator.allValid()) {
      this.setState({
        uploading: true
      })
      let fileName = "assignments/" + this.state.assignmentFor + "/" + Date.now() + "/" + this.state.file.name;
      const uploadPDF = new Promise((resolve, reject) => {
        resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
      })

      const fileUploader = new Promise((resolve, reject) => {

        let url = baseUrl + "assignments/";
        if (this.state.assignmentID)
          url += this.state.assignmentID;
        else
          url += 'add';

        resolve(create(url, this.state.assignmentID ? 'put' : 'post', {
          classID: this.state.classID,
          subjectID: this.state.subjectID,
          chapterID: this.state.chapterID,
          topicID: this.state.topicID,
          assignmentFor: this.state.assignmentFor,
          studentID: this.state.studentID,
          title: this.state.title,
          description: this.state.description,
          marks: this.state.marks,
          fileKey: this.state.file ? fileName : undefined,
          fileType: this.state.file ? this.state.file.type.split("/")[0] : undefined,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate
        }))
      })

      Promise.all([
        uploadPDF, fileUploader
      ])
        .then((response) => {
          this.getAssignmentsList(this.state.classID, this.state.subjectID)
          this.setState({
            uploading: false,
            studentID: '',
            title: '',
            description: '',
            marks: '',
            file: '',
            fromDate: '',
            toDate: '',
            showSection: 'assignments'
          })
        })
        .catch((error) => {
        });
    } else {
      this.assignmentValidator.showMessages();
      this.forceUpdate();
    }
  }

  onSubmitAssignment() {
    let promises = [], files = this.state.files;

    this.state.submissionMedia.forEach((submission) => {
      let fileName = "assignments/submission/" + this.state.studentID + "/" + Date.now() + "/" + submission.name;
      files.push({
        fileType: submission.type.split("/")[0],
        fileKey: fileName,
        name: submission.name
      })
      promises.push(new Promise((resolve, reject) => {
        resolve(uploadToS3(fileName, submission, submission.type));
      }))
    })

    Promise.all(promises)
      .then((response) => {
        let url = baseUrl + "assignments/";
        if (this.state.submissionID)
          url += "submission/" + this.state.submissionID;
        else
          url += 'submit';

        return create(url, this.state.submissionID ? 'put' : 'post', {
          assignmentID: this.state.assignmentID,
          description: this.state.submissionDescription,
          files
        })
      })
      .then((response) => {
        swal.fire({
          title: "Info",
          text: response.data.message,
          icon: "warning"
        });
      })
      .catch((error) => {
      });
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] })
  }

  uploadMedia(event) {
    let submissionMedia = this.state.submissionMedia;
    submissionMedia.push(event.target.files[0]);
    this.setState({
      submissionMedia
    })
  }

  removeMedia(sIndex, name) {
    let submissionMedia = this.state[name];

    submissionMedia.splice(sIndex, 1);
    this.setState({
      [name]: submissionMedia
    })
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
    if (event.target.name === 'chapterID')
      this.getTopicsList(this.state.classID, this.state.subjectID, event.target.value)
    if (event.target.name === 'assignmentFor') {
      this.getStudentsList(this.state.classID);
    }
  }

  getStudentsList(classID) {
    get(baseUrl + "users/list/student/" + classID)
      .then((response) => {
        this.setState({
          students: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  onClick(type) {
    this.setState({ showSection: type })
  }

  onClickSubmitAssignment(type, assignment) {
    this.setState({
      showSection: type,
      assignment
    })
  }

  getTopicsList(classID, subjectID, chapterID) {
    get(baseUrl + "topics/list/" + (this.state.classID || classID) + "/" + (this.state.subjectID || subjectID) + "/" + (this.state.chapterID || chapterID))
      .then((response) => {
        this.setState({
          topics: response.data.data
        })
      })
      .catch((error) => {
        console.log(error)
      });
  }

  getChaptersList(classID, subjectID) {
    get(baseUrl + "chapters/list/" + classID + "/" + subjectID)
      .then((response) => {
        this.setState({
          chapters: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  getAssignmentsList(classID, subjectID) {
    get(baseUrl + "assignments/list/" + classID + "/" + subjectID)
      .then((response) => {
        this.setState({
          assignments: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });

    if (userType === 'student') {
      this.setState({
        studentID: userID
      })
    }
  }

  componentDidMount() {
    this.assignUserData();
    let { classID, subjectID } = this.props.match.params;
    this.setState({
      classID,
      subjectID
    })
    this.getChaptersList(classID, subjectID);
    this.getAssignmentsList(classID, subjectID);

  }

  onClickAssignment(assignment) {

    this.setState({
      assignmentID: assignment._id,
      submissionID: '',
      files: []
    })
  }

  onClickEditAssignment(assignment, submission) {
    this.setState({
      assignmentID: assignment._id,
      submissionDescription: submission.description,
      submissionID: submission._id,
      files: submission.files,
      submissionStatus: submission.status
    })
  }

  handleFieldEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const index = splitted[1];
      const value = event.target.value;
      let assignment = this.state.assignment;
      assignment.submissions[parseInt(index, 10)].marks = value;
      this.setState({
        assignment
      })
    }
  }

  submitMarks(index) {
    let submission = this.state.assignment.submissions[index];

    let url = baseUrl + "assignments/submission/" + submission._id;

    create(url, 'put', {
      status: 'verified',
      marks: submission.marks,
      verifiedBy: this.state.userID
    })
      .then((response) => {
        this.setState({
          showSection: 'assignments'
        })
      })
  }

  render() {
    const { userType } = this.state
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper"  className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className='app-assignment-details-page'>
            {this.state.showSection === 'assignments' && <div className="assignment-details-page-container">
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className=""><Link to={"/subjects/"+this.state.classID}><i className='uil-arrow-left' /></Link> Assignments</h2>
                </div>
                <div className='app-page-header-right'>
                  {this.state.userType !== 'student' && this.state.userType !== 'parent' && 
                    <button className="app-add-option-button" onClick={() => this.onClick('new-assignment')}>Add Assignment</button>
                  }
                </div>
              </div>
              <ul className="course-curriculum pt-2 accordion-content-container" uk-accordion="multiple: true">
                {this.state.assignments.map((task, index) => {
                  return (<li key={"task-" + index} className={(index === 0) ? "uk-open" : ""}>
                    <a className="uk-accordion-title" > {task.date} </a>
                    <div className="uk-accordion-content">
                      <ul className="course-curriculum-list">
                        {task.assignments.map((assignment, j) => {
                          return (<li key={"assignment-" + index + "-" + j}> {assignment.title} for <a className="green-font">{assignment.assignmentFor ? ((assignment.assignmentFor === 'student') ? assignment.student.firstName : (assignment.assignmentFor + " Students").toUpperCase()) : 'NA'}</a> {(assignment.submission && assignment.submission.status === 'verified') ? " - " + (assignment.submission.marks + "/" + assignment.marks) + " marks" : ""}
                            {assignment.assignmentFor === 'student' && assignment.submitted && <span> 5/{assignment.marks} Marks </span>} {assignment.submitted && <button className="btn btn-info btn-xxs"> Evaluate </button>} <button className="btn btn-danger btn-xxs pull-right mt-1"> Delete </button>&nbsp;&nbsp;<button className="btn btn-success btn-xxs pull-right mt-1 mr-1"> Edit </button>
                            <br />
                            {assignment.submission && assignment.submission.status && <small>{assignment.submission.status}</small>}
                            {this.state.userType === 'student' && (assignment.submissionsCount === 0) && <button uk-toggle="target: #code" className="btn btn-default btn-xs" onClick={() => this.onClickAssignment(assignment)} >Submit Assignment</button>}
                            {this.state.userType === 'student' && (assignment.submissionsCount > 0) && <button uk-toggle="target: #code" className="btn btn-default btn-xs" onClick={() => this.onClickEditAssignment(assignment._id, assignment.submission)} >Edit Assignment</button>}
                            {(this.state.userType !== 'student') && (assignment.submissionsCount > 0) && <button className="btn btn-success btn-xs" onClick={() => this.onClickSubmitAssignment('view-assignments', assignment)}>View Submissions</button>}
                          </li>)
                        })}
                      </ul>
                    </div>
                  </li>)
                })}
              </ul>
            </div>}
            {this.state.showSection === 'new-assignment' && <div className="add-assignment-form-container">
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className="">
                    <span onClick={() => this.onClick('assignments')}><i className="uil-arrow-left"></i></span> Add Assignment
                  </h2>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row col-lg-12">
                    <div className="col-lg-6">
                      <div className='form-group'>
                        <label>Chapter</label>
                        <select className="form-control" value={this.state.chapterID} name="chapterID" onChange={this.setFormField.bind(this)}>
                          <option value="">Select Chapter</option>
                          {
                            this.state.chapters.map((chapter, index) => {
                              return (<option key={"chapter-" + index} value={chapter._id}>{chapter.name}</option>)
                            })
                          }
                        </select>
                      </div>
                      <div className='form-group'>
                        <label>Chapter</label>
                        <select className="form-control" value={this.state.topicID} name="topicID" onChange={this.setFormField.bind(this)}>
                          <option value="">Select Topic</option>
                          {
                            this.state.topics.map((topic, index) => {
                              return (<option key={"topic-" + index} value={topic._id}>{topic.name}</option>)
                            })
                          }
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">From Date:</label>
                        <input
                          type="date"
                          className="form-control form-control-user"
                          value={this.state.fromDate}
                          name="fromDate"
                          onChange={this.setFormField.bind(this)}
                          placeholder="Assignment From Date"
                        />
                        {this.assignmentValidator.message(
                          "From Date",
                          this.state.fromDate,
                          "required"
                        )}
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">To Date:</label>
                        <input
                          type="date"
                          className="form-control form-control-user"
                          value={this.state.toDate}
                          name="toDate"
                          onChange={this.setFormField.bind(this)}
                          placeholder="Assignment To Date"
                        />
                        {this.assignmentValidator.message(
                          "To Date",
                          this.state.toDate,
                          "required"
                        )}
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Assignment Marks:</label>
                        <input
                          type="number"
                          className="form-control form-control-user"
                          value={this.state.marks}
                          name="marks"
                          onChange={this.setFormField.bind(this)}
                          placeholder="Enter Assignment Marks"
                        />
                        {this.assignmentValidator.message(
                          "Assignment Marks",
                          this.state.marks,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="col-form-label">Assignment For:</label>
                        <select
                          className="form-control form-control-user"
                          value={this.state.assignmentFor}
                          name="assignmentFor"
                          onChange={this.setFormField.bind(this)}
                          placeholder="Select Assignment For"
                        >
                          <option value="">Select Assignment For</option>
                          <option value="all">All</option>
                          <option value="student">Single Student</option>
                        </select>
                        {this.assignmentValidator.message(
                          "Assignment For",
                          this.state.assignmentFor,
                          "required"
                        )}
                      </div>
                      {this.state.assignmentFor === 'student' && <div className="form-group">
                        <label className="col-form-label">Student Name:</label>
                        <select
                          type="text"
                          className="form-control form-control-user"
                          value={this.state.studentID}
                          name="studentID"
                          onChange={this.setFormField.bind(this)}
                          placeholder="Select Student"
                        >
                          <option value="">Select Student</option>
                          {this.state.students.map((student, index) => {
                            return (<option key={"index"} value={student._id}>{student.firstName} {student.lastName}</option>)
                          })}
                        </select>
                        {this.assignmentValidator.message(
                          "Student",
                          this.state.studentID,
                          "required"
                        )}
                      </div>}
                      <div className="form-group">
                        <label className="col-form-label">Assignment Title:</label>
                        <input
                          type="text"
                          className="form-control form-control-user"
                          value={this.state.title}
                          name="title"
                          onChange={this.setFormField.bind(this)}
                          placeholder="Enter Assignment Title"
                        />
                        {this.assignmentValidator.message(
                          "Assignment Title",
                          this.state.title,
                          "required"
                        )}
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Description:</label>
                        <textarea
                          type="text"
                          rows="2"
                          className="form-control form-control-user"
                          value={this.state.description}
                          name="description"
                          onChange={this.setFormField.bind(this)}
                          placeholder="Enter Assignment Description"
                        ></textarea>
                        {/* {this.assignmentValidator.message(
                            "Description",
                            this.state.description,
                            "required"
                            )} */}
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Attachment: </label>
                        <div className="choose-file-section">
                          <label httmlFor="aqqQuestionImageFile">
                            <input type="file" id="aqqQuestionImageFile" onChange={this.uploadFile} />
                            <i className='uil-file' /> Choose File
                          </label>
                        </div>
                        {/* <input type="file" name="myFile" className="form-control" onChange={this.uploadFile} /> */}
                        {/* {this.mediaValidator.message(
                            "File",
                            this.state.file,
                            "required"
                            )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="save-button-container">
                  <button className="btn btn-default grey btn-sm" onClick={() => this.save()}>{this.state.uploading && <IconLoader size={2} text="" color={"#fff"}></IconLoader>} <i className='fa fa-save'/> Save Assignment</button>
                </div>
              </div>
            </div>}
            {this.state.showSection === 'view-assignments' && <div className="view-assignment-submissions-container">
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className=""><span onClick={() => this.onClick('assignments')}><i className="uil-arrow-left"></i></span> View Assignment</h2>
                </div>
                <div className='app-page-header-right'>
                  <span>Total Marks: {this.state.assignment.marks}</span>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  {this.state.assignment && <div className="row col-lg-12">
                    <div className="col-lg-6 np">
                      <h6>{this.state.assignment.title}</h6>
                      <p>{this.state.assignment.description}</p>
                    </div>
                    <div className="col-lg-6 np">
                      <h6>Media:</h6>
                      {this.state.assignment.fileType === 'image' && <img src={this.state.assignment.fileUrl} alt="assignment" />}
                    </div>
                  </div>}
                  <hr />
                  <h6>Submissions</h6>
                  {this.state.assignment.submissions.length &&
                    this.state.assignment.submissions.map((submission, k) => {
                      return (
                        <div key={"submission-index-" + k} className="row col-lg-12">
                          <div className="col-lg-6">
                            <p>Submitted By: {submission.userID.firstName}</p>
                            <p>{submission.description}</p>
                          </div>
                          <div className="col-lg-6">
                            <h6>Media:</h6>
                            {submission.files.map((file, sIndex) => {
                              return (<div className="submission-file" key={"submission-media-" + sIndex}>
                                <a href={file.fileUrl} target="_blank">{file.name}</a>
                              </div>)
                            })}
                          </div>
                          <div className="col-lg-6 np">
                            <h6>Marks:</h6>
                            <div className="">
                              <input
                                type="number"
                                className="uk-form-width-small"
                                value={submission.marks}
                                name={"marks#" + k}
                                onChange={this.handleFieldEdit}
                                placeholder="Enter Marks"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 pt-5">
                            <button className="btn btn-info btn-sm" onClick={() => this.submitMarks(k)}>Submit Marks</button>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>}
          </div>
          <div className="content-body">
            <section id="dashboard-ecommerce">
              <div id="code" uk-offcanvas="flip: true; overlay: true">
                <div className="uk-offcanvas-bar uk-width-large p-0">
                  <div className="pb-0 mr-0 pr-0">
                    <div className="uk-flex uk-flex-between">
                      <div className="card np pb-0 mr-0 pr-0">
                        <div className="card-header">
                          <h6>Submit your Answer <button className="uk-offcanvas-close" type="button" uk-close={"true"}></button></h6>
                        </div>
                        <div className="card-body">
                          <div className="form-group">
                            <textarea
                              type="text"
                              rows="2"
                              disabled={this.state.submissionStatus === 'verified'}
                              className="form-control form-control-user"
                              value={this.state.submissionDescription}
                              name="submissionDescription"
                              onChange={this.setFormField.bind(this)}
                              placeholder="Enter Description"
                            ></textarea>
                            <h6>Media Attachments: </h6>
                            <div className="form-group">
                              {this.state.files.map((file, fIndex) => {
                                return (<div className="submission-file" key={"submission-file-" + fIndex}>
                                  <p>
                                    <a href={file.fileUrl} target="_blank">{file.name}</a> {this.state.submissionStatus !== 'verified' && <span className="pointer pull-right float-right" onClick={() => this.removeMedia(fIndex, 'files')} >X</span>}</p>
                                </div>)
                              })
                              }
                              {this.state.submissionMedia.map((submission, sIndex) => {
                                return (<div className="submission-file" key={"submission-media-" + sIndex}>
                                  <p>{submission.name} {this.state.submissionStatus !== 'verified' && <span className="pointer pull-right float-right" onClick={() => this.removeMedia(sIndex, 'removeMedia')} >X</span>} </p>
                                </div>)
                              })}
                              {this.state.submissionStatus !== 'verified' && <input type="file" name="file" className="form-control" onChange={this.uploadMedia} />}
                            </div>
                          </div>
                        </div>
                        {this.state.submissionStatus !== 'verified' && <div className="card-footer">
                          <button className="btn btn-primary btn-xs" onClick={() => this.onSubmitAssignment()}>Submit</button>
                        </div>}
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default AssignmentsComponent;