import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { remove } from "../../utils";
import { baseUrl } from "../../config";
import Swal from "sweetalert2";

const Delete = ({ url, onDeleteSuccess, onDeleteFailure, size = "sm" }) => {
  const [reqInProgress, setReqInProgress] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [deleteFailed, setDeleteFailed] = useState(false);

  const onSuccess = React.useCallback(
    (response) => {
      setDeleted(true);
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
      if (onDeleteSuccess) onDeleteSuccess(response);
    },
    [onDeleteSuccess]
  );
  const onFailure = React.useCallback((error) => {
    setDeleted(false);
    setDeleteFailed(true);

    if (onDeleteFailure) onDeleteFailure(error);
  });
  const onClickHandler = useCallback(() => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      debugger;
      if (result.value) {
        setReqInProgress(true);
        remove(baseUrl + url)
          .then(onSuccess)
          .catch(onFailure)
          .finally(() => setReqInProgress(false));
      }
    });
  });
  if (reqInProgress) {
    return <i className="fas fa-circle-notch fa-spin" />;
  }
  if (deleted) {
    return <i className="fa fa-check" style={{ color: "green" }} />;
  }
  if (deleteFailed) {
    return <i className="fa fa-times" style={{ color: "red" }} />;
  }
  return (
    <button
      type="button"
      className={`btn btn-danger btn-${size}`}
      onClick={onClickHandler}
    >
      <i className="uil-trash" />
    </button>
  );
};

Delete.propTypes = {
  url: PropTypes.string.isRequired,
  onDeleteSuccess: PropTypes.func,
  onDeleteFailure: PropTypes.func,
  size: PropTypes.string,
};

export default Delete;
