import React from "react";
import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";
import { WithTranslations } from "../../../i18n/withTranslations";

const paras = {
  en: [
    `Augmented reality in education is surging in popularity in schools worldwide. Through augmented reality (AR), educators are able to improve learning outcomes through increased engagement and interactivity. And that’s just for starters. AR even has some surprising advantages over virtual reality (VR).`,
    `Augmented Reality (AR) in education features aspects that enhance learning of abilities like problem-solving, collaboration, and creation to better prepare students for the future. It is also good for traditional pedagogy focused on technical knowledge and proficiencies.`,
    `While AR is still somewhat in its infancy, especially in comparison to virtual reality (VR), it does offer more cost-friendly options to school districts with tighter budgets – while still providing many of the same features and benefits.`,
    `AR’s relative seamlessness of digital objects within the “real world” encourages interactivity and engagement. It maximizes students’ ability to spend their time learning curricular subjects while minimizing the time spent learning how to use the new tech.`,
    `Therefore, AR provides students with opportunities to deepen their knowledge within several areas, including:`,
    `When combined with assignments involving teamwork, AR similarly helps provide new opportunities for students to learn how to communicate and collaborate with one another. It could potentially also be the same technologies they will use in the workforce later on.`,
    `When adopting AR there is also no need for a complete curriculum overhaul: it can be even more effective in supplementing current pedagogical materials by simply adding more contextual experiences. It can be used to just stimulate interest and discussion in different subject areas and be the basis for class activities.`
  ],
  it: [
    `La realtà aumentata nell'istruzione sta diventando sempre più popolare nelle scuole di tutto il mondo. Attraverso la realtà aumentata (AR), gli educatori sono in grado di migliorare i risultati di apprendimento attraverso un maggiore coinvolgimento e interattività. E questo è solo l'inizio. L'AR ha anche alcuni vantaggi sorprendenti rispetto alla realtà virtuale (VR).`,
    `La Realtà Aumentata (AR) nell'istruzione presenta aspetti che migliorano l'apprendimento di abilità come la risoluzione dei problemi, la collaborazione e la creazione per preparare meglio gli studenti per il futuro. È utile anche per la pedagogia tradizionale incentrata su conoscenze e competenze tecniche.`,
    `Sebbene l'AR sia ancora un po 'agli inizi, soprattutto rispetto alla realtà virtuale (VR), offre opzioni più convenienti ai distretti scolastici con budget più ristretti, pur fornendo molte delle stesse funzionalità e vantaggi.`,
    `La relativa omogeneità degli oggetti digitali all'interno del "mondo reale" incoraggia l'interattività e il coinvolgimento. Massimizza la capacità degli studenti di dedicare il loro tempo all'apprendimento di materie curriculari riducendo al minimo il tempo speso per imparare a utilizzare la nuova tecnologia.`,
    `Pertanto, AR offre agli studenti l'opportunità di approfondire le proprie conoscenze in diverse aree, tra cui:`,
    `Se combinato con incarichi che coinvolgono il lavoro di squadra, l'AR aiuta allo stesso modo a fornire nuove opportunità agli studenti per imparare a comunicare e collaborare tra loro. Potenzialmente potrebbero anche essere le stesse tecnologie che useranno in seguito nella forza lavoro.`,
    `Quando si adotta l'AR, inoltre, non è necessaria una revisione completa del curriculum: può essere ancora più efficace nell'integrare i materiali pedagogici attuali semplicemente aggiungendo più esperienze contestuali. Può essere utilizzato solo per stimolare l'interesse e la discussione in diverse aree tematiche ed essere la base per le attività di classe.`
  ],
};

const points = {
  en: [
    `Reading`,`Working with numbers`,
    `Spatial concepts`,
    `Playing`,
    `Content creation`,
    `Real-life environments & scenarios`,
  ],
  it: [
    "Lettura",
    "Lavorare con i numeri",
    "Concetti spaziali",
    "Giocando",
    "Creazione di contenuti",
    "Ambienti e scenari di vita reale",
  ],
};

class AugumentedRealityComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { translations, siteLanguage } = this.props;
    const lp = paras[siteLanguage] || paras.en;
    const ls = points[siteLanguage] || points.en;
    const bgStyles = {
      backgroundImage:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/ar-bg.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: 550,
    };
    return (
      <div className="homepage-main-container">
        <Navbar />
        <div className="about-us-info-container" style={bgStyles}>
          <div className="about-us-info-section">
            <h4>{translations.MISSION || `We're on a mission to use`}</h4>
            <h2>
              {translations.AR_IN_EDU ||
                `Augmented Reality in Education and bring all the benefits`}
            </h2>
            <button className="btn know-more-button">
              {translations.KNOW_MORE || `Know More`}
              <i className="uil-arrow-down" />
            </button>
          </div>
        </div>
        <div className="about-us-details-container">
          <div className="about-us-main-content">
            <h3 className="text-center">
              {translations.AR_HEADING || `Augumented Reality`}
            </h3>
            <div className="row tetu-2-extra-details-container">
              <div className="col-md-6">
                <p className="tetu-2-main-desc">{lp[0]}</p>
                <p className="tetu-2-main-desc">{lp[1]}</p>
                <p className="tetu-2-main-desc">{lp[2]}</p>
              </div>
              <div className="col-md-6">
                <img
                  src={"/assets/images/augumented-reality.jpg"}
                  alt="Augumented Reality"
                  className="bordered-image-tetu-2"
                />
              </div>
            </div>
            <div className="benefits-details-main-section">
              <h4 className="text-center">
                {translations.MAIN_AR_USES || `Main uses of Augumented Reality`}
              </h4>
              <p>{lp[3]}</p>
              <p>{lp[4]}</p>
              <ul className="benefits-details-main-section-list">
                {ls.map((l) => (
                  <li key={l}>
                    <i className="uil-arrow-right" />
                    {l}
                  </li>
                ))}
              </ul>
              <p>
                {lp[5]}
              </p>
              <p>
              {lp[6]}
             
              </p>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default WithTranslations(AugumentedRealityComponent);
