import React from "react";
import SVG from 'react-inlinesvg';
// import swal from 'sweetalert2';

// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';

// import { Loader, IconLoader } from "../../shared/loader";

import { baseUrl } from "../../../config";
import { getUserData, get } from "../../../utils";
import './parent.scss';
import { ParentChart, ParentLineChart } from "./charts/parent-charts";
import { WithTranslations } from "../../../i18n/withTranslations";

const stats = [
  {
    name: 'Attendance',
    icon: '/assets/images/dashboard/timetable.svg',
  },
  {
    name: 'Rank',
    icon: '/assets/images/dashboard/award.svg'
  },
  {
    name: 'Complaints',
    icon: '/assets/images/dashboard/complaint.svg'
  },
  {
    name: 'Pending Fees',
    icon: '/assets/images/dashboard/fees.svg'
  },
]

const colors = ['rgba(55,125,255,.2)', 'rgba(222,68,55,.2)', 'rgba(0,201,167,.2)', 'rgba(255,193,7,.2)', 'rgba(0,223,252,.2)']
class ParentDashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      students : []
    }
  }

  getClientsList(userID) {
    get(baseUrl+"dashboard")
      .then((response) => {
        let { students } = response.data.data;
        students.forEach((student) => {
          student.stats.forEach((stat) => {
            let index = stats.findIndex(a => a.name+"" == stat.name+"");
            if(index>=0)
              stat.icon = stats[index].icon;
          })
        })
        this.setState({
          students: students
        })
        
      })
      .catch((error) => {
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID});
    this.getClientsList(userID);
  }
  
  componentDidMount() {
    this.assignUserData();
  }

  render() {
    const { students } = this.state;
    const  {tid}=this.props;
    return (
      <div className='dashboard-main-container'>
        <div className='childrens-details-flex-container row col-lg-12'>
          {students && students.map((children, index) => {
            const {firstName, lastName, className, admissionNo, profilePicUrl, classTeacher} = children;
            return (
              <div key={"child-"+index} className='db-p-details-main-section col-lg-6 py-2'>
                <div className='children-details-top-container'>
                  <div className='children-left-side-section'>
                    <div className="db-p-student-profile">
                      <img src={profilePicUrl || "/assets/images/profile.svg"} alt=""/>
                    </div>
                    <h4 className='text-center'>{firstName} {lastName}</h4>
                    <p className='text-center'> {className} #{admissionNo} </p>
                    <div className='db-p-class-teacher-details text-center'>
                      <p className='class-teacher text-center'>{tid(`Class Teacher`)}</p>
                      {classTeacher && <p>{classTeacher.firstName}</p>}
                      {classTeacher && <p><i className='uil-phone' /> +91 {classTeacher.mobile}</p>}
                    </div>
                  </div>
                  <div className='children-right-side-section'>
                    {children.stats && children.stats.map((s, index) => {
                    const { name, value, icon} = s;
                    return (
                      <div className='stat-card-container'>
                        <div className='stat-left-side-section' style={{backgroundColor: colors[index]}}>
                          <SVG src={icon}/>
                        </div>
                        <div className='stat-right-side-section'>
                          <h3>{value}</h3>
                          <h5>{tid(name)}</h5>
                        </div>
                      </div>
                    )
                  })}
                  </div>
                </div>
                {/* <div className='children-details-bottom-container'>
                  <h4>Performance Report</h4>
                  <ParentLineChart />
                </div> */}
                <div className='children-details-bottom-container'>
                  <h4>{tid(`Attedance Report`)}</h4>
                  <ParentChart attendance={children.attendance}/>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      // <div className="row col-lg-12 np">
      //   <h6 className="row col-lg-12">Access your Childresn data from below</h6>
      //   {childrens && childrens.map((children, index) => {
      //     const {firstName, lastName, classID, admissionNo, profilePicUrl} = children;
      //     return (<div key={'children-'+index} className="col-lg-6 pl-0">
      //       <div className="row col-lg-12 white-bg np">
      //         <div className="col-lg-8 np">
      //           <div className="uk-card-default rounded text-center p-4">
      //             <div className="m-auto student-profile">
      //               <img src={profilePicUrl || "/assets/images/profile.svg"} alt=""/>
      //             </div>
      //             <h4 className="mb-2 mt-3"> {firstName} {lastName} </h4>
      //             <p className="m-0"> {classID.name}  #{admissionNo} </p>
      //           </div>
      //           <div className="text-center py-2"> <span classname="pl-1">Performance </span> | <span classname="pl-1">Progress Report</span></div>
      //         </div>
      //         <div className="col-lg-4 np pl-2">
      //           <ul className="uk-list uk-list-divider text-left pt-3">
      //             <li>
      //               Attendance
      //             </li>
      //             <li>
      //               Time Table
      //             </li>
      //             <li>
      //               Live Classes
      //             </li>
      //             <li>
      //               Complaints
      //             </li>
      //             <li>
      //               Leaves
      //             </li>
      //           </ul>
      //         </div>

      //       </div>
      //     </div>)})
      //   }
      // </div>
    )
  }
}

export default WithTranslations(ParentDashboardComponent)