import React from "react";

import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";

import StudentsListComponent from './students-list';
import './students.scss'

class StudentsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      students: []
    }
  }

  render() {
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <StudentsListComponent></StudentsListComponent>
        </div>
      </div>
    );
  }
}

export default StudentsComponent;