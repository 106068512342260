import React from "react";
import swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";
import * as $ from "jquery";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import {
  get,
  create,
  uploadToS3,
  decodeJwtToken,
  getUserData,
} from "../../../utils";
import { IconLoader } from "../../shared/loader";
import "./profile.scss";
import { WithTranslations } from "../../../i18n/withTranslations";

class ProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      gender: "",
      dob: "",

      flatNo: "",
      street: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",

      file: "",
      displayImg: "",
      profilePicUrl: "",
      password: "",
      confirmPassword: "",
      message: "",

      userID: "",
      token: "",

      name: "",
      email: "",
      mobile: "",
      merchantLogoKey: "",
      schoolName: "",
      academicStartDate: "",
      academicEndDate: "",
      currentAcademicYear: "",

      logo: "",
      displayLogo: "",
      merchantLogoUrl: "",

      amountPaid: "",
      amountPending: "",
      usersCount: "",

      savingLogo: false,

      savingPhoto: false,
      savingProfile: false,
      savingAddress: false,
      savingPassword: false,

      showMerchant: false,
      merchantID: "",
    };

    this.uploadFile = this.uploadFile.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);

    this.basicValidator = new SimpleReactValidator({});
    this.merchantValidator = new SimpleReactValidator({});
    this.addressValidator = new SimpleReactValidator({});
    this.passwordValidator = new SimpleReactValidator({});
  }

  uploadFile(event) {
    this.setState({
      file: event.target.files[0],
      displayImg: URL.createObjectURL(event.target.files[0]),
    });
  }

  uploadLogo(event) {
    this.setState({
      logo: event.target.files[0],
      displayLogo: URL.createObjectURL(event.target.files[0]),
    });
  }

  saveBasic() {
    if (this.basicValidator.allValid()) {
      this.setState({
        savingProfile: true,
      });
      this.saveData({
        email: this.state.email,
        gender: this.state.gender,
        dob: this.state.dob,
      });
    } else {
      this.basicValidator.showMessages();
      this.forceUpdate();
    }
  }

  saveAddress() {
    if (this.addressValidator.allValid()) {
      this.setState({
        savingAddress: true,
      });
      this.saveData({
        flatNo: this.state.flatNo,
        street: this.state.street,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zipcode: this.state.zipcode,
      });
    } else {
      this.addressValidator.showMessages();
      this.forceUpdate();
    }
  }

  saveData(data) {
    create(baseUrl + "users/profile", "post", data).then((response) => {
      sessionStorage.setItem("token", response.data.data);
      this.setState({
        savingPhoto: false,
        savingProfile: false,
        savingAddress: false,
        savingPassword: false,
      });
      swal.fire({
        title: "Info",
        text: response.data.message,
        icon: "warning",
      });
      this.setState({
        token: response.data.data.token,
      });
    });
  }

  savePic() {
    if (this.state.file) {
      let fileName =
        "profiles/" + this.state.userID + "/" + this.state.file.name;
      this.setState({
        savingPhoto: true,
      });
      new Promise((resolve, reject) => {
        resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
      })
        .then((response) => {
          this.saveData({
            profilePicKey: fileName,
          });
        })
        .catch((error) => {});
    }
  }

  savePassword() {
    if (this.passwordValidator.allValid()) {
      this.setState({
        savingPassword: true,
      });
      if (this.state.password === this.state.confirmPassword) {
        this.saveData({
          password: this.state.password,
        });
      } else {
        this.setState({
          message: "Password and Confirm Password are not same.",
        });
      }
    } else {
      this.passwordValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  onClickInputFile() {
    $("#input-file").trigger("click");
  }

  onClickInputLogo() {
    $("#input-logo").trigger("click");
  }

  getProfile() {
    get(baseUrl + "users/profile").then((response) => {
      let {
        firstName,
        lastName,
        email,
        mobile,
        gender,
        dob,

        flatNo,
        street,
        city,
        state,
        country,
        zipcode,
        profilePicUrl,
        showMerchant,
      } = response.data.data;
      this.setState({
        firstName,
        lastName,
        email,
        mobile,
        gender,
        dob,
        flatNo,
        street,
        city,
        state,
        country,
        zipcode,
        profilePicUrl,
        showMerchant,
      });
    });
  }

  componentDidMount() {
    this.getProfile();
    this.assignUserData();
    let userDetails = JSON.parse(sessionStorage.getItem("user_data"));
    if (userDetails && userDetails.token) {
      let userData = decodeJwtToken(userDetails.token);
      this.setState({
        userType: userData.userType,
        userID: userData.userID,
      });
    }
    this.getMerchantID();
  }

  getMerchantID() {
    let schoolcode = sessionStorage.getItem("schoolcode");
    if (schoolcode) {
      let { _id } = decodeJwtToken(schoolcode);
      this.setState({
        merchantID: _id,
      });
    }
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  getMerchant() {
    get(baseUrl + "merchants/" + this.state.merchantID).then((response) => {
      let {
        name,
        email,
        mobile,
        merchantLogoUrl,
        schoolName,
        academicStartDate,
        academicEndDate,
        currentAcademicYear,
        amountPaid,
        amountPending,
        usersCount,
      } = response.data.data;
      this.setState({
        name,
        email,
        mobile,
        merchantLogoUrl,
        schoolName,
        academicStartDate,
        academicEndDate,
        currentAcademicYear,

        amountPaid,
        amountPending,
        usersCount,
      });
    });
  }

  saveInformation(data) {
    create(baseUrl + "merchants/" + this.state.merchantID, "put", data).then(
      (response) => {
        swal.fire({
          title: "Info",
          text: response.data.message,
          icon: "warning",
        });
      }
    );
  }

  saveLogo() {
    if (this.state.logo) {
      let fileName = "logos/" + this.state.name + "/" + this.state.logo.name;
      this.setState({
        savingLogo: true,
      });
      new Promise((resolve, reject) => {
        resolve(uploadToS3(fileName, this.state.logo, this.state.logo.type));
      })
        .then((response) => {
          this.saveInformation({
            merchantLogoKey: fileName,
          });
        })
        .catch((error) => {});
    }
  }

  render() {
    let { token, userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar token={token}></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{tid(`Profile`)}</h2>
            </div>
          </div>
          <div className="user-profile-change-form-container">
            <ul
              className="uk-tab"
              uk-switcher="connect: #component-tab-left; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
            >
              <li className="uk-active">
                <a>
                  {" "}
                  <i className="icon-feather-home mr-2"></i>
                  {tid(`Basic`)}
                </a>
              </li>
              <li>
                <a>
                  {" "}
                  <i className="icon-feather-lock mr-2"></i> {tid(`Password`)}
                </a>
              </li>
              {this.state.showMerchant && (
                <li onClick={() => this.getMerchant()}>
                  <a>
                    {" "}
                    <i className="icon-feather-settings mr-2"></i>{" "}
                    {tid(`Settings`)}
                  </a>
                </li>
              )}
            </ul>
            <ul className="uk-switcher uk-margin" id="component-tab-left">
              <li>
                <div uk-grid={"true"}>
                  <div className="uk-width-2-5@m uk-flex-last@m">
                    <div className="uk-card-default rounded text-center p-4">
                      <div className="uk-position-relative my-4">
                        <div className="user-profile-photo  m-auto">
                          <img
                            src={
                              this.state.displayImg ||
                              this.state.profilePicUrl ||
                              "/assets/images/profile.svg"
                            }
                            alt=""
                          />
                        </div>

                        <div className="uk-position-center">
                          <div>
                            <input
                              type="file"
                              id="input-file"
                              accept="image/*"
                              onChange={this.uploadFile}
                            />
                            <span
                              className="uk-link icon-feather-camera icon-medium text-white"
                              onClick={() => this.onClickInputFile()}
                            >
                              {" "}
                            </span>
                          </div>
                        </div>
                      </div>

                      <button
                        className="btn btn-default m-auto mb-3 btn-sm"
                        onClick={() => this.savePic()}
                      >
                        {this.state.savingPhoto && (
                          <IconLoader
                            size={2}
                            text=""
                            color={"#fff"}
                          ></IconLoader>
                        )}
                        &nbsp; {tid(`Save photo`)}
                      </button>
                    </div>
                  </div>
                  <div className="uk-width-expand@m">
                    <div className="uk-card-default rounded">
                      <div className="p-3">
                        <h5 className="mb-0"> {tid(`Basic info`)} </h5>
                      </div>
                      <hr className="m-0" />
                      <form
                        className="uk-child-width-1-2@s uk-grid-small p-4"
                        uk-grid={"true"}
                      >
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`First Name`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("First Name")}
                            value={this.state.firstName}
                            name="firstName"
                            disabled={true}
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.basicValidator.message(
                            "First Name",
                            this.state.firstName,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Last Name`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Last Name")}
                            value={this.state.lastName}
                            name="lastName"
                            disabled={true}
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.basicValidator.message(
                            "Last Name",
                            this.state.lastName,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Email`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Email")}
                            value={this.state.email}
                            name="email"
                            onChange={this.setFormField.bind(this)}
                          />
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2"> Phone </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Phone ")}
                            value={this.state.mobile}
                            name="mobile"
                            disabled={true}
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.basicValidator.message(
                            "Phone Number",
                            this.state.mobile,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Gender`)}{" "}
                          </h5>
                          <select
                            className="form-control"
                            value={this.state.gender}
                            name="gender"
                            onChange={this.setFormField.bind(this)}
                          >
                            <option value="">{tid(`Select Gender`)}</option>
                            <option value="male">{tid(`Male`)}</option>
                            <option value="female">{tid(`Female`)}</option>
                          </select>
                          {this.basicValidator.message(
                            "Gender",
                            this.state.gender,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2"> {tid(`DOB`)} </h5>
                          <input
                            type="date"
                            className="form-control"
                            placeholder={tid("Date of Birth")}
                            value={
                              this.state.dob
                                ? new Date(this.state.dob)
                                    .toISOString()
                                    .substring(0, 10)
                                : ""
                            }
                            name="dob"
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.basicValidator.message(
                            "Date of Birth",
                            this.state.dob,
                            "required"
                          )}
                        </div>
                      </form>

                      <div className="uk-flex uk-flex-right p-4">
                        <button
                          className="btn btn-default grey btn-sm"
                          onClick={() => this.saveBasic()}
                        >
                          {this.state.savingProfile && (
                            <IconLoader
                              size={2}
                              text=""
                              color={"#fff"}
                            ></IconLoader>
                          )}
                          &nbsp; {tid(`Save Profile`)}
                        </button>
                      </div>
                    </div>

                    <div className="uk-card-default rounded mt-4">
                      <div className="p-3">
                        <h5 className="mb-0"> {tid(`Address`)} </h5>
                      </div>
                      <hr className="m-0" />
                      <form
                        className="uk-child-width-1-2@s uk-grid-small p-4"
                        uk-grid={"true"}
                      >
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Flat Number`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Flat No")}
                            value={this.state.flatNo}
                            name="flatNo"
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.addressValidator.message(
                            "Flat No",
                            this.state.flatNo,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Street`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Street")}
                            value={this.state.street}
                            name="street"
                            onChange={this.setFormField.bind(this)}
                          />
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2"> {tid(`City`)} </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("City Name")}
                            value={this.state.city}
                            name="city"
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.addressValidator.message(
                            "City",
                            this.state.city,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`State`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("State Name")}
                            value={this.state.state}
                            name="state"
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.addressValidator.message(
                            "State",
                            this.state.state,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Country`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Country Name")}
                            value={this.state.country}
                            name="country"
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.addressValidator.message(
                            "Country",
                            this.state.country,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Zip Code`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Zip Code")}
                            value={this.state.zipcode}
                            name="zipcode"
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.addressValidator.message(
                            "Zip Code",
                            this.state.zipcode,
                            "required"
                          )}
                        </div>
                      </form>
                      <div className="uk-flex uk-flex-right p-4">
                        <button
                          className="btn btn-default grey btn-sm"
                          onClick={() => this.saveAddress()}
                        >
                          {this.state.savingAddress && (
                            <IconLoader
                              size={2}
                              text=""
                              color={"#fff"}
                            ></IconLoader>
                          )}
                          &nbsp; {tid(`Save Address`)}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div uk-grid={"true"}>
                  <div className="uk-width-3-5@m uk-flex-last@m">
                    <div className="uk-card-default rounded">
                      <div className="p-3">
                        <h5 className="mb-0"> {tid(`Password Details`)} </h5>
                      </div>
                      <hr className="m-0" />
                      <form
                        className="uk-child-width-2-2@s uk-grid-small p-4"
                        uk-grid={"true"}
                      >
                        <div className="row col-lg-12 change-password-form-container">
                          <div className="col-lg-5">
                            <h5 className="uk-text-bold mb-2">
                              {" "}
                              {tid(`Password`)}{" "}
                            </h5>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="password"
                              className="form-control"
                              placeholder={tid("Enter new Password")}
                              value={this.state.password}
                              name="password"
                              onChange={this.setFormField.bind(this)}
                            />

                            {this.passwordValidator.message(
                              "Password",
                              this.state.password,
                              "required"
                            )}
                          </div>
                        </div>
                        <div className="row col-lg-12 change-password-form-container">
                          <div className="col-lg-5">
                            <h5 className="uk-text-bold mb-2">
                              {" "}
                              {tid(`Repeat Password`)}{" "}
                            </h5>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="password"
                              className="form-control"
                              placeholder={tid("Repeat Password")}
                              value={this.state.confirmPassword}
                              name="confirmPassword"
                              onChange={this.setFormField.bind(this)}
                            />

                            {this.passwordValidator.message(
                              "Password",
                              this.state.confirmPassword,
                              "required"
                            )}
                            <span className="red-text">
                              {this.state.message}
                            </span>
                          </div>
                        </div>
                      </form>

                      <div className="uk-flex pl-4 pb-4">
                        <button
                          className="btn btn-default grey btn-sm"
                          onClick={() => this.savePassword()}
                        >
                          {this.state.savingPassword && (
                            <IconLoader
                              size={2}
                              text=""
                              color={"#fff"}
                            ></IconLoader>
                          )}
                          &nbsp; {tid(`Update Password`)}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div uk-grid={"true"}>
                  <div className="uk-width-2-5@m uk-flex-last@m">
                    <div className="uk-card-default rounded text-center p-4">
                      <div className="uk-position-relative my-4">
                        <div className="user-profile-photo  m-auto">
                          <img
                            src={
                              this.state.displayLogo ||
                              this.state.merchantLogoUrl ||
                              "/assets/images/profile.svg"
                            }
                            alt=""
                          />
                        </div>

                        <div className="uk-position-center">
                          <div>
                            <input
                              type="file"
                              id="input-logo"
                              accept="image/*"
                              onChange={this.uploadLogo}
                            />
                            <span
                              className="uk-link icon-feather-camera icon-medium text-white"
                              onClick={() => this.onClickInputLogo()}
                            >
                              {" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn btn-default m-auto mb-3 btn-sm"
                        onClick={() => this.saveLogo()}
                      >
                        {this.state.savingPhoto && (
                          <IconLoader
                            size={2}
                            text=""
                            color={"#fff"}
                          ></IconLoader>
                        )}
                        &nbsp; {tid(`Save Logo`)}
                      </button>
                    </div>
                    <div className="uk-card-default rounded mt-4">
                      <div className="p-3">
                        <h5 className="mb-0">
                          {" "}
                          {tid(`Subscription Details `)}
                        </h5>
                      </div>
                      <hr className="m-0" />
                      <div
                        className="uk-child-width-1-2@s uk-grid-small p-4"
                        uk-grid={"true"}
                      >
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {tid(` Users Limit `)}
                          </h5>
                        </div>
                        <div>
                          <h6>{this.state.usersCount || "0"}</h6>
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Amount Paid`)}{" "}
                          </h5>
                        </div>
                        <div>
                          <h6>{this.state.amountPaid || "0"}</h6>
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Amount Pending`)}{" "}
                          </h5>
                        </div>
                        <div>
                          <h6>{this.state.amountPending || "0"}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-expand@m">
                    <div className="uk-card-default rounded">
                      <div className="p-3">
                        <h5 className="mb-0"> {tid(`Basic info `)}</h5>
                      </div>
                      <hr className="m-0" />
                      <form
                        className="uk-child-width-1-2@s uk-grid-small p-4"
                        uk-grid={"true"}
                      >
                        <div>
                          <h5 className="uk-text-bold mb-2"> {tid(`Name`)} </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Name")}
                            value={this.state.name}
                            name="name"
                            // disabled={true}
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.merchantValidator.message(
                            "Name",
                            this.state.name,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Email`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Email")}
                            value={this.state.email}
                            name="email"
                            onChange={this.setFormField.bind(this)}
                          />
                          {this.merchantValidator.message(
                            "Email",
                            this.state.email,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Phone`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("Phone ")}
                            value={this.state.mobile}
                            name="mobile"
                            // disabled={true}
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.merchantValidator.message(
                            "Phone Number",
                            this.state.mobile,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`School Name`)}{" "}
                          </h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={tid("School Name")}
                            value={this.state.schoolName}
                            name="schoolName"
                            // disabled={true}
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.merchantValidator.message(
                            "School Name",
                            this.state.schoolName,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Academic Start Date`)}{" "}
                          </h5>
                          <input
                            type="date"
                            className="form-control"
                            placeholder={tid("Start Date")}
                            value={
                              this.state.academicStartDate
                                ? new Date(this.state.academicStartDate)
                                    .toISOString()
                                    .substring(0, 10)
                                : ""
                            }
                            name="academicStartDate"
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.merchantValidator.message(
                            "Start Date",
                            this.state.academicStartDate,
                            "required"
                          )}
                        </div>
                        <div>
                          <h5 className="uk-text-bold mb-2">
                            {" "}
                            {tid(`Academic End Date`)}{" "}
                          </h5>
                          <input
                            type="date"
                            className="form-control"
                            placeholder={tid("End Date")}
                            value={
                              this.state.academicEndDate
                                ? new Date(this.state.academicEndDate)
                                    .toISOString()
                                    .substring(0, 10)
                                : ""
                            }
                            name="academicEndDate"
                            onChange={this.setFormField.bind(this)}
                          />

                          {this.merchantValidator.message(
                            "End Date",
                            this.state.academicEndDate,
                            "required"
                          )}
                        </div>
                      </form>

                      <div className="uk-flex uk-flex-right p-4">
                        <button
                          className="btn btn-default grey btn-sm"
                          onClick={() =>
                            this.saveInformation({
                              name: this.state.name,
                              email: this.state.email,
                              mobile: this.state.mobile,
                              schoolName: this.state.schoolName,
                              academicStartDate: this.state.academicStartDate,
                              academicEndDate: this.state.academicEndDate,
                            })
                          }
                        >
                          {tid(` Save Info`)}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(ProfileComponent);
