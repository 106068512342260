import React, { PureComponent } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, Tooltip,
  PieChart, Pie, Cell, linearGradient
} from 'recharts';

export class RevenueChart extends PureComponent {
  render() {
    return (
      <AreaChart width={550} height={250} data={this.props.branchWiseRevenues}
        margin={{ top: 10, right: 20, left: -20, bottom: 0 }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="10%" stopColor="#3e9ae0" stopOpacity={0.95}/>
            <stop offset="105%" stopColor="#3e9ae0" stopOpacity={0.4}/>
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#3e416b" stopOpacity={0.95}/>
            <stop offset="105%" stopColor="#3e416b" stopOpacity={0.4}/>
          </linearGradient>
          <linearGradient id="colorAmt" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#3e416b" stopOpacity={0.95}/>
            <stop offset="105%" stopColor="#3e416b" stopOpacity={0.4}/>
          </linearGradient>
        </defs>
        
        <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <YAxis tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <Tooltip />
        {this.props.branchNames.map((name, index) => {
          return <Area key={"name-"+index} type="monotone" dataKey={name} stroke="#3e9ae0" fillOpacity={1} fill="url(#colorUv)" />
        })}
      </AreaChart>
    );
  }
}

const pieData = [
  { name: 'Branch One', value: 400 },
  { name: 'Branch Two', value: 300 },
  { name: 'Branch Three', value: 200 },
  { name: 'Branch Two', value: 600 },
  { name: 'Branch Three', value: 100 },
];

const COLORS = ['rgba(55,125,255,.3)', 'rgba(222,68,55,.3)', 'rgba(0,201,167,.3)', 'rgba(255,193,7,.3)', 'rgba(0,223,252,.3)']
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="#333" fontSize="10" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export class RevenuePieChart extends PureComponent {
  render() {
    return (
      <>
        <PieChart width={500} height={250}>
          <Pie
            data={this.props.branches}
            cx={200}
            cy={125}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={90}
            fill="#8884d8"
            dataKey="revenue"
          >
            {
              this.props.branches.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
          <Tooltip />
        </PieChart>
        <div className='labels-list-container'>
          {this.props.branches.map((p, index) => {
            const { name } = p;
            const color = COLORS[index];
            return (
              <div className='label-data-container'>
                <div className='color-box' style={{backgroundColor: color}} />
                <p>{name}</p>
              </div>
            )
          })}
        </div>
      </>
    );
  }
}
