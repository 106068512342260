import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
// import { Loader, IconLoader } from "../../shared/loader";

import StudentPaymentsComponent from "./student-payments";

import { getUserData, get, featureAccess } from "../../../utils";
import './accounts.scss'
import { lightColors } from "../../../constants";
import { WithTranslations } from "../../../i18n/withTranslations";

const summaryCards = [
  {
    name: 'Expenses',
    value: 'expenses',
    image: '/assets/images/dashboard/money.png',
    link: '/expenses'
  },
  {
    name: 'Staff Salaries',
    value: 'salaries',
    image: '/assets/images/dashboard/pay.png',
    link: '/salaries'
  },
  {
    name: 'Inventory',
    value: 'inventory',
    image: '/assets/images/dashboard/supplier.png',
    link: '/inventories'
  },
  {
    name: 'Toppers List',
    value: 'toppers',
    image: '/assets/images/dashboard/leader.png',
    link: '/toppers'
  },
  {
    name: 'Staff Meetings',
    value: 'parents-meetings',
    image: '/assets/images/dashboard/meeting.svg',
    link: '/meetings'
  }
  ,{
    name: 'Achievements',
    value: 'achievements',
    image: '/assets/images/dashboard/win.png',
    link: '/achievements'
  }
]

class AccountsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      students: [],
      classID: '',
      showSection: 'summary',
      selectedStudent: {},

      payments: [],
      dues: [],
      summaryCards: []
    };

  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
    })
  }

  componentDidMount() {
    this.assignUserData();
    this.getClassesList();

    let cards = [];

    summaryCards.forEach((card) => {
      if(featureAccess(card.value))
        cards.push(card)
    })

    this.setState({
      summaryCards: cards
    })
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  getClassesList() {
    get(baseUrl + "classes/list-names")
      .then((response) => {
        this.setState({
          classes: response.data.data
        })
      })
  }

  onClickClass(classID) {
    this.setState({
      classID,
      showSection: 'list'
    })

    get(baseUrl + "payments/list/" + classID)
      .then((response) => {
        this.setState({
          students: response.data.data
        })
      })
  }

  showHistory(student) {
    this.setState({
      showSection: 'student-details',
      selectedStudent: student,
      studentID: student._id
    })
  }

  getSum(students, name) {
    let sum = 0;
    students.forEach((student) => {
      sum += parseInt(student[name], 10)
    })
    return sum;
  }

  render() {
    const { classID } = this.state;
    const {translations,tid}=this.props;
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{translations.ADMINISTRATION||`Administration`}</h2>
            </div>
          </div>
          {featureAccess('online-payments') && <div className='admin-payments-info-container'>
            <div className="course-grid-slider" uk-slider="finite: true">
              <div className="grid-slider-header">
                <div>
                  <h4 className="uk-text-truncate"> {translations.PAYMENTS||`Payments`} </h4>
                </div>
                <div className="grid-slider-header-link">
                  {/* <a href="course-path.html" className="button transparent uk-visible@m"> View all </a> */}
                  <a className="slide-nav-prev" uk-slider-item="previous"> </a>
                  <a className="slide-nav-next" uk-slider-item="next"> </a>
                </div>
              </div>

              <ul className="uk-slider-items">
                {this.state.classes.map((c, index) => {
                  return (<li key={"class-"+index} className={`${classID === c._id ? 'active-item' : ''}`}>
                    <a onClick={() => this.onClickClass(c._id)} className="skill-card">
                      <div>
                        {c.name}
                      </div>
                    </a>
                  </li>)
                })}
              </ul>
            </div>
          </div>}
          <div className="">
            {this.state.showSection === 'list' && <div className="ac-students-payments-info-container">
              <div className='go-back-container' onClick={() => this.setState({showSection: 'summary'})}>
                <i className='uil-arrow-left' /> {translations.BACK||`Back`}
              </div>
              <div className="students-paymet-stats-container">
                <p><b>{translations.STUDENTS||`Students`}: </b><span>{this.state.students.length}</span></p>
                <p><b>{translations.AMOUNT||`Amount`}:</b> <span>{this.getSum(this.state.students, 'amount')}</span></p>
                <p><b>{translations.PAID||`Paid`}:</b> <span>{this.getSum(this.state.students, 'paid')}</span></p>
                <p><b>{translations.PENDING||`Pending`}:</b> <span>{this.getSum(this.state.students, 'due')}</span></p>
              </div>
              <table className="table">
                <thead>
                  <tr className='table-headers-row'>
                    <th>{translations.ADMISSION_NO||`Admission No`}</th>
                    <th>{translations.PHOTO||`Photo`}</th>
                    <th>{translations.NAME||`Name`}</th>
                    <th>{translations.MOBILE||`Mobile`}</th>
                    <th>{translations.AMOUNT||`Amount`}</th>
                    <th>{translations.PAIND||`Paid`}</th>
                    <th>{translations.PENDING||`Pending`}</th>
                    <th>{translations.ACTIONS||`Actions`}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.students.map((student, index) => {
                    return (<tr className='table-data-row' key={"student" + index}>
                      <td>
                        {student.admissionNo}
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <img className="rounded-circle" src={student.profilePicUrl || "/assets/images/avatars/user.svg"}
                            alt="avatar" height="32" width="32" />
                        </div>
                      </td>
                      <td className="np">
                        <div className="d-flex align-items-center">
                          <div className="ml-1"><span className="text-bold-500">{student.name} </span></div>
                        </div>
                      </td>
                      <td>
                        {student.mobile}
                      </td>
                      <td>
                        {student.amount}
                      </td>
                      <td>
                        {student.paid}
                      </td>
                      <td>
                        {student.due}
                      </td>
                      <td>
                        <button type="button" className="btn btn-success btn-xs" onClick={() => this.showHistory(student)}>History</button>&nbsp;
                      </td>
                    </tr>)
                  })}
                </tbody>
              </table>
              {(this.state.students.length === 0) && <div className="no-results-found-container">
                <div className="text-center">{translations.NO_STUDENTS_FOUND||`No Students Found`}</div></div>}
            </div>}
            {this.state.showSection === 'student-details' && <div className="acs-payments-history-main-container">
              <StudentPaymentsComponent classID={this.state.classID} student={this.state.selectedStudent} payments={this.state.payments} dues={this.state.dues}></StudentPaymentsComponent>
            </div>}
            {this.state.showSection === 'summary' && <div className="summary-cards-container">
              {this.state.summaryCards && this.state.summaryCards.map((s, index) => {
                const { name, image, link } = s;
                return (
                  <div className="col-lg-4 pl-0" key={`summary-card-${index}`}>
                    <Link to={link}>
                      <div className="summary-card-container"  style={{backgroundColor: lightColors[index % 5]}}>
                        <div className="summary-card-left-side">
                          <div className='image-container'>
                            <img src={image} />
                          </div>
                        </div>
                        <div className='summary-card-right-side'>
                          <p>{tid(name)}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(AccountsComponent);