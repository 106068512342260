import React from "react";
import SVG from 'react-inlinesvg';
import './faculty.scss';
import { baseUrl } from "../../../config";
import { get } from "../../../utils";

import { FacultyChartLine, ClassPieChart } from "./charts/faculty-charts";
import { WithTranslations } from "../../../i18n/withTranslations";

const colors = ['rgba(55,125,255,.2)', 'rgba(222,68,55,.2)', 'rgba(0,201,167,.2)', 'rgba(255,193,7,.2)', 'rgba(0,223,252,.2)']

class FacultyDashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      stats: [
        {
          name: 'Classes',
          value: 0,
          icon: '/assets/images/dashboard/class.svg'
        },
        {
          name: 'Subjects',
          value: 0,
          icon: '/assets/images/dashboard/student.svg'
        },
        {
          name: 'Books',
          value: 0,
          icon: '/assets/images/dashboard/library.svg'
        },
        {
          name: 'Doubts',
          value: 0,
          icon: '/assets/images/dashboard/doubt.svg'
        },
        {
          name: 'Tests',
          value: 0,
          icon: '/assets/images/dashboard/doubt.svg'
        },
        {
          name: 'Quiz',
          value: 0,
          icon: '/assets/images/dashboard/doubt.svg'
        }
      ],
      classes: [],
      timetable: [],
      doubts: [],
      performance: [],
      classNames: []
    };
  }

  componentDidMount() {
    this.setState({
      firstName: this.props.firstName,
    });

    get(baseUrl+"dashboard")
    .then((response) => {
      let { classes, stats, timetable, doubts, performance, classNames } = response.data.data;
      let statsData = [];
      this.state.stats.forEach((stat) => {
        stat.value = (stats && stats[stat.name.toLowerCase()])?stats[stat.name.toLowerCase()]:0;
        statsData.push(stat);
      })
      this.setState({
        stats: statsData,
        classes: classes,
        timetable,
        doubts,
        performance,
        classNames
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }

  render() {
    const { firstName, timetable, doubts } = this.state;
const {tid}=this.props;
    return (
      <div className='dashboard-main-container'>
        <div className='db-faculty-top-row-flex-container'>
          <div className='db-f-left-side-section'>
            <div className='db-performance-percentange-graph'>
              <h4>{tid(`Class Students Chart`)}</h4>
              <ClassPieChart classes={this.state.classes} />
            </div>
            <div className='db-attendance-percentange-graph'>
              <h4>{tid(`Class Performance Report`)}</h4>
              <FacultyChartLine performance={this.state.performance} classNames={this.state.classNames}/>
            </div>
          </div>
          <div className='db-f-right-side-section'>
            <div className='db-f-top-numbers-list-container'>
              {this.state.stats && this.state.stats.map((s, index) => {
                const { name, value, icon} = s;
                return (
                  <div className='stat-card-container'>
                    <div className='stat-left-side-section' style={{backgroundColor: colors[index]}}>
                      <SVG src={icon} />
                    </div>
                    <div className='stat-right-side-section'>
                      <h3>{value}</h3>
                      <h5>{tid(name)}</h5>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='db-faculty-bottom-row-flex-container'>
          <div className='db-f-left-side-section'>
            <div className='db-f-upcoming-events-container'>
              <h4>{tid(`Today's Timetable`)}</h4>
              <div className='meetings-list-main-container'>
                {timetable && timetable.map((m, index) => {
                  const { name, time, className } = m;
                  return (
                    <div className='meeting-list-flex-section'>
                      <div className='meeting-left-side'>
                        <div className='meeting-top-container'>
                          <span className='meeting-top-month-name'>{tid(`Class`)}</span>
                        </div>
                        <div className='meeting-bottom-container'>
                          <span className='meeting-bottom-date-name'>{className && className.split('Class')[0]}</span>
                        </div>
                      </div>
                      <div className='meeting-right-side'>
                        <h5>{name}</h5>
                        <p>{time}</p>
                      </div>
                    </div>
                  ) 
                })}
              </div>
            </div>
          </div>
          <div className='db-f-right-side-section'>
            <div className='doubt-request-container'>
              <h4>{tid(`Doubts raised by Students`)}</h4>
              <div className='doubt-request-list'>
                {doubts && doubts.map((l, index) => {
                  const { _id,
                    title,
                    createdAt,
                    studentName,
                    studentID,
                    profilePicUrl,
                    className } = l;
                  return (
                    <div key={`doubt-request-${index}`} className='doubt-request-row'>
                      <div className="xs-sec">
                        <img src={profilePicUrl || '/assets/images/avatars/avatar-2.jpg'} alt={studentName} class="l-r-image" />
                      </div>
                      <div className="big-sec">
                        <h5 className='l-r-name'>{studentName}</h5>
                      </div>
                      <div className="large-sec">
                        <h5 className='l-r-query'>{title}</h5>
                      </div>
                      <div className="small-sec">
                        <h5 className='l-r-date'>{className}</h5>
                      </div>
                      <div className='action-sec'>
                        <button className='l-r-approve'>
                          {tid(`Answer`)} <i className="uil-check-circle" />
                        </button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className='db-upcoming-birthdays-container'>
          <h4>Upcoming Birthdays</h4>
          <div className='meetings-list-main-container'>
            {birthdays && birthdays.map((b, index) => {
              const { date, title } = b;
              return (
                <div className='meeting-list-flex-section'>
                  <div className='meeting-left-side'>
                    <img src='/assets/images/avatars/avatar-2.jpg' alt={title} className='user-img' />
                  </div>
                  <div className='meeting-right-side'>
                    <h5>{title}</h5>
                    <p>{date}</p>
                  </div>
                </div>
              ) 
            })}
          </div>
        </div> */}
      </div>
    )
  }
}
export default WithTranslations(FacultyDashboardComponent);