import React from "react";
import swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";
import { baseUrl } from "../../../config";
import { earningOptions } from "../../../constants";
import { get, create } from "../../../utils";
import Autocomplete from "../shared/autocomplete";
import { WithTranslations } from "../../../i18n/withTranslations";

// import Loader from "../../shared/loader";

class StaffPayrollComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identityNo: "",
      bank: "",
      doj: "",
      pfNo: "",
      location: "",
      department: "",
      name: "",
      bankAccount: "",
      designation: "",
      earnings: [],
      staffID: "",
      payrollID: "",
    };

    this.save = this.save.bind(this);
    this.payrollValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.payrollValidator.allValid()) {
      let url = baseUrl + "payrolls/";

      if (this.state.payrollID) url += this.state.payrollID;
      else url += "add";

      let data = {
        identityNo: this.state.identityNo,
        bank: this.state.bank,
        doj: this.state.doj,
        pfNo: this.state.pfNo,
        location: this.state.location,
        department: this.state.department,
        name: this.state.name,
        bankAccount: this.state.bankAccount,
        designation: this.state.designation,
        earnings: this.state.earnings,
        userID: this.state.staffID,
      };

      create(url, this.state.payrollID ? "put" : "post", data)
        .then((response) => {
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.payrollValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({
      [event.target.name]: event.target.value,
      message: "",
      passwordMessage: "",
    });
  }

  componentDidMount() {
    let staffID = this.props.staffID;
    this.setState({
      staffID,
    });
    this.loadPayrollDetails(staffID);
  }

  getPermissions() {
    get(baseUrl + "users/permissions-master")
      .then((response) => {
        let permissions = response.data.data;
        this.setState({ permissions });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadPayrollDetails(staffID) {
    get(baseUrl + "payrolls/details/" + staffID)
      .then((response) => {
        let {
          identityNo,
          bank,
          doj,
          pfNo,
          location,
          department,
          name,
          bankAccount,
          designation,
          earnings,
          _id,
        } = response.data.data;
        this.setState({
          identityNo,
          bank,
          doj,
          pfNo,
          location,
          department,
          name,
          bankAccount,
          designation,
          earnings,
          payrollID: _id,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addEarning() {
    let earnings = this.state.earnings;
    earnings.push({
      earning: "",
      amount: "",
    });
    this.setState({
      earnings,
    });
  }

  handleFieldEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const index = splitted[1];
      const value = event.target.value;
      let earnings = this.state.earnings;
      earnings[parseInt(index, 10)][name] = value;
      console.log(earnings);
      this.setState({
        earnings: earnings,
      });
    }
  };

  remove(index) {
    let earnings = this.state.earnings;
    earnings.splice(index, 1);
    this.setState({
      earnings,
    });
  }

  render() {
    const { tid } = this.props;
    return (
      <div className="add-staff-form-page-container">
        <div className="app-page-header-section">
          <div className="app-page-header-left">
            <h2 className="">
              <span onClick={() => this.props.showList()}>
                <i className="uil-arrow-left"></i>
              </span>{" "}
              {tid("Staff Payroll Details")}
            </h2>
          </div>
          <div className="app-page-header-right">
            <button
              className="btn btn-sm d-none d-sm-block btn-default"
              onClick={this.save.bind(this)}
            >
              <i className="cursor-pointer fa fa-save font-small-3 mr-50"></i>
              <span>{tid(`Save Changes`)}</span>
            </button>
          </div>
        </div>
        <div className="add-staff-form-section">
          <div className="first-row">
            <div className="form-group-section">
              <fieldset className="form-group">
                <div className="row col-lg-12">
                  <div className="col-lg-4">
                    <label htmlFor="basicInput">{tid(`Identity No`)}(*):</label>
                  </div>
                  <div className="col-lg-8 np">
                    <input
                      type="text"
                      className="form-control "
                      value={this.state.identityNo}
                      name="identityNo"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Identity No")}
                    />
                    {this.payrollValidator.message(
                      "Identity No",
                      this.state.identityNo,
                      "required"
                    )}
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="form-group-section">
              <fieldset className="form-group">
                <div className="row col-lg-12">
                  <div className="col-lg-4">
                    <label htmlFor="helperText">{tid(`Name`)}(*): </label>
                  </div>
                  <div className="col-lg-8 np">
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.name}
                      name="name"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Name")}
                    />
                    {this.payrollValidator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="second-row">
            <div className="form-group-section">
              <fieldset className="form-group">
                <div className="row col-lg-12">
                  <div className="col-lg-4">
                    <label htmlFor="helperText">{tid(`Bank`)}(*): </label>
                  </div>
                  <div className="col-lg-8 np">
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.bank}
                      name="bank"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Bank")}
                    />
                    {this.payrollValidator.message(
                      "Bank",
                      this.state.bank,
                      "required"
                    )}
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="form-group-section">
              <fieldset className="form-group">
                <div className="row col-lg-12">
                  <div className="col-lg-4">
                    <label htmlFor="helperText">{tid(`Bank A/C No`)}(*): </label>
                  </div>
                  <div className="col-lg-8 np">
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.bankAccount}
                      name="bankAccount"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Bank")}
                    />
                    {this.payrollValidator.message(
                      "Bank Account No",
                      this.state.bankAccount,
                      "required"
                    )}
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="second-row">
            <div className="form-group-section">
              <fieldset className="form-group">
                <div className="row col-lg-12">
                  <div className="col-lg-4">
                    <label htmlFor="helperText">{tid(`DOJ`)}(*): </label>
                  </div>
                  <div className="col-lg-8 np">
                    <input
                      type="date"
                      className="form-control form-control-user"
                      value={this.state.doj}
                      name="doj"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter DOJ")}
                    />
                    {this.payrollValidator.message(
                      "DOJ",
                      this.state.doj,
                      "required"
                    )}
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="form-group-section">
              <fieldset className="form-group">
                <div className="row col-lg-12">
                  <div className="col-lg-4">
                    <label htmlFor="helperText">{tid(`PF No`)}:</label>
                  </div>
                  <div className="col-lg-8 np">
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.pfNo}
                      name="pfNo"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter PF No")}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="second-row">
            <div className="form-group-section">
              <fieldset className="form-group">
                <div className="row col-lg-12">
                  <div className="col-lg-4">
                    <label htmlFor="helpInputTop">{tid(`Department`)}(*): </label>
                  </div>
                  <div className="col-lg-8 np">
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.department}
                      name="department"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Department")}
                    />
                    {this.payrollValidator.message(
                      "Department",
                      this.state.department,
                      "required"
                    )}
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="form-group-section">
              <fieldset className="form-group">
                <div className="row col-lg-12">
                  <div className="col-lg-4">
                    <label htmlFor="helpInputTop">{tid(`Designation`)}(*): </label>
                  </div>
                  <div className="col-lg-8 np">
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.designation}
                      name="designation"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Designation")}
                    />
                    {this.payrollValidator.message(
                      "Designation",
                      this.state.designation,
                      "required"
                    )}
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="add-staff-form-section mb-5">
          <h6>
            {tid(`Earnings Information`)}{" "}
            <button
              className="float-right btn btn-default btn-xs"
              onClick={() => this.addEarning()}
            >
              {tid(`Add Row`)}
            </button>
          </h6>
          <table className="table">
            <thead>
              <th>{tid(`S.No`)}</th>
              <th>{tid(`Earnings`)}</th>
              <th>{tid(`Amount`)}</th>
              <th>{tid(`Actions`)}</th>
            </thead>
            <tbody>
              {this.state.earnings.map((earning, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        placeholder={tid("Enter Earning")}
                        value={earning.earning}
                        name={"earning#" + index}
                        onChange={this.handleFieldEdit}
                        type="text"
                      />
                    </td>
                    <td>
                      <input
                        placeholder={tid("Enter Amount")}
                        value={earning.amount}
                        name={"amount#" + index}
                        onChange={this.handleFieldEdit}
                        type="number"
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => this.remove(index)}
                      >
                        <i className="uil-trash" />
                      </button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default WithTranslations(StaffPayrollComponent);
