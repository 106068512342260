import React from "react";
// import swal from 'sweetalert2';

import { Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';

// import { Loader, IconLoader } from "../../shared/loader";
// import Navbar from "../shared/navbar/Navbar";
// import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl, videoUrl } from "../../../config";
import { get, create, uploadFile, getUserData, getBase64Data } from "../../../utils";
import "./whiteboard.scss";
const colors = ['black', 'red', 'green', 'blue', 'yellow']
export default class WhiteboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conferenceID: '',
      userType: '',
      drafts: [],
      draftUrls: [],
      selectedIndex: ''
    };
  }

  assignUserData() {
    if(sessionStorage.getItem("token")) {
      let { firstName, lastName, userType, userID } = getUserData();
      this.setState({ firstName, lastName, userType, userID });
    } else {
      let { firstName, lastName, userType, userID } = this.props;
      this.setState({ firstName, lastName, userType, userID });
    }
  }

  componentDidMount() {

    let scope = this;

    this.setState({
      conferenceID: this.props.conferenceID
    })
    this.assignUserData();
    var socket = window.io(videoUrl, { resource: "nodejs" });
    var canvas = document.getElementsByClassName("whiteboard")[0];
    var colors = document.getElementsByClassName("color");
    var context = canvas.getContext("2d");

    var current = {
      color: "black",
    };

    var drawing = false;

    canvas.addEventListener("mousedown", onMouseDown, false);
    canvas.addEventListener("mouseup", onMouseUp, false);
    canvas.addEventListener("mouseout", onMouseUp, false);
    canvas.addEventListener("mousemove", throttle(onMouseMove, 10), false);

    //Touch support for mobile devices
    canvas.addEventListener("touchstart", onMouseDown, false);
    canvas.addEventListener("touchend", onMouseUp, false);
    canvas.addEventListener("touchcancel", onMouseUp, false);
    canvas.addEventListener("touchmove", throttle(onMouseMove, 10), false);

    for (var i = 0; i < colors.length; i++) {
      colors[i].addEventListener("click", onColorUpdate, false);
    }

    socket.on("drawing" + this.props.conferenceID, onDrawingEvent);

    // window.addEventListener("resize", onResize, false);
    onResize();

    let { conferenceID } = this.props;

    function drawLine(x0, y0, x1, y1, color, emit) {
      context.beginPath();
      context.moveTo(x0, y0);
      context.lineTo(x1, y1);
      context.strokeStyle = color;
      context.lineWidth = 2;
      context.stroke();
      context.closePath();

      if (!emit) {
        return;
      }
      var w = canvas.width;
      var h = canvas.height;

      socket.emit("drawing", {
        conferenceID: conferenceID,
        x0: x0 / w,
        y0: y0 / h,
        x1: x1 / w,
        y1: y1 / h,
        color: color,
      });
    }

    function onMouseDown(e) {
      drawing = true;
      current.x = e.clientX || e.touches[0].clientX;
      current.y = e.clientY || e.touches[0].clientY;
    }

    function onMouseUp(e) {
      if (!drawing) {
        return;
      }
      drawing = false;
      drawLine(
        current.x,
        current.y,
        e.clientX || e.touches[0].clientX,
        e.clientY || e.touches[0].clientY,
        current.color,
        true
      );
    }

    function onMouseMove(e) {
      if (!drawing) {
        return;
      }
      drawLine(
        current.x,
        current.y,
        e.clientX || e.touches[0].clientX,
        e.clientY || e.touches[0].clientY,
        current.color,
        true
      );
      current.x = e.clientX || e.touches[0].clientX;
      current.y = e.clientY || e.touches[0].clientY;
    }

    function removeActiveClass () {
      for (var i = 0; i < colors.length; i++) {
        colors[i].classList.remove("active")
      }
    }

    function onColorUpdate(e) {
      removeActiveClass()
      current.color = e.target.className.split(" ")[1];
      e.target.classList.add('active')
    }

    // limit the number of events per second
    function throttle(callback, delay) {
      var previousCall = new Date().getTime();
      return function () {
        var time = new Date().getTime();

        if (time - previousCall >= delay) {
          previousCall = time;
          callback.apply(null, arguments);
        }
      };
    }

    function onDrawingEvent(data) {
      var w = canvas.width;
      var h = canvas.height;
      drawLine(data.x0 * w, data.y0 * h, data.x1 * w, data.y1 * h, data.color);
    }

    function onResize() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }

    document.getElementById('file').onchange = function(e) {
      var img = new Image();
      img.onload = draw;
      img.src = URL.createObjectURL(this.files[0]);
    };
    
    function draw() {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(this, 0, 0, canvas.width, canvas.height);
    }

    document.getElementById('download').addEventListener("click", function(e) {
      var dataURL = canvas.toDataURL("image/png", 1.0);
      downloadImage(dataURL);
    });

    document.addEventListener('click', function(e){
      if(e.target && e.target.className== 'uploaded-image'){
        let id = ''+e.target.id.replace('image-','');
        scope.setState({
          selectedIndex: id
        })
        
        create(baseUrl + "live-classes/get-url", "post", {url: ''+document.getElementById(""+e.target.id).src}, scope.props.codeToken, scope.props.userToken)
        .then((response) => {
          let { encoded } = response.data.data;
          var image = new Image();
          image.onload = draw;
          image.src = encoded+'';
        })
      }
    });
  
    function downloadImage(data) {
        new Promise((resolve, reject) => {
          let fileName = 'drafts/'+scope.props.conferenceID+"/"+(scope.state.drafts.length+1)+"-draft.png"
          resolve(uploadFile(fileName, data, "image/png"));
        })
        .then((response) => {
          let drafts = scope.state.drafts;
          if(scope.state.selectedIndex)
            drafts[parseInt(scope.state.selectedIndex)] = response;
          else 
            drafts.push(response);
          scope.setState({
            drafts,
            selectedIndex: ''
          })

          return create(baseUrl + "live-classes/save-draft/" + scope.props.conferenceID, "post", {drafts}, scope.props.codeToken, scope.props.userToken)
        })
        .then((response) => {
          let { drafts, draftUrls } = response.data.data;
          scope.setState({
            drafts,
            draftUrls
          })
          context.clearRect(0, 0, canvas.width, canvas.height);
        })
        .catch((error) => {
          console.log(error)
        });
    }

    document.getElementById('reset').addEventListener("click", function(e) {
      context.clearRect(0, 0, canvas.width, canvas.height);
    });

    document.getElementById('delete').addEventListener("click", function(e) {
      if(scope.state.selectedIndex) {
        let drafts = scope.state.drafts;
        drafts.splice(parseInt(scope.state.selectedIndex), 1)
        scope.setState({
          drafts,
          selectedIndex: ''
        })

        create(baseUrl + "live-classes/save-draft/" + scope.props.conferenceID, "post", {drafts}, scope.props.codeToken, scope.props.userToken)
        .then((response) => {
          let { drafts, draftUrls } = response.data.data;
          scope.setState({
            drafts,
            draftUrls
          })
          context.clearRect(0, 0, canvas.width, canvas.height);
        })
        .catch((error) => {
          console.log(error)
        });
      }
    });

    function getDrafts() {
      get(baseUrl + "live-classes/drafts/" + scope.props.conferenceID, scope.props.codeToken, scope.props.userToken)
      .then((response) => {
        let { drafts, draftUrls } = response.data.data;
        scope.setState({
          drafts,
          draftUrls
        })
      })
    }

    getDrafts();
    
  }
  

  render() {
    return (
      <div id="whiteboard">
        <canvas className="whiteboard"></canvas>
        <div className="top-controls-container">
          <div className="colors-container">
            {colors && colors.map((c, i) => {
              return (
                <div className={`color ${c}`} />
              )
            })}
          </div>
          <div className='upload-file-section'>
            <div className="choose-file-section">
              <label httmlFor="file" title='Upload Image'>
                <input accept="image/*" id="file" type="file" />
                <i className='uil-file' /> Upload Image
              </label>
            </div>
            <div className='buttons-container'>
              <button id="delete">
                <i className='uil-trash-alt' /> Delete
              </button>
              <button id="reset">
                <i className='uil-refresh' /> Reset
              </button>
              <button id="download">
                <i className='uil-download-alt' /> Save
              </button>
            </div>
          </div>
        </div>
        <div className="uploaded-images-container">
          {this.state.draftUrls.map((image, i) => {
            return <img className="uploaded-image" key={"img-"+i} id={"image-"+i} src={image} />
          })}
        </div>
      </div>
    );
  }
}
