import React from "react";
import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";

class BlogsComponent extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
        return (
            <div className="homepage-main-container">
                <div className="navbar-container navbar-container-inverted">
                    <Navbar />
                </div>
                <div class="container section white-bg">
                    <div class="page-content-inner pt-3">
                        <h1>Blogs</h1>
                    </div>
                </div>
                <div className='footer-container'>
                    <Footer ></Footer>
                </div>
            </div>
      );
    }
}

export default BlogsComponent;