import React from "react";
import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";
import { WithTranslations } from "../../../i18n/withTranslations";

const pointsEN = [
  `The first step involves the design engineer getting a solid idea of what kind of scene is to be created for the client based on the consultation. The plans will be drawn up, sketches made, and reference images identified that will give the design team direction.`,
`Students can witness what happened in history in the first person, go deep into the human body and experience new learning experiences from a different point of view. They will see everything much better than through explanations and images.`,
`With virtual reality, students will be able to travel in time and space. They can go anywhere we want them to see, return to the past or unveil the mysteries of the future. Without limits, without big expenses.`,
`Making trips to developing countries through immersive education brings students closer to other communities, fostering their values, kindness and empathy with others.`,
`Students can also use virtual reality to learn about the careers that may be of their interest, to have a better professional orientation that allows them to better decide their future. feedback.`,
`Being able to visualize what they are learning from a different point of view, in a three dimensions view, allows them to better enjoy their learning time. It’s fun!`,
`In order for all this to be possible, many educational applications have been created that can immerse us in new worlds inside our classroom. The best of all is that there is so much to discover and to create that we will soon learn new applications of virtual reality in education, and they will be very exciting.`,
]
const pointsIT = [
  `Il primo passo prevede che l'ingegnere progettista abbia una solida idea del tipo di scena da creare per il cliente in base alla consulenza. Verranno redatte le planimetrie, realizzati schizzi e identificate immagini di riferimento che daranno la direzione del team di progettazione.`,
`Gli studenti possono testimoniare in prima persona ciò che è accaduto nella storia, approfondire il corpo umano e sperimentare nuove esperienze di apprendimento da un punto di vista diverso. Vedranno tutto molto meglio che attraverso spiegazioni e immagini.`,
`Con la realtà virtuale, gli studenti potranno viaggiare nel tempo e nello spazio. Possono andare ovunque vogliamo che vedano, tornare al passato o svelare i misteri del futuro. Senza limiti, senza grandi spese.`,
`Fare viaggi nei paesi in via di sviluppo attraverso un'educazione immersiva avvicina gli studenti ad altre comunità, promuovendo i loro valori, la gentilezza e l'empatia con gli altri.`,
`Gli studenti possono anche utilizzare la realtà virtuale per conoscere le carriere che possono essere di loro interesse, per avere un migliore orientamento professionale che permetta loro di decidere meglio il proprio futuro. risposta.`,
`Essere in grado di visualizzare ciò che stanno imparando da un punto di vista diverso, in una visione tridimensionale, consente loro di godersi al meglio il loro tempo di apprendimento. È divertente!`,
`Affinché tutto ciò sia possibile, sono state create molte applicazioni educative che possono immergerci in nuovi mondi all'interno della nostra classe. La cosa migliore è che c'è così tanto da scoprire e da creare che presto impareremo nuove applicazioni della realtà virtuale nell'istruzione, e saranno molto eccitanti.`,
]

class VirtualRealityComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { translations, siteLanguage } = this.props;
const points = siteLanguage==="it" ? pointsIT : pointsEN;
    const bgStyles = {
      backgroundImage:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/vr-bg.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: 550,
    };
    return (
      <div className="homepage-main-container">
        <Navbar />
        <div className="about-us-info-container" style={bgStyles}>
          <div className="about-us-info-section">
            <h4>{translations.MISSION || `We're on a mission to use`}</h4>
            <h2>
              {translations.VR_IN_EDU ||
                `Virtual Reality in Education and bring all the benefits`}
            </h2>
            <button className="btn know-more-button">
              {translations.KNOW_MORE || `Know More`}
              <i className="uil-arrow-down" />
            </button>
          </div>
        </div>
        <div className="about-us-details-container">
          <div className="about-us-main-content">
            <h3 className="text-center">
              {translations.VR_HEADING || `Virtual Reality`}
            </h3>
            <div className="row tetu-2-extra-details-container">
              <div className="col-md-6">
                <p className="tetu-2-main-desc">
                  {translations.VR_P1 ||
                    ` Virtual reality (VR) has entered the world of education
                  through the big door creating new resources to teach and
                  learn.`}
                </p>
                <p className="tetu-2-main-desc pt-2">
                  {translations.VR_P2 ||
                    `Students absorb information much better if they enter a 3D
                  environment that makes everything more fun, exciting and
                  enjoyable.`}
                </p>
                <p className="tetu-2-main-desc pt-2">
                  {translations.VR_P3 ||
                    `Virtual reality allows you to explore, travel without leaving
                  the classroom, visit what you want to learn without moving,
                  have a greater professional orientation and much more.`}
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src={"/assets/images/virtual-reality.jpg"}
                  alt="Artificial Intelligence"
                  className="bordered-image-tetu-2"
                />
              </div>
            </div>
            <div className="benefits-details-main-section">
              <h4 className="text-center">{translations.VR_MAIN_USES || `Main Uses of Virtual Reality`}</h4>
              <ul className="benefits-details-main-section-list">
                {
                  points.map(p=><li key={p}>
                    <i className="uil-arrow-right" />
                   {p}
                  </li>)
                }
                
               
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default WithTranslations(VirtualRealityComponent);
