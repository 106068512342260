import React from "react";
import swal from 'sweetalert2';
import * as $ from "jquery";
import { Link } from "react-router-dom";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import SimpleReactValidator from 'simple-react-validator';

import { baseUrl } from "../../../config";
import { months, years } from "../../../constants";
// import Loader from "../../../../shared/loader";

import { getUserData, get, create, uploadToS3 } from "../../../utils";
import Delete from "../../common/Delete";

class SalariesComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      salaries: [],
      employees: [],
      userID: "",
      month: "",
      year: "",
      paidAmount: "",
      comments: "",
      paidDate: "",
      salaryID: "",
      deductions: [],
      showSection: 'list'
    }
    this.salaryValidator = new SimpleReactValidator({});
  }

  save() {
    if (this.salaryValidator.allValid()) {

      let url = baseUrl + "salaries/";
      if (!this.state.salaryID)
        url += "add";
      else
        url += this.state.salaryID

      create(url, (this.state.salaryID) ? "put" : "post", {
        userID: this.state.userID,
        month: this.state.month,
        year: this.state.year,
        paidAmount: this.state.paidAmount,
        comments: this.state.comments,
        paidDate: this.state.paidDate,
        deductions: this.state.deductions
      })
      .then((response) => {
        this.getSalariesList();
        $("#add-salary").trigger("click");
        swal.fire({
          title: "Info",
          text: response.data.message,
          icon: "warning"
        });
      })
      .catch((error) => {

      });
    } else {
      this.salaryValidator.showMessages();
      this.forceUpdate();
    }
  }

  getSalariesList() {
    get(baseUrl + "salaries/list")
      .then((response) => {
        this.setState({
          salaries: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();

    this.setState({
      firstName,
      lastName,
      userType,
      userID,
    })
  }

  componentDidMount() {
    this.assignUserData();
    this.getSalariesList();
    this.getFaculty();
  }

  getFaculty() {
    get(baseUrl + "users/list/staff")
    .then((response) => {
      this.setState({
        employees: response.data.data
      })
    })
    .catch((error) => {
    });
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  editSalary(salary) {
    this.setState({
      userID: salary.userID._id,
      month: salary.month,
      year: salary.year,
      paidAmount: salary.paidAmount,
      deductions: salary.deductions,
      comments: salary.comments,
      paidDate: new Date(salary.paidDate).toISOString().substr(0, 10),
      salaryID: salary._id,
      showSection: 'add-salary'
    })
  }

  addDediction() {
    let deductions = this.state.deductions;
    deductions.push({
      deduction: "",
      amount: ""
    })
    this.setState({
      deductions
    })
  }

  handleFieldEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const index = splitted[1];
      const value = event.target.value;
      let deductions = this.state.deductions;
      deductions[parseInt(index, 10)][name] = value;
      this.setState({
        deductions: deductions
      })
    }
  }

  render() {
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{this.state.showSection === 'list'?<Link to={"/accounts"}><i className="uil-arrow-left"></i></Link>:<i className="uil-arrow-left pointer" onClick={() => this.setState({showSection: 'list'})}></i>} Salaries</h2>
            </div>
            <div className="app-page-header-right">
              {(this.state.userType !== 'salary' && this.state.userType !== 'parent') && <div className="section-header-right">
                <button className="app-add-option-button" onClick={() => this.setState({showSection: 'add-salary'})}><i className="uil-plus"></i> Add Salary</button>
              </div>}
            </div>
          </div>
          {this.state.showSection === 'list' && <div className="expenses-list-table-container" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 100">
            <table className="table">
              <thead>
                <tr className='table-header-row'>
                  <th>S.No</th>
                  <th>Employee Name</th>
                  <th>Month</th>
                  <th>Year</th>
                  <th>Paid</th>
                  <th>Invoice</th>
                  {/* <th>Comments</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.salaries.map((salary, index) => {
                  return (<tr className='table-data-row' key={"salary" + index}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="ml-1"><span className="text-bold-500">{salary.userID.firstName} </span></div>
                      </div>
                    </td>
                    <td>
                      {salary.month}
                    </td>
                    <td>
                      {salary.year}
                    </td>
                    <td>
                      <span uk-tooltip={salary.comments}>{salary.paidAmount}</span>
                    </td>
                    <td>
                      {salary.fileUrl && <a target="_blank" href={salary.fileUrl}>Preview</a>}
                    </td>
                    <td>
                      <div className='action-items'>
                        <button type="button" className="btn btn-success btn-sm" onClick={() => this.editSalary(salary)}><i className='uil-edit' /></button>
                     <Delete url={`salaries/${salary._id}`} />
                      </div>
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>}
          {this.state.showSection === 'add-salary' && <div className="expenses-list-table-container" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 100">
            <div className="row col-lg-12 np">
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Employee</label>
                  <select
                    className="form-control form-control-user"
                    value={this.state.userID}
                    name="userID"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Select Employee"
                  >
                    <option value="">Select Employee</option>
                    {this.state.employees.map((faculty, index) => {
                      return (<option key={"index-" + index} value={faculty._id}>{faculty.firstName} {faculty.lastName}</option>)
                    })}
                  </select>
                  {this.salaryValidator.message(
                    "Employee",
                    this.state.userID,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Year</label>
                  <select
                    className="form-control form-control-user"
                    value={this.state.year}
                    name="year"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Select Year"
                  >
                    <option value="">Select Year</option>
                    {years.map((year, index) => {
                      return (<option key={"year-" + index} value={year.value}>{year.name}</option>)
                    })}
                  </select>
                  {this.salaryValidator.message(
                    "Year",
                    this.state.year,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Month</label>
                  <select
                    className="form-control form-control-user"
                    value={this.state.month}
                    name="month"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Select Month"
                  >
                    <option value="">Select Month</option>
                    {months.map((month, index) => {
                      return (<option key={"month-" + index} value={month.value}>{month.name}</option>)
                    })}
                  </select>
                  {this.salaryValidator.message(
                    "Month",
                    this.state.month,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Amount Paid</label>
                  <input
                    type="number"
                    className="form-control form-control-user"
                    value={this.state.paidAmount}
                    name="paidAmount"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Amount Paid"
                  />
                  {this.salaryValidator.message(
                    "Amount Paid",
                    this.state.paidAmount,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Salary Date</label>
                  <input
                    type="date"
                    className="form-control form-control-user"
                    value={this.state.paidDate}
                    name="paidDate"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Enter Salary Date"
                  />
                  {this.salaryValidator.message(
                    "Salary Date",
                    this.state.paidDate,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Comments</label>
                  <textarea
                    type="text"
                    className="form-control form-control-user"
                    value={this.state.comments}
                    name="comments"
                    onChange={this.setFormField.bind(this)}
                    placeholder="Comments"
                  ></textarea>
                </div>
              </div>
            </div>
            
            <div className='add-staff-form-section col-lg-12'>
              <h6>Deductions Information <button className="float-right btn btn-default btn-xs" onClick={() => this.addDediction()}>Add Row</button></h6>
              <table className="table">
                <thead>
                  <th>S.No</th>
                  <th>Deductions</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {this.state.deductions.map((deduction, index) => {
                  return (<tr>
                    <td>{index + 1}</td>
                    <td>
                      <input 
                      placeholder="Enter Deduction" 
                      value={deduction.deduction}
                      name={"deduction#" + index}
                      onChange={this.handleFieldEdit} 
                      type="text"/>
                    </td>
                    <td>
                      <input 
                        placeholder="Enter Amount"
                        value={deduction.amount}
                        name={"amount#" + index}
                        onChange={this.handleFieldEdit} 
                        type="number"/>
                    </td>
                    <td><button type="button" className="btn btn-danger btn-sm" onClick={() => this.remove(index)}><i className='uil-trash' /></button> </td>
                  </tr>)})}
                </tbody>
              </table>
              <button type="button" className="btn btn-default btn-sm" onClick={() => this.save()}>
                <i className="bx bx-check d-block d-sm-none"></i>
                <span className="d-none d-sm-block">Save</span>
              </button>
            </div>
          </div>}
          
        </div>
      </div>
    );
  }
}

export default SalariesComponent;