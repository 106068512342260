import React, { Component } from "react";
import { TestPerformanceReport, GradeWiseChart } from "./charts/performance";
import SVG from "react-inlinesvg";
import { WithTranslations } from "../../../i18n/withTranslations";
const colors = [
  "rgba(55,125,255,.2)",
  "rgba(222,68,55,.2)",
  "rgba(0,201,167,.2)",
  "rgba(255,193,7,.2)",
];

const gradeColors = [
  "#3c9be2",
  "rgba(55,125,255,.4)",
  "rgba(0,201,167,.8)",
  "rgba(0,201,167,.4)",
  "#3e416c60",
  "rgba(222,68,55,.2)",
  "rgba(222,68,55,.65)",
];

class ClassPerformance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: [
        {
          name: "Students",
          value: 0,
          icon: "/assets/images/dashboard/student.svg",
        },
        {
          name: "Attendance",
          value: 0,
          icon: "/assets/images/dashboard/timetable.svg",
        },
        {
          name: "Fees",
          value: 0,
          icon: "/assets/images/dashboard/fees.svg",
        },
        {
          name: "Books",
          value: 0,
          icon: "/assets/images/dashboard/library.svg",
        },
        {
          name: "Complaints",
          value: 0,
          icon: "/assets/images/dashboard/complaint.svg",
        },
      ],
      grades: [],
      reports: [],
      performance: [],
    };
  }

  componentDidMount() {
    const { performance, stats, grades, reports } = this.props;
    let statsData = [];
    this.state.stats.forEach((stat) => {
      stat.value =
        stats && stats[stat.name.toLowerCase()]
          ? stats[stat.name.toLowerCase()]
          : 0;
      if (stat.name == "Attendance") stat.value += "%";
      statsData.push(stat);
    });
    this.setState({
      stats: statsData,
      grades,
      reports,
      performance,
    });
  }

  render() {
    const { tid } = this.props;
    return (
      <div className="app-class-performance-main-container">
        <div className="acp-top-flex-container">
          <div className="acp-top-left-side">
            <div className="acp-test-performance-graph">
              <h4>{tid('Test Performance')}</h4>
              <TestPerformanceReport data={this.state.performance} />
            </div>
          </div>
          <div className="acp-top-right-side">
            <div className="acp-top-right-stats-container">
              {this.state.stats &&
                this.state.stats.map((s, index) => {
                  const { name, value, icon } = s;
                  return (
                    <div key={"stat-" + index} className="stat-card-container">
                      <div
                        className="stat-left-side-section"
                        style={{ backgroundColor: colors[index % 4] }}
                      >
                        <SVG src={icon} />
                      </div>
                      <div className="stat-right-side-section">
                        <h3>{value}</h3>
                        <h5>{ tid(name)}</h5>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="acp-bottom-flex-container">
          <div className="acp-top-right-side">
            <div className="acp-top-right-stats-container">
              {this.state.grades &&
                this.state.grades.map((g, index) => {
                  return (
                    <div key={"stat-" + index} className="stat-card-container">
                      <div
                        className="stat-left-side-section"
                        style={{
                          backgroundColor:
                            gradeColors[index % gradeColors.length],
                        }}
                      >
                        <h5>{g.name}</h5>
                      </div>
                      <div className="stat-right-side-section">
                        <p>{tid('Students')}</p>
                        <h3>{g.value}</h3>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="acp-top-left-side">
            <div className="acp-test-performance-graph">
              <h4>{tid('Grade-Wise Report')}</h4>
              <GradeWiseChart
                data={this.state.reports}
                grades={this.state.grades}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(ClassPerformance);
