import React, { PureComponent } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, Legend,
  AreaChart, Area
} from 'recharts';

// const data = [
//   {
//     name: 'Jan', Present: 24, Absent: 2,
//   },
//   {
//     name: 'Feb',  Present: 20, Absent: 6,
//   },
//   {
//     name: 'Mar', Present: 24, Absent: 2,
//   },
//   {
//     name: 'Apr',  Present: 20, Absent: 6,
//   },
//   {
//     name: 'May',  Present: 23, Absent: 3,
//   },
//   {
//     name: 'Jun',  Present: 21, Absent: 5,
//   },
//   {
//     name: 'Jul',  Present: 25, Absent: 1,
//   },
//   {
//     name: 'Aug',  Present: 19, Absent: 7,
//   }
// ];

export class StudentAttendanceChart extends PureComponent {
  render() {
    return (
      <BarChart
        width={300}
        height={200}
        data={this.props.attendance}
        margin={{
          top: 5, right: 20, left: -30, bottom: 5,
        }}
      >
        <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <YAxis tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <Tooltip />
        <Legend />
        <Bar maxBarSize={8} dataKey="Present" fill="#3c9be2" radius={[50, 50, 0, 0]} />
        <Bar maxBarSize={8} dataKey="Absent" fill="#3e416c" radius={[50, 50, 0, 0]} />
      </BarChart>
    );
  }
}


export class StudentPerformanceChart extends PureComponent {
  render() {
    const data = this.props.performance;
    return (
      <AreaChart
        width={300}
        height={200}
        data={data}
        margin={{
          top: 10, right: 20, left: -30, bottom: 0,
        }}
      >
        <XAxis dataKey="name" tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <YAxis tick={{ fill: '#333', fontSize: 10 }} stroke="#ddd" />
        <Tooltip />
        <Area type="monotone" dataKey="Grade" stroke="rgba(0,201,167,.8)" fill="rgba(0,201,167,.4)" dot={{ stroke: '#3e416b', strokeWidth: 2 }} />
      </AreaChart>
    );
  }
}