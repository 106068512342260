export const menuList = [
  {
    id: "STAFF",
    name: "Staff",
    value: "staff",
    icon: "/assets/images/dashboard/staff.svg",
    link: "/staff",
  },
  {
    id: "STUDENTS",
    name: "Students",
    value: "students",
    icon: "/assets/images/dashboard/student.svg",
    link: "/students",
  },
  {
    id: "SUBJECTS",
    name: "Subjects",
    value: "subjects",
    icon: "/assets/images/dashboard/student.svg",
    link: "/subjects",
  },
  {
    id: "CLASSES",
    name: "Classes",
    value: "classes",
    icon: "/assets/images/dashboard/class.svg",
    link: "/classes",
  },
  {
    id: "ACCOUNTS",
    name: "Accounts",
    value: "accounts",
    icon: "/assets/images/dashboard/account.svg",
    link: "/accounts",
  },
  {
    id: "FEES",
    name: "Fees",
    value: "fee",
    icon: "/assets/images/dashboard/fees.svg",
    link: "/fee",
  },
  {
    id: "DOUBTS",
    name: "Doubts",
    value: "doubts",
    icon: "/assets/images/dashboard/doubt.svg",
    link: "/doubts",
  },
  {
    id: "LIBRARY",
    name: "Library",
    value: "library",
    icon: "/assets/images/dashboard/library.svg",
    link: "/library",
  },
  {
    id: "PERFORMANCE",
    name: "Performance",
    value: "performance",
    icon: "/assets/images/dashboard/performance.svg",
    link: "/performance",
  },
  {
    id: "GRADING_SYSTEM",
    name: "Grading System",
    value: "grading-system",
    icon: "/assets/images/dashboard/grade.svg",
    link: "/grading-system",
  },
  {
    id: "LEAVES",
    name: "Leaves",
    value: "leaves",
    icon: "/assets/images/dashboard/leaves.svg",
    link: "/leaves",
  },
  {
    id: "EVENTS_GALLERY",
    name: "Events/Gallery",
    value: "gallery",
    icon: "/assets/images/dashboard/gallery.svg",
    link: "/gallery",
  },
  {
    id: "SKILL_DEVELOPMENT",
    name: "Skill Development",
    value: "skills",
    icon: "/assets/images/dashboard/skills.svg",
    link: "/skills",
  },
  {
    id: "SOCIAL",
    name: "Social",
    value: "social",
    icon: "/assets/images/dashboard/social.svg",
    link: "/social",
  },
  {
    id: "MY_PARENTS",
    name: "My Parents",
    value: "parents",
    icon: "/assets/images/dashboard/parents.svg",
    link: "/parents",
  },
  {
    id: "COMPLAINTS",
    name: "Complaints",
    value: "complaints",
    icon: "/assets/images/dashboard/complaint.svg",
    link: "/complaints",
  },
  {
    id: "MEETINGS",
    name: "Meetings",
    value: "meetings",
    icon: "/assets/images/dashboard/meeting.svg",
    link: "/meetings",
  },
  {
    id: "CALENDAR",
    name: "Calendar",
    value: "calendar",
    icon: "/assets/images/dashboard/timetable.svg",
    link: "/calendar",
  },
  {
    id: "NOTICE_BOARD",
    name: "Notice Board",
    value: "notice-board",
    icon: "/assets/images/dashboard/noticeboard.svg",
    link: "/notice-board",
  },
];
