import React from "react";
// import swal from 'sweetalert2';

import { Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';

// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { getUserData, get } from "../../../utils";

class ParentsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parents: []
    }
  }

  getClientsList(userID) {
    get(baseUrl + "assigned-parents/parents/" + userID)
      .then((response) => {
        this.setState({
          parents: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
    this.getClientsList(userID);
  }

  componentDidMount() {
    this.assignUserData();
  }

  render() {
    const { userType } = this.state;
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper" className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">Parents</h2>
            </div>
            <div className="app-page-header-right">
              <Link to={"/add-parents"} className="app-add-option-button"><i className="uil-plus"></i> Add Parent</Link>  
            </div>
          </div>
          <div className='student-parents-list-container'>
            {this.state.parents.map((parent, index) => {
              return (
              <div key={"parent-" + index} className="parent-info-card">
                <div className="parent-info-card-left-side">
                  <img src={parent.profilePicUrl || "/assets/images/profile.svg"} alt="" />
                </div>
                <div className="parent-info-card-right-side">
                  <h3>{parent.firstName} {parent.lastName}</h3>
                  <span>{parent.relation}</span>
                  <h6><i className='uil-phone' />{parent.mobile}</h6>
                </div>
              </div>)
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ParentsComponent;