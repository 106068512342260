import React from "react";
// import swal from 'sweetalert2';
// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
import moment from "moment";
import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get, create, remove, uploadToS3, getUserData } from "../../../utils";
import "./social.scss";
import swal from "sweetalert2";
import { WithTranslations } from "../../../i18n/withTranslations";

class SocialComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classID: "all",
      posts: [],
      classes: [],
      commentsPostID: "",
      postID: "",
      postType: "",
      description: "",
      file: "",
      fileUrl: "",
      comment: "",
      uploading: false,
      userID: "",
    };
    this.uploadFile = this.uploadFile.bind(this);
    this.assignUserData = this.assignUserData.bind(this);
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] });
    var FR = new FileReader();
    FR.addEventListener("load", function (e) {
      document.getElementById("uploadedImage").src = e.target.result;
    });

    FR.readAsDataURL(event.target.files[0]);
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  save() {
    let fileName = "";
    this.setState({
      uploading: true,
    });
    const uploadFile = new Promise((resolve, reject) => {
      if (this.state.file) {
        fileName = "posts/" + Date.now() + "/" + this.state.file.name;
        resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
      } else {
        resolve();
      }
    });

    const fileUploader = new Promise((resolve, reject) => {
      let url = baseUrl + "posts/";
      if (this.state.postID) url += this.state.postID;
      else url += "add";

      resolve(
        create(url, this.state.postID ? "put" : "post", {
          fileKey: fileName ? fileName : undefined,
          fileType: this.state.file
            ? this.state.file.type.split("/")[0]
            : undefined,
          postType: this.state.postType,
          description: this.state.description,
        })
      );
    });

    Promise.all([uploadFile, fileUploader])
      .then((response) => {
        this.getPosts();
        this.setState({
          uploading: false,
          fileKey: "",
          postType: "",
          description: "",
          file: "",
        });
      })
      .catch((error) => {});
  }

  componentDidMount() {
    this.getPosts();
    this.assignUserData();
    this.getClasses();
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  getClasses() {
    get(baseUrl + "classes/list-names")
      .then((response) => {
        this.setState({
          classes: response.data.data,
        });
      })
      .catch((error) => {});
  }

  getPosts(classID) {
    get(
      baseUrl +
        (classID || this.state.classID
          ? "posts/list/" + (classID || this.state.classID)
          : "posts/list")
    )
      .then((response) => {
        this.setState({
          posts: response.data.data,
          // classes: response.data.classes
        });
      })
      .catch((error) => {});
  }

  onClickClass(classID) {
    this.getPosts(classID);
    this.setState({
      classID,
    });
  }

  addAction(postID, type) {
    get(baseUrl + "posts/actions/" + postID + "/" + type)
      .then((response) => {
        let posts = this.state.posts;
        posts.forEach((post) => {
          if (post._id === response.data.data.postID) {
            post.likesCount = response.data.data.likesCount;
            post.flagsCount = response.data.data.flagsCount;
            post.commentsCount = response.data.data.commentsCount;
          }
        });
        console.log(posts);

        this.setState({
          posts,
        });
      })
      .catch((error) => {});
  }

  saveComment() {
    create(baseUrl + "posts/comment/" + this.state.commentsPostID, "post", {
      comment: this.state.comment,
    })
      .then((response) => {
        this.setState({
          comment: "",
        });

        let posts = this.state.posts;
        posts.forEach((post) => {
          if (post._id === response.data.data.postID) {
            post.likesCount = response.data.data.likesCount;
            post.flagsCount = response.data.data.flagsCount;
            post.commentsCount = response.data.data.commentsCount;
            post.comments = response.data.data.comments;
          }
        });

        this.setState({
          posts,
        });
      })
      .catch((error) => {});
  }

  showComments(postID) {
    this.setState({
      commentsPostID: this.state.commentsPostID !== postID ? postID : "",
    });

    get(baseUrl + "posts/comments/" + postID)
      .then((response) => {
        let posts = this.state.posts;
        posts.forEach((post) => {
          if (post._id === response.data.data.postID) {
            post.likesCount = response.data.data.likesCount;
            post.flagsCount = response.data.data.flagsCount;
            post.commentsCount = response.data.data.commentsCount;
            post.comments = response.data.data.comments;
          }
        });
        this.setState({
          posts,
        });
      })
      .catch((error) => {});
  }

  onClickEdit(post) {
    let { _id, postType, description, fileUrl } = post;
    this.setState({
      postID: _id,
      postType,
      description,
      fileUrl,
    });
  }

  removePost(postID) {
    swal
      .fire({
        title: "Are you sure?",
        text: "Are you sure want to remove?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((response) => {
        if (response.value) {
          remove(baseUrl + "posts/" + postID)
            .then((response) => {
              this.getPosts(this.state.classID);
            })
            .catch((error) => {});
        }
      });
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="social-posts-flex-container">
            <div className="scf-left-side">
              <div className="upload-new-post-container">
                <div className="upload-form">
                  <div className="top-row">
                    {/* <label>Upload Post</label> */}
                    <textarea
                      className="social-form-input"
                      value={this.state.description}
                      name="description"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid(
                        "Write something here to upload post..."
                      )}
                    ></textarea>
                  </div>
                  {this.state.file && (
                    <div className="selected-file-section">
                      <div className="image-section">
                        <div
                          className="remove-icon"
                          onClick={() => this.setState({ file: "" })}
                        >
                          <i className="uil-trash" />
                        </div>
                        <img id="uploadedImage" alt="uploaded-image" />
                      </div>
                    </div>
                  )}
                  <div className="bottom-row">
                    <div className="post-type-section">
                      <select
                        className=""
                        alue={this.state.postType}
                        name="postType"
                        onChange={this.setFormField.bind(this)}
                      >
                        <option value="social">{tid("Social")}</option>
                        <option value="arts">{tid("Arts & Crafts")}</option>
                      </select>
                      <div className="choose-file-section">
                        <label httmlFor="socialMediafile">
                          <input
                            type="file"
                            id="socialMediafile"
                            onChange={this.uploadFile}
                          />
                          <i className="uil-image" />
                        </label>
                      </div>
                    </div>
                    <div className="save-button-section">
                      <button onClick={() => this.save()}>
                        {this.state.uploading && (
                          <IconLoader
                            size={2}
                            text=""
                            color={"#fff"}
                          ></IconLoader>
                        )}
                        &nbsp;{tid("Post")}
                      </button>
                    </div>
                  </div>
                  <div className="bottom-row small-text">
                    {this.state.fileUrl && (
                      <a href={this.state.fileUrl} target="_blank">
                        {tid("Attachment")}
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="social-feed-list-container">
                {this.state.posts.map((post, index) => {
                  return (
                    <div className="social-post-container">
                      <div className="social-post-top-container">
                        <div className="sftc-left-side">
                          <div className="sftc-user-details">
                            <span className="notification-avatar">
                              <img
                                src="/assets/images/avatars/avatar-2.jpg"
                                alt=""
                              />
                            </span>
                            <div className="info-section">
                              <h6>
                                {post.user.firstName} {post.user.lastName}
                              </h6>
                              <p>{moment(post.createdAt).calendar()}</p>
                            </div>
                          </div>
                        </div>
                        {this.state.userID == post.createdBy && (
                          <div className="sftc-right-side">
                            <a
                              hred="#"
                              aria-expanded="false"
                              className=" uk-text-right pull-right float-right"
                            >
                              <i className="icon-feather-more-vertical btns"></i>
                            </a>
                            <div
                              className="dropdown-option-nav uk-dropdown uk-dropdown-bottom-right"
                              uk-dropdown="pos: bottom-right ;mode : click"
                            >
                              <ul>
                                <li>
                                  <a onClick={() => this.onClickEdit(post)}>
                                    <i className="icon-feather-edit"></i>{tid("Edit")}
                                  </a>
                                </li>
                                <li>
                                  <a onClick={() => this.removePost(post._id)}>
                                    <i className="icon-feather-x-square"></i>
                                    {tid("Remove")}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="social-post-body-container">
                        {post.postType && (
                          <span className="tag-text">#{post.postType}</span>
                        )}
                        <p className="post-desc">{post.description}</p>
                        {post.fileUrl && (
                          <div className="text-center mh-150 post-bg-section">
                            {post.fileType === "image" && (
                              <img
                                alt="File"
                                className="post-image"
                                src={post.fileUrl}
                              />
                            )}
                            {post.fileType === "video" && (
                              <video controls>
                                <source
                                  src={post.fileUrl}
                                  type="video/mp4"
                                ></source>
                              </video>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="social-post-footer-container">
                        <div className="post-actions-container">
                          <div className="likes-section">
                            <div
                              onClick={() => this.addAction(post._id, "like")}
                            >
                              <i className="icon-feather-thumbs-up"></i>
                              {post.likesCount > 0 && (
                                <span>{post.likesCount}</span>
                              )}
                              {post.likesCount > 1  ? tid("Likes") :tid("Like")}
                            </div>
                          </div>
                          <div className="comments-section">
                            <div onClick={() => this.showComments(post._id)}>
                              <i className="icon-feather-message-square"></i>
                              {post.commentsCount > 0 && (
                                <span>{post.commentsCount}</span>
                              )}
                              {post.commentsCount > 1 ? tid("Comments") : tid("Comment")}
                            </div>
                          </div>
                          <div className="flags-section">
                            <div
                              onClick={() => this.addAction(post._id, "flag")}
                            >
                              <i className="icon-feather-flag"></i>
                              {post.flagsCount > 0 && (
                                <span>{post.flagsCount}</span>
                              )}
                              {post.commentsCount > 1 ? tid("Flags") : tid("Flag")}

                            </div>
                          </div>
                        </div>
                        {this.state.commentsPostID === post._id && (
                          <div className="post-comments-list-container">
                            <div className="post-comments">
                              {post.comments.map((comment, j) => {
                                return (
                                  <div
                                    key={"comment-" + index + "-" + j}
                                    className="comment-row"
                                  >
                                    <h6>{comment.user.name}</h6>
                                    <p className="date-time">
                                      {moment(comment.createdAt).calendar()}
                                    </p>
                                    <p>{comment.comment}</p>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="comment-form-container">
                              <div className="cfc-left-side">
                                <input
                                  type="text"
                                  value={this.state.comment}
                                  name="comment"
                                  onChange={this.setFormField.bind(this)}
                                  placeholder={tid("Enter your Comments")}
                                />
                              </div>
                              <div className="cfc-right-side">
                                <button
                                  onClick={() => this.saveComment()}
                                  type="button"
                                >
                                  <i className="uil-message" />
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {this.state.posts.length === 0 && (
                  <span className="row col-lg-12 pt-2">{tid("No posts found")}</span>
                )}
              </div>
            </div>
            <div className="scf-right-side">
              <h4> {tid("Filter By Class")} </h4>
              <ul
                className="sidebar-filter-list uk-accordion"
                uk-accordion="multiple: true"
              >
                <li className="uk-open">
                  <label>
                    <input
                      checked={this.state.classID == "all"}
                      value={this.state.classID}
                      className="uk-radio"
                      type="radio"
                      name="radio1"
                      onClick={() => this.onClickClass("all")}
                    />
                    <span className="test"> {tid("All Classes")} </span>
                  </label>
                </li>
                {this.state.classes.map((c, index) => {
                  return (
                    <li key={"classes-" + index} className="uk-open">
                      <label>
                        <input
                          checked={this.state.classID === c._id}
                          value={c._id}
                          className="uk-radio"
                          type="radio"
                          name="radio1"
                          onClick={() => this.onClickClass(c._id)}
                        />
                        <span className="test"> {c.name} </span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(SocialComponent);
