import React from "react";
import { Link } from "react-router-dom";
import swal from 'sweetalert2';
import SimpleReactValidator from 'simple-react-validator';
import { baseUrl } from "../../../config";
import { get, create, remove } from "../../../utils";
import { WithTranslations } from "../../../i18n/withTranslations";
// import Loader from "../../shared/loader";

class AddStaffsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  remove(userID) {
    swal.fire({
      title: "Are you sure?",
      text: "Want to delete the staff?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((response) => {
        if (response.value) {
          remove(baseUrl + "users/" + userID)
            .then((response) => {
              this.getLeaves();
            })
        }
      });
  }


  render() {
    let { staff, index } = this.props;
    const {translations}=this.props;

    return (
      <div key={"staff-" + index} className='ac-staff-card-container'>
        <div className='ac-staff-card-top-container'>
          <div className='left-side'>
            <div className='image-section'>
              <img alt="Placeholder" src={staff.profilePicUrl || "/assets/images/profile.svg"} />
            </div>
          </div>
          <div className='right-side'>
            <h5>{staff.firstName} </h5>
            <p>{staff.userType}</p>
            <p><i className='uil-phone' />{staff.mobile}</p>
            <p className='subject-link' onClick={() => this.props.payroll()}>{translations.PAYROLL_DETAILS ||`Payroll Details`} <i className='uil-arrow-right' /></p>
          </div>
        </div>
        <div className='ac-staff-card-bottom-container'>
          <div onClick={() => this.props.showAdd()} uk-tooltip="Edit"><i className="uil-edit" />{translations.EDIT|| `Edit`}</div>
          <div uk-tooltip="Delete" onClick={() => this.remove(staff._id)}><i className="uil-trash-alt" />{translations.DELETE||`Delete`}</div>
        </div>
          {/* <div className="card-footer text-center py-2">
                <a className="text-muted uk-text-small"> {staff.classes || '0'} Classes </a>
              </div> */}
      </div>
    );
  }
}

export default WithTranslations(AddStaffsComponent);