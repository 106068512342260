import React from "react";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { getUserData } from "../../../utils";
import CharimanDashboardComponent from "./chairman";
import FacultyDashboardComponent from "./faculty";
import ParentDashboardComponent from "./parent";
import PrincipalDashboardComponent from "./principal";
import StaffDashboardComponent from "./staff";
import StudentDashboardComponent from "./student";
import TutorDashboardComponent from "./tutor";
import './index.scss';

class DashboardComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          firstName: '',
          lastName: ''
        }
    }

    assignUserData() {
      let { firstName, lastName, userType, userID, profilePicUrl } = getUserData();
      this.setState({
        firstName,
        lastName,
        userType,
        userID,
        profilePicUrl
      })
    }

    componentDidMount() {
      this.assignUserData();
    }

    render() {
      const { userType, firstName } = this.state;
      const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
      return (
      <div id="wrapper" className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar userType={userType}></Sidebar>
        <Navbar></Navbar>
        <div className={!isSecondaryDashboard ? "app-dashboard-main-container" : "app-dashboard-secondary-container"}>
          <div className="section-small calendar min-h-90">
            { userType === 'chairman' && <CharimanDashboardComponent firstName={firstName} />}
            { userType === 'faculty' && <FacultyDashboardComponent firstName={firstName} />}
            { userType === 'parent' && <ParentDashboardComponent firstName={firstName} />}
            { userType === 'principal' && <PrincipalDashboardComponent firstName={firstName} />}
            { userType === 'staff' && <StaffDashboardComponent firstName={firstName} />}
            { userType === 'student' && <StudentDashboardComponent firstName={firstName} />}
            { userType === 'tutor' && <TutorDashboardComponent firstName={this.state.firstName} />}
          </div>
        </div>
      </div>
      );
    }
}

export default DashboardComponent;