import React from "react";
import SVG from 'react-inlinesvg';
import './chairman.scss';
import {RevenueChart, RevenuePieChart} from './charts/chairman-charts';

import { baseUrl } from "../../../config";
import { get } from "../../../utils";

const colors = ['rgba(55,125,255,.2)', 'rgba(222,68,55,.2)', 'rgba(0,201,167,.2)', 'rgba(255,193,7,.2)', 'rgba(0,223,252,.2)']
export default class ChairmanDashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      stats: [
        {
          name: 'Branches',
          value: 0,
          icon: '/assets/images/dashboard/student.svg'
        },
        {
          name: 'Students',
          value: 0,
          icon: '/assets/images/dashboard/student.svg'
        },
        {
          name: 'Staff',
          value: 0,
          icon: '/assets/images/dashboard/staff.svg'
        },
        {
          name: 'Books',
          value: 0,
          icon: '/assets/images/dashboard/library.svg'
        },
        {
          name: 'Complaints',
          value: 0,
          icon: '/assets/images/dashboard/complaint.svg'
        }
      ],
      branches: [],
      branchNames: [],
      branchWiseRevenues: []
    };
  }

  componentDidMount() {
    this.setState({
      firstName: this.props.firstName,
    });

    get(baseUrl+"dashboard")
    .then((response) => {
      let { stats, branches, branchNames, branchWiseRevenues } = response.data.data;
      let statsData = [];
      this.state.stats.forEach((stat) => {
        stat.value = (stats && stats[stat.name.toLowerCase()])?stats[stat.name.toLowerCase()]:0;
        statsData.push(stat);
      })
      this.setState({
        stats: statsData,
        branches,
        branchNames,
        branchWiseRevenues
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }

  render() {
    const { firstName, stats, branches, branchNames, branchWiseRevenues } = this.state;
    return (
      <div className='dashboard-main-container'>
        <h4 className='main-heading'>
          Hello, {firstName}
        </h4>
        <div className='db-ch-top-flex-container'>
          <div className='left-revenue-container'>
            <h4>Branchwise Revenue Report</h4>
            <RevenueChart branchNames={branchNames} branchWiseRevenues={branchWiseRevenues} />
          </div>
          <div className='right-revenue-container'>
            <h4>Branchwise Revenue</h4>
            <RevenuePieChart branches={branches} />
          </div>
        </div>
        <div className='db-ch-bottom-flex-container'>
          {stats && stats.map((s, index) => {
            const { name, value, icon} = s;
            return (
              <div className='stat-card-container'>
                <div className='stat-right-side-section'>
                  <h3>{value}</h3>
                  <h5>{name}</h5>
                </div>
                <div className='stat-left-side-section' style={{backgroundColor: colors[index]}}>
                  <SVG src={icon} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
