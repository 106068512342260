import React from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../../i18n/LanguageProvider";

const WebsiteFooter = () => {
  const languageContext = React.useContext(LanguageContext)
  const { content: pc } = languageContext;
  return (
    <div>
      <div className="footer-flex-section">
        <div className="footer-app-details-section">
          <div className="app-logo-section">
            <Link to={"/"} className="uk-text-lead uk-text-bold">
              <img
                className="footer-logo"
                src={"/assets/images/logo.jpg"}
                alt="TeTu"
              />
            </Link>
          </div>
          <p className="footer-app-desc">
            {pc.PAGE_MAIN_SUBHEADING}
          </p>
        </div>
        <div className="footer-benefits-links-section">
          <h3 className="footer-link-section-heading">TeTu 2.0</h3>
          <ul className="benefits-links-section">
            <li>
              <Link to={"/artificial-intelligence"}>
                {pc.AI_HEADING}
              </Link>
            </li>
            <li>
              <Link to={"/virtual-reality"}>{pc.VR_HEADING}</Link>
            </li>
            <li>
              <Link to={"/augumented-reality"}>{pc.AR_HEADING}</Link>
            </li>
            <li>
              <Link to={"/3d-view"}>{pc.THREE_D_HEADING}</Link>
            </li>
          </ul>
        </div>
        <div className="footer-company-links-section">
          <h3 className="footer-link-section-heading">{pc.COMPANY_HEADING}</h3>
          <ul className="company-links-section">
            <li>
              <Link to={"/terms-and-conditions"}>{pc.TERMS_HEADING}</Link>
            </li>
            <li>
              <Link to={"/contact-us"}>{pc.CONTACT_US_HEADING}</Link>
            </li>
            <li>
              <Link to={"/privacy-policy"}>{pc.PRIVACY_HEADING}</Link>
            </li>
            {/*  <li>
              <Link to={"/faqs"}>{pc.FAQ_HEADING}</Link>
            </li> */}
          </ul>
        </div>
        <div className="footer-contact-links-section">
          <h3 className="footer-link-section-heading">{pc.CONTACT_US_HEADING}</h3>
          <ul className="company-links-section">
            {/* <li>
              <a href="callto:+918341380020">
                <i className="uil-phone" />
                +91 83413 80020
              </a>
            </li> */}
            <li>
              <a href="mailto:tetu.platform@gmail.com">
                <i className="uil-envelope" />
                tetu.platform@gmail.com{" "}
              </a>
              <a href="tel:+91 9550324800">
                <i className="uil-envelope" />
                +91 95503 24800{" "}
              </a>
            </li>
            <li>
              <a href='https://drive.google.com/file/d/13LOSiJAtsupAqN0qtNyj8NzwEUCkjrlr/view?usp=sharing' target="_blank" className='app-store-link' />
            </li>
            {/* <li>
              <a href='#' className='apple-store-link' />
            </li> */}
            {/* <li className='appstore--image'>
              <a href='#'>
                <img loading="lazy" alt="TeTu" src='/assets/images/appstore.png' />
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="footer-bottom-section">
        <div className="footer-flex-bottom-row">
          <p>
            © 2023 <strong>TeTu</strong> {pc.ALL_RIGHTS_RESERVED_HEADING}<br />
            <small>
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/freepik"
                title="Freepik"
              >
                Freepik
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                {" "}
                www.flaticon.com
              </a>
            </small>
          </p>
          <nav className="footer-bottom-social-icons footer-nav-icon">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/TeTu-108866490913553"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-brand-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/tetu-in"
                  target="_blank"
                >
                  <i className="icon-brand-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/tetu.co.in/" target="_blank">
                  <i className="icon-brand-instagram"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default WebsiteFooter;