import React from "react";
import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";

class FAQsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const bgStyles = {
      backgroundImage:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/faq-bg.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: 550,
    };
    return (
      <div className="homepage-main-container">
        <Navbar />
        <div className="about-us-info-container" style={bgStyles}>
          <div className="about-us-info-section">
            <h4>Confused about something</h4>
            <h2>Checkout Frequently Asked Questions</h2>
            <button className="btn know-more-button">
              Know More
              <i className="uil-arrow-down" />
            </button>
          </div>
        </div>
        <div className='about-us-details-container'>
          <div className='about-us-main-content'>
            <div className="faq-section">
              <div className="text-center">
                <h2>Frequently asked question</h2>
              </div>
              <div className="uk-child-width-1-2@s pt-4" uk-grid={"true"}>
                <div>
                  <h4>What is TeTu?</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </div>

                <div>
                  <h4>Who can use this Platform?</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam ut laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>

                <div>
                  <h4>Can it be used by an individual student?</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </div>

                <div>
                  <h4>Does it supports Multilingual?</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </div>

                <div>
                  <h4>How to Choose a plan first?</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetue aoreet dolore magna
                    aliquam erat volutpat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default FAQsComponent;
