import React from "react";
import swal from "sweetalert2";
// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get, create, remove, uploadToS3, getUserData } from "../../../utils";
import { Multiselect } from "multiselect-react-dropdown";
import "./complaints.scss";
import { WithTranslations } from "../../../i18n/withTranslations";

class ComplaintsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complaints: [],
      showSection: "complaints",
      userType: "",
      complaintType: "add",
      complaintSubject: "",
      complaintMessage: "",
      complaintID: "",
      file: "",
      defendantsList: [],
      defendants: [],
      selected: [],
      uploading: false,
      fileUrl: "",
    };

    this.uploadFile = this.uploadFile.bind(this);
    this.assignUserData = this.assignUserData.bind(this);
    this.complaintValidator = new SimpleReactValidator({});
  }

  onClick(type) {
    this.setState({
      showSection: type,
      complaintType: "add",
      complaintDate: "",
      complaintSubject: "",
      complaintMessage: "",
      complaintID: "",
    });
    if (type === "complaints") {
      this.getComplaints();
    }
    if (type === "new-complaint") {
      this.setState({
        selected: [],
        defendants: [],
      });
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  saveComplaint(tid) {
    if (this.complaintValidator.allValid()) {
      this.setState({
        uploading: true,
      });
      let message = "";
      if (!this.state.complaintID) message = tid("Are you sure want to complaint?");
      else message = tid("Are you sure want to modify?");

      swal
        .fire({
          title: tid("Are you sure?"),
          text: message,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((response) => {
          if (response.value) {
            let fileName =
              "complaints/" +
              moment().format("DD-MM-YYYY") +
              "/" +
              this.state.file.name;
            const uploadPDF = new Promise((resolve, reject) => {
              if (this.state.file)
                resolve(
                  uploadToS3(fileName, this.state.file, this.state.file.type)
                );
              else resolve();
            });

            const fileUploader = new Promise((resolve, reject) => {
              let url = baseUrl + "complaints/";
              if (this.state.complaintID) url += this.state.complaintID;
              else url += "add";

              let defendants = [];
              this.state.selected.forEach((selected) => {
                defendants.push({
                  userID: selected.id,
                });
              });

              resolve(
                create(url, this.state.complaintID ? "put" : "post", {
                  subject: this.state.complaintSubject,
                  message: this.state.complaintMessage,
                  fileKey: this.state.file ? fileName : undefined,
                  fileType: this.state.file
                    ? this.state.file.type.split("/")[0]
                    : undefined,
                  defendants,
                })
              );
            });

            Promise.all([uploadPDF, fileUploader]).then((response) => {
              this.setState({
                uploading: false,
                complaintDate: "",
                complaintSubject: "",
                complaintID: "",
                complaintMessage: "",
                showSection: "complaints",
                complaintType: "add",
              });
            });
          }
        })
        .catch((error) => {});
    } else {
      this.complaintValidator.showMessages();
      this.forceUpdate();
    }
  }

  resolveComplaint(complaintID, type, title,tid) {
    swal
      .fire({
        title:tid("Are you sure?"),
        text: tid("Are you sure want to ") + tid(title) + "?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((response) => {
        if (response.value) {
          create(baseUrl + "complaints/" + complaintID, "put", {
            status: type,
          })
            .then((response) => {
              this.getComplaints();
              this.setState({
                showSection: "complaints",
                complaintType: "add",
              });
            })
            .catch((error) => {});
        }
      });
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] });
  }

  getComplaints() {
    get(baseUrl + "complaints/list").then((response) => {
      this.setState({
        complaints: response.data.data,
      });
    });
  }

  onClickComplaint(complaint, type) {
    this.setState({
      fileUrl: complaint.fileUrl || "",
      complaintSubject: complaint.subject,
      complaintMessage: complaint.message,
      complaintID: complaint._id,
      showSection: "new-complaint",
      complaintType: type,
      selected: complaint.defendants,
      defendants: complaint.defendants,
    });
  }

  removeComplaint(complaintID,tid) {
    swal
      .fire({
        title: tid("Are you sure?"),
        text: tid("Want to delete the complaint?"),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((response) => {
        if (response.value) {
          remove(baseUrl + "complaints/" + complaintID).then((response) => {
            this.getComplaints();
          });
        }
      });
  }

  componentDidMount() {
    this.getComplaints();
    this.usersList();
    this.assignUserData();
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  onChangeList(selectedList, selectedItem) {
    this.setState({ selected: selectedList });
  }

  usersList() {
    get(baseUrl + "users/list/all").then((response) => {
      let defendantsList = [];
      response.data.data.forEach((user) => {
        defendantsList.push({
          id: user._id,
          name: user.firstName + " " + user.lastName,
        });
      });
      this.setState({
        defendantsList,
      });
    });
  }

  render() {
    const { userType } = this.state;
    const {tid}=this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          {this.state.showSection === "complaints" && (
            <div>
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2 className="">{tid("Complaints")}</h2>
                </div>
                <div className="app-page-header-right">
                  <button
                    className="app-add-option-button"
                    onClick={() => this.onClick("new-complaint")}
                  >
                    <i className="uil-plus" />
                    {tid("Add Complaint")}
                  </button>
                </div>
              </div>
              <div className="table-responsive complaints-list-data-main-container">
                <table id="table-extended-transactions" className="table mb-0">
                  <thead>
                    <tr className="table-header-row">
                      <th>{tid("S.No")}</th>
                      <th>{tid("Status")}</th>
                      {this.state.userType !== "student" && <th>{tid("Name")}</th>}
                      {this.state.userType !== "student" && <th>{tid("Class Name")}</th>}
                      <th>{tid("Subject")}</th>
                      <th>{tid("Attachment")}</th>
                      <th>{tid("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.complaints.map((complaint, index) => {
                      return (
                        <tr
                          className="table-data-row"
                          key={"complaint-" + index}
                        >
                          <td>{index + 1}</td>
                          <td>{complaint.status}</td>
                          {this.state.userType !== "student" && (
                            <td>{complaint.name}</td>
                          )}
                          {this.state.userType !== "student" && (
                            <td>{complaint.className}</td>
                          )}
                          <td>{complaint.subject}</td>
                          <td>
                            {complaint.fileUrl && (
                              <a href={complaint.fileUrl} target="_blank">
                                {tid("View")}
                              </a>
                            )}
                          </td>
                          <td className="text-bold-600">
                            <button
                              className="btn btn-success btn-xs"
                              onClick={() =>
                                this.onClickComplaint(complaint, "view")
                              }
                            >
                              {tid("View")}
                            </button>
                            &nbsp;
                            <button
                              className="btn btn-info btn-xs"
                              onClick={() =>
                                this.onClickComplaint(complaint, "edit")
                              }
                            >
                              {tid("Edit")}
                            </button>{" "}
                            &nbsp;
                            {this.state.userType === "student" && (
                              <button
                                className="btn btn-danger btn-xs"
                                onClick={() =>
                                  this.removeComplaint(complaint._id,tid)
                                }
                              >
                                {tid("Remove")}
                              </button>
                            )}
                            {this.state.userType !== "student" &&
                              this.state.userType !== "parent" && (
                                <button
                                  className="btn btn-danger btn-xs"
                                  onClick={() =>
                                    this.resolveComplaint(
                                      complaint._id,
                                      "rejected",
                                      "reject",
                                      tid
                                    )
                                  }
                                >
                                  {tid("Reject")}
                                </button>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.complaints.length === 0 && (
                      <td colSpan="6">
                        <div className="text-center">
                          {tid("No Complaints applied..")}
                        </div>
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {this.state.showSection === "new-complaint" && (
            <div className="">
              <div className="app-page-header-section">
                <div className="app-page-header-left">
                  <h2>
                    <span
                      className="pointer"
                      onClick={() => this.onClick("complaints")}
                    >
                      <i className="uil-arrow-left" />
                    </span>{" "}
                    {this.state.complaintType === "add"
                      ? tid("Add New Complaint")
                      : this.state.complaintType === "view"
                      ? tid("View Complaint")
                      : tid("Edit Complaint")}
                  </h2>
                </div>
              </div>
              <div className="add-complaint-form-container">
                <div className="card-content">
                  <div className="reason-input-section">
                    <h5 className="uk-text-bold mb-2"> {tid("Subject")} </h5>
                    <input
                      type="text"
                      className="uk-input"
                      value={this.state.complaintSubject}
                      name="complaintSubject"
                      disabled={this.state.complaintType === "view"}
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Subject")}
                    />
                    {this.complaintValidator.message(
                      "Subject",
                      this.state.complaintSubject,
                      "required"
                    )}
                  </div>

                  <div className="reason-input-section">
                    <h5 className="uk-text-bold mb-2"> {tid("Select Defendants")} </h5>
                    <Multiselect
                      className="form-control"
                      options={this.state.defendantsList}
                      value={this.state.selected}
                      selectedValues={this.state.selected}
                      onSelect={this.onChangeList.bind(this)}
                      onRemove={this.onChangeList.bind(this)}
                      displayValue="name"
                    />
                  </div>

                  <div className="reason-input-section">
                    <h5 className="uk-text-bold mb-2"> {tid("Message")} </h5>
                    <textarea
                      type="text"
                      className="uk-input"
                      rows="2"
                      value={this.state.complaintMessage}
                      name="complaintMessage"
                      disabled={this.state.complaintType === "view"}
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Complaint Message")}
                    ></textarea>
                    {this.complaintValidator.message(
                      "Message",
                      this.state.complaintMessage,
                      "required"
                    )}
                  </div>

                  <div className="reason-input-section ">
                    <h5 className="uk-text-bold mb-2"> {tid("Attach Image")} </h5>
                    <input
                      accept="image/*"
                      type="file"
                      className="uk-input row col-lg-6"
                      onChange={this.uploadFile}
                      name="complaintSubject"
                      placeholder={tid("Input File")}
                    />
                  </div>

                  <div className="bottom-buttons-section">
                    <button
                      className="btn btn-link mr-2"
                      onClick={() => this.onClick("complaints")}
                    >
                      {tid("Cancel")}
                    </button>
                    {this.state.complaintType !== "view" && (
                      <button
                        className="btn btn-default grey"
                        onClick={() => this.saveComplaint(tid)}
                      >
                        {this.state.uploading && (
                          <IconLoader
                            size={2}
                            text=""
                            color={"#fff"}
                          ></IconLoader>
                        )}
                        &nbsp; {tid("Confirm")} &{" "}
                        {this.state.complaintType !== "edit"
                          ? tid("Submit")
                          : tid("Modify")}
                      </button>
                    )}
                    {this.state.complaintType === "view" &&
                      this.state.userType !== "student" &&
                      this.state.userType !== "parent" && (
                        <button
                          className="btn btn-default grey"
                          onClick={() =>
                            this.resolveComplaint(
                              this.state.complaintID,
                              "resolved",
                              "resolve"
                            )
                          }
                        >
                          {tid("Resolved")}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WithTranslations(ComplaintsComponent);
