import React from "react";
import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";
import { WithTranslations } from "../../../i18n/withTranslations";

const aiActivitisEN = [
  "Educational software can be adapted to student needs.",
"It can point out places where courses need to improve.",
"Students could get additional support from AI tutors.",
"AI-driven programs can give students and educators helpful feedback.",
"It is altering how we find and interact with information.",
"It could change the role of teachers.",
"AI can make trial-and-error learning less intimidating.",
"Data powered by AI can change how schools find, teach, and support students.",
"AI may change where students learn, who teaches them, and how they acquire basic skills.",

];
const aiActivitisIT = [`Il software educativo può essere adattato alle esigenze degli studenti.`,
`Può indicare i punti in cui i corsi devono migliorare.`,
`Gli studenti potrebbero ottenere supporto aggiuntivo da tutor di intelligenza artificiale.`,
`I programmi basati sull'intelligenza artificiale possono fornire utili feedback a studenti ed educatori.`,
`Sta alterando il modo in cui troviamo e interagiamo con le informazioni.`,
`Potrebbe cambiare il ruolo degli insegnanti.`,
`L'intelligenza artificiale può rendere l'apprendimento basato su prove ed errori meno intimidatorio.`,
`I dati alimentati dall'intelligenza artificiale possono cambiare il modo in cui le scuole trovano, insegnano e supportano gli studenti.`,
`L'intelligenza artificiale può cambiare il modo in cui gli studenti imparano, chi insegna loro e come acquisiscono le competenze di base.`,]

class ArtificialIntelligenceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { siteLanguage ,translations } = this.props;
    debugger;
const activities = siteLanguage==="it" ? aiActivitisIT : aiActivitisEN;
    const bgStyles = {
      backgroundImage:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/ai-bg.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: 550,
    };
    return (
      <div className="homepage-main-container">
        <Navbar />
        <div className="about-us-info-container" style={bgStyles}>
          <div className="about-us-info-section">
            <h4>{translations.MISSION || `We're on a mission to use`}</h4>
            <h2>
              {translations.IN_EDU ||
                `Artificial Intelligence in Education and bring all the benefits`}
            </h2>
            <button className="btn know-more-button">
              {translations.KNOW_MORE || `Know More`}
              <i className="uil-arrow-down" />
            </button>
          </div>
        </div>
        <div className="about-us-details-container">
          <div className="about-us-main-content">
            <h3 className="text-center">
              {translations.AI_HEADING || `Artificial Intelligence`}
            </h3>
            <div className="row tetu-2-extra-details-container">
              <div className="col-md-6">
                <p className="tetu-2-main-desc">
                  {translations.AI__P1 ||
                    ` AI has already been applied to education primarily in some
                  tools that help develop skills and testing systems. ... AI can
                  drive efficiency, personalization and streamline admin tasks
                  to allow teachers the time and freedom to provide
                  understanding and adaptability—uniquely human capabilities
                  where machines would struggle`}
                </p>
                <p className="tetu-2-main-desc">
                  {translations.AI__P2 ||
                    ` AI is increasingly used in education and learning contexts.
                  The collision of three areas – data, computation and education
                  – is set to have far-reaching consequences, raising
                  fundamental questions about the nature of education: what is
                  taught and how it is taught.`}
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src={"/assets/images/artificial-intelligence.jpg"}
                  alt="Artificial Intelligence"
                  className="bordered-image-tetu-2"
                />
              </div>
            </div>
            <div className="benefits-details-main-section">
              <h4 className="text-center">
                {translations.AI__BENEFITS ||
                  `Benefits by using Artificial Intelligence`}
              </h4>
              <p>
                {translations.AI__P3|| `The first step involves the design engineer getting a solid idea
                of what kind of scene is to be created for the client based on
                the consultation. The plans will be drawn up, sketches made, and
                reference images identified that will give the design team
                direction.`}
              </p>
              <ul className="benefits-details-main-section-list">
               
                <li>
                  <i className="uil-arrow-right" />
                  {translations.AI__P4 ||
                    `Artificial intelligence can automate basic activities in
                  education, like grading.`}
                </li>
                {
                  activities.map(act=> <li key={act}>
                    <i className="uil-arrow-right" />
                   {act}
                  </li>)
                }
               
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default WithTranslations(ArtificialIntelligenceComponent);
