import React from "react";
// import swal from 'sweetalert2';
import { Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get, create } from "../../../utils";
import StudentsListComponent from '../students/students-list';
import SubjectsListComponent from '../subjects/subjects-list';
import ListStaffComponent from '../staff/staff-list';
import './class-details.scss'
import ClassPerformance from "./class-performance";
import NoResults from "../../shared/no-results/no-results";
import { WithTranslations } from "../../../i18n/withTranslations";

class ClassDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classID: '',
      tests: [],
      subjects: [],
      staff: [],
      classTeacherID: '',
      classTeacher: {},
      performance: [], 
      stats: null, 
      grades: [], 
      reports: []
    }
  }

  componentDidMount() {
    let { classID } = this.props.match.params;
    this.setState({
      classID
    })
    this.getClassDetails(classID);
  }

  getClassDetails(classID) {
    get(baseUrl + "classes/details/" + classID)
      .then((response) => {
        let { performance, stats, grades, reports } = response.data.data;
        this.setState({
          performance, stats, grades, reports
        })
      })
      .catch((error) => {
      });
  }

  getClassTeacher(classID) {
    get(baseUrl + "classes/" + classID)
      .then((response) => {
        let { classTeacherID, name, standard } = response.data.data;
        this.setState({
          classTeacher: classTeacherID, name, standard
        })
      })
      .catch((error) => {
      });
  }

  getStudentsList(classID) {
    get(baseUrl + "users/list/student/" + classID)
      .then((response) => {
        this.setState({
          students: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  getStaff() {
    get(baseUrl + "users/list/faculty/"+this.state.classID)
      .then((response) => {
        this.setState({
          staff: response.data.data
        })
        this.updateStaff(response.data.data);
      })
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
    create(baseUrl + "classes/" + this.props.match.params.classID, "put", {
      classTeacherID: event.target.value
    })
      .then((response) => {
        this.updateStaff(this.state.staff);
      })
  }

  updateStaff(staff) {
    staff.forEach((st) => {
      if (st._id === this.state.classTeacherID)
        this.setState({
          classTeacher: st
        })
    })
  }

  render() {
    const { performance, stats, grades, reports } = this.state;
    let { classTeacher } = this.state;
const {tid}=this.props;
    return (
      <div id="wrapper" className="admin-panel">
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{tid('Class Details')}
            </h2>
            </div>
            <div className="app-page-header-right">
            </div>
          </div>
          <div className="classes-details-main-container">
            <div className='class-details-tabs-container'>
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="performance-tab" data-toggle="tab" href="#performance" aria-controls="home" role="tab"
                    aria-selected="true">
                    <i className="uil-graph-bar" />
                    <span className="align-middle">{tid('Overall Performance')}</span>
                  </a>
                </li>
                <li className="nav-item" onClick={() => this.getStudentsList(this.state.classID)}>
                  <a className="nav-link " id="students-tab" data-toggle="tab" href="#students" aria-controls="home" role="tab"
                    aria-selected="true">
                    <i className="fa fa-user"></i>
                    <span className="align-middle">{tid('Students')}</span>
                  </a>
                </li>
                <li className="nav-item" >
                  <a className="nav-link" id="subjects-tab" data-toggle="tab" href="#subjects" aria-controls="subjects" role="tab"
                    aria-selected="false">
                    <i className="uil-book-open"></i>
                    <span className="align-middle">{tid('Subjects')}</span>
                  </a>
                </li>
                <li className="nav-item" onClick={() => this.getStaff()}>
                  <a className="nav-link" id="teachers-tab" data-toggle="tab" href="#teachers" aria-controls="subjects" role="tab"
                    aria-selected="false">
                    <i className="uil-users-alt"></i>
                    <span className="align-middle">{tid('Teachers')}</span>
                  </a>
                </li>
                <li className="nav-item"  onClick={() => this.getClassTeacher(this.state.classID)}>
                  <a className="nav-link" id="class-teacher-tab" data-toggle="tab" href="#class-teacher" aria-controls="class-teacher" role="tab"
                    aria-selected="false">
                    <i className="fa fa-list"></i>
                    <span className="align-middle">{tid('Class Teacher')}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-content">
              <div className="card-body">
                <div className="tab-content">
                  <div className="tab-pane active" id="performance" aria-labelledby="performance-tab" role="tabpanel">
                    {stats && <ClassPerformance performance={performance} stats={stats} grades={grades} reports={reports} />}
                  </div>
                  <div className="tab-pane" id="students" aria-labelledby="students-tab" role="tabpanel">
                    <StudentsListComponent hideStudents={true} classID={this.props.match.params.classID}></StudentsListComponent>
                  </div>
                  <div className="tab-pane" id="subjects" aria-labelledby="subjects-tab" role="tabpanel">
                    <div className="card">
                      <SubjectsListComponent showTitle={false} create={true} edit={true} userType={this.state.userType} classID={this.props.match.params.classID}></SubjectsListComponent>
                    </div>
                  </div>
                  <div className="tab-pane" id="teachers" aria-labelledby="teachers-tab" role="tabpanel">
                  <div className="ac-staff-list-flex-container">
                    {this.state.staff.map((staff, index) => {
                      return (
                        <ListStaffComponent staff={staff} index={index} payroll={() => this.setState({ staffID: staff._id, showSection: 'payroll' })} showAdd={() => this.setState({ staffID: staff._id, showSection: 'add-staff' })}></ListStaffComponent>
                      )
                    })}
                    {!this.state.staff.length && <NoResults title="No Teachers Found." /> }
                  </div>
                  </div>
                  <div className="tab-pane" id="class-teacher" aria-labelledby="class-teacher-tab" role="tabpanel">
                    <div className="row col-lg-12">
                      <div className="col-lg-6">
                        <label>{tid('Class Teacher')}: </label>
                        <select className="uk-input" value={this.state.classTeacherID} name="classTeacherID" onChange={this.setFormField.bind(this)} placeholder="Class Teacher ID">
                          <option value="">{tid('Select Class Teacher')}</option>
                          {
                            this.state.staff.map((faculty, index) => {
                              return <option key={"faculty-" + index} value={faculty._id}> {faculty.firstName} {faculty.lastName}</option>
                            })
                          }
                        </select>
                      </div>
                      <div className="col-lg-6">
                        {classTeacher && <div className='db-p-class-teacher-details'>
                          <p className='class-teacher'>{tid('Class Teacher')}</p>
                          <p>{classTeacher.firstName} {classTeacher.lastName}</p>
                          <p><i className='uil-phone' /> {classTeacher.mobile}</p>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(ClassDetailsComponent);