import React from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { baseUrl } from "../../../config";
import { LanguageContext } from "../../../i18n/LanguageProvider";
import { create } from "../../../utils";
import "./login.scss";
import SchoolCode from "./school-code";

class ManagementLoginComponent extends React.Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: "",
      faculty_username: "",
      faculty_password: "",
      faculty_message: "",
      activeTab: "Faculty",
      activePage: "SchoolCode",
      schoolCode: "",
    };

    this.principalLogin = this.principalLogin.bind(this);
    this.facultyLogin = this.facultyLogin.bind(this);

    this.principalValidator = new SimpleReactValidator({});
    this.facultyValidator = new SimpleReactValidator({});
  }

  componentDidMount() {
    const schoolCode = localStorage.getItem("schoolcode");
    if (schoolCode) {
      this.setState({
        activePage: "login",
        schoolCode,
      });
    } else {
      sessionStorage.clear();
    }
  }

  principalLogin() {
    if (this.principalValidator.allValid()) {
      this.requestAPI({
        username: this.state.username,
        password: this.state.password,
        userType: "principal",
      });
    } else {
      this.principalValidator.showMessages();
      this.forceUpdate();
    }
  }

  facultyLogin() {
    if (this.facultyValidator.allValid()) {
      this.requestAPI({
        username: this.state.faculty_username,
        password: this.state.faculty_password,
        userType: "faculty",
      });
    } else {
      this.facultyValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  requestAPI(data) {
    if(data.username && data.password) {
      create(baseUrl + "users/login", "post", data)
        .then((response) => {
          if (response.data.success) {
            sessionStorage.setItem("token", response.data.data);
            sessionStorage.setItem( "permissionsToken", response.data.permissionsToken);
            sessionStorage.setItem("academicYearToken", response.data.academicYearToken);
            this.props.history.push("/dashboard");
          } else {
            this.setState({
              message: response.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleOnEditSchoolCode = () => {
    this.setState({
      activePage: "SchoolCode",
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { activeTab } = this.state;
    const fn =
      activeTab === "Faculty" ? this.facultyLogin : this.principalLogin;
    fn();
  };

  render() {
    const { content } = this.context;
    const { activeTab, activePage, schoolCode } = this.state;
    const bgStyles = {
      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(62, 65, 109, 0.95)), url(/assets/images/${activeTab}.jpg)`,
      backgroundSize: "cover",
      backgroundPosition: "center center",
    };
    return (
      <div className="login-page-main-container">
        <div className="login-page-layout">
          <div className="login-page-flex-section">
            <div className="left-side-section" style={bgStyles}>
              <div className="app-details-section">
                <Link to={"/"}>
                  <img
                    className="main-logo"
                    src={"/assets/images/logo.jpg"}
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="extra-info-container">
                {activeTab === "Faculty" && (
                  <div className="student-info-container">
                    <h4>{content.WELCOMEBACK_FACULTY || 'Welcome Back Faculty!'}</h4>
                    <p>
                      {content.WELCOMEBACK_FACULTY_DESC||`Access the most advanced teaching features by logging into
                      your account.`}
                    </p>
                    <ul>
                      <li>
                        <i className="uil-arrow-right" /> {content.LIVE_CLASSES||'Live Classes'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.QUALITY_VIDEO_LECTURES ||'Quality Video lectures'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.ONLINE_ASSESSMENTS_AND_QUIZES||`Online Assignments &
                        Quizzes`}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.SKILL_DEV_PROGRAMS||`Skill Development
                        Programmes`}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.MANY_MORE||'Many more...'}
                      </li>
                    </ul>
                  </div>
                )}
                {activeTab === "Principal" && (
                  <div className="student-info-container">
                    <h4>{content.WELCOMEBACK_PRINCIPAL||'Welcome Back Principal!'}</h4>
                    <p>
                      {content.WELCOMEBACK_PRINCIPAL_DESC||`Easily access your School & Academic details by logging
                      into your account.`}
                    </p>
                    <ul>
                      <li>
                        <i className="uil-arrow-right" /> {content.STUDENT_PERFORMANCE||'Student Performance'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.STUDENT_ATTENDANCE||'Student Attendance'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.ONLINE_FEE_PAYMENTS||'Online Fee Payments'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.PARENT_MEETINGS||'Parent Meetings'}
                      </li>
                      <li>
                        <i className="uil-arrow-right" /> {content.MANY_MORE|| 'Many more...'}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {/* <img src='/assets/images/login.png' className='login-illustration-image' alt="Login"/> */}
            </div>
            {activePage === "login" && (
              <div className="right-side-section">
                <div className='image-section-xs'>
                  <Link to={"/"}>
                    <img
                      className="main-logo"
                      src={"/assets/images/logo.jpg"}
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="login-tab-flex-section">
                  <div
                    className={`student-section ${
                      activeTab === "Faculty" ? "active-tab" : ""
                    }`}
                    onClick={() => this.setState({ activeTab: "Faculty" })}
                  >
                    <img
                      src={
                        activeTab === "Faculty"
                          ? "/assets/images/faculty-white.svg"
                          : "/assets/images/faculty.svg"
                      }
                      className="login-tab-image"
                      alt="Faculty"
                      style={{ maxWidth: 40, maxHeight: 40 }}
                    />
                    <h4 className="login-tab-heading">{content.FACULTY||'Faculty'}</h4>
                  </div>
                  <div
                    className={`parent-section ${
                      activeTab === "Principal" ? "active-tab" : ""
                    }`}
                    onClick={() => this.setState({ activeTab: "Principal" })}
                  >
                    <img
                      src={
                        activeTab === "Principal"
                          ? "/assets/images/principal-white.svg"
                          : "/assets/images/principal.svg"
                      }
                      className="login-tab-image"
                      alt="Principal"
                    />
                    <h4 className="login-tab-heading">{content.PRINCIPAL||'Principal'}</h4>
                  </div>
                </div>
                <div className="code-data-container">
                  <div className="school-code-section">
                    <p>
                      <span>
                      {content.SCHOOL_CODE || 'Code '}
                        <i
                          className="uil-info-circle"
                          uk-tooltip={content.SCHOOL_CODE_HELP || "School / College / Institute / Tutor Code"}
                        />
                      </span>{" "}
                      {schoolCode}
                    </p>
                    <i
                      className="uil-edit"
                      onClick={this.handleOnEditSchoolCode}
                    />
                  </div>
                </div>
                {activeTab === "Faculty" && (
                  <div className="login-form-container-main">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> {content.EMAIL_ADDRESS||"Email Address"}</label>
                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-mail"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="email"
                          placeholder={content.EMAIL_ADDRESS||"Email Address"}
                          value={this.state.faculty_username}
                          name="faculty_username"
                          onChange={this.setFormField.bind(this)}
                        />
                      </div>
                      {this.facultyValidator.message(
                        "Email Address",
                        this.state.faculty_username,
                        "required|email"
                      )}
                    </div>
                    <div className="uk-form-group">
                      <label className="uk-form-label"> {content.PASSWORD||'Password'}</label>
                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-lock"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="password"
                          name="faculty_password"
                          placeholder={content.PASSWORD||'Password'}
                          value={this.state.faculty_password}
                          onChange={this.setFormField.bind(this)}
                        />
                      </div>
                      {this.facultyValidator.message(
                        "Password",
                        this.state.faculty_password,
                        "required"
                      )}
                    </div>
                    <div className="uk-width-auto@s">
                      <span className="red-text text-center">
                        {this.state.message}
                      </span>
                      <button
                        onClick={() => this.facultyLogin()}
                        className="btn btn-default login-button"
                      >
                        {content.LOGIN||'Login'}
                      </button>
                    </div>
                  </div>
                )}
                {activeTab === "Principal" && (
                  <div className="login-form-container-main">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> {content.EMAIL_ADDRESS||"Email Address"}</label>
                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-mail"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="email"
                          placeholder={content.EMAIL_ADDRESS||"Email Address"}
                          value={this.state.username}
                          name="username"
                          onChange={this.setFormField.bind(this)}
                        />
                      </div>
                      {this.principalValidator.message(
                        "Email Address",
                        this.state.username,
                        "required|email"
                      )}
                    </div>
                    <div className="uk-form-group">
                      <label className="uk-form-label"> {content.PASSWORD||'Password'}</label>
                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-lock"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.setFormField.bind(this)}
                          placeholder={content.PASSWORD||'Password'}
                        />
                      </div>
                      {this.principalValidator.message(
                        "Password",
                        this.state.password,
                        "required"
                      )}
                    </div>
                    <div className="uk-width-auto@s">
                      <span className="red-text text-center">
                        {this.state.message}
                      </span>
                      <button
                        onClick={() => this.principalLogin()}
                        className="btn btn-default login-button"
                      >
                      {content.LOGIN||'Login'}
                      </button>
                    </div>
                  </div>
                )}
                <div className="faculty-login-link-section">
                  <Link to={"/login"}>{content.STUDENT_LOGIN||'Student Login'}</Link>
                </div>
              </div>
            )}
            {activePage === "SchoolCode" && (
              <div className="right-side-section">
                <SchoolCode
                  schoolCode={schoolCode}
                  showLogin={(code) =>
                    this.setState({ activePage: "login", schoolCode: code })
                  }
                ></SchoolCode>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ManagementLoginComponent;
