export const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 }
]

export const years = [
    { name: "2018", value: 2018 },
    { name: "2019", value: 2019 },
    { name: "2020", value: 2020 },
    { name: "2021", value: 2021 },
    { name: "2022", value: 2022 },
    { name: "2023", value: 2023 },
    { name: "2024", value: 2024 },
    { name: "2025", value: 2025 },
    { name: "2026", value: 2026 },
    { name: "2027", value: 2027 },
    { name: "2028", value: 2028 },
    { name: "2029", value: 2029 },
    { name: "2030", value: 2030 },
    { name: "2031", value: 2031 },
    { name: "2032", value: 2032 },
    { name: "2033", value: 2033 },
    { name: "2034", value: 2034 },
    { name: "2035", value: 2035 },
]


export const localLanguages = [
  {
    name: "English",
    value: 'en-US',
  },
 
  {
    name: "Bengali",
    value: 'be',
  },
  {
    name: "Hindi",
    value: 'hi',
  },
  {
    name: "Kannada",
    value: 'ka',
  },
  {
    name: "Malayalam",
    value: 'ma',
  },
  {
    name: "Marathi",
    value: 'mr',
  },
  {
    name: "Tamil",
    value: 'ta',
  },
  {
    name: "Telugu",
    value: 'te',
  },
  {
    name: "Urdu",
    value: 'ur',
  },
  {
    name: "Oriya",
    value: 'or',
  }
]
export const days = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY"
]

export const subjectIcons = [
    {
        name: 'english',
        image: '/assets/images/dashboard/english.png',
      },
      {
        name: 'maths',
        image: '/assets/images/dashboard/maths.png',
      },
      {
        name: 'physics',
        image: '/assets/images/dashboard/physics.png',
      },
      {
        name: 'biology',
        image: '/assets/images/dashboard/biology.png',
      },
      {
        name: 'social',
        image: '/assets/images/dashboard/social.png',
      },
      {
        name: 'chemistry',
        image: '/assets/images/dashboard/chemistry.png',
      }
]

export const earningOptions = [
  'Basic',
  'House Rent Allowance',
  'Special Allowance',
  'Conveyance',
  'Additional Special Allowance',
  'On call/Shift Allowance',
  'Stat Bonus'
]

export const deductionOptions = [
  'Provident Fund',
  'Professional Tax',
  'Income Tax'
]

export const ignoreMenuOptions = [
  'subjects',
  'fee',
  'meetings',
  'notice-board'
]

export const lightColors = ['rgba(55,125,255,.2)', 'rgba(222,68,55,.2)', 'rgba(0,201,167,.2)', 'rgba(255,193,7,.2)', 'rgba(0,223,252,.2)']
export const lightColorsOne = ['rgba(55,125,255,0.05)', 'rgba(222,68,55,0.05)', 'rgba(0,201,167,0.05)', 'rgba(255,193,7,0.05)', 'rgba(0,223,252,0.05)']