import React from "react";
// import swal from 'sweetalert2';

// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
// import { baseUrl } from "../../../config";
// import { getUserData, get, create } from "../../../utils";
import LiveChatComponent from "./live-chat";
import { getUserData } from "../../../utils";
import './chat.scss'

class ChatDoubtsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.assignUserData();
  }

  assignUserData = () => {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  render() {
    const { userType } = this.state
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper"  className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">Video Lecture Chat</h2>
            </div>
          </div>
          <div className='video-lecture-chat-container'>
            <LiveChatComponent name="Vide Live Chat" chatFrom="video" chatID={this.props.match.params.chatID} />
          </div>
        </div>
      </div>
    );
  }
}

export default ChatDoubtsComponent;