import React from "react";
import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";
import { StatCardNew } from "../../shared/common";
import './features.scss';

const title = "Previous Papers/Practice Papers";

const paragraphs = [`Students dashboard is pre-loaded with content from the curriculum and also exam patterns. It contains the question papers for each test conducted chapter wise and also pattern wise.Answers to most of the question papers are available for convenience.`]

const howItWorks = [
  {
    icon: '/assets/images/video-conference.png',
    title: 'Step One',
    description: 'lorem ipsum content aboi step.'
  },
  {
    icon: '/assets/images/video-conference.png',
    title: 'Step Two',
    description: 'lorem ipsum content aboi step.'
  }
]

const advantages = [
  {
    icon: '/assets/images/video-conference.png',
    title: 'ABC',
    description: 'Lorem ipsum dolor sit amet.'
  },
  {
    icon: '/assets/images/video-conference.png',
    title: 'ABC',
    description: 'Lorem ipsum dolor sit amet.'
  },
  {
    icon: '/assets/images/video-conference.png',
    title: 'ABC',
    description: 'Lorem ipsum dolor sit amet.'
  },
  {
    icon: '/assets/images/video-conference.png',
    title: 'ABC',
    description: 'Lorem ipsum dolor sit amet.'
  }
]

class PapersFeatureComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const bgStyles = {
      backgroundImage:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/home-bg.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: 400,
    };
    return (
      <div className="homepage-main-container white-bg">
        <Navbar />
        <div className="about-us-info-container" style={bgStyles}>
          <div className="about-us-info-section">
            <h4>Student's Feature</h4>
            <h2>{title}</h2>
          </div>
        </div>
        <div className='about-us-details-container'>
          <div className='about-us-main-content'>
            <div className='app-main-features-flex-container'>
              <div className='amf-left-side'>
                <div className='amf-left-top-container'>
                  {paragraphs.map((paragraph, index) => {
                    return(<p className="amf-left-content" key={"paragraph-"+index}>
                      {paragraph}
                    </p>)})}
                </div>
                <div className='amf-left-bottom-container'>
                  <h4>Advantages</h4>
                  <div
                    className="amf-advantages-container row uk-grid-match uk-child-width-1-3@m uk-text-center uk-scrollspy-inview"
                    uk-grid=""
                  >
                    {advantages.map((advantage, index) => {
                    return(<StatCardNew
                      key={"advantage"+index}
                      title={advantage.title}
                      image={advantage.icon}
                      desc={advantage.description}
                      className="col-lg-4"
                    ></StatCardNew>)})}
                  </div>
                </div>
              </div>
              <div className='amf-right-side'>
                <h4 className='amf-right-heading'>How it works?</h4>
                  {
                    howItWorks.map((item, index) => {
                      return (
                        <div key={"index-"+index}>
                          <div className="how-it-works-flex-container">
                            <div className="hiw-left-side">
                              <h5>{index + 1}</h5>
                            </div>
                            <div className="hiw-right-side">
                              <h2 className="hiw-title">{item.title}</h2>
                              <p className="hiw-subtitle">{item.description}</p>
                            </div>
                          </div>
                          {
                            (index < (howItWorks.length - 1)) && 
                            <div className='hiw-arrow-container'>
                              <img
                                alt="TeTu"
                                src={"/assets/images/download.svg"}
                                className="small-img"
                              />
                            </div>
                          }
                        </div>
                      )
                    })
                  }
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer />
        </div>
      </div>
    );
  }
}

export default PapersFeatureComponent;
