import React from "react";
// import swal from 'sweetalert2';

import { Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';

// import { Loader, IconLoader } from "../../shared/loader";
// import Navbar from "../shared/navbar/Navbar";
// import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { get } from "../../../utils";

class TopicViewPreviewComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      topicID: "",
      videoID: "",
      videos: [],
      previewVideo: {
        videoUrl: ""
      }
    }
  }

  componentDidMount() {
    let topicID = this.props.match.params.topicID;
    let videoID = this.props.match.params.videoID;
    this.setState({
      topicID,
      videoID
    })
    this.getTopicVideos(topicID, videoID);
  }

  getTopicVideos(topicID, videoID) {
    this.setState({
      videos: [],
      previewVideo: {
        videoUrl: ""
      }
    })

    get(baseUrl + "videos/topic-videos/" + (this.state.topicID || topicID) + "/" + (this.state.videoID || videoID))
      .then((response) => {
        this.setState({
          previewVideo: response.data.previewVideo,
          videos: response.data.data
        })
      })
      .catch((error) => {
      });
  }

  onClickVideo(video) {
    window.location.href = "/topic-videos/" + this.state.topicID + "/" + video._id;
  }

  render() {

    return (
      <div className="course-layouts">
        <div className="course-content bg-dark">

          <div className="course-header">

            <h4 className="text-white"> <Link to={"/dashboard"} ><i className="icon-feather-home"></i></Link> {this.state.previewVideo.name} </h4>

            <div>
              <a className="uk-visible@s" uk-toggle="target: .course-layouts; cls: course-sidebar-collapse">
                <i className="btns icon-feather-chevron-right"></i>
              </a>
            </div>
          </div>
          <div className="course-content-inner">
            <div >
              <div className="video-responsive">
                {(this.state.previewVideo.videoType == 'file' || (this.state.previewVideo.videoUrl.indexOf("amazonaws.com") >= 0)) && <video poster={this.state.previewVideo.thumbnailUrl} controls controlsList="nodownload">
                  <source src={this.state.previewVideo.videoUrl} type="video/mp4" />
                </video>}

                {(this.state.previewVideo.videoType == 'url' && this.state.previewVideo.videoUrl.indexOf("amazonaws.com") < 0) && <iframe src={this.state.previewVideo.videoUrl} frameBorder="0"
                  uk-video="automute: true" allowFullScreen uk-responsive=''></iframe>}
              </div>
            </div>
          </div>
        </div>


        <div className="course-sidebar uk-flex-last">
          <div className="course-sidebar-title">
            <h3> Similar Videos </h3>
          </div>
          {(this.state.videos.length > 0) && <div className="course-sidebar-container" data-simplebar={"true"}>
            <ul className="course-video-list-2" uk-switcher=" connect: #video-slider ; animation: uk-animation-slide-right-small, uk-animation-slide-left-medium">

              {this.state.videos.map((video, index) => {
                return (<li className={(this.state.videoID === video._id) ? "uk-active" : ""} key={"video-" + index}>
                  <a onClick={() => this.onClickVideo(video)}>
                    <img src={video.thumbnailUrl} alt="" />
                    {(this.state.videoID === video._id) && <span className="now-playing">Now Playing</span>}
                    <span className="video-title"> {video.name} </span>
                    <time> {video.duration} </time>
                  </a>
                </li>)
              })}
            </ul>

          </div>}

          {(this.state.videos.length <= 0) && <h6 className="text-center pt-2">..No Videos Found..</h6>}


        </div>

      </div>

    );
  }
}

export default TopicViewPreviewComponent;