import React, { Component } from "react";
import "./StreamComponent.scss";
import OvVideoComponent from "./OvVideo";

import MicOff from "@material-ui/icons/MicOff";
import VideocamOff from "@material-ui/icons/VideocamOff";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeOff from "@material-ui/icons/VolumeOff";
import IconButton from "@material-ui/core/IconButton";

export default class StreamComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nickname: this.props.user.getNickname() || 'NA',
      showForm: false,
      mutedSound: false,
      isFormValid: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePressKey = this.handlePressKey.bind(this);
    this.toggleNicknameForm = this.toggleNicknameForm.bind(this);
    this.toggleSound = this.toggleSound.bind(this);
  }

  handleChange(event) {
    this.setState({ nickname: event.target.value });
    event.preventDefault();
  }

  toggleNicknameForm() {
    if (this.props.user.isLocal()) {
      this.setState({ showForm: !this.state.showForm });
    }
  }

  toggleSound() {
    this.setState({ mutedSound: !this.state.mutedSound });
  }

  handlePressKey(event) {
    if (event.key === "Enter") {
      if (this.state.nickname.length >= 3 && this.state.nickname.length <= 20) {
        this.props.handleNickname(this.state.nickname);
        this.toggleNicknameForm();
        this.setState({ isFormValid: true });
      } else {
        this.setState({ isFormValid: false });
      }
    }
  }

  removeParticipant(e) {
    this.props.removeParticipant(e);
  }
  
  handleOnUserClick = () => {
    const {handleOnUserClick, userID} = this.props
    handleOnUserClick(userID)
  }

  render() {
    let firstLetter = 'G';
    if (this.props.user.getNickname()) {
      console.log('this.props.user.getNickname()', this.props.user.getNickname().substr(0, 1))
      firstLetter = this.props.user.getNickname().substr(0, 1)
    }
    return (
      <div className="OT_widget-container" onClick={this.handleOnUserClick}>
        <div className="video-subscriber-info-section">
          <div className='status-icons-section'>
            {!this.props.user.isVideoActive() ? (
              <div className="video-secion">
                <i className="icon-feather-video-off" />
              </div>
            ) : null}
            {!this.props.user.isAudioActive() ? (
              <div className="mic-section">
                <i className="icon-feather-mic-off" />
              </div>
            ) : null}
          </div>
          <div className='nickname-info-section'>{this.props.user.getNickname()}</div>
            {this.props.showRemove && (this.props.userID == this.props.hostID) && <i onClick={() => this.removeParticipant({liveClassID: this.props.liveClassID, userID: this.props.userID})} className='uil-times-circle' title="Remove Participant" />}
            
        </div>

        {this.props.user !== undefined &&
        this.props.user.getStreamManager() !== undefined ? (
          <div className="streamComponent">
            <OvVideoComponent
              user={this.props.user}
              mutedSound={this.state.mutedSound}
            />
            {!this.props.user.isVideoActive() && (
              <div className="user-symbol-info-section">
                <div className="usi-circle">
                  {firstLetter}
                </div>
              </div>
            )}
            <div>
              {!this.props.user.isLocal() && (
                <IconButton id="volumeButton" onClick={this.toggleSound}>
                  {this.state.mutedSound ? (
                    <VolumeOff color="secondary" />
                  ) : (
                    <VolumeUp />
                  )}
                </IconButton>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
