import React from "react";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get, create, getUserData, featureAccess } from "../../../utils";
import { Multiselect } from "multiselect-react-dropdown";
import QuestionComponent from "../questions/question";
import PreviousQuestion from "../questions/previous-question";
import "../quiz/quiz.scss";
import { WithTranslations } from "../../../i18n/withTranslations";

class AddTestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjects: [],
      classID: "",
      className: "",
      subjectID: "",
      subjectName: "",
      chapterID: "",
      topics: [],
      topicID: "",
      gradingSystemID: "",
      testTypes: [],
      testTypeID: "",
      testTypeName: "",
      optionsMaster: [],
      selected: [],
      selectedValues: [],
      selectedChapters: [],
      name: "",
      duration: "",
      marks: "",
      typeID: "",
      evaluation: "",
      releaseDate: "",
      instructions: [],
      isActive: false,
      testID: "",
      questionsMaster: [],
      questions: [],
      gradingSystems: [],
      questionAdded: false,

      previousQuestion: {},
      previousQuestionNo: "",
      questionMessage: "",
    };
    this.saveTestType = this.saveTestType.bind(this);
    this.testTypeValidator = new SimpleReactValidator({});

    this.saveTest = this.saveTest.bind(this);
    this.testValidator = new SimpleReactValidator({});
  }

  componentDidMount() {
    let classID = this.props.match.params.classID;
    let subjectID = this.props.match.params.subjectID;
    let chapterID = this.props.match.params.chapterID;
    let testID = this.props.match.params.testID;
    this.setState({
      classID,
      subjectID,
      chapterID,
      testID,
    });
    if (classID && subjectID) {
      this.getChaptersList(classID, subjectID);
    }
    if (!testID) this.getTopicsList(classID, subjectID, [chapterID]);
    if (testID) this.loadTestData(testID);

    this.getTestTypes();
    this.getGradingSystem();
    this.assignUserData();
  }

  assignUserData = () => {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  };

  loadTestData(testID) {
    get(baseUrl + "tests/" + testID)
      .then((response) => {
        let {
          chapterID,
          classID,
          duration,
          gradingSystemID,
          isActive,
          marks,
          name,
          releaseDate,
          subjectID,
          topics,
          typeID,
          instructions,
          questionsMaster,
          questions,
          evaluation,
          chapters,
        } = response.data.data;

        let selectedValues = [];

        topics.forEach((topic) => {
          selectedValues.push({
            id: topic._id,
            name: topic.name,
          });
        });

        let chaptersMaster = [],
          chapterIDs = [];

        chapters.forEach((chapter) => {
          chaptersMaster.push({
            id: chapter._id,
            name: chapter.name,
          });

          chapterIDs.push(chapter.chapterID);
        });

        if (classID && subjectID) {
          this.getChaptersList(classID, subjectID);
        }

        this.getTopicsList(classID, subjectID, chapterIDs);

        this.setState({
          chapterID,
          classID,
          gradingSystemID,
          duration,
          isActive,
          marks,
          name,
          releaseDate: releaseDate
            ? new Date(releaseDate).toISOString().substring(0, 10)
            : "",
          subjectID,
          selectedValues,
          selectedChapters: chapters,
          selected: selectedValues,
          typeID,
          instructions,
          questionsMaster,
          questions,
          evaluation,
          chapters: chaptersMaster,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getTestTypes() {
    get(baseUrl + "test-types/list")
      .then((response) => {
        this.setState({
          testTypes: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getGradingSystem() {
    get(baseUrl + "grading-system/names")
      .then((response) => {
        this.setState({
          gradingSystems: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getChaptersList(classID, subjectID) {
    get(
      baseUrl +
        "chapters/list/" +
        (this.state.classID || classID) +
        "/" +
        (this.state.subjectID || subjectID)
    )
      .then((response) => {
        let chapters = [];
        response.data.data.forEach((master) => {
          chapters.push({
            id: master._id,
            name: master.name,
          });
        });

        this.setState({
          className: response.data.className,
          subjectName: response.data.subjectName,
          chaptersMaster: response.data.data,
          chapters: chapters,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getTopicsList(classID, subjectID, chapters) {
    create(
      baseUrl +
        "topics/list/" +
        (this.state.classID || classID) +
        "/" +
        (this.state.subjectID || subjectID),
      "post",
      { chapterIDs: chapters }
    )
      .then((response) => {
        let optionsMaster = [];
        response.data.data.forEach((master) => {
          optionsMaster.push({
            id: master._id,
            name: master.name,
          });
        });

        this.setState({
          topics: response.data.data,
          optionsMaster: optionsMaster,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
    if (event.target.name === "previousQuestionNo") {
      this.setState({
        previousQuestion: {},
      });
    }
  }

  saveTestType() {
    this.save(this.state.testType, "");
  }

  save(name, id) {
    let url = baseUrl + "test-types/";
    if (!id) {
      url += "add";
    } else url += id;
    create(url, id ? "put" : "post", { name: name })
      .then((response) => {
        this.setState({
          testType: "",
          testTypeID: "",
          testTypeName: "",
        });
        this.getTestTypes();
      })
      .catch((error) => {});
  }

  onClickEdit(testTypeMaster) {
    this.setState({
      testTypeID: testTypeMaster._id,
      testTypeName: testTypeMaster.name,
    });
  }

  saveType() {
    this.save(this.state.testTypeName, this.state.testTypeID);
  }

  onChangeList(selectedList, selectedItem) {
    this.setState({ selected: selectedList });
  }

  onChangeChapters(selectedList, selectedItem) {
    this.setState({ selectedChapters: selectedList });
    let ids = [];
    selectedList.forEach((chapter) => {
      ids.push(chapter.id);
    });

    this.getTopicsList(this.state.classID, this.state.subjectID, ids);
  }

  saveTest() {
    if (this.testValidator.allValid()) {
      let url = baseUrl + "tests/";
      if (!this.state.testID) url += "add";
      else url += this.state.testID;
      create(url, this.state.testID ? "put" : "post", {
        instructions: this.state.instructions,
        classID: this.state.classID,
        gradingSystemID: this.state.gradingSystemID,
        evaluation: this.state.evaluation,
        subjectID: this.state.subjectID,
        chapterID: this.state.chapterID,
        topics: this.state.selected,
        chapters: this.state.selectedChapters,
        name: this.state.name,
        duration: this.state.duration,
        marks: this.state.marks,
        typeID: this.state.typeID,
        releaseDate: this.state.releaseDate,
        isActive: this.state.isActive,
      })
        .then((response) => {
          this.setState({
            testID: response.data.data._id,
          });
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.testValidator.showMessages();
      this.forceUpdate();
    }
  }

  onAddQuestion(question) {
    let questionsMaster = this.state.questionsMaster;
    let questions = this.state.questions;

    let index = questions
      .map(function (q) {
        return "" + q.questionID;
      })
      .indexOf("" + question._id);
    if (index < 0) {
      questionsMaster.push(question);
      questions.push({
        questionID: question._id,
      });

      this.setState({
        questionsMaster,
        questions,
      });

      create(
        baseUrl + "tests/" + this.state.testID,
        this.state.testID ? "put" : "post",
        { questions }
      )
        .then((response) => {
          this.setState({
            previousQuestion: {},
            previousQuestionNo: "",
            questionMessage: "",
          });
          $("#previous-question").trigger("click");
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
          this.loadTestData(this.state.testID);
        })
        .catch((error) => {});
    } else {
      questionsMaster[index] = question;
      this.setState({
        questionsMaster,
      });
    }
  }

  onClickAddQuestion() {
    if (!this.state.questionAdded) {
      let questionsMaster = this.state.questionsMaster;
      questionsMaster.push({});
      this.setState({
        questionsMaster,
        questionAdded: true,
      });
    }
  }

  handleFieldEdit = (event) => {
    const splitted = event.target.name.split("#");
    const name = splitted[0];
    if (name) {
      const index = splitted[1];
      const value = event.target.value;
      let items = this.state[name];
      items[parseInt(index, 10)] = value;
      this.setState({
        [name]: items,
      });
    }
  };

  addInstruction() {
    let instructions = this.state.instructions;
    instructions.push("");
    this.setState({
      instructions,
    });
  }

  removeInstruction(index) {
    let instructions = this.state.instructions;
    instructions.splice(index, 1);
    this.setState({
      instructions,
    });
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">
                <Link
                  to={
                    this.state.chapterID
                      ? "/chapter-details/" +
                        this.state.classID +
                        "/" +
                        this.state.subjectID +
                        "/" +
                        this.state.chapterID
                      : "/online-tests/" +
                        this.state.classID +
                        "/" +
                        this.state.subjectID
                  }
                >
                  <i className="uil-arrow-left" />
                </Link>{" "}
                {tid('Create Online Test')}
              </h2>
            </div>
            <div className="app-page-header-right">
              <button
                type="button"
                className="app-add-option-button"
                data-toggle="modal"
                data-target="#add-test-types"
              >
               {tid(' Test Types')}
              </button>
            </div>
          </div>
          <div className="add-online-test--details-container">
            <h6 className="row col-lg-12 np">
              {tid('Class')}: <span>{this.state.className}</span>
            </h6>
            <h6 className="row col-lg-12 np">
              {tid('Subject')}: <span>{this.state.subjectName}</span>
            </h6>
            <div className="add-ot-tab-switcher-container">
              <ul
                class="uk-tab style-4"
                uk-switcher="connect: #course-edit-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
              >
                <li class="uk-active">
                  <a> {tid('Test Details')} </a>
                </li>
                <li>
                  <a> {tid('Instructions')} </a>
                </li>
                <li>
                  <a> {tid('Questions')} </a>
                </li>
              </ul>
            </div>
            <div className="ot-tab-content-container">
              <ul class="uk-switcher uk-margin" id="course-edit-tab">
                <li className="add-test-form-container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row col-lg-12">
                        <label>{tid('Name')}</label>
                        <input
                          type="text"
                          className="form-control form-control-user"
                          value={this.state.name}
                          name="name"
                          onChange={this.setFormField.bind(this)}
                          placeholder={tid("Enter Name")}
                        />
                        {this.testValidator.message(
                          "Test Name",
                          this.state.name,
                          "required"
                        )}
                      </div>
                      <div className="row col-lg-12">
                        <label>{tid('Test Type')}</label>
                        <select
                          className="form-control"
                          id="basicSelect"
                          value={this.state.typeID}
                          name="typeID"
                          onChange={this.setFormField.bind(this)}
                        >
                          <option value="">{tid('Select Test Type')}</option>
                          {this.state.testTypes.map((type, index) => {
                            return (
                              <option
                                key={"test-type" + index}
                                value={type._id}
                              >
                                {type.name}
                              </option>
                            );
                          })}
                        </select>
                        {this.testValidator.message(
                          "Test Type",
                          this.state.typeID,
                          "required"
                        )}
                      </div>
                      <div className="row col-lg-12">
                        <label>{tid('Chapters')}</label>
                        <Multiselect
                          className="form-control"
                          options={this.state.chapters} // Options to display in the dropdown
                          value={this.state.selectedChapters} // Preselected value to persist in dropdown
                          selectedValues={this.state.selectedChapters}
                          onSelect={this.onChangeChapters.bind(this)} // Function will trigger on select event
                          onRemove={this.onChangeChapters.bind(this)} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      </div>
                      <div className="row col-lg-12">
                        <label>{tid('Topics')}</label>
                        <Multiselect
                          className="form-control"
                          options={this.state.optionsMaster} // Options to display in the dropdown
                          value={this.state.selected} // Preselected value to persist in dropdown
                          selectedValues={this.state.selectedValues}
                          onSelect={this.onChangeList.bind(this)} // Function will trigger on select event
                          onRemove={this.onChangeList.bind(this)} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      </div>
                      <div className="row col-lg-12">
                        <label>{tid('Grading System')}</label>
                        <select
                          className="form-control"
                          id="basicSelect"
                          value={this.state.gradingSystemID}
                          name="gradingSystemID"
                          onChange={this.setFormField.bind(this)}
                        >
                          <option value="">{tid('Select Test Type')}</option>
                          {this.state.gradingSystems.map((type, index) => {
                            return (
                              <option
                                key={"test-type" + index}
                                value={type._id}
                              >
                                {type.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row col-lg-12">
                        <label>{tid('Duration')}</label>
                        <input
                          type="number"
                          className="form-control form-control-user"
                          value={this.state.duration}
                          name="duration"
                          onChange={this.setFormField.bind(this)}
                          placeholder={tid("Enter Duration in mins")}
                        />
                        {this.testValidator.message(
                          "Duration",
                          this.state.duration,
                          "required"
                        )}
                      </div>
                      <div className="row col-lg-12">
                        <label>{tid('Marks')}</label>
                        <input
                          type="number"
                          className="form-control form-control-user"
                          value={this.state.marks}
                          name="marks"
                          onChange={this.setFormField.bind(this)}
                          placeholder={tid("Enter Marks")}
                        />
                        {this.testValidator.message(
                          "Marks",
                          this.state.marks,
                          "required"
                        )}
                      </div>
                      <div className="row col-lg-12">
                        <label>{tid('Release Date')}</label>
                        <input
                          type="date"
                          className="form-control form-control-user"
                          value={this.state.releaseDate}
                          name="releaseDate"
                          onChange={this.setFormField.bind(this)}
                          placeholder={tid("Release Date")}
                        />
                        {this.testValidator.message(
                          "Release Date",
                          this.state.releaseDate,
                          "required"
                        )}
                      </div>
                      <div className="row col-lg-12">
                        <label> {tid('Evaluation')} </label>
                        <select
                          className="form-control"
                          id="basicSelect"
                          value={this.state.evaluation}
                          name="evaluation"
                          onChange={this.setFormField.bind(this)}
                        >
                          <option value="">{tid('Select Evaluation Type')}</option>
                          <option value="automatic">
                            {tid('Automatic Evaluation')}
                          </option>
                          {featureAccess("online-tests-manual-evaluation") && (
                            <option value="manual">{tid('Manual Evaluation')}</option>
                          )}
                        </select>
                        {this.testValidator.message(
                          "Test Type",
                          this.state.typeID,
                          "required"
                        )}
                      </div>
                      <div className="row col-lg-12">
                        <label>{tid('Active')}</label>
                        <select
                          className="form-control"
                          id="basicSelect"
                          value={this.state.isActive}
                          name="isActive"
                          onChange={this.setFormField.bind(this)}
                          placeholder={tid("Enter Name")}
                        >
                          <option value="true">{tid('Active')}</option>
                          <option value="false">{tid('In Active')}</option>
                        </select>
                        {this.testValidator.message(
                          "Active",
                          this.state.isActive,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 save-button-section">
                      <button
                        className="btn btn-default btn-sm"
                        onClick={this.saveTest.bind(this)}
                      >
                        <i className="fa fa-save" /> {tid('Save')}
                      </button>
                    </div>
                  </div>
                </li>
                <li className="add-instructions-form-container">
                  <div className="">
                    <div className="app-page-header-section">
                      <div className="app-page-header-left">
                        <h2 className=""> {tid('Instructions')}</h2>
                      </div>
                      {this.state.testID && (
                        <div className="app-page-header-right">
                          <button
                            className="app-add-option-button"
                            onClick={() => this.addInstruction()}
                          >
                            <i className="uil-plus" />
                            {tid('Add')}
                          </button>
                        </div>
                      )}
                      {/* {this.state.testID &&  */}
                      {/* <div className="section-header-right">
                        <button className="btn btn-info btn-xs" onClick={() => this.addInstruction()}>Add</button>
                      </div> */}
                      {/* } */}
                    </div>
                    <div className="ot-instruction-form">
                      {this.state.instructions.map((instruction, index) => {
                        return (
                          <div className="ot-instructions-form-row">
                            <div className="ot-instructions-form-card">
                              <input
                                key={"ans-" + index}
                                type="text"
                                className="form-control p10"
                                value={instruction}
                                name={"instructions#" + index}
                                onChange={this.handleFieldEdit}
                                placeholder={tid("Enter Instruction")}
                              />
                              <button
                                className="btn btn-danger btn-xs"
                                onClick={() => this.removeInstruction(index)}
                              >
                                {" "}
                                <i className="icon-feather-x"></i> {tid('Remove')}
                              </button>
                            </div>
                          </div>
                        );
                      })}
                      {this.state.instructions &&
                        this.state.instructions.length > 0 && (
                          <div className="col-lg-12 pt-3 save-button-container">
                            <button
                              className="btn btn-default btn-sm"
                              onClick={this.saveTest.bind(this)}
                            >
                              <i className="fa fa-save" />
                              {tid('Save')}
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </li>
                <li className="aq-q-container-collapsible-container">
                  {this.state.testID && (
                    <div className="col-lg-12 mb-1">
                      <div className="section-header pt-1 mb-lg-2 border-0 uk-flex-middle">
                        <div className="section-header-left">
                          <h5 className="uk-heading-line text-left">
                            {" "}
                            {tid('Questions List')}{" "}
                          </h5>
                          <p>
                            {tid('Total')} {this.state.questions.length} {tid('questions')}
                            {tid('loadded')}
                          </p>
                        </div>
                        <div className="section-header-right">
                          <PreviousQuestion
                            onAddQuestion={this.onAddQuestion.bind(this)}
                          ></PreviousQuestion>
                          <button
                            className="btn btn-xs btn-info"
                            onClick={() => this.onClickAddQuestion()}
                          >
                            {tid('Add New Question')}
                          </button>
                        </div>
                      </div>
                      <section id="collapsible np">
                        {this.state.questionsMaster.map((question, index) => {
                          return (
                            <QuestionComponent
                              questionFor="test"
                              className="np"
                              key={"question-" + index}
                              question={question}
                              index={index}
                              onAddQuestion={this.onAddQuestion.bind(this)}
                            ></QuestionComponent>
                          );
                        })}
                      </section>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div
            className="modal fade text-left"
            id="add-test-types"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myModalLabel1">
                    {tid('Test Types')}
                  </h4>
                  <button
                    type="button"
                    className="close rounded-pill"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.testTypes.map((testType, index) => {
                    return (
                      <div
                        key={"testtype" + index}
                        className="row col-lg-12 py-1"
                      >
                        <div className="col-lg-10">
                          {this.state.testTypeID !== testType._id && (
                            <span>{testType.name}</span>
                          )}
                          {this.state.testTypeID === testType._id && (
                            <input
                              type="text"
                              className="form-control form-control-user"
                              value={this.state.testTypeName}
                              name="testTypeName"
                              onChange={this.setFormField.bind(this)}
                              placeholder={tid("Enter Test Type")}
                            />
                          )}
                        </div>
                        <div className="col-lg-2">
                          {this.state.testTypeID !== testType._id && (
                            <button
                              type="button"
                              className="btn btn-outline-warning btn-sm"
                              onClick={() => this.onClickEdit(testType)}
                            >
                              <i className="fa fa-edit"></i>
                            </button>
                          )}
                          {this.state.testTypeID === testType._id && (
                            <button
                              type="button"
                              className="btn btn-outline-success btn-sm"
                              onClick={() => this.saveType()}
                            >
                              <i className="fa fa-save"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="row align-items-center pt-1 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">{tid('Test Type')}:</label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.testType}
                        name="testType"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Enter Test Type")}
                      />
                      {this.testTypeValidator.message(
                        "Test Type",
                        this.state.testType,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-secondary btn-sm"
                    data-dismiss="modal"
                  >
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid('Close')}</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-default ml-1 btn-sm"
                    onClick={this.saveTestType.bind(this)}
                  >
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid('Save')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <section id="basic-input">
            <div className="row col-lg-12">
              <div className="col-lg-12 card"></div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default WithTranslations(AddTestComponent);
