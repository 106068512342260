import React from "react";
import swal from "sweetalert2";
import moment from "moment";

// import {  Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";

// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { getUserData, get, create, hasPermission } from "../../../utils";
import "./notice-board.scss";
import Delete from "../../common/Delete";
import { WithTranslations } from "../../../i18n/withTranslations";

class NoticeBoardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notices: [],
      date: "",
      description: "",
    };
    this.save = this.save.bind(this);
    this.noticeValidator = new SimpleReactValidator({});
  }

  removeNoticeById = (id) => () => {
    console.log("ey del", id);
    this.setState({
      notices: this.state.notices.filter((k) => k._id != id),
    });
  };

  save() {
    if (this.noticeValidator.allValid()) {
      create(baseUrl + "notices/add", "post", {
        name: this.state.name,
        date: this.state.date,
        description: this.state.description,
      })
        .then((response) => {
          $("#add-notice").trigger("click");
          this.setState({
            name: "",
            message: "",
          });
          this.getNoticesList();
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => {});
    } else {
      this.noticeValidator.showMessages();
      this.forceUpdate();
    }
  }

  getNoticesList() {
    get(baseUrl + "notices/list")
      .then((response) => {
        this.setState({
          notices: response.data.data,
        });
      })
      .catch((error) => {});
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    this.getNoticesList();
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{tid("Notice Board")}</h2>
            </div>
            <div className="app-page-header-right">
              {hasPermission("notice-board", "create") && (
                <button
                  className="app-add-option-button"
                  data-toggle="modal"
                  data-target="#add-notice"
                >
                  {" "}
                  <i className="uil-plus"></i> {tid("Add Notice")}
                </button>
              )}
            </div>
          </div>
          <div className="notice-list-container">
            <ul className="notice-list-section">
              {this.state.notices.map((notice, index) => {
                return (
                  <li className="notice-list-row">
                    <h5 className="notice-heading">
                      {notice.name}
                      <span className="date-info">
                        {moment(notice.date).format("DD-MM-YYYY")}
                      </span>
                    </h5>
                    <p className="notice-desc">{notice.description}</p>
                    {hasPermission("notice-board", "remove") && (
                      <Delete
                        url={`notices/${notice._id}`}
                        size="xxs"
                        onDeleteSuccess={this.removeNoticeById(notice._id)}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div
          className="modal fade text-left"
          id="add-notice"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="myModalLabel1">
                 {tid(" Add Notice")}
                </h3>
                <button
                  type="button"
                  className="close rounded-pill"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group row align-items-center t-0 b-0">
                  <div className="col-lg-4">
                    <label className="col-form-label">{tid("Notice Date")}:</label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      className="form-control form-control-user"
                      value={this.state.date}
                      name="date"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Select Date")}
                    />
                    {this.noticeValidator.message(
                      "Select Date",
                      this.state.date,
                      "required"
                    )}
                  </div>
                </div>
                <div className="form-group row align-items-center t-0 b-0">
                  <div className="col-lg-4">
                    <label className="col-form-label">{tid("Notice Name")}:</label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.name}
                      name="name"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Notice Name")}
                    />
                    {this.noticeValidator.message(
                      "Notice Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div className="form-group row align-items-center t-0 b-0">
                  <div className="col-lg-4">
                    <label className="col-form-label">
                     {tid(" Notice Description:")}
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <textarea
                      type="text"
                      className="form-control form-control-user"
                      value={this.state.description}
                      name="description"
                      rows="3"
                      onChange={this.setFormField.bind(this)}
                      placeholder={tid("Enter Notice Name")}
                    ></textarea>
                    {this.noticeValidator.message(
                      "Notice Name",
                      this.state.description,
                      "required"
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-secondary btn-xs"
                  data-dismiss="modal"
                >
                  <i className="bx bx-x d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid("Close")}</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-1 btn-xs"
                  onClick={this.save.bind(this)}
                >
                  <i className="bx bx-check d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">{tid("Save")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(NoticeBoardComponent);
