import React from "react";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get, create, uploadToS3, getUserData, remove } from "../../../utils";
import TestDataComponent from "../tests/tests-list";
import { WithTranslations } from "../../../i18n/withTranslations";
import './chapters.scss';


class ChapterDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      practicePapers: [],
      previousPapers: [],
      topics: [],
      name: "",
      message: "",
      classID: "",
      subjectID: "",
      chapterID: "",
      topicID: "",
      thumbnail: {},
      file: {},
      uploadType: "",
      title: "",
      videoType: "",
      videoUrl: "",
      duration: "",
      userType: "",
      chapterName: "",
      uploadingVideo: false,
      materials: [],

      materialFile: "",
      materialTitle: "",
      materialID: "",
      previewBook: "",
      topicID: "",
      selectedVideoID: ""
    };
    this.save = this.save.bind(this);
    this.topicValidator = new SimpleReactValidator({});
    this.fileValidator = new SimpleReactValidator({});
    this.materialValidator = new SimpleReactValidator({});

    this.uploadFile = this.uploadFile.bind(this);
    this.uploadMaterialFile = this.uploadMaterialFile.bind(this);
    this.uploadThumbnail = this.uploadThumbnail.bind(this);
    this.assignUserData = this.assignUserData.bind(this);
  }

  save() {
    if (this.topicValidator.allValid()) {
      let url = baseUrl + "topics/";
      if (this.state.topicID) url += this.state.topicID;
      else url += "add";

      create(url, this.state.topicID ? "put" : "post", {
        name: this.state.name,
        classID: this.state.classID,
        subjectID: this.state.subjectID,
        chapterID: this.state.chapterID,
      })
        .then((response) => {
          $("#add-topic").trigger("click");
          this.setState({
            name: "",
            message: "",
            topicID: "",
          });
          this.getTopicsList(
            this.state.classID,
            this.state.subjectID,
            this.state.chapterID
          );
          swal.fire({
            title: "Info",
            text: response.data.message,
            icon: "warning",
          });
        })
        .catch((error) => { });
    } else {
      this.topicValidator.showMessages();
      this.forceUpdate();
    }
  }

  getTopicsList(classID, subjectID, chapterID) {
    get(
      baseUrl +
      "topics/list/" +
      (this.state.classID || classID) +
      "/" +
      (this.state.subjectID || subjectID) +
      "/" +
      (this.state.chapterID || chapterID)
    )
      .then((response) => {
        this.setState({
          topics: response.data.data,
          chapterName: response.data.chapterName,
        });
        this.setDisplayData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getVideos() {
    get(baseUrl + "videos/list/" + this.state.chapterID)
      .then((response) => {
        let videos = this.state.videos;
        videos.forEach((video) => {
          video.videos = [];
          response.data.data.forEach((v) => {
            if (video.topicID === v.topicID) {
              video.videos.push(v);
            }
          });
        });
        this.setState({ videos });
      })
      .catch((error) => { });
  }

  getPapers(type) {
    get(baseUrl + "papers/list/" + this.state.chapterID + "/" + type)
      .then((response) => {
        let papers =
          type === "previous"
            ? this.state.previousPapers
            : this.state.practicePapers;

        papers.forEach((paper) => {
          paper.papers = [];
          response.data.data.forEach((p) => {
            if (paper.topicID === p.topicID) {
              paper.papers.push(p);
            }
          });
        });

        if (type === "previous") {
          this.setState({
            previousPapers: papers,
          });
        } else {
          this.setState({
            practicePapers: papers,
          });
        }
      })
      .catch((error) => { });
  }

  setDisplayData() {
    let videos = [],
      practicePapers = [],
      previousPapers = [];
    this.state.topics.forEach((topic) => {
      videos.push({
        topicName: topic.name,
        topicID: topic._id,
        videos: [],
      });
      practicePapers.push({
        topicName: topic.name,
        topicID: topic._id,
        papers: [],
      });
      previousPapers.push({
        topicName: topic.name,
        topicID: topic._id,
        papers: [],
      });
    });

    this.setState({
      videos,
      practicePapers,
      previousPapers,
    });
  }

  componentDidMount() {
    let classID = this.props.match.params.classID;
    let subjectID = this.props.match.params.subjectID;
    let chapterID = this.props.match.params.chapterID;
    this.setState({
      classID,
      subjectID,
      chapterID,
    });
    this.getTopicsList(classID, subjectID, chapterID);
    this.assignUserData();
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] });
  }

  uploadMaterialFile(event) {
    this.setState({ materialFile: event.target.files[0] });
  }

  uploadThumbnail(event) {
    this.setState({ thumbnail: event.target.files[0] });
  }

  onClickUploadMaterial(file) {
    if (this.materialValidator.allValid()) {
      this.setState({
        uploadMaterial: true,
      });

      const fileUploader = new Promise((resolve, reject) => {
        let fileName =
          "materials/" +
          this.state.subjectID +
          "/" +
          this.state.chapterID +
          "/material_" +
          Date.now() +
          "-" +
          file.name;
        resolve(uploadToS3(fileName, file, file.type));
      });

      Promise.all([fileUploader])
        .then((response) => {
          let url = baseUrl,
            paperType = "";
          url += "materials/add";

          let data = {
            title: this.state.materialTitle,
            classID: this.state.classID,
            subjectID: this.state.subjectID,
            chapterID: this.state.chapterID,
            fileKey: response[0],
          };

          create(url, "post", data)
            .then((response) => {
              $("#material-upload").trigger("click");
              this.setState({
                materialTitle: "",
                selectedVideoID: ""
              });

              this.getMaterial();

              swal.fire({
                title: "Info",
                text: response.data.message,
                icon: "warning",
              });
            })
            .catch((error) => { });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.fileValidator.showMessages();
      this.forceUpdate();
    }
  }

  onClickUpload(file, thumbnail) {
    if (this.fileValidator.allValid()) {
      this.setState({
        uploadingVideo: true,
      });
      const thumbnailUploader = new Promise((resolve, reject) => {
        if (this.state.uploadType === "Video" && thumbnail.name) {
          let fileName =
            "videos/" +
            this.state.topicID +
            "/thumbnails/" +
            Date.now() +
            "-" +
            thumbnail.name;
          resolve(uploadToS3(fileName, thumbnail, thumbnail.type));
        } else {
          resolve();
        }
      });

      const fileUploader = new Promise((resolve, reject) => {
        if (file && this.state.videoType == "file" && file.name) {
          let fileName =
            "videos/" +
            this.state.topicID +
            "/" +
            this.state.uploadType +
            "/" +
            Date.now() +
            "-" +
            file.name;
          resolve(uploadToS3(fileName, file, file.type));
        } else {
          resolve();
        }
      });

      Promise.all([thumbnailUploader, fileUploader])
        .then((response) => {
          let url = baseUrl,
            paperType = "";

          if (this.state.uploadType === "Video") {
            if (this.state.selectedVideoID) {
              url += "videos/" + this.state.selectedVideoID;
            } else {
              url += "videos/add";
            }
          } else {
            url += "papers/add";
            paperType =
              this.state.uploadType === "Previous Papers"
                ? "previous"
                : "practice";
          }

          let data = {
            name: this.state.title,
            classID: this.state.classID,
            subjectID: this.state.subjectID,
            chapterID: this.state.chapterID,
            topicID: this.state.topicID,
            thumbnailKey: response[0],
            videoType: this.state.videoType,
            videoUrl: this.state.videoUrl,
            duration: this.state.duration,
            pdfKey: response[1],
            videoKey: response[1],
            paperType,
          };

          create(url, this.state.selectedVideoID ? "put" : "post", data)
            .then((response) => {
              $("#upload").trigger("click");
              this.setState({
                title: "",
                selectedVideoID: ""
              });

              if (this.state.uploadType === "Video") {
                this.getVideos();
              } else {
                this.getPapers(this.state.uploadType);
              }

              swal.fire({
                title: "Info",
                text: response.data.message,
                icon: "warning",
              });
            })
            .catch((error) => { });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.fileValidator.showMessages();
      this.forceUpdate();
    }
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  uploadType(type, topicID) {
    this.setState({
      uploadType: type,
      topicID,
      selectedVideoID: ""
    });
  }

  getMaterial() {
    get(
      baseUrl +
      "materials/list/" +
      this.state.subjectID +
      "/" +
      this.state.chapterID
    )
      .then((response) => {
        this.setState({
          materials: response.data.data,
        });
      })
      .catch((error) => { });
  }

  onClickTopic(topic) {
    let { _id, name } = topic;
    this.setState({
      topicID: _id,
      name,
    });
  }

  onClick(video) {
    let { uploadType, topicID, name, videoType, videoUrl, duration, _id } = video;
    if (videoUrl.indexOf("amazonaws.com") >= 0) {
      videoUrl = videoUrl?.split("com/")[1]?.split("?")[0]
    }
    if (duration.indexOf("min") >= 0)
      duration = duration.replace("min", "")
    this.setState({
      selectedVideoID: _id, topicID, uploadType, title: name, videoType, videoUrl, duration, uploadType: 'Video'
    });
  }

  onClickDelete(video) {
    swal.fire({
      title: 'Are you sure to delete this video?',
      showCancelButton: true,
      confirmButtonText: 'Yes Delete',
    }).then((result) => {
      if (result.value) {
        remove(
          baseUrl +
          "videos/" +
          video._id
        )
          .then((response) => {
            swal.fire('Video Deleted', '', 'info');
            this.getVideos()
          })
          .catch((error) => { });
      } else if (result.isDenied) {

      }
    })
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";
    return (
      <div
        id="wrapper"
        className={`admin-panel ${isSecondaryDashboard ? "secondary-panel" : ""
          }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="subject-chapter-details-container">
            <div className="top-container bg-soft-primary">
              <div className="chapter-heading">
                <h1>
                  <Link
                    to={
                      "/chapters/" +
                      this.state.classID +
                      "/" +
                      this.state.subjectID
                    }
                  >
                    <i className="uil-arrow-left" />
                  </Link>{" "}
                  {this.state.chapterName}
                </h1>
              </div>
              <nav className="responsive-tab style-4">
                <ul uk-switcher="connect: #course-intro-tab ;">
                  <li className="uk-active">
                    <a>{tid("Topics")}</a>
                  </li>
                  <li>
                    <a onClick={() => this.getVideos()}>{tid("Videos")}</a>
                  </li>
                  <li>
                    <a onClick={() => this.getMaterial()}>
                      {tid("Study Material")}
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.getPapers("previous")}>
                      {tid("Previous Papers")}
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.getPapers("practice")}>
                      {tid("Practice Papers")}
                    </a>
                  </li>
                  <li>
                    <a>{tid("Online Tests")}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="chapter-tab-content-container">
              <ul id="course-intro-tab" className="uk-switcher mt-2">
                <li className="course-description-content uk-active">
                  <div className="app-page-header-section">
                    <div className="app-page-header-left">
                      <h2 className="">{tid("Topics")}</h2>
                    </div>
                    <div className="app-page-header-right">
                      {this.state.userType !== "student" && (
                        <button
                          type="button"
                          className="btn btn-xs btn-default uk-visible@s"
                          data-toggle="modal"
                          data-target="#add-topic"
                        >
                          {tid("Add New Topic")}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="topic-list-container">
                    <ul>
                      {this.state.topics.map((topic, index) => {
                        return (
                          <li key={"topic-" + index}>
                            <i className="uil-arrow-right" />
                            {topic.name}{" "}
                            <button
                              className="btn btn-xxs"
                              onClick={() => this.onClickTopic(topic)}
                              data-toggle="modal"
                              data-target="#add-topic"
                            >
                              {tid("Edit")}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>

                <li>
                  <h4 className="">{tid("Videos Uploaded")}</h4>
                  {this.state.videos.map((topic, index) => {
                    return (
                      <div key={"video-topic-" + index}>
                        <div
                          className="course-grid-slider chapter-topic-wise-details-container"
                          uk-slider="finite: true"
                        >
                          <div className="grid-slider-header">
                            <div>
                              <h4 className="timeline-title">
                                <i className="icon-feather-arrow-right-circle"></i>{" "}
                                {topic.topicName} &nbsp;&nbsp;
                                {this.state.userType !== "student" && (
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary block btn-xs pull-right mt-1"
                                    onClick={() =>
                                      this.uploadType("Video", topic.topicID)
                                    }
                                    data-toggle="modal"
                                    data-target="#upload"
                                  >
                                    {tid("Upload Video")}
                                  </button>
                                )}
                              </h4>
                            </div>
                            {topic.videos.length >= 2 && (
                              <div className="grid-slider-header-link">
                                <a
                                  className="slide-nav-prev"
                                  uk-slider-item="previous"
                                >
                                  {" "}
                                </a>
                                <a
                                  className="slide-nav-next"
                                  uk-slider-item="next"
                                >
                                  {" "}
                                </a>
                              </div>
                            )}
                          </div>
                          {topic.videos.length > 0 && (
                            <ul className="chapter-videos-slider-container">
                              {topic.videos.map((video, index) => {
                                return (
                                  <li key={"video-" + index}>
                                    <div>
                                      <div className="course-card chapter-video-card">
                                        <div className="course-card-thumbnail ">
                                          <Link
                                            to={
                                              this.state.userType !== "student"
                                                ? "/topic-videos/" +
                                                topic.topicID +
                                                "/" +
                                                video._id
                                                : "/topic-videos/" +
                                                topic.topicID +
                                                "/" +
                                                video._id
                                            }
                                          >
                                            <img
                                              src={video.thumbnailUrl}
                                              alt="Video Thumbnail"
                                            />
                                            <span className="play-button-trigger"></span>
                                          </Link>
                                        </div>
                                        <div className="course-card-body">
                                          <h4 className="pointer" title={video.name}>{video.name}</h4>
                                          <div className="course-card-info">
                                            <div>
                                              <Link
                                                to={
                                                  this.state.userType !==
                                                    "student" || !video.quizID
                                                    ? "/quiz/video/" + video._id
                                                    : video.quizSubmitted
                                                      ? "/review-quiz/" +
                                                      video.quizID
                                                      : "/quiz/" + video.quizID
                                                }
                                                className="catagroy"
                                              >
                                                {this.state.userType !==
                                                  "student" || !video.quizID
                                                  ? "Quiz"
                                                  : video.quizSubmitted
                                                    ? "Review"
                                                    : "Attempt Quiz"}
                                              </Link>
                                            </div>
                                            <div>
                                              <div className="actions">
                                                <Link to={"/chat/" + video._id}>
                                                  <i className="icon-feather-message-square"></i>
                                                </Link>
                                                <button type="button" class="btn btn-primary btn-xxs" data-toggle="modal" data-target="#upload" onClick={() => this.onClick(video)}><i class="uil-edit"></i></button>
                                                <button type="button" class="btn btn-danger btn-xxs" onClick={() => this.onClickDelete(video)}><i class="uil-trash"></i></button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="course-card-footer">
                                            <h5>
                                              {" "}
                                              <i className="icon-feather-film"></i>{" "}
                                              {video.questionsCount}{" "}
                                              {tid("Questions")}{" "}
                                            </h5>
                                            <h5>
                                              {" "}
                                              <i className="icon-feather-clock"></i>{" "}
                                              {(video.duration.indexOf('min') >= 0 ? video.duration : video.duration + "min") ||
                                                "Not Mentioned"}{" "}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                          {topic.videos.length <= 0 && (
                            <h6 className="text-center">
                              {tid("No Videos Uploaded for this topic")}
                            </h6>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </li>
                <li>
                  <h6>
                    {tid("Study Material")}{" "}
                    <button
                      className="btn btn-default btn-xs"
                      data-toggle="modal"
                      data-target="#material-upload"
                    >
                      {tid("Add Book")}
                    </button>
                  </h6>
                  {this.state.materials.map((material, index) => {
                    return (
                      <div
                        key={"index-" + index}
                        className="icon-set-container pointer"
                        onClick={() =>
                          this.setState({
                            previewBook: material.fileUrl,
                          })
                        }
                        data-toggle="modal"
                        data-target="#preview-book"
                      >
                        <div className="glyph fs1">
                          <div className="clearfix bshadow0 pbs">
                            <span className="icon-feather-file"> </span>
                            <span className="mls"> {material.title} </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </li>
                <li>
                  {this.state.previousPapers.map((topic, index) => {
                    return (
                      <div key={"practice-papers-topic-" + index}>
                        <div
                          className="course-grid-slider chapter-topic-wise-details-container"
                          uk-slider="finite: true"
                        >
                          <div className="grid-slider-header">
                            <div>
                              <h6 className="timeline-title">
                                <i className="icon-feather-arrow-right-circle"></i>{" "}
                                {topic.topicName} &nbsp;&nbsp;
                                {this.state.userType !== "student" && (
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary block btn-xs pull-right"
                                    onClick={() =>
                                      this.uploadType(
                                        "Previous Papers",
                                        topic.topicID
                                      )
                                    }
                                    data-toggle="modal"
                                    data-target="#upload"
                                  >
                                    {tid("Upload Paper")}
                                  </button>
                                )}
                              </h6>
                            </div>
                            {topic.papers.length >= 2 && (
                              <div className="grid-slider-header-link">
                                <a
                                  className="slide-nav-prev"
                                  uk-slider-item="previous"
                                >
                                  {" "}
                                </a>
                                <a
                                  className="slide-nav-next"
                                  uk-slider-item="next"
                                >
                                  {" "}
                                </a>
                              </div>
                            )}
                          </div>
                          <ul className="uk-slider-items chapter-videos-slider-container">
                            {topic.papers.map((paper, index) => {
                              return (
                                <li key={"previous-paper-" + index}>
                                  <Link
                                    to={
                                      this.state.userType !== "student"
                                        ? "/topic-papers/" +
                                        topic.topicID +
                                        "/" +
                                        paper._id
                                        : "/topic-papers/" +
                                        topic.topicID +
                                        "/" +
                                        paper._id
                                    }
                                  >
                                    <a className="pointer">
                                      <div className="course-card chapter-video-card">
                                        <div className="course-card-thumbnail chapter-card-image">
                                          <img
                                            src={"/assets/images/pdf.png"}
                                            alt="PDF"
                                          />
                                        </div>
                                        <div className="course-card-body">
                                          <h4>{paper.name}</h4>
                                        </div>
                                      </div>
                                    </a>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                          {topic.papers.length <= 0 && (
                            <h6 className="text-center">
                              {tid("No Previous papers available.")}
                            </h6>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </li>
                <li>
                  {this.state.practicePapers.map((topic, index) => {
                    return (
                      <div key={"practice-papers-topic-" + index}>
                        <div
                          className="course-grid-slider chapter-topic-wise-details-container"
                          uk-slider="finite: true"
                        >
                          <div className="grid-slider-header">
                            <div>
                              <h6 className="timeline-title">
                                <i className="icon-feather-arrow-right-circle"></i>{" "}
                                {topic.topicName} &nbsp;&nbsp;
                                {this.state.userType !== "student" && (
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary block btn-xs pull-right"
                                    onClick={() =>
                                      this.uploadType(
                                        "Practice Papers",
                                        topic.topicID
                                      )
                                    }
                                    data-toggle="modal"
                                    data-target="#upload"
                                  >
                                    {tid("Upload Paper")}
                                  </button>
                                )}
                              </h6>
                            </div>
                            {topic.papers.length >= 2 && (
                              <div className="grid-slider-header-link">
                                <a
                                  className="slide-nav-prev"
                                  uk-slider-item="previous"
                                >
                                  {" "}
                                </a>
                                <a
                                  className="slide-nav-next"
                                  uk-slider-item="next"
                                >
                                  {" "}
                                </a>
                              </div>
                            )}
                          </div>
                          <ul className="uk-slider-items chapter-videos-slider-container">
                            {topic.papers.map((paper, index) => {
                              return (
                                <li key={"practice-paper-" + index}>
                                  <Link
                                    to={
                                      this.state.userType !== "student"
                                        ? "/topic-papers/" +
                                        topic.topicID +
                                        "/" +
                                        paper._id
                                        : "/topic-papers/" +
                                        topic.topicID +
                                        "/" +
                                        paper._id
                                    }
                                  >
                                    <a className="pointer">
                                      <div className="course-card chapter-video-card">
                                        <div className="course-card-thumbnail chapter-card-image ">
                                          <img
                                            src={"/assets/images/pdf.png"}
                                            alt="TeTu"
                                          />
                                        </div>
                                        <div className="course-card-body">
                                          <h4>{paper.name}</h4>
                                        </div>
                                      </div>
                                    </a>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                          {topic.papers.length <= 0 && (
                            <h6 className="text-center">
                              {tid("No Practice papers available.")}
                            </h6>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </li>
                <li>
                  <TestDataComponent
                    display={false}
                    classID={this.props.match.params.classID}
                    subjectID={this.props.match.params.subjectID}
                    chapterID={this.props.match.params.chapterID}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div
            className="modal fade text-left uk-modal-full"
            id="preview-book"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <iframe
                    src={this.state.previewBook}
                    frameBorder="0"
                    height="450"
                    title={this.state.previewBook.title}
                    width="100%"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade text-left"
            id="add-topic"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">
                    {tid("Add Topic")}
                  </h3>
                  <button
                    type="button"
                    className="close rounded-pill"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row col-lg-12">
                    <div className="col-lg-3">
                      <label className="col-form-label">
                        {tid("Topic Name")}:
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.name}
                        name="name"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Enter Topic Name")}
                      />
                      {this.topicValidator.message(
                        "Topic Name",
                        this.state.name,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-secondary btn-sm"
                    data-dismiss="modal"
                  >
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid("Close")}</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ml-1 btn-sm"
                    onClick={this.save.bind(this)}
                  >
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid("Save")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade text-left"
            id="upload"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">
                    {tid("Upload")} {this.state.uploadType}
                  </h3>
                  <button
                    type="button"
                    className="close rounded-pill"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {tid("Title Name")}:
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.title}
                        name="title"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Enter Title")}
                      />
                      {this.fileValidator.message(
                        tid("File Title"),
                        this.state.title,
                        "required"
                      )}
                    </div>
                  </div>
                  {this.state.uploadType === "Video" && (
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {tid("Thumbnail Image")}:
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="file"
                          name="myFile"
                          className="form-control"
                          onChange={this.uploadThumbnail}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.uploadType === "Video" && (
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {tid("Video Upload Type")}:
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <select
                          className="form-control form-control-user"
                          value={this.state.videoType}
                          name="videoType"
                          onChange={this.setFormField.bind(this)}
                        >
                          <option>{tid("Select Video Type")}</option>
                          <option value="file">{tid("Video as File")}</option>
                          <option value="url">{tid("External URL")}</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {this.state.uploadType === "Video" && (
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {tid("Duration(mins)")}:
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="number"
                          className="form-control form-control-user"
                          value={this.state.duration}
                          name="duration"
                          onChange={this.setFormField.bind(this)}
                          placeholder={tid("Enter Duration")}
                        />
                        {this.fileValidator.message(
                          tid("Duration"),
                          this.state.duration,
                          "required"
                        )}
                      </div>
                    </div>
                  )}
                  {this.state.videoType == "file" && (
                    <div className="form-group row align-items-center t-0 b-0">
                      <div className="col-lg-4">
                        <label className="col-form-label">
                          {this.state.uploadType} {tid("File")}:
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="file"
                          name="myFile"
                          className="form-control"
                          onChange={this.uploadFile}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.videoType === "url" && (
                    <div className="row col-lg-12">
                      <div className="col-lg-12">
                        <label className="col-form-label">
                          {tid("Video External Link(AWS Key/YouTube Video URL)")}:
                        </label>
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          className="form-control form-control-user"
                          value={this.state.videoUrl}
                          name="videoUrl"
                          onChange={this.setFormField.bind(this)}
                          placeholder={tid("Enter External URL")}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-secondary btn-sm"
                    data-dismiss="modal"
                  >
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid("Close")}</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ml-1 btn-sm"
                    onClick={() =>
                      this.onClickUpload(this.state.file, this.state.thumbnail)
                    }
                  >
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">
                      {" "}
                      {this.state.uploadingVideo && (
                        <IconLoader
                          size={2}
                          text=""
                          color={"#fff"}
                        ></IconLoader>
                      )}
                      &nbsp; {tid("Save")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade text-left"
            id="material-upload"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="myModalLabel1">
                    {tid("Upload Material")}
                  </h3>
                  <button
                    type="button"
                    className="close rounded-pill"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {tid("Material Title")}:
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        value={this.state.materialTitle}
                        name="materialTitle"
                        onChange={this.setFormField.bind(this)}
                        placeholder={tid("Enter Material Title")}
                      />
                      {this.materialValidator.message(
                        "File Material Title",
                        this.state.materialTitle,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group row align-items-center t-0 b-0">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {tid("Material File")}:
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="file"
                        name="myFile"
                        className="form-control"
                        onChange={this.uploadMaterialFile}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-secondary btn-sm"
                    data-dismiss="modal"
                  >
                    <i className="bx bx-x d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">{tid("Close")}</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ml-1 btn-sm"
                    onClick={() =>
                      this.onClickUploadMaterial(this.state.materialFile)
                    }
                  >
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">
                      {" "}
                      {this.state.uploadMaterial && (
                        <IconLoader
                          size={2}
                          text=""
                          color={"#fff"}
                        ></IconLoader>
                      )}
                      &nbsp; {tid("Save")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(ChapterDetailsComponent);
