import React from "react";
import "chart.js";
import moment from "moment";
import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl, RAZOR_PAY_TEST_KEY } from "../../../config";
import { get, create, getUserData } from "../../../utils";
import "./fee.scss";
import { WithTranslations } from "../../../i18n/withTranslations";

class StudentPaymentsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: {},

      total: 0,
      paid: 0,
      due: 0,

      totalPending: 0,

      dues: [],
      payments: [],
      userType: "",
    };
  }

  assignUserData() {
    let { userType } = getUserData();
    this.setState({ userType });
  }

  componentDidMount() {
    this.assignUserData();
    this.getHistory();
  }

  getHistory() {
    get(baseUrl + "payments/fee").then((response) => {
      let { payments, dues, totalPending } = response.data.data;
      let paid = this.getSum(payments);
      let due = this.getSum(dues);
      this.setState({
        payments,
        dues,
        total: paid + due,
        paid,
        due,
        totalPending,
      });
    });
  }

  getSum(list) {
    let sum = 0;
    list.forEach((item) => {
      sum += parseInt(item.amount, 10);
    });
    return sum;
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" });
  }

  render() {
    const { userType } = this.state;
    const { tid } = this.props;
    const isSecondaryDashboard =
      userType === "parent" || userType === "student";

    const paymentHandler = async (e) => {
      const API_URL = baseUrl;
      e.preventDefault();
      const orderUrl = `${API_URL}payments/order`;
      const response = await get(orderUrl);
      const { orderID, amount, name, description } = response.data.data;
      console.log(response.data.data);
      const options = {
        key: RAZOR_PAY_TEST_KEY,
        name,
        description,
        order_id: orderID,
        handler: async (response) => {
          try {
            const paymentId = response.razorpay_payment_id;
            const url = `${API_URL}payments/payment/${paymentId}`;
            const captureResponse = await create(url, "post", { amount });
          } catch (err) {
            console.log(err);
          }
        },
        theme: {
          color: "#686CFD",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    };

    return (
      <div
        id="wrapper"
        className={`admin-panel ${
          isSecondaryDashboard ? "secondary-panel" : ""
        }`}
      >
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container mb-5">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{tid(`Fee Details`)}</h2>
            </div>
          </div>
          <div className="fee-details-main-container">
            <nav class="responsive-tab style-4">
              <ul
                data-submenu-title="Elements"
                uk-switcher="connect: #elements-nav ;animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
              >
                <li>
                  <a>{tid(`Dues`)}</a>
                </li>
                <li>
                  <a>{tid(`Payments`)}</a>
                </li>
              </ul>
            </nav>
            <div className="fee-data-table-container">
              <ul class="uk-switcher" id="elements-nav">
                <li className="np">
                  <table className="uk-table uk-table-divider np">
                    <thead>
                      <tr className="table-header-row">
                        {userType === "parent" && <th>{tid(`Children Name`)} </th>}
                        <th>{tid(`Amount`)} </th>
                        <th>{tid(`Status`)}</th>
                        <th>{tid(`Title`)}</th>
                        <th>{tid(`Description`)}</th>
                        <th>{tid(`Due Date`)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dues.map((due, index) => {
                        return (
                          <tr
                            className="table-data-row"
                            key={`table-fee-data-row${index}`}
                          >
                            {userType === "parent" && (
                              <td>{due.userID.firstName}</td>
                            )}
                            <td>{due.amount}</td>
                            <td>{due.status}</td>
                            <td>{due.title}</td>
                            <td>{due.description}</td>
                            <td>{moment(due.dueDate).format("DD-MM-YYYY")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="5">
                          {tid(`Total Payable Amount:`)}
                          <b>{this.state.totalPending || 0}</b>
                          {this.state.totalPending > 0 && (
                            <a
                              className="btn btn-info btn-xs white-font"
                              onClick={paymentHandler}
                            >
                              {tid(`Pay Now`)}
                            </a>
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </li>

                <li className="np">
                  <table className="uk-table uk-table-divider np">
                    <thead>
                      <tr className="table-header-row">
                        {userType === "parent" && <th>{tid(`Children Name`)} </th>}
                        <th>{tid(`Amount`)}</th>
                        <th>{tid(`Status`)}</th>
                        <th>{tid(`Paid Mode`)}</th>
                        <th>{tid(`Comment`)}</th>
                        <th>{tid(`Paid Date`)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.payments.map((due, index) => {
                        return (
                          <tr
                            className="table-data-row"
                            key={`table-fee-payments-data-row${index}`}
                          >
                            {userType === "parent" && (
                              <td>{due.userID.firstName}</td>
                            )}
                            <td>{due.amount}</td>
                            <td>{due.status}</td>
                            <td>{due.mode}</td>
                            <td>{due.comment}</td>
                            <td>{moment(due.paidDate).format("DD-MM-YYYY")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WithTranslations(StudentPaymentsComponent);
