import React from "react";
import SVG from 'react-inlinesvg';
import './principal.scss';
import swal from 'sweetalert2';

import { baseUrl } from "../../../config";
import { get, create } from "../../../utils";

import { PrincipalCharts, RevenueReportChart } from "./charts/principal-charts";
import NoResults from "../../shared/no-results/no-results";
import { LanguageContext } from "../../../i18n/LanguageProvider";

const getID=(s)=>s.toUpperCase().replace(/[\W_]+/g,"_");

const colors = ['rgba(55,125,255,.2)', 'rgba(222,68,55,.2)', 'rgba(0,201,167,.2)', 'rgba(255,193,7,.2)', 'rgba(0,223,252,.2)']

export default class PrincipalDashboardComponent extends React.Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      stats: [
        {
          name: 'Classes',
          value: 0,
          icon: '/assets/images/dashboard/class.svg'
        },
        {
          name: 'Students',
          value: 0,
          icon: '/assets/images/dashboard/student.svg'
        },
        {
          name: 'Staff',
          value: 0,
          icon: '/assets/images/dashboard/staff.svg'
        },
        {
          name: 'Fees',
          value: 0,
          icon: '/assets/images/dashboard/fees.svg'
        },
        {
          name: 'Books',
          value: 0,
          icon: '/assets/images/dashboard/library.svg'
        },
        {
          name: 'Complaints',
          value: 0,
          icon: '/assets/images/dashboard/complaint.svg'
        }
      ],
      meetings: [],
      revenues: [],
      attendance: [],
      leaveRequests: [],
      birthdays: []
    };
  }

  componentDidMount() {
    this.setState({
      firstName: this.props.firstName,
    });

    get(baseUrl+"dashboard")
    .then((response) => {
      let { stats, meetings, revenues, attendance, leaveRequests, dobs } = response.data.data;
      let statsData = [];
      this.state.stats.forEach((stat) => {
        stat.value = (stats && stats[stat.name.toLowerCase()])?stats[stat.name.toLowerCase()]:0;
        statsData.push(stat);
      })
      this.setState({
        stats: statsData,
        meetings,
        revenues,
        attendance,
        leaveRequests,
        birthdays: dobs
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }

  approveLeave(leaveID, type, title) {
    swal.fire({
      title: "Are you sure?",
      text: 'Are you sure want to ' + title + '?',
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((response) => {
        if (response.value) {
          create(baseUrl + "leaves/" + leaveID, 'put', {
            status: type
          })
            .then((response) => {
              this.getLeaves();
              this.setState({
                showSection: 'leaves',
                leaveType: 'add'
              })
            })
            .catch((error) => {
            });
        }
      })
  }

  render() {
    const { firstName } = this.state;
    const { content } = this.context;
    const meetings = this.state.meetings;

    const birthdays = this.state.birthdays;

    const leaveRequests = this.state.leaveRequests;

    return (
      <div className='dashboard-main-container'>
        <div className='db-top-numbers-list-container'>
          {this.state.stats && this.state.stats.map((s, index) => {
            const { name, value, icon} = s;
            return (
              <div key={"stat-"+index} className='stat-card-container'>
                <div className='stat-left-side-section' style={{backgroundColor: colors[index]}}>
                  <SVG src={icon} />
                </div>
                <div className='stat-right-side-section'>
                  <h3>{value}</h3>
                  <h5>{content[getID(name)] || name}</h5>
                </div>
              </div>
            )
          })}
        </div>
        <div className='dashboard-flex-container'>
          <div className='db-left-side-section'>
            <div className='db-left-flex-container'>
              <div className='db-attendance-percentange-graph'>
                <h4>{content.REVENUE_REPORT||`Revenue Report`}</h4>
                <RevenueReportChart data={this.state.revenues} />
              </div>
              <div className='db-performance-percentange-graph'>
                <h4>{content.CURRENT_WEEK_ATTENDANCE||`Attendance Report for the Week`}</h4>
                <PrincipalCharts data = {this.state.attendance}/>
              </div>
            </div>
            <div className='db-left-flex-container'>
              <div className='leave-request-container'>
                <h4>{content.LEAVE_REQUESTS||'Leave Requests'}</h4>
                <div className='leave-request-list'>
                  {leaveRequests && leaveRequests.map((l, index) => {
                    const { name, fromDate, toDate, days, approved, profilePicUrl } = l;
                    return (
                      <div key={`leave-request-${index}`} className='leave-request-row'>
                        <div className="xs-sec">
                          <img src={profilePicUrl || '/assets/images/avatars/avatar-2.jpg'} alt={name} className="l-r-image" />
                        </div>
                        <div className="big-sec">
                          <h5 className='l-r-name'>{name}</h5>
                        </div>
                        <div className="small-sec">
                          <h5 className='l-r-date'>{fromDate}</h5>
                        </div>
                        <div className="small-sec">
                          <h5 className='l-r-date'>{toDate}</h5>
                        </div>
                        <div className="small-sec">
                          <h5 className='l-r-date'>{`${days} day${days > 1 ? 's' : ''}`}</h5>
                        </div>
                        <div className='large-sec'>
                          <button className='l-r-approve pointer' onClick={() => this.approveLeave(l._id, 'approved', 'approve')}>
                            {content.APPROVE||'Approve'} <i className="uil-check-circle" />
                          </button>
                          <button className='l-r-reject pointer' onClick={() => this.approveLeave(l._id, 'rejected', 'reject')}>
                            {content.REJECT ||'Reject'}<i className="uil-times-circle" />
                          </button>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {leaveRequests && leaveRequests.length === 0 && <NoResults title={content.NO_LEAVE_REQUESTS||"No Leave Requests"} /> }
              </div>
            </div>
          </div>
          <div className='db-right-side-section'>
            <div className='db-upcoming-events-container'>
              <h4>{content.UPCOMING_MEETINGS||'Upcoming Meetings'}</h4>
              <div className='meetings-list-main-container'>
                {meetings && meetings.map((m, index) => {
                  const { date, month, title, time } = m;
                  return (
                    <div key={"meeting-"+index} className='meeting-list-flex-section'>
                      <div className='meeting-left-side'>
                        <div className='meeting-top-container'>
                          <span className='meeting-top-month-name'>{month}</span>
                        </div>
                        <div className='meeting-bottom-container'>
                          <span className='meeting-bottom-date-name'>{date}</span>
                        </div>
                      </div>
                      <div className='meeting-right-side'>
                        <h5>{title}</h5>
                        <p>{time}</p>
                      </div>
                    </div>
                  ) 
                })}
                {meetings && meetings.length === 0 && <NoResults title={content.NO_UPCOMING_MEETINS||"No Upcoming Meetings" }/> }
              </div>
            </div>
            <div className='db-upcoming-birthdays-container'>
              <h4>{content.UPCOMING_BIRTHDAYS||'Upcoming Birthdays'}</h4>
              <div className='meetings-list-main-container'>
                {birthdays && birthdays.map((b, index) => {
                  const { name, dob } = b;
                  return (
                    <div key={"birthday-"+index} className='meeting-list-flex-section'>
                      <div className='meeting-left-side'>
                        <img src={b.profilePicUrl || '/assets/images/avatars/avatar-2.jpg'} alt={name} className='user-img' />
                      </div>
                      <div className='meeting-right-side'>
                        <h5>{name}</h5>
                        <p>{dob}</p>
                      </div>
                    </div>
                  ) 
                })}
                {birthdays && birthdays.length === 0 && <NoResults title={content.NO_UPCOMING_BIRTHDAYS||"No Upcoming Birthdays"}/> }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
