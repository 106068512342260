import React from "react";
import swal from 'sweetalert2';

// import {  Link } from "react-router-dom";
import * as $ from "jquery";
import SimpleReactValidator from 'simple-react-validator';

import { IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";

import { baseUrl } from "../../../config";
import { get, getUserData, create, uploadToS3, remove, hasPermission } from "../../../utils";
import './library.scss'
import PdfReader from "../../shared/pdf-reader/pdf-reader";
import { WithTranslations } from "../../../i18n/withTranslations";

class LibraryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      books: [],
      name: '',
      author: '',
      bookType: '',
      className: '',
      uploadType: '',
      file: '',
      loading: false,
      classBooks: [],
      generalBooks: [],
      classIndex: 0,
      generalBookIndex: 0,
      previewBook: {},
      uploading: false,
      showPdfReader: false
    }
    this.save = this.save.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.bookValidator = new SimpleReactValidator({});
    this.assignUserData = this.assignUserData.bind(this)
  }

  assignUserData() {
    let { firstName, lastName, userType, userID, classID } = getUserData();
    this.setState({ firstName, lastName, userType, userID, classID });
  }

  save() {
    if (this.bookValidator.allValid()) {
      this.setState({
        uploading: true
      })
      let fileName = "books/" + this.state.bookType + "/" + (this.state.bookType === 'class' ? this.state.className + '/' : '') + this.state.file.name;
      const uploadPDF = new Promise((resolve, reject) => {
        resolve(uploadToS3(fileName, this.state.file, this.state.file.type));
      })

      const fileUploader = new Promise((resolve, reject) => {

        resolve(create(baseUrl + "library/add", "post", {
          name: this.state.name,
          author: this.state.author,
          bookType: this.state.bookType,
          className: this.state.className,
          uploadType: this.state.uploadType,
          fileKey: fileName
        }))
      })

      Promise.all([
        uploadPDF, fileUploader
      ])
        .then((response) => {
          this.libraryList();
          this.reset();
          $("file").val('')
          $('#add-book').trigger('click');
        })
        .catch((error) => {
          console.log(error)
        })
    } else{
      this.bookValidator.showMessages();
      this.forceUpdate();
    }
  }

  reset() {
    this.bookValidator.hideMessages();
    this.setState({
      uploading: false,
      name: '',
      author: '',
      bookType: '',
      className: '',
      uploadType: '',
      fileKey: ''
    })
  }

  setFormField(event) {
    this.setState({ [event.target.name]: event.target.value, message: "" })
  }

  uploadFile(event) {
    this.setState({ file: event.target.files[0] })
  }

  componentDidMount() {
    this.libraryList();
    this.assignUserData();
  }

  libraryList() {
    get(baseUrl + "library/list")
      .then((response) => {
        this.setState({
          classBooks: response.data.data.classBooks,
          generalBooks: response.data.data.generalBooks
        })
      })
      .catch((error) => {
      });
  }

  onPreview(book) {
    this.setState({
      previewBook: book,
      showPdfReader: true,
    })
  }

  toggleReader = () => {
    this.setState({
      showPdfReader: false
    })
  }

  onDeleteBook(bookID, from) {
    swal.fire({
      title: "Are you sure?",
      text: "Want to remove the book?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((response) => {
        if (response.value) {
          remove(baseUrl + "library/" + bookID + "/" + from)
            .then((response) => {
              this.libraryList();
            })
            .catch((error) => {
            });
        }
      });

  }

  render() {
    const { classBooks, generalBooks, userType, showPdfReader } = this.state;
    const {tid}=this.props
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper" className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <div className="app-page-header-section">
            <div className="app-page-header-left">
              <h2 className="">{tid("Library")}</h2>
            </div>
            <div className="app-page-header-right">
              {hasPermission('library', 'create') && <button className="app-add-option-button" data-toggle="modal" data-target="#add-book" onClick={() => this.reset()}><i className="uil-plus"></i> {tid("Add Book")}</button>}
            </div>
          </div>
          <div className='library-books-container'>
            <div className='lib-book-section-header'>
              {tid("Class Books")}
            </div>
            <div className='lib-class-books-list-container'>
              {classBooks && classBooks.map((cb, index) => {
                const {books, className} = cb;
                return (
                  <div className='class-books-main-container'>
                    <div className='class-heding'>
                      {className}
                    </div>
                    <div className='class-books-list-flex-container'>
                      {books && books.map((b, ind) => {
                        const { name, author = 'NA' } = b;
                        return (
                          <div key={`class-book-list-${ind}`} className='class-book-card'>
                            {hasPermission('library', 'remove') && <i className="icon-feather-x btn btn-danger btn-xs float-right pointer" onClick={() => this.onDeleteBook(b._id, b.from)}></i>}
                            <div className='book-image-section'>
                              <img src='/assets/images/pdf.png' />
                            </div>
                            <div className='book-details-section'>
                              <h4 onClick={() => this.onPreview(b)} data-toggle="modal" data-target="#preview-book" className='book-name'>{name}</h4>
                              <p className='author-name'>{tid("Author")}: {author}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ) 
              })}
            </div>
            <div className='library-books-container-list' style={{ marginTop: 15 }}>
              <div className='lib-book-section-header'>
                {tid("General Books")} 
              </div>
              <div className='lib-class-books-list-container'>
                {generalBooks && generalBooks.map((gb, index) => {
                  const {books, author} = gb;
                  return (
                    <div className='class-books-main-container'>
                      <div className='class-heding'>
                        {author}
                      </div>
                      <div className='class-books-list-flex-container'>
                        {books && books.map((b, ind) => {
                          const { name, author = 'NA' } = b;
                          return (
                            <div key={`class-book-list-${ind}`} className='class-book-card'>
                              {hasPermission('library', 'remove') && <i className="icon-feather-x btn btn-danger btn-xs float-right pointer" onClick={() => this.onDeleteBook(b._id, b.from)}></i>}
                              <div className='book-image-section'>
                                <img src='/assets/images/pdf.png'/>
                              </div>
                              <div className='book-details-section'>
                                <h4 onClick={() => this.onPreview(b)} data-toggle="modal" data-target="#preview-book" className='book-name'>{name}</h4>
                                <p className='author-name'>{tid("Author")}: {author}</p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ) 
                })}
              </div>
            </div>
          </div>
          <div className="content-body pt-1">
            <section id="dashboard-ecommerce">
              {/* <div className="container">
                <div uk-grid={"true"}>
                  <div className="uk-width@m">
                    <div className="section-header np border-0 uk-flex-middle">
                      <div className="section-header-left">
                        <h4 className="uk-heading-line text-left"><span> Class Books </span></h4>
                      </div>
                      <div className="section-header-right">
                        <select className="ml-3 form-control" name="classIndex" onChange={this.setFormField.bind(this)}>
                          {this.state.classBooks.map((className, index) => {
                            return (<option key={"classbook-" + index} value={index}> {className.className} </option>)
                          })}
                        </select>
                      </div>
                    </div>
                    {(this.state.classBooks.length > 0) &&
                      <div className="row col-lg-12 np">
                        {this.state.classBooks[this.state.classIndex].books.map((book, index) => {
                          return (
                            <div key={"book-" + index} className={"col-lg-4 blue-font "}>
                              <h6 className="clearfix bshadow0 pbs library-book">
                                <span onClick={() => this.onPreview(book)} data-toggle="modal" data-target="#preview-book" className="mls blue-font pointer"> {book.name}</span>
                                <i className="icon-feather-x float-right pointer" onClick={() => this.onDeleteBook(book._id, book.from)}></i>
                                <br />
                                <small><b>Author: </b>{book.author ? book.author : 'NA'}</small>
                              </h6>
                            </div>)
                        })}
                      </div>}
                  </div>
                </div>

                <div uk-grid={"true"}>
                  <div className="uk-width@m">
                    <div className="section-header np border-0 uk-flex-middle">
                      <div className="section-header-left">
                        <h4 className="uk-heading-line text-left"><span> General Books </span></h4>
                      </div>
                      <div className="section-header-right">
                        <select className="ml-3 form-control" name="generalBookIndex" onChange={this.setFormField.bind(this)}>
                          {this.state.generalBooks.map((generalBook, index) => {
                            return (<option key={"generalbook-" + index} value={index}> {generalBook.author} </option>)
                          })}
                        </select>
                      </div>

                    </div>
                    {(this.state.classBooks.length > 0) &&
                      <div className="row col-lg-12 np">
                        {this.state.generalBooks[this.state.generalBookIndex].books.map((book, index) => {
                          return (
                            <div key={"general-book-" + index} className={"col-lg-4 blue-font "}>
                              <h6 className="clearfix bshadow0 pbs library-book">
                                <span onClick={() => this.onPreview(book)} data-toggle="modal" data-target="#preview-book" className="mls blue-font pointer"> {book.name}</span>
                                <i className="icon-feather-x float-right pointer" onClick={() => this.onDeleteBook(book._id, book.from)}></i>
                                <br />
                                <small><b>Author: </b>{book.author ? book.author : 'NA'}</small>
                              </h6>
                            </div>)
                        })}
                      </div>}
                  </div>
                </div>
              </div> */}

              {/* <div className="modal fade text-left uk-modal-full" id="preview-book" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <iframe src={this.state.previewBook.fileUrl} frameBorder="0" height="450" title={this.state.previewBook.title} width="100%"></iframe>
                    </div>
                  </div>
                </div>
              </div> */}
              {showPdfReader && <PdfReader closeReader={this.toggleReader} pdfUrl={this.state.previewBook.fileUrl} /> }
              <div className="modal fade text-left" id="add-book" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h3 className="modal-title" id="myModalLabel1">{tid("Add Book")} </h3>
                      <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                        <i className="fa fa-close"></i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group row align-items-center t-0 b-0">
                        <div className="col-lg-4">
                          <label className="col-form-label">{tid("Book Name")} :</label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control form-control-user"
                            value={this.state.name}
                            name="name"
                            onChange={this.setFormField.bind(this)}
                            placeholder={tid("Enter Book Name")}
                          />
                          {this.bookValidator.message(
                            "Book Name",
                            this.state.name,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="form-group row align-items-center t-0 b-0">
                        <div className="col-lg-4">
                          <label className="col-form-label">{tid("Author Name")}:</label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control form-control-user"
                            value={this.state.author}
                            name="author"
                            onChange={this.setFormField.bind(this)}
                            placeholder={tid("Enter Author Name")}
                          />
                          {this.bookValidator.message(
                            "Author Name",
                            this.state.author,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="form-group row align-items-center t-0 b-0">
                        <div className="col-lg-4">
                          <label className="col-form-label">{tid("Book Type")}:</label>
                        </div>
                        <div className="col-lg-8">
                          <select
                            type="text"
                            className="form-control form-control-user"
                            value={this.state.bookType}
                            name="bookType"
                            onChange={this.setFormField.bind(this)}
                            placeholder={tid("Enter Book Type")}
                          >
                            <option value="">{tid("Select Book Type")} </option>
                            <option value="class">{tid("Class Syllabus Book")} </option>
                            <option value="general">{tid("General Book")} </option>
                          </select>
                          {this.bookValidator.message(
                            "Book Type",
                            this.state.bookType,
                            "required"
                          )}
                        </div>
                      </div>

                      {this.state.bookType === 'class' && <div className="form-group row align-items-center t-0 b-0">
                        <div className="col-lg-4">
                          <label className="col-form-label">{tid("Class Name")} :</label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control form-control-user"
                            value={this.state.className}
                            name="className"
                            onChange={this.setFormField.bind(this)}
                            placeholder={tid("Enter Class Name")}
                          />
                          {this.bookValidator.message(
                            "Class Name",
                            this.state.className,
                            "required"
                          )}
                        </div>
                      </div>}

                      <div className="form-group row align-items-center t-0 b-0">
                        <div className="col-lg-4">
                          <label className="col-form-label">{tid("Upload Type")} :</label>
                        </div>
                        <div className="col-lg-8">
                          <select
                            type="text"
                            className="form-control form-control-user"
                            value={this.state.uploadType}
                            name="uploadType"
                            onChange={this.setFormField.bind(this)}
                            placeholder={tid("Enter Upload Type")}
                          >
                            <option value="">{tid("Select Upload Type")} </option>
                            <option value="private">{tid("Private")}</option>
                            <option value="public">{tid("Public")}</option>
                          </select>
                          {this.bookValidator.message(
                            "Upload Type",
                            this.state.uploadType,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="form-group row align-items-center t-0 b-0">
                        <div className="col-lg-4">
                          <label className="col-form-label">{tid("Upload Book")} :</label>
                        </div>
                        <div className="col-lg-8">
                          <input type="file" id="file" name="myFile" className="form-control" onChange={this.uploadFile} />
                          {this.bookValidator.message(
                            "File",
                            this.state.file,
                            "required"
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-light-secondary btn-sm" data-dismiss="modal">
                        <i className="bx bx-x d-block d-sm-none"></i>
                        <span className="d-none d-sm-block">{tid("Close")}</span>
                      </button>
                      <button type="button" className="btn btn-primary ml-1 btn-sm" onClick={this.save.bind(this)}>
                        {this.state.uploading && <IconLoader size={2} text="" color={"#fff"}></IconLoader>}&nbsp;{tid("Save")}
                            </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default WithTranslations(LibraryComponent);