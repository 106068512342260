import React from "react";

import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";

import { getUserData } from "../../../utils";

import AcademicYearsListComponent from "./academic-years-list";
import { WithTranslations } from "../../../i18n/withTranslations";

class AcademicYearsComponent extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        classID: "",
        userType: ""
      }
    }

    assignUserData() {
      let { firstName, lastName, userType, userID, classID } = getUserData();
      this.setState({ firstName, lastName, userType, userID})
      if(userType !== 'student') {
        classID = this.props.match.params.classID;
      }
      this.setState({
        classID
      });
    }

    componentDidMount() {
      this.assignUserData();
    }

    render() {
      const { userType } = this.state;
      const {tid}=this.props;
      const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
      return (
      <div id="wrapper" className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
          <Sidebar></Sidebar>
          <Navbar></Navbar>
          <div className="page-content app-dashboard-details-main-container">
            <AcademicYearsListComponent></AcademicYearsListComponent>
          </div>
        </div>
      );
    }
}

export default WithTranslations(AcademicYearsComponent);