import React from "react";
import Navbar from "../shared/website-navbar/Navbar";
import Footer from "../shared/website-footer/Footer";

const PrivacyPolicyIt = () => {
  const bgStyles = {
    backgroundImage:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(62,65,109, 0.95)), url(/assets/images/privacy.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: 550,
  };
  return (
    <div className="homepage-main-container">
      <Navbar />
      <div className="about-us-info-container" style={bgStyles}>
        <div className="about-us-info-section">
          <h4>Controlla il nostro</h4>
          <h2>politica sulla riservatezza</h2>
        </div>
      </div>
      <div className="about-us-details-container">
        <div className="about-us-main-content">
          <div class="mt-lg-12">
            <article class="uk-article">
              <p>Data di validità: 2020-08-14</p>
              <p>
                1.
                <b>Introduzione</b>
              </p>
              <p>
                Benvenuto in
                <b>TeTu</b>.
              </p>
              <p>
                <b>TeTu</b> ("noi", "noi" o "nostro") gestisce <b>tetu.co.in</b>{" "}
                (di seguito denominato <b>"Servizio"</b>
                ).
              </p>
              <p>
                La nostra Informativa sulla privacy regola la tua visita a{" "}
                <b>tetu.co.in</b> e spiega come raccogliamo, salvaguardiamo e
                divulghiamo le informazioni che derivano dall'utilizzo del
                nostro Servizio.
              </p>
              <p>
                Utilizziamo i tuoi dati per fornire e migliorare il Servizio.{" "}
                Utilizzando il Servizio, accetti la raccolta e l'uso delle
                informazioni in conformità con questa politica. Se non
                diversamente definito nella presente Informativa sulla privacy,
                i termini utilizzati nella presente Informativa sulla privacy
                hanno lo stesso significato dei nostri Termini e condizioni.
              </p>
              <p>
                I nostri Termini e condizioni ( <b>"Termini"</b> ) regolano
                l'utilizzo del nostro Servizio e, insieme all'Informativa sulla
                privacy, costituiscono il tuo accordo con noi ( <b>"accordo"</b>
                ).
              </p>
              <p>
                2.
                <b>Definizioni</b>
              </p>
              <p>
                <b>SERVIZIO</b> indica il sito web tetu.co.in gestito da TeTu.
              </p>
              <p>
                <b>DATI PERSONALI</b> indica i dati su una persona vivente che
                può essere identificata da quei dati (o da quelli e altre
                informazioni in nostro possesso o che potrebbero entrare in
                nostro possesso).
              </p>
              <p>
                <b>I DATI DI UTILIZZO</b> sono dati raccolti automaticamente
                generati dall'utilizzo del Servizio o dall'infrastruttura del
                Servizio stesso (ad esempio, la durata della visita di una
                pagina).
              </p>
              <p>
                <b>I COOKIE</b> sono piccoli file memorizzati sul tuo
                dispositivo (computer o dispositivo mobile).
              </p>
              <p>
                <b>TITOLARE DEL TRATTAMENTO</b> indica una persona fisica o
                giuridica che (da sola o congiuntamente o in comune con altre
                persone) determina le finalità per le quali e il modo in cui i
                dati personali sono o devono essere trattati. Ai fini della
                presente Informativa sulla privacy, siamo il titolare del
                trattamento dei tuoi dati.
              </p>
              <p>
                <b>
                  PER RESPONSABILE DEL TRATTAMENTO (O FORNITORI DI SERVIZI) si
                </b>{" "}
                intende qualsiasi persona fisica o giuridica che tratta i dati
                per conto del Titolare. Potremmo utilizzare i servizi di vari
                fornitori di servizi per elaborare i tuoi dati in modo più
                efficace.
              </p>{" "}
              <p>
                <b>L'INTERESSATO</b> è qualsiasi individuo vivente che è oggetto
                di dati personali.
              </p>
              <p>
                <b>L'UTENTE</b> è l'individuo che utilizza il nostro servizio.{" "}
                L'Utente corrisponde all'Interessato, che è oggetto di Dati
                Personali.
              </p>
              <p>
                3.
                <b>Raccolta e utilizzo delle informazioni</b>
              </p>
              <p>
                Raccogliamo diversi tipi di informazioni per vari scopi per
                fornire e migliorare il nostro servizio.
              </p>
              <p>
                4.
                <b>Tipi di dati raccolti</b>
              </p>
              <p>
                <b>Dati personali</b>
              </p>
              <p>
                Durante l'utilizzo del nostro Servizio, potremmo chiederti di
                fornirci alcune informazioni di identificazione personale che
                possono essere utilizzate per contattarti o identificarti ({" "}
                <b>"Dati personali"</b>
                ). Le informazioni di identificazione personale possono
                includere, ma non sono limitate a:
              </p>
              <p>0.1. Indirizzo email</p>
              <p>0.2. Nome e cognome</p>
              <p>0.3. Numero di telefono</p>
              <p>
                0.4. Indirizzo, paese, stato, provincia, codice postale, città
              </p>
              <p>0,5. Cookie e dati di utilizzo</p>
              <p>
                Potremmo utilizzare i tuoi dati personali per contattarti con
                newsletter, materiali di marketing o promozionali e altre
                informazioni che potrebbero interessarti. Puoi scegliere di non
                ricevere alcuna o tutte queste comunicazioni da noi seguendo il
                link di annullamento dell'iscrizione.
              </p>
              <p>
                <b>Dati di utilizzo</b>
              </p>
              <p>
                Potremmo anche raccogliere informazioni che il tuo browser invia
                ogni volta che visiti il ​​nostro Servizio o quando accedi al
                Servizio da o tramite qualsiasi dispositivo ({" "}
                <b>"Dati di utilizzo"</b>
                ).
              </p>
              <p>
                Questi Dati di utilizzo possono includere informazioni come
                l'indirizzo del protocollo Internet del tuo computer (ad es.
                Indirizzo IP), il tipo di browser, la versione del browser, le
                pagine del nostro servizio che visiti, l'ora e la data della tua
                visita, il tempo trascorso su quelle pagine, unico
                identificatori del dispositivo e altri dati diagnostici.
              </p>
              <p>
                Quando accedi al Servizio con un dispositivo, questi Dati di
                utilizzo possono includere informazioni come il tipo di
                dispositivo che utilizzi, l'ID univoco del tuo dispositivo,
                l'indirizzo IP del tuo dispositivo, il sistema operativo del tuo
                dispositivo, il tipo di browser Internet che utilizzi,
                dispositivo unico identificatori e altri dati diagnostici.
              </p>
              <p>
                <b>Tracciamento dei dati dei cookie</b>
              </p>
              <p>
                Utilizziamo cookie e tecnologie di tracciamento simili per
                tracciare l'attività sul nostro Servizio e conserviamo
                determinate informazioni.
              </p>
              <p>
                I cookie sono file con una piccola quantità di dati che possono
                includere un identificatore univoco anonimo. I cookie vengono
                inviati al tuo browser da un sito web e memorizzati sul tuo
                dispositivo. Vengono utilizzate anche altre tecnologie di
                tracciamento come beacon, tag e script per raccogliere e tenere
                traccia delle informazioni e per migliorare e analizzare il
                nostro Servizio.
              </p>
              <p>
                Puoi indicare al tuo browser di rifiutare tutti i cookie o di
                indicare quando viene inviato un cookie. Tuttavia, se non
                accetti i cookie, potresti non essere in grado di utilizzare
                alcune parti del nostro Servizio.
              </p>
              <p>Esempi di cookie che utilizziamo:</p>
              <p>
                0.1.
                <b>Cookie di sessione:</b> utilizziamo i cookie di sessione per
                gestire il nostro servizio.
              </p>
              <p>
                0.2.
                <b>Cookie di preferenza:</b> utilizziamo i cookie di preferenza
                per ricordare le tue preferenze e varie impostazioni.
              </p>
              <p>
                0.3.
                <b>Cookie di sicurezza:</b> utilizziamo cookie di sicurezza per
                scopi di sicurezza.
              </p>
              <p>
                0.4.
                <b>Cookie pubblicitari: i cookie</b> pubblicitari vengono
                utilizzati per fornire annunci pubblicitari che possono essere
                pertinenti per te e per i tuoi interessi.
              </p>
              <p>
                <b>Altri dati</b>
              </p>
              <p>
                Durante l'utilizzo del nostro Servizio, possiamo anche
                raccogliere le seguenti informazioni: sesso, età, data di
                nascita, luogo di nascita, dettagli del passaporto,
                cittadinanza, registrazione nel luogo di residenza e indirizzo
                effettivo, numero di telefono (lavoro, cellulare), dettagli dei
                documenti su istruzione, qualifica, formazione professionale,
                contratti di lavoro, accordi di non{" "}
                <a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=en&amp;tl=it&amp;u=https://policymaker.io/non-disclosure-agreement/">
                  divulgazione
                </a>{" "}
                , informazioni su bonus e indennità, informazioni sullo stato
                civile, familiari, numero di previdenza sociale (o altro
                identificativo del contribuente), sede dell'ufficio e altri
                dati.
              </p>
              <p>
                5.
                <b>Utilizzo dei dati</b>
              </p>
              <p>TeTu utilizza i dati raccolti per vari scopi:</p>
              <p>0.1. per fornire e mantenere il nostro servizio;</p>
              <p>0.2. per informarti sulle modifiche al nostro Servizio;</p>
              <p>
                0.3. per consentirti di partecipare alle funzionalità
                interattive del nostro Servizio quando scegli di farlo;
              </p>
              <p>0.4. fornire assistenza ai clienti;</p>
              <p>
                0,5. per raccogliere analisi o informazioni preziose in modo da
                poter migliorare il nostro servizio;
              </p>
              <p>0.6. monitorare l'utilizzo del nostro servizio;</p>
              <p>0.7. rilevare, prevenire e affrontare problemi tecnici;</p>
              <p>
                0.8. per adempiere a qualsiasi altro scopo per cui lo fornisci;
              </p>
              <p>
                0.9. per adempiere ai nostri obblighi e far valere i nostri
                diritti derivanti da eventuali contratti stipulati tra te e noi,
                anche per la fatturazione e l'incasso;
              </p>
              <p>
                0.10. per fornirti avvisi sul tuo account e / o abbonamento,
                inclusi avvisi di scadenza e rinnovo, istruzioni e-mail, ecc .;
              </p>
              <p>
                0.11. per fornirti notizie, offerte speciali e informazioni
                generali su altri beni, servizi ed eventi che offriamo simili a
                quelli che hai già acquistato o richiesto a meno che tu non
                abbia scelto di non ricevere tali informazioni;
              </p>
              <p>
                0.12. in qualsiasi altro modo che possiamo descrivere quando
                fornisci le informazioni;
              </p>
              <p>0.13. per qualsiasi altro scopo con il tuo consenso.</p>
              <p>
                6.
                <b>Conservazione dei dati</b>
              </p>
              <p>
                Conserveremo i tuoi dati personali solo per il tempo necessario
                agli scopi indicati nella presente Informativa sulla privacy.{" "}
                Conserveremo e utilizzeremo i tuoi dati personali nella misura
                necessaria per adempiere ai nostri obblighi legali (ad esempio,
                se ci viene richiesto di conservare i tuoi dati per rispettare
                le leggi applicabili), risolvere controversie e far rispettare i
                nostri accordi e politiche legali.
              </p>
              <p>
                Conserveremo anche i dati di utilizzo per scopi di analisi
                interna. I dati di utilizzo vengono generalmente conservati per
                un periodo più breve, tranne quando questi dati vengono
                utilizzati per rafforzare la sicurezza o per migliorare la
                funzionalità del nostro servizio, o siamo legalmente obbligati a
                conservare questi dati per periodi di tempo più lunghi.
              </p>
              <p>
                7.
                <b>Trasferimento dei dati</b>
              </p>
              <p>
                Le tue informazioni, inclusi i dati personali, possono essere
                trasferite a - e mantenute su - computer situati al di fuori del
                tuo stato, provincia, paese o altra giurisdizione governativa in
                cui le leggi sulla protezione dei dati possono differire da
                quelle della tua giurisdizione.
              </p>
              <p>
                Se ti trovi al di fuori dell'India e scegli di fornirci
                informazioni, tieni presente che trasferiamo i dati, inclusi i
                dati personali, in India e li elaboriamo lì.
              </p>
              <p>
                Il tuo consenso alla presente Informativa sulla privacy seguito
                dall'invio di tali informazioni rappresenta il tuo consenso a
                tale trasferimento.
              </p>
              <p>
                TeTu adotterà tutte le misure ragionevolmente necessarie per
                garantire che i tuoi dati siano trattati in modo sicuro e in
                conformità con la presente Informativa sulla privacy e nessun
                trasferimento dei tuoi Dati personali avrà luogo a
                un'organizzazione o un paese a meno che non siano in atto
                controlli adeguati, inclusa la sicurezza di i tuoi dati e altre
                informazioni personali.
              </p>
              <p>
                8.
                <b>Divulgazione dei dati</b>
              </p>
              <p>
                Potremmo divulgare le informazioni personali che raccogliamo o
                fornisci:
              </p>
              <p>
                0.1.
                <b>Transazione d'affari.</b>
              </p>
              <p>
                Se noi o le nostre sussidiarie siamo coinvolti in una fusione,
                acquisizione o vendita di attività, i tuoi dati personali
                potrebbero essere trasferiti.
              </p>
              <p>
                0.2.
                <b>Altri casi. Potremmo divulgare le tue informazioni anche:</b>
              </p>
              <p>0.2.1. alle nostre sussidiarie e affiliate;</p>
              <p>
                0.2.2. ad appaltatori, fornitori di servizi e altre terze parti
                che utilizziamo per supportare la nostra attività;
              </p>
              <p>0.2.3. per adempiere allo scopo per il quale lo fornite;</p>
              <p>
                0.2.4. allo scopo di includere il logo della tua azienda sul
                nostro sito web;
              </p>
              <p>
                0.2.5. per qualsiasi altro scopo da noi divulgato quando fornite
                le informazioni;
              </p>
              <p>0.2.6. con il tuo consenso in tutti gli altri casi;</p>
              <p>
                0.2.7. se riteniamo che la divulgazione sia necessaria o
                appropriata per proteggere i diritti, la proprietà o la
                sicurezza della Società, dei nostri clienti o di altri.
              </p>
              <p>
                9.
                <b>Sicurezza dei dati</b>
              </p>
              <p>
                La sicurezza dei tuoi dati è importante per noi, ma ricorda che
                nessun metodo di trasmissione su Internet o metodo di
                archiviazione elettronica è sicuro al 100%. Sebbene ci sforziamo
                di utilizzare mezzi commercialmente accettabili per proteggere i
                tuoi dati personali, non possiamo garantirne l'assoluta
                sicurezza.
              </p>
              <p>
                10. I
                <b>
                  tuoi diritti sulla protezione dei dati ai sensi del
                  Regolamento generale sulla protezione dei dati (GDPR)
                </b>
              </p>
              <p>
                Se risiedi nell'Unione Europea (UE) e nello Spazio economico
                europeo (SEE), disponi di determinati diritti di protezione dei
                dati, coperti dal GDPR.
              </p>
              <p>
                Miriamo ad adottare misure ragionevoli per consentirti di
                correggere, modificare, eliminare o limitare l'uso dei tuoi dati
                personali.
              </p>
              <p>
                Se desideri essere informato sui tuoi dati personali in nostro
                possesso e se desideri che vengano rimossi dai nostri sistemi,
                inviaci un'e-mail a <b>contact@tetu.co.in</b>.
              </p>
              <p>
                In determinate circostanze, hai i seguenti diritti di protezione
                dei dati:
              </p>
              <p>
                0.1. il diritto di accedere, aggiornare o cancellare le
                informazioni che abbiamo su di te;
              </p>
              <p>
                0.2. il diritto di rettifica. Hai il diritto di far rettificare
                le tue informazioni se tali informazioni sono inesatte o
                incomplete;
              </p>
              <p>
                0.3. il diritto di opporsi. Hai il diritto di opporti al nostro
                trattamento dei tuoi dati personali;
              </p>
              <p>
                0.4. il diritto di restrizione. Hai il diritto di richiedere che
                limitiamo il trattamento delle tue informazioni personali;
              </p>
              <p>
                0,5. il diritto alla portabilità dei dati. Hai il diritto di
                ricevere una copia dei tuoi dati personali in un formato
                strutturato, leggibile da una macchina e di uso comune;
              </p>
              <p>
                0.6. il diritto di revocare il consenso. Hai anche il diritto di
                revocare il tuo consenso in qualsiasi momento in cui ci basiamo
                sul tuo consenso per elaborare le tue informazioni personali;
              </p>
              <p>
                Tieni presente che potremmo chiederti di verificare la tua
                identità prima di rispondere a tali richieste. Si prega di
                notare che potremmo non essere in grado di fornire il Servizio
                senza alcuni dati necessari.
              </p>
              <p>
                Hai il diritto di presentare reclamo a un'autorità per la
                protezione dei dati in merito alla nostra raccolta e utilizzo
                dei tuoi dati personali. Per ulteriori informazioni, contattare
                l'autorità locale per la protezione dei dati nello Spazio
                economico europeo (SEE).
              </p>
              <p>
                11. I
                <b>
                  tuoi diritti sulla protezione dei dati ai sensi del California
                  Privacy Protection Act (CalOPPA)
                </b>
              </p>
              <p>
                CalOPPA è la prima legge statale della nazione a richiedere ai
                siti Web commerciali e ai servizi online di pubblicare una
                politica sulla privacy. La portata della legge si estende ben
                oltre la California per richiedere a una persona o società negli
                Stati Uniti (e immaginabile nel mondo) che gestisce siti Web che
                raccolgono informazioni di identificazione personale dai
                consumatori della California di pubblicare una politica sulla
                privacy ben visibile sul proprio sito Web che dichiari
                esattamente le informazioni raccolte e quelle persone con cui
                viene condiviso e di conformarsi a questa politica.
              </p>
              <p>Secondo CalOPPA accettiamo quanto segue:</p>
              <p>
                0.1. gli utenti possono visitare il nostro sito in modo anonimo;
              </p>
              <p>
                0.2. il nostro collegamento alla Privacy Policy include la
                parola "Privacy" e può essere facilmente trovato sulla home page
                del nostro sito web;
              </p>
              <p>
                0.3. gli utenti saranno informati di eventuali modifiche alla
                politica sulla privacy nella nostra pagina della politica sulla
                privacy;
              </p>
              <p>
                0.4. gli utenti possono modificare le proprie informazioni
                personali inviandoci un'e-mail a <b>contact@tetu.co.in</b>.
              </p>
              <p>La nostra politica sui segnali "Do Not Track":</p>
              <p>
                Rispettiamo i segnali Do Not Track e non tracciamo, piantiamo
                cookie o utilizziamo pubblicità quando è in atto un meccanismo
                del browser Do Not Track. Do Not Track è una preferenza che puoi
                impostare nel tuo browser web per informare i siti web che non
                vuoi essere tracciato.
              </p>
              <p>
                Puoi abilitare o disabilitare Do Not Track visitando la pagina
                Preferenze o Impostazioni del tuo browser web.
              </p>
              <p>
                12. I
                <b>
                  tuoi diritti sulla protezione dei dati ai sensi del California
                  Consumer Privacy Act (CCPA)
                </b>
              </p>
              <p>
                Se risiedi in California, hai il diritto di conoscere quali dati
                raccogliamo su di te, chiedere di cancellare i tuoi dati e non
                venderli (condividerli). Per esercitare i tuoi diritti di
                protezione dei dati, puoi fare determinate richieste e
                chiederci:
              </p>
              <p>
                <b>
                  0.1. Quali informazioni personali abbiamo su di te. Se fai
                  questa richiesta, ti ricontatteremo:
                </b>
              </p>
              <p>
                0.0.1. Le categorie di informazioni personali che abbiamo
                raccolto su di te.
              </p>
              <p>
                0.0.2. Le categorie di fonti da cui raccogliamo le tue
                informazioni personali.
              </p>
              <p>
                0.0.3. Lo scopo aziendale o commerciale per la raccolta o la
                vendita delle informazioni personali.
              </p>
              <p>
                0.0.4. Le categorie di terze parti con cui condividiamo le
                informazioni personali.
              </p>
              <p>
                0.0.5. Le parti specifiche di informazioni personali che abbiamo
                raccolto su di te.
              </p>
              <p>
                0.0.6. Un elenco di categorie di informazioni personali che
                abbiamo venduto, insieme alla categoria di qualsiasi altra
                azienda a cui le abbiamo vendute. Se non abbiamo venduto le tue
                informazioni personali, ti informeremo di questo fatto.
              </p>
              <p>
                0.0.7. Un elenco di categorie di informazioni personali che
                abbiamo divulgato per scopi commerciali, insieme alla categoria
                di qualsiasi altra azienda con cui le abbiamo condivise.
              </p>
              <p>
                Tieni presente che hai il diritto di chiederci di fornirti
                queste informazioni fino a due volte in un periodo di dodici
                mesi consecutivi. Quando effettui questa richiesta, le
                informazioni fornite potrebbero essere limitate alle
                informazioni personali che abbiamo raccolto su di te nei 12 mesi
                precedenti.
              </p>
              <p>
                <b>
                  0.2. Per eliminare le tue informazioni personali. Se effettui
                  questa richiesta, elimineremo le informazioni personali in
                  nostro possesso su di te alla data della tua richiesta dai
                  nostri archivi e invieremo eventuali fornitori di servizi a
                  fare lo stesso. In alcuni casi, la cancellazione può essere
                  eseguita mediante la deidentificazione delle informazioni. Se
                  scegli di eliminare le tue informazioni personali, potresti
                  non essere in grado di utilizzare alcune funzioni che
                  richiedono che le tue informazioni personali funzionino.
                </b>
              </p>
              <p>
                <b>
                  0.3. Per interrompere la vendita delle tue informazioni
                  personali. Non vendiamo o affittiamo le tue informazioni
                  personali a terze parti per nessuno scopo. Non vendiamo le tue
                  informazioni personali a titolo oneroso. Tuttavia, in alcune
                  circostanze, un trasferimento di informazioni personali a una
                  terza parte, o all'interno della nostra famiglia di società,
                  senza corrispettivo monetario può essere considerato una
                  "vendita" ai sensi della legge della California. Sei l'unico
                  proprietario dei tuoi Dati Personali e puoi richiederne la
                  divulgazione o la cancellazione in qualsiasi momento.
                </b>
              </p>
              <p>
                Se invii una richiesta per interrompere la vendita delle tue
                informazioni personali, smetteremo di effettuare tali
                trasferimenti.
              </p>
              <p>
                Tieni presente che se ci chiedi di eliminare o interrompere la
                vendita dei tuoi dati, ciò potrebbe influire sulla tua
                esperienza con noi e potresti non essere in grado di partecipare
                a determinati programmi o servizi di abbonamento che richiedono
                l'utilizzo delle tue informazioni personali per funzionare. Ma
                in nessun caso ti discrimineremo per aver esercitato i tuoi
                diritti.
              </p>
              <p>
                Per esercitare i diritti di protezione dei dati della California
                sopra descritti, inviare le richieste tramite e-mail:{" "}
                <b>contact@tetu.co.in</b>.
              </p>
              <p>
                I tuoi diritti di protezione dei dati, descritti sopra, sono
                coperti dal CCPA, abbreviazione di California Consumer Privacy
                Act. Per saperne di più, visitare il sito Web ufficiale delle
                informazioni legislative sulla California. Il CCPA è entrato in
                vigore il 01/01/2020.
              </p>
              <p>
                13.
                <b>Fornitori di servizi</b>
              </p>
              <p>
                Possiamo impiegare società e individui di terze parti per
                facilitare il nostro Servizio ( <b>"Fornitori di servizi"</b> ),
                fornire il Servizio per nostro conto, eseguire servizi relativi
                al Servizio o aiutarci ad analizzare come viene utilizzato il
                nostro Servizio.
              </p>
              <p>
                Queste terze parti hanno accesso ai tuoi dati personali solo per
                eseguire queste attività per nostro conto e sono obbligate a non
                rivelarle o utilizzarle per nessun altro scopo.
              </p>
              <p>
                14.
                <b>Analisi</b>
              </p>
              <p>
                Possiamo utilizzare fornitori di servizi di terze parti per
                monitorare e analizzare l'utilizzo del nostro servizio.
              </p>
              <p>
                15.
                <b>Strumenti CI / CD</b>
              </p>
              <p>
                Possiamo utilizzare fornitori di servizi di terze parti per
                automatizzare il processo di sviluppo del nostro servizio.
              </p>
              <p>
                16.
                <b>Remarketing comportamentale</b>
              </p>
              <p>
                Potremmo utilizzare i servizi di remarketing per fare pubblicità
                su siti Web di terzi dopo che hai visitato il nostro Servizio.{" "}
                Noi ei nostri fornitori di terze parti utilizziamo i cookie per
                informare, ottimizzare e offrire annunci in base alle tue
                passate visite al nostro Servizio.
              </p>
              <p>
                17.
                <b>Pagamenti</b>
              </p>
              <p>
                Possiamo fornire prodotti e / o servizi a pagamento all'interno
                del Servizio. In tal caso, utilizziamo servizi di terze parti
                per l'elaborazione dei pagamenti (ad es. Processori di
                pagamento).
              </p>
              <p>
                Non memorizzeremo né raccoglieremo i dati della tua carta di
                pagamento. Tali informazioni vengono fornite direttamente ai
                nostri processori di pagamento di terze parti il ​​cui utilizzo
                delle informazioni personali è regolato dalla loro Informativa
                sulla privacy. Questi processori di pagamento aderiscono agli
                standard stabiliti da PCI-DSS come gestito dal PCI Security
                Standards Council, che è uno sforzo congiunto di marchi come
                Visa, Mastercard, American Express e Discover. I requisiti
                PCI-DSS aiutano a garantire la gestione sicura delle
                informazioni di pagamento.
              </p>
              <p>
                18.
                <b>Collegamenti ad altri siti</b>
              </p>
              <p>
                Il nostro servizio può contenere collegamenti ad altri siti che
                non sono gestiti da noi. Se fai clic su un collegamento di terze
                parti, verrai indirizzato al sito di quella terza parte. Ti
                consigliamo vivamente di rivedere l'Informativa sulla privacy di
                ogni sito che visiti.
              </p>
              <p>
                Non abbiamo alcun controllo e non ci assumiamo alcuna
                responsabilità per il contenuto, le politiche sulla privacy o le
                pratiche di siti o servizi di terze parti.
              </p>
              <p>
                Ad esempio, l'
                <a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=en&amp;tl=it&amp;u=https://policymaker.io/privacy-policy/">
                  informativa sulla privacy
                </a>
                delineata è stata realizzata utilizzando{" "}
                <a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=en&amp;tl=it&amp;u=https://policymaker.io/">
                  PolicyMaker.io
                </a>{" "}
                , uno strumento gratuito che aiuta a creare documenti legali di
                alta qualità. Il
                <a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=en&amp;tl=it&amp;u=https://policymaker.io/privacy-policy/">
                  generatore
                </a>
                di
                <a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=en&amp;tl=it&amp;u=https://policymaker.io/privacy-policy/">
                  norme
                </a>
                sulla
                <a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=en&amp;tl=it&amp;u=https://policymaker.io/privacy-policy/">
                  privacy
                </a>
                di PolicyMaker è uno strumento di facile utilizzo per creare una{" "}
                <a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=en&amp;tl=it&amp;u=https://policymaker.io/blog-privacy-policy/">
                  politica sulla privacy per blog
                </a>{" "}
                , sito Web, negozio di e-commerce o app mobile.
              </p>
              <p>
                19.
                <b>
                  <b>Privacy dei bambini</b>
                </b>
              </p>
              <p>
                I nostri Servizi non sono destinati all'uso da parte di bambini
                di età inferiore ai 18 anni ( <b>"Bambini"</b>o<b>"Bambini"</b>
                ).
              </p>
              <p>
                Non raccogliamo consapevolmente informazioni di identificazione
                personale da bambini di età inferiore a 18 anni. Se vieni a
                conoscenza che un bambino ci ha fornito dati personali, ti
                preghiamo di contattarci. Se veniamo a conoscenza del fatto che
                abbiamo raccolto Dati personali da bambini senza la verifica del
                consenso dei genitori, adottiamo misure per rimuovere tali
                informazioni dai nostri server.
              </p>
              <p>
                20.
                <b>Modifiche alla presente informativa sulla privacy</b>
              </p>
              <p>
                Potremmo aggiornare la nostra politica sulla privacy di volta in
                volta. Ti informeremo di eventuali modifiche pubblicando la
                nuova Informativa sulla privacy in questa pagina.
              </p>
              <p>
                Ti informeremo via e-mail e / o un avviso ben visibile sul
                nostro Servizio, prima che la modifica diventi effettiva e
                aggiorneremo la "data di validità" nella parte superiore della
                presente Informativa sulla privacy.
              </p>
              <p>
                Si consiglia di rivedere periodicamente la presente Informativa
                sulla privacy per eventuali modifiche. Le modifiche alla
                presente Informativa sulla privacy sono efficaci quando vengono
                pubblicate su questa pagina.
              </p>
              <p>
                21.
                <b>Contattaci</b>
              </p>
              <p>
                In caso di domande sulla presente Informativa sulla privacy,
                contattaci tramite e-mail: <b>contact@tetu.co.in</b>.
              </p>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicyIt;
