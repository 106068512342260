import React from "react";
import SVG from 'react-inlinesvg';
import { baseUrl } from "../../../config";
import { get } from "../../../utils";
import { subjectIcons } from "../../../constants";

import { StudentPerformanceChart, StudentAttendanceChart } from "./charts/student-charts";
import './student.scss';
import NoResults from "../../shared/no-results/no-results";
import { WithTranslations } from "../../../i18n/withTranslations";

const colors = ['rgba(55,125,255,.2)', 'rgba(222,68,55,.2)', 'rgba(0,201,167,.2)', 'rgba(255,193,7,.2)', 'rgba(0,223,252,.2)']


class StudentDashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: "",
      classID: "",
      subjects: [],
      stats: [
        {
          name: 'Subjects',
          value: 0,
          icon: '/assets/images/dashboard/student.svg'
        },
        {
          name: 'Books',
          value: 0,
          icon: '/assets/images/dashboard/library.svg'
        },
        {
          name: 'Doubts',
          value: 0,
          icon: '/assets/images/dashboard/doubt.svg'
        },
        {
          name: 'Tests',
          value: 0,
          icon: '/assets/images/dashboard/doubt.svg'
        },
        {
          name: 'Quiz',
          value: 0,
          icon: '/assets/images/dashboard/doubt.svg'
        }
      ],
      attendance: [],
      timetable: [],
      testsData: [],
      doubts: [],
      assignments: [],
      performance: []
    }
  }

  componentDidMount() {
    
    this.setState({
      firstName: this.props.firstName,
    });

    get(baseUrl+"dashboard")
    .then((response) => {
      let { stats, subjects, attendance, timetable, testsData, doubts, assignments, performance } = response.data.data;
      let statsData = [];

      this.state.stats.forEach((stat) => {
        stat.value = (stats && stats[stat.name.toLowerCase()])?stats[stat.name.toLowerCase()]:0;
        statsData.push(stat);
      });

      subjects.forEach((subject) => {
        let index = subjectIcons.findIndex(s => s.name.toLowerCase() == subject.name);
        if(index >= 0)
          subject.image = subjectIcons[index].image;
      });

      this.setState({
        subjects: subjects,
        stats: statsData,
        attendance,
        timetable,
        testsData,
        doubts, 
        assignments, 
        performance
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }

  render() {
    const {tid}=this.props;

    const timetable = this.state.timetable;

    const upcomingTests = this.state.testsData;

    const assignments = this.state.assignments;

    const doubts = this.state.doubts;

    const { firstName } = this.props
    return (
      <div className='dashboard-main-container'>
        <div className='db-student-top-flex-container'>
          <div className='db-s-left-side-section'>
            <h4>{tid('Subjects List')}</h4>
            <div className='db-subjects-list-container'>
              {this.state.subjects &&
                this.state.subjects.map((s, index) => {
                  const {
                    name,
                    chaptersCount,
                    image,
                  } = s;
                  const colorIndex = (index % 5)
                  return (
                    <div className='subject-card-container' style={{backgroundColor: colors[colorIndex]}}>
                      <div className='db-l-left-side-section'>
                        <h4>{name}</h4>
                        <h5>{chaptersCount || '0'} {tid('Chapters')}</h5>
                        <p>{tid('Assignments')}</p>
                      </div>
                      {/* <div className='db-r-right-side-section'>
                        <img src={image || '/assets/images/logo_v3.jpg'} alt={name} />
                      </div> */}
                    </div>
                  );
                })}
            </div>
            {(!this.state.subjects || !this.state.subjects.length) && <NoResults title={tid("No Subjects Found")} /> }
          </div>
          <div className='db-s-right-side-section'>
            <div className='db-s-upcoming-events-container'>
              <h4>{tid(`Today's Timetable`)}</h4>
              <div className='meetings-list-main-container'>
                {timetable && timetable.map((m, index) => {
                  const { name, time } = m;
                  return (
                    <div className='meeting-list-flex-section'>
                      <div className='meeting-left-side'>
                        <div className='meeting-top-container'>
                          <span className='meeting-top-month-name'>{tid('Period')}</span>
                        </div>
                        <div className='meeting-bottom-container'>
                          <span className='meeting-bottom-date-name'>{index + 1}</span>
                        </div>
                      </div>
                      <div className='meeting-right-side'>
                        <h5>{name}</h5>
                        <p>{time}</p>
                      </div>
                    </div>
                  ) 
                })}
                {(!timetable || !timetable.length) && <NoResults title={tid("Timetable Not Found")} /> }
              </div>
            </div>
          </div>
        </div>
        <div className='db-student-bottom-flex-container'>
          <div className='db-s-left-side-section'>
            <div className='db-attendance-percentange-graph'>
              <h4>{tid(`Performance Report`)}</h4>
              <StudentPerformanceChart performance={this.state.performance} />
            </div>
            <div className='db-performance-percentange-graph'>
              <h4>{tid(`Attendance Report`)}</h4>
              <StudentAttendanceChart attendance={this.state.attendance} />
            </div>
          </div>
          <div className='db-s-right-side-section'>
            <div className='db-s-upcoming-events-container'>
              <h4>{tid(`Upcoming Tests`)}</h4>
              <div className='meetings-list-main-container'>
                {upcomingTests && upcomingTests.map((m, index) => {
                  const { title, duration, subject, month, date } = m;
                  return (
                    <div className='meeting-list-flex-section'>
                      <div className='meeting-left-side'>
                        <div className='meeting-top-container'>
                          <span className='meeting-top-month-name'>{month}</span>
                        </div>
                        <div className='meeting-bottom-container'>
                          <span className='meeting-bottom-date-name'>{date}</span>
                        </div>
                      </div>
                      <div className='meeting-right-side'>
                        <h5>{title}</h5>
                        <p>{subject} | {duration} mins</p>
                      </div>
                    </div>
                  ) 
                })}
                {(!upcomingTests || upcomingTests.length === 0) && <NoResults title={tid("No Upcoming Tests")}  /> }
              </div>
            </div>
          </div>
        </div>
        <div className='db-s-top-numbers-list-container'>
          {this.state.stats && this.state.stats.map((s, index) => {
            const { name, value, icon} = s;
            return (
              <div className='stat-card-container'>
                <div className='stat-left-side-section' style={{backgroundColor: colors[index]}}>
                  <SVG src={icon} />
                </div>
                <div className='stat-right-side-section'>
                  <h3>{value}</h3>
                  <h5>{tid(name)}</h5>
                </div>
              </div>
            )
          })}
        </div>
        <div className='db-student-last-flex-container'>
          <div className='db-s-last-left-container'>
            <h4>{tid(`Quiz, Tests & Assignment Status`)}</h4>
            <div className='db-s-quiz-status-container scroll'>
              {assignments && assignments.map((t, index) => {
                const { title, marks, toDate } = t;
                return (
                  <div key={`quiz-status-${index}`} className='quiz-status-row'>
                    <div className="big-sec">
                      <h5 className='l-r-name'>{title}</h5>
                    </div>
                    <div className="small-sec">
                      <h5 className='l-r-date'>{toDate}</h5>
                    </div>
                    <div className="small-sec">
                      <h5 className='l-r-date'>{marks}</h5>
                    </div>
                    <div className='action-sec'>
                      {<button className='l-r-approve'>
                      {tid(`View`)} <i className="uil-arrow-right" />
                      </button>}
                    </div>
                  </div>
                )
              })}
              {(!assignments || assignments.length === 0) && <NoResults title={tid(`No Quizes, Tests & Assignments Found`)}  /> }
            </div>
          </div>
          <div className='db-s-last-right-container'>
            <h4>{tid(`Doubts Status`)}</h4>
            <div className='db-s-doubt-status-container'>
              {doubts && doubts.map((t, index) => {
                const { title, answered, createdAt } = t;
                return (
                  <div key={`doubt-status-${index}`} className='doubt-status-row'>
                    <div className="big-sec">
                      <h5 className='l-r-name'>{title}</h5>
                    </div>
                    <div className="small-sec">
                      <h5 className='l-r-date'>{createdAt}</h5>
                    </div>
                    <div className='action-sec'>
                      {answered && <button className='l-r-approve'>
                        {tid(`View`)} <i className="uil-arrow-right" />
                      </button>}
                      {!answered && <button className='l-r-reject'>
                        {tid(`Remind`)} <i className="uil-clock" />
                      </button>}
                    </div>
                  </div>
                )
              })}
              {(!doubts || doubts.length === 0) && <NoResults title={tid(`No Doubts Found`)}  /> }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default  WithTranslations(StudentDashboardComponent)