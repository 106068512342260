import React from "react";
// import swal from 'sweetalert2';
// import {  Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
import Navbar from "../shared/navbar/Navbar";
import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get, getUserData } from "../../../utils";
import TestDataComponent from './tests-list';
import './tests.scss'

class TestsListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classID: "",
      subjectID: "",
      className: '',
      subjectName: ''
    }
  }

  componentDidMount() {
    let classID = this.props.match.params.classID;
    let subjectID = this.props.match.params.subjectID;
    this.setState({
      classID,
      subjectID
    })
    this.getTestsList(classID, subjectID);
    this.assignUserData();
  }

  assignUserData = () => {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  getTestsList(classID, subjectID) {
    get(baseUrl + "tests/list/" + classID + "/" + subjectID)
      .then((response) => {
        this.setState({
          years: response.data.data,
          classID: response.data.class._id,
          className: response.data.class.name,
          subjectID: response.data.subject._id,
          subjectName: response.data.subject.name
        })
      })
      .catch((error) => {
      });
  }

  render() {
    const { userType } = this.state
    const isSecondaryDashboard = (userType === 'parent' || userType === 'student')
    return (
      <div id="wrapper"  className={`admin-panel ${isSecondaryDashboard ? 'secondary-panel' : ''}`}>
        <Sidebar></Sidebar>
        <Navbar></Navbar>
        <div className="page-content app-dashboard-details-main-container">
          <TestDataComponent display={true} classID={this.props.match.params.classID} subjectID={this.props.match.params.subjectID} />
        </div>
      </div>
    );
  }
}

export default TestsListComponent;