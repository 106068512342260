import React from "react";
// import swal from 'sweetalert2';
import { Link } from "react-router-dom";
// import * as $ from "jquery";
// import SimpleReactValidator from 'simple-react-validator';
// import { Loader, IconLoader } from "../../shared/loader";
// import Navbar from "../shared/navbar/Navbar";
// import Sidebar from "../shared/sidebar/sidebar";
import { baseUrl } from "../../../config";
import { get, getUserData } from "../../../utils";

class TestReviewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testID: "",
      name: "",
      duration: 0,
      questions: [],
      currentQuestion: {},
      currentQuestionIndex: -1,
      instructions: [],
      totalMarks: 0,
      marks: 0,
      timeTaken: {}
    }
  }

  assignUserData() {
    let { firstName, lastName, userType, userID } = getUserData();
    this.setState({ firstName, lastName, userType, userID });
  }

  componentDidMount() {
    this.assignUserData();
    let testID = this.props.match.params.testID;
    this.setState({
      testID
    })

    this.getQuestions(testID);
  }

  getQuestions(testID) {
    get(baseUrl + "tests/review/" + (this.state.testID || testID))
      .then((response) => {
        let { name, questions, duration, totalMarks, marks, instructions, timeTaken } = response.data.data;

        this.setState({
          timeTaken,
          totalMarks,
          marks,
          name,
          questions,
          duration, instructions
        })

        this.startTest();
      })
      .catch((error) => {

      });
  }

  startTest() {
    this.setState({
      currentQuestion: this.state.questions[0],
      currentQuestionIndex: 0
    })
  }

  onClickActions(type) {
    let counter = 0;
    if (type === 'previous')
      counter = -1;
    else
      counter = 1;
    let currentQuestionIndex = this.state.currentQuestionIndex + counter;
    let question = this.state.questions[currentQuestionIndex];
    this.setState({
      currentQuestion: question,
      currentQuestionIndex: currentQuestionIndex
    })
  }

  render() {
    return (
      <div className='app-test-review-page-main-container'>
        <div className='atr-top-flex-container'>
          <div className='atr-left-side'>
            <div className="atr-page-header">
              <h4 className="card-title">
                <span onClick={() => this.props.history.goBack()}><i className="uil-arrow-left pointer"></i></span>
                {this.state.name}
              </h4>
              <div className='score-bubble-section'>
                <div className='score-bubble'>
                  <span className='top'>
                    {this.state.marks}
                  </span>
                  <span className='bottom'>
                    {this.state.totalMarks}
                  </span>
                </div>
              </div>
              <div className='time-taken'>Time taken: {this.state.timeTaken.hours < 10 ? "0" + this.state.timeTaken.hours : "00"}h : {this.state.timeTaken.minutes < 10 ? "0" + this.state.timeTaken.minutes : "00"}m : {this.state.timeTaken.seconds < 10 ? "0" + this.state.timeTaken.seconds : "00"}s</div>

            </div>
            <div className='app-ot-questions-main-container'>
              <h6 className='ot-question-number-heading'>Question {this.state.currentQuestionIndex + 1} of {this.state.questions.length}:</h6>
              {(this.state.currentQuestion && this.state.currentQuestion._id) && <div className="">
                {(this.state.currentQuestion.questionType !== 'matching') &&
                  <div className="question-section">{this.state.currentQuestion.question}</div>}
                {this.state.currentQuestion.questionType === 'matching' && <div className='match-the-following-question'>
                  <h6 className='mtf-heading'>Match the following:</h6>
                  <div className="mtf-flex">
                    <div className="mtf-left-side">
                      <label className="mtf-left-heading">(A)</label>
                      {this.state.currentQuestion.matchingA.map((option, index) => {
                        return (<div key={"matchingA-" + index} className="mtf-value">
                          <span>
                            {option.optionTitle})
                          </span>
                          <span htmlFor={"option-" + index}>
                            {option.question}
                          </span>
                        </div>)
                      })}
                    </div>
                    <div className="mtf-right-side">
                      <label className="mtf-right-heading">(B)</label>
                      {this.state.currentQuestion.matchingB.map((option, index) => {
                        return (<div key={"matchingB-" + index} className="mtf-value">
                            <span>
                              {option.optionTitle})
                            </span>                            
                            <span htmlFor={"option-" + index}>
                              {option.question}
                            </span>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>}
                {(this.state.currentQuestion.questionType !== 'blanks') && (this.state.currentQuestion.questionType !== 'matching') && (this.state.currentQuestion.questionType !== 'essay') && <div>
                  <ul className="list-unstyled mb-0 question-options-list-container">
                    {this.state.currentQuestion.options && this.state.currentQuestion.options.map((option, index) => {
                      return (<li className="">
                        <div className="ot-q-option-checkbox-row">
                          <input type="checkbox"
                            disabled
                            id={"checkboxshadow" + index}
                            name={"options#selected#" + index}
                            value={option.selected}
                            checked={option.selected} />
                            <label htmlFor={"checkboxshadow" + index}>{option.value}</label>
                        </div>
                      </li>)
                    })}
                  </ul>
                </div>}
                {this.state.currentQuestion.questionType === 'essay' && <div>
                  <textarea className="form-control" rows="8" disabled
                    value={this.state.currentQuestion.essayAnswer}
                    name="essayAnswer"
                  ></textarea>
                </div>}
                {
                  this.state.currentQuestion.questionType === 'blanks' && <div className="row col-lg-12">
                    <div className="col-lg-6 np">
                      <fieldset className="form-group">
                        {this.state.currentQuestion.blanks.map((answer, index) => {
                          return (<input key={"currentQuestion-" + index} type="text" className="form-control p10"
                            value={answer}
                            disabled
                            name={"blanks#" + index}
                            placeholder={"Enter Answer for Blank " + (index + 1)}
                          />)
                        })}
                      </fieldset>
                    </div>
                  </div>
                }
                {
                  this.state.currentQuestion.questionType === 'matching' && <div className="matches-with-options-container">
                    {this.state.currentQuestion.matchingA.map((option, i) => {
                      return (
                        <div className="flex-container">
                          <div className="left-side">
                            <span className="">{option.optionTitle}) Matches With <i className="icon-feather-arrow-right"></i> </span>
                          </div>
                          <div className="right-side">
                            {this.state.currentQuestion.matchingB.map((option, j) => {
                              return (
                                <button type="button" className={"btn ml-1 " + ((this.state.currentQuestion && this.state.currentQuestion.answers && (this.state.currentQuestion.answers[i] === j)) ? " btn-default" : " btn-outline-default")}>{option.optionTitle}</button>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                }
                <div className='ot-q-extra-info-text-section'>
                  {this.state.currentQuestion.answerType !== 'skipped' && <span>You have choosen {this.state.currentQuestion.answerType === 'correct' ? <b className="green-text">Correct Answer</b> : <b className="red-text">Wrong Answer</b>}</span>}
                  {this.state.currentQuestion.answerType === 'skipped' && <span>You have <b className="info-text">skipped</b> this question.</span>}
                  <span className="pull-right blue-font bold">Your Score: {this.state.currentQuestion.marks}/{this.state.currentQuestion.totalMarks}</span>
                </div>
                <div className="pt-5 ot-q-buttons-container">
                  {(this.state.currentQuestionIndex > 0) && <button type="button" className="btn btn-danger previous-button" onClick={() => this.onClickActions('previous')}><i className="fa fa-angle-left"></i> <span className="align-middle ml-25">Previous</span></button>}
                  {(this.state.currentQuestionIndex < (this.state.questions.length - 1)) && <button type="button" className="btn btn-primary float-right pull-right next-button" onClick={() => this.onClickActions('next')}><span className="align-middle ml-25">Next </span><i className="fa fa-angle-right"></i></button>}
                </div>
              </div>}
            </div>
          </div>
          <div className='atr-right-side'>
            <div className='atr-q-detail-container'>
              <h4 className="atr-q-heading">Questions:</h4>
              <div className="question-details-row">
                {this.state.questions.map((question, index) => {
                  if (question.answerType !== 'skipped') {
                    return (<button key={"qstn-" + index} type="button" className={"btn btn-icon mr-1 mt-1 " + ((question.answerType === 'correct') ? "btn-success" : "btn-danger")}>{question.index}</button>)
                  } else {
                    return (<button key={"qstn-" + index} type="button" className={"btn btn-icon mr-1 mt-1 btn-outline-info"}>{question.index}</button>);
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestReviewComponent;